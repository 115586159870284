import { Component, OnInit, ViewChild } from '@angular/core';
import { Sort, MatDialog, MatTableDataSource, MatSnackBar } from '@angular/material';
import { PrdctnCrudComponent } from '../../prdctn-crud/prdctn-crud.component';
import { PrdctnService } from '../prdctn.service';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { MatPaginator } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-prdctn-list',
  templateUrl: './prdctn-list.component.html',
  styleUrls: ['./prdctn-list.component.css']
})
export class PrdctnListComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  DataForm: FormGroup;
  repmodel: any = {};
  Dept_nm: any = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private _snackBar: MatSnackBar,
              private PrdctnService: PrdctnService,
              private dialog: MatDialog,
              private adminservice: AdminService,
              private notificationService: NotificationService,
              private hardfunc: HardFuncService,
              private fb: FormBuilder,
			  private _http: HttpClient) { }

  ngOnInit() {
    this.DataForm = this.createFormGroup();
	this.getDeptName();
  }
  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['Slip_no','Tdate','Product_id','Product_nm','Sender_dept', 'Rcvr_dept', 'Qnty','Qnty2', 'Unit', 'Remarks','Action'];
  }
  getData() {
		this.loading = true;
		this.datalist = [];
		var data: any = {};
		this.DataForm.controls.Fdate.patchValue(this.hardfunc.hsConvDt(this.DataForm.get('Fdate').value));
		this.DataForm.controls.Tdate.patchValue(this.hardfunc.hsConvDt(this.DataForm.get('Tdate').value));
		this.PrdctnService.getDatas(this.DataForm.value)
			.subscribe(
				data => {
				console.log(data);
				this.datalist = data;
				this.loading = false;
				this.dataSource = new MatTableDataSource(<any>this.datalist);
				this.DisplayCol();
				this.dataSource.paginator = this.paginator;
				},
				err => {
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description, "dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					}
				}
			)
	}
	getDeptName() {
	let url = this.hardfunc.BaseAPIUrl + "api/Sales/deptssAPI/GetDatas";
	this._http.post(url, this.repmodel)
		.subscribe((data1: any) => {
		this.Dept_nm = data1;
		}, err => {
		console.log(err);
		});
	}
	openDialog(Facmast_id: number, Product_id: number,TranType: string){
	const dialogRef = this.dialog.open(PrdctnCrudComponent, {
		width: '1150px',
		height: '458px',
		data: { id: Facmast_id, product_id: Product_id, TranType: TranType, data: this.datalist }
	});
	dialogRef.afterClosed().subscribe(result => {
		console.log('The dialog was closed');
	});
	}
	DeleteRcd(Facmast_id: number): void {
	this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
		.afterClosed().subscribe(res => {
		if (res) {
			this.loading = true;
			const dialogRef = this.PrdctnService.DeleteFactrans(Facmast_id)
				.subscribe(
					(data: any) => {
						this.loading = false;
						this.notificationService.warn("Data deleted succefully");
						this.getData();
					},
					err => {
						this.loading = false;
						let errstr = this.hardfunc.getError(err);
						console.log(err);
						this.notificationService.warn(errstr);
					}
				)
		}
		});
	}
	
	createFormGroup() {
		return this.fb.group({
		  Fdate: new FormControl(new Date()),
		  Tdate: new FormControl(new Date()),
		  Dept_id: new FormControl(1),
		  TranType: new FormControl('PRDC')
		});
	  }
  openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}

}
