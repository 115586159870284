import {Routes} from '@angular/router';
import {UsrregComponent} from './usrreg/usrreg.component';
import { Component } from '@angular/core';
import { UsrlstComponent } from './usrlst/usrlst.component';
import { ChangePwdComponent } from './change-pwd/change-pwd.component';
import { ChangePwdNewComponent } from './change-pwd-new/change-pwd-new.component';
import { RoleManagementComponent } from './role-management/role-management.component';
import { ModuleManagementComponent } from './module-management/module-management.component';
import { ProfileComponent } from './profile/profile.component';
import { FmoduleComponent } from './fmodule/fmodule.component';
import { FmoduleListComponent } from './fmodule/fmodule-list/fmodule-list.component';
import { UmoduleComponent } from './umodule/umodule.component';
import { FusermastComponent } from './fusermast/fusermast.component';
import { FusermastListComponent } from './fusermast/fusermast-list/fusermast-list.component';
import { SessionDataComponent } from './session-data/session-data.component';
import { DocuRightsComponent } from './module-management/docu-rights/docu-rights.component';


export const AdminRoutes : Routes =[

    {
        path: 'usrreg',
        component: UsrregComponent,
        data: { title: 'User Registration' }
    },
    {
        path: 'usrlst',
        component: UsrlstComponent,
        data: { title: 'Userlist' }
    },
    {
        path:'change-pwd',
        component:ChangePwdComponent,
        data:{title:'Change Password'}
    },
    {
        path:'change-pwd-new',
        component:ChangePwdNewComponent,
        data:{title:'Change Password New'}
    },
    {
        path:'role-management',
        component:RoleManagementComponent,
        data:{title:'Role Management'}
    },
    {
        path:'module-management',
        component:ModuleManagementComponent,
        data:{title:'Module Management'}
    },
    {
        path:'profile',
        component:ProfileComponent,
        data:{title:'Profile Management'}
    },
    {
        path:'fmodule',
        component:FmoduleListComponent,
        data:{title:'fmodule'}
    },
    {
        path:'fusermast',
        component:FusermastListComponent,
        data:{title:'fusermast'}
    },
    {
        path:'session-data',
        component:SessionDataComponent,
        data:{title:'session-data'}
    },
    {
        path:'docu-rights',
        component:DocuRightsComponent,
        data:{title:'docu-rights'}
    }
];