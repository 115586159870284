import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodemastComponent, CodemastCRUDDialog } from './codemast/codemast.component';
import { GstmastComponent, GstmastCRUDDialog } from './gstmast/gstmast.component';
import { RouterModule } from '@angular/router';
import { GeneralRoutes } from './general.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule, MatIconModule, MatCardModule, MatMenuModule, MatProgressBarModule, MatButtonModule, MatChipsModule, MatListModule, MatGridListModule, MatDialogModule, MatToolbarModule, MatSelectModule, MatSidenavModule, MatTooltipModule, MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule, MatCheckboxModule, MatExpansionModule } from '@angular/material';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CommonPipesModule } from 'src/app/Pipes/common/common-pipes.module';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Pdfviewer1Component } from './pdfviewer1/pdfviewer1.component';
import { PartyLookupDialog } from '../shared/partyDialog/dialog';
import { QuillModule } from 'ngx-quill';
//import { PartyListComponent } from '../shared/party/party';
import { IndianCurrencyPipe } from 'src/app/Pipes/common/inr.pipe';
import { IndianRupeesPipe } from 'src/app/Pipes/common/inr_rs.pipe';

import { FirmYearComponent } from './firm-year/firm-year.component';
import { AppInboxComponent } from './email/app-inbox.component';
import { MailComposeComponent } from './email/mail-compose.component';
import { BankEntryComponent } from './bank-entry/bank-entry.component';


const Material = [
  MatInputModule,
  MatIconModule,
  MatCardModule,
  MatMenuModule,
  MatProgressBarModule,
  MatButtonModule,
  MatChipsModule,
  MatListModule,
  MatSidenavModule,
  MatGridListModule,
  MatTooltipModule,
  MatToolbarModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatDialogModule
]



@NgModule({
  declarations: [CodemastComponent,
    CodemastCRUDDialog,
    GstmastComponent,
    GstmastCRUDDialog,
    Pdfviewer1Component,
    PartyLookupDialog,
    //PartyListComponent,
    IndianCurrencyPipe,
    IndianRupeesPipe,
    FirmYearComponent,
    AppInboxComponent,
    MailComposeComponent,
    BankEntryComponent
    //MatTableExporterModule
 
  ],
  entryComponents: [CodemastCRUDDialog, GstmastCRUDDialog, PartyLookupDialog],
  imports: [
    CommonModule,
    FormsModule,
    Material,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    PdfViewerModule,
    QuillModule,

    // Route
    QuillModule.forRoot(),
    RouterModule.forChild(GeneralRoutes)
  ] ,
  exports :[
      // PartyListComponent,
      IndianCurrencyPipe,
      IndianRupeesPipe

  ]
})
export class GeneralModule { }
