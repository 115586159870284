import { Component, OnInit,Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormGroup, FormControl,Validators, PatternValidator  } from '@angular/forms';
import { UsrlstComponent } from '../usrlst/usrlst.component';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-reset-pwd',
  templateUrl: './reset-pwd.component.html',
  styleUrls: ['./reset-pwd.component.css']
})
export class ResetPwdComponent implements OnInit {
  DataForm: FormGroup;
  SaveEvent:any = {};
  loading:boolean = false;
  msgs:any = [];
  ConfirmPassword:string="";

  constructor(
    private _http:HttpClient,
    private service: CommonService, 
    private hardfunc : HardFuncService,
    private notificationService: NotificationService,
    private adminservice : AdminService,
    public dialogRef: MatDialogRef<UsrlstComponent>,
    @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    private _snackBar: MatSnackBar
  ) { 
      this.DataForm = this.createFormGroup();
      this.DataForm.patchValue(
        {"Username":ReceivedData.UserName});
  }
    ngOnInit(){
    }
  

  SaveData() {
    this.loading = true;    
    let url =  this.hardfunc.BaseAPIUrl+"api/UsersAPI/ResetPwd";
    var headers = new HttpHeaders({ 'content-Type':'application/json' });

    this._http.post(url,JSON.stringify(this.DataForm.value),{headers:headers})
      .subscribe(data => {
        this.loading=false;
        this.onClose();
        this.notificationService.success(' Password Changed Successfully... ');
        this.msgs.push({ severity: 'info', summary: 'Success', detail: "Data saved successfully" });       
      },
      err=> {
        this.loading=false;
        console.log(err);
        this.notificationService.warn('Error in saving...');
        this.msgs.push({ severity: 'error', summary: 'Error', detail: "Error in data getting" }); 
        this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
      });
  }
  onClear(){
    
  }

  createFormGroup() {
    return new FormGroup({
      Username: new FormControl('', Validators.required),
      password: new FormControl('', [Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
       ]),
    });
  }

  initializeFormGroup(){

  }

  onClose() {
    this.DataForm.reset();
    this.initializeFormGroup();
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}




 
