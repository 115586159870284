import { Injectable } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FdrService {

  constructor(private hardfunc: HardFuncService,
              private http: HttpClient) { }

  SaveData(data: any){
    data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		let url = this.hardfunc.BaseAPIUrl + "api/Sales/fdsAPI/SaveData";
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  getDatas(data: any){
    data.Firmx = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    let url = this.hardfunc.BaseAPIUrl+"api/Sales/fdsAPI/GetDatas"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }
  GetDatabyId(fd_id: number) {
		let data: any = {};
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		data.fd_id = fd_id;
		let url = this.hardfunc.BaseAPIUrl + "api/Sales/fdsAPI/GetDatabyId?id="+fd_id;
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
  DeleteFdr(Fd_id: number) {
    let url = this.hardfunc.BaseAPIUrl+"api/Sales/fdsAPI/DeleteData?id="+Fd_id;
        return this.http.post(url,null,  { headers: this.hardfunc.PostHeaders() });
  }
}
