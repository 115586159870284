import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-packing-list',
  templateUrl: './packing-list.component.html',
  styleUrls: ['./packing-list.component.css']
})
export class PackingListComponent implements OnInit {

  DataForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.DataForm = this.createFormGroup();
  }
  createFormGroup() {
    return this.fb.group({
      Firm: new FormControl('NFF'),
      Year: new FormControl(),
      Ordno: new FormControl(),
      Oloc: new FormControl(),
      
      Cartoon: new FormControl(),
      Qnt: new FormControl(),
      CmlNo: new FormControl(),
      GrsWeight: new FormControl(),
      
      Cartoon1: new FormControl(),
      To: new FormControl(),
      Nett: new FormControl(),

    });
  }
}
