import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray, FormControlName } from '@angular/forms';
import { ManufacturingService } from '../../manufacturing.services';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment'

@Component({
  selector: 'app-openstock',
  templateUrl: './openstock.component.html',
  styleUrls: ['./openstock.component.css']
})
export class OpenstockComponent implements OnInit {

  loading: boolean = false;
  DataForm: FormGroup;
  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDay();
  minDate = moment(new Date(this.HardFunc.Fdate)).format("YYYY-MM-DD");
  maxDate = this.HardFunc.Tdate;

  constructor(private fb: FormBuilder,
              private ManufacService: ManufacturingService,
              private notificationService: NotificationService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private HardFunc: HardFuncService) { }

  ngOnInit() {
    this.DataForm = this.createStock();
  }

  //1. api/Sales/factransAPI/OpStockUPD
  UpdateStock(){
    this.loading = true;
    let AsOnDate = this.HardFunc.hsConvDt(this.DataForm.get('Tdate').value);
    this.DataForm.controls.Tdate.patchValue(AsOnDate);
    this.ManufacService.UpdateStock(this.DataForm.value)
      .subscribe(
        data => {
          this.loading = false;
          this.notificationService.success(' Data Saved Successfully... ');
        },
        err => {
          let errstr = this.HardFunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  createStock() {
    return this.fb.group({
      product_id: new FormControl(this.ReceivedData.Product_id),
      Tdate: new FormControl(new Date(), Validators.min(moment(new Date()).millisecond())),
      Qnty2: new FormControl(Validators.required),
      Issue_to: new FormControl(this.ReceivedData.Dept),
      TranType: new FormControl('OP')
    });
  }
}
