import { Component } from '@angular/core';
import { HardFuncService } from './Services/Common/hardfunc.service';
import { NotificationService } from './Services/Common/notification.service';
import { AdminService } from './Views/admin/admin.service';
import * as moment from 'moment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'NewAge7';
  PermissionLoaded :boolean=false;
  yrx: string;
  constructor(
    private hardfunc: HardFuncService,
    private adminservice : AdminService,
    private notificationservice : NotificationService
   ) { }
  

   ngOnInit() {
    let myItem = localStorage.getItem("Server");
    this.hardfunc.ChangeServer(myItem);

    let myItem1 = localStorage.getItem("Firmx");
    this.hardfunc.ChangeFirm(myItem1);
    
    const myItem2 = localStorage.getItem("Yrx");
    // console.log(myItem2);
    // const date = new Date(myItem2);
    // console.log(date);
    // const momentDate = moment(date.toISOString());
    // console.log(momentDate);

    this.hardfunc.ChangeYear(myItem2);


    this.adminservice.getAllModulesbyUser(this.hardfunc.UserName)
    .subscribe(
      (data:any) => {
        data = JSON.parse(data);
        this.hardfunc.SetPermittedModules(data);
        this.PermissionLoaded=true;
      },
      err => {
        let errstr =  this.hardfunc.getError(err);
        console.log(err);
        this.notificationservice.warn(errstr);
        this.PermissionLoaded=true;

      }
    );

   }
}
