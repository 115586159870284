import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatTableDataSource } from '@angular/material';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient } from '@angular/common/http';
import { EmailserviceService } from '../email/emailservice.service';

@Component({
  selector: 'app-grp-balance',
  templateUrl: './grp-balance.component.html',
  styleUrls: ['./grp-balance.component.css']
})
export class GrpBalanceComponent implements OnInit {

  loading: boolean = false;
  grplist: any = [];
  repmodel: any = {};
  dataSource: MatTableDataSource<unknown>;
  grpdata: any = [];

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private hardfunc: HardFuncService,
              private _http: HttpClient,
              private emailservice: EmailserviceService,
              private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.loading = true;
    this.repmodel.select = "O";
    this.GetDatas();
  }
  GetDatas(){
    this.loading = true;
    let r1: any = {};
    r1.Firmx = this.hardfunc.Firmx;
    r1.Yrx = this.hardfunc.Yrx;
    r1.Pmst_cod = this.ReceivedData.Pmst_cod;
    this.grplist = [];
    this.emailservice.GetGrpBalance(r1)
        .subscribe((data: any) => {
          this.grpdata = data;
          console.log(this.grpdata);
          if(this.repmodel.select === "O"){
            this.grpdata.map(m => {
              if(m.Pyr_Clbal != 0 || m.Lyr_Clbal != 0)
              {
                this.grplist.push(m);
              }
            });
          }else{
            this.grplist = data;
          }          
          this.loading = false;
        },err => {
          console.log(err);
          this.openSnackBar("Data not found...", "dismiss");
          this.loading = false;
        });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
