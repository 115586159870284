import { Component, OnInit, RendererStyleFlags2 } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { RouterLinkActive, ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { EmailComponent } from '../../shared/email/email.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnqService } from '../../sales/Enq/Enq.service';
import { MatSnackBar } from '@angular/material';
import * as moment from 'moment'
import { DmsService } from 'src/app/Services/Common/dms.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { createGunzip } from 'zlib';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.css']
})
export class PdfviewerComponent implements OnInit {
  loading: boolean = false;
  src: string = "";
  r1: any = {};
  ErrMsg: string = "";
  MsgData: any = [];
  MsgFile: boolean = false;
  datalist: any = [];
  FileType: string = "";
  FileURL: string = "";

  url: string = this.hardfunc.BaseAPIUrl + "api/Common/DocuViewerAPI/GetDocu";

  constructor(
    private _http: HttpClient,
    private hardfunc: HardFuncService,
    private _cservice: CommonService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private Enqservice: EnqService,
    private snackBar: MatSnackBar,
    private dmsservice: DmsService,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit() {
    
    this.route.paramMap
      .subscribe(params => {
        this.r1.Firmx = this.hardfunc.Firmx;
        this.r1.Yrx = params.get('Yrx');
        this.r1.ordno = params.get('Ordno');
        this.r1.oloc = params.get('Oloc');
        this.r1.DocuType = params.get('DocuType');
        this.r1.Filename = params.get('FileName');
        this.r1.Vtype = params.get('Vtype');
        this.FileType = this.r1.Filename.toLocaleLowerCase().split('.').pop();
        // this.r1.Srno = params.get
        //this.r1.Output = "JSON";
        this.r1.Output = "JSN2";
      })
    this.GetEnqData();
    console.log(this.MsgData);

    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    });
    this.ErrMsg = "";
    let fn: string = this.r1.Filename || "";

    if (fn.toLowerCase().includes(".msg")) {
      this.MsgFile = true;
      this.OpenMsgFile();
    } else {
      this.OpenOtherFiles();
    }
    this.loading = true;
  }

  DownloadMsgFile = function () {
    this.r1.Output = "FILE";
    this.OpenOtherFiles();
  }

  // OpenMsgFile = function(){

  OpenMsgFile = function () {
    this.datalist = [];
    this.loading = true;
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    this.ErrMsg = "";
    let fn: string = this.r1.Filename || "";

    this._http.post(this.url, this.r1, { headers: headers })
      .subscribe((data1: any) => {
        console.log(data1);
        let Ordno: number = this.r1.ordno;
        this.MsgData = [];
        this.MsgData = data1;
        this.MsgData.Atts.map(w => {
          w.FileType = w.FileName.split('.').pop();
          w.FileType = w.FileType.toUpperCase();
        });

        this.loading = false;
      },
        err => {
          console.log(err);
          if (err.status == 404) {
            this.ErrMsg = "Requested file not found ... ";
          }
          else {
            this.ErrMsg = "Error in getting pdf file " + err.message;
          }
        });
  }

  ForwardMail(data: any): void {
    console.log(data);
    const dialogRef = this.dialog.open(EmailComponent, {
      width: '1340px',
      height: '645px',
      data: { data: data }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  ReplyMail(data: any, Reply: string): void {
    const dialogRef = this.dialog.open(EmailComponent, {
      width: '1340px',
      height: '645px',
      data: { data: data, Reply: Reply }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  // OpenOtherFiles = function(){

  OpenOtherFiles = function () {
    this.loading = true;
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    });

    let fn: string = this.r1.Filename || "";
    console.log(fn);
    this._http.post(this.url, this.r1, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((data1: any) => {
        this.loading = false;
        let FileType = fn.split('.').pop();
        FileType = FileType.toUpperCase();
        let Ordno: number = this.r1.ordno;

        switch (this.FileType) {
          case "pdf":
            var file = new Blob([data1], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, "_self");
            break;
          case "png":
            // var file = new Blob([data1], { type: 'application/png' });
            // var fileURL = URL.createObjectURL(file);
            // window.open(fileURL, "_self");
            
            this.FileURL = URL.createObjectURL(data1);
            console.log(this.FileURL);
            this.FileURL = this.sanitizer.bypassSecurityTrustUrl(this.FileURL);
            console.log(this.FileURL);
            break;
          case "jpg":
              //var file = new Blob([data1]);
              this.FileURL = URL.createObjectURL(data1);
              console.log(this.FileURL);
              this.FileURL = this.sanitizer.bypassSecurityTrustUrl(this.FileURL);
              console.log(this.FileURL);
              break;            
          case "doc":
          case "docx":
            var file = new Blob([data1], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            this.FileURL = URL.createObjectURL(file);
            console.log(this.FileURL);

            this.FileURL = "https://docs.google.com/gview?url=" + this.fileURL + "&embedded=true" + "&embedded=true"
            //window.open(fileURL, "_self");
            break;
          case "msg":
            let a: any = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;
            a.target = "_blank";
            a.download = fn;
            a.click();
            window.URL.revokeObjectURL(fileURL);
            break;  
          default:
            var file = new Blob([data1], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, "_self");
            break;
            break;
        }

      },
        err => {
          if (err.status == 404) {
            this.ErrMsg = "Requested file not found ... ";
          }
          else {
            this.ErrMsg = "Error in getting pdf file " + err.message;
          }
        });
  }


  DownLoadAtt = function (att_nm: string) {
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    });

    this.r1.Status = att_nm;
    this.r1.Output = "FILE";
    let fn: string = this.r1.Status;
    let url1 = this.hardfunc.BaseAPIUrl + "api/Common/DocuViewerAPI/DownLoadAtt";

    this._http.post(url1, this.r1, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((data1: any) => {
        console.log(data1);
        // debugger;
        let ext = fn.toLocaleLowerCase().split('.').pop();
        let type = this.hardfunc.getFileType(ext);
        var file = new Blob([data1], { type: type });
        var fileURL = URL.createObjectURL(file);

        let a: any = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = fileURL;
        a.target = "_blank";
        a.download = fn;
        a.click();
        window.URL.revokeObjectURL(fileURL);

      },
        err => {
          if (err.status == 404) {
            this.ErrMsg = "Requested file not found ... ";
          }
          else {
            this.ErrMsg = "Error in getting pdf file " + err.message;
          }
        });
  }

  GetEnqData() {
    this.loading = true;
    this.datalist = [];
    var data: any = {};

    data.Fdates = moment(this.hardfunc.hsConvDt(this.hardfunc.Fdate)).format("DD/MM/yyyy");

    data.Tdates = moment(this.hardfunc.hsConvDt(this.hardfunc.Tdate)).format("DD/MM/yyyy");
    this.Enqservice.getDatas(data)
      .subscribe(
        (data: any) => {
          console.log(data);
          this.datalist = data;
          //this.LoadImages();
          this.loading = false;
        },
        err => {
          this.loading = false;
          if (err.error.error_description) {
            this.openSnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          }
        }
      )

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
