import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/Services/Common/common.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PartyLookupDialog } from '../partyDialog/dialog'


@Component({
    selector: 'app-party-list',
    templateUrl: './party.html',
})
export class PartyListComponent implements OnInit {
    Repmodel: any = {};
    party: any = {};
    cols: any[];


    constructor(private c_service: CommonService,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        this.Repmodel =
            {
                Pmst_cod: 'N0003',
                Fdate: new Date(2019, 3, 1),
                Tdate: new Date(),
                Output: 'PDF'
            }
    }

    GetPartyDetail() {
        this.c_service.GetPartyDetail(this.Repmodel.Pmst_cod)
            .subscribe(
                data => {
                    this.party = data[0];
                },
                err => {
                    this.party = {};
                }
            )
    }

    openDialog(): void {
        this.c_service.GetPartyList("NFF","2122")
            .subscribe(
                data => {
                    this.cols = [
                        { field: 'party_name', header: 'Name' },
                        { field: 'party_city', header: 'City' },
                        { field: 'pmst_cod', header: 'Code' }
                    ];
                    let dialogData = {
                        cols: this.cols,
                        data: data
                    }
                    const dialogRef = this.dialog.open(PartyLookupDialog, {
                        width: '600px',
                        data: dialogData,
                        disableClose: true
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        this.Repmodel.Pmst_cod = result.pmst_cod;
                        this.GetPartyDetail();
                    });
                },
                err => {
                }
            )
    }

}
