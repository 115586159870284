import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatInputModule } from '@angular/material';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent {
  DataForm: FormGroup;
  SaveEvent: any = {};
  loading: boolean = false;
  DashData: any = [];
  ConfirmPassword: string = "";
  title: String;
  srcResult: any;
  imageError: string;
  cardImageBase64: string;
  isImageSaved: boolean = false;
  
  constructor(
    private _http: HttpClient,
    private service: CommonService,
    private hardfunc: HardFuncService,
    private notificationService: NotificationService,
    private adminservice: AdminService,
    private matInput: MatInputModule,
    private _snackBar: MatSnackBar
  ) {
    this.DataForm = this.createFormGroup();
    this.GetData(this.hardfunc.UserName);
  }

  GetData(username : string) {
    
      this.adminservice.getUserbyUsername(username)
        .subscribe(
          (data:any) => {
            //data["EmailPwd"] = "";
            // if(data.EmailPwd){
            //   data["EmailPwd"] = "*******";
            // }
            this.cardImageBase64 = data.ImageStr;
            this.isImageSaved = true;
            this.DataForm.patchValue(data);
            this.title = "EDITING";
          },
          err => {
            this.notificationService.warn('Error in getting data...');
          }
        )
        
        this.adminservice.getModulesbyUserAndParent_id(username,1057)
        .subscribe(
          (data:any) => {
            data = JSON.parse(data);
            this.DashData = data.filter(w=> w.Permission.substring(0,1) === 'Y' );
            this.DashData.map(w=> {
              if (w.Permission.substring(1,2) === 'Y')
              {w.Status = true;}
              else
              {w.Status = false;}
            });
          },
          err => {
            this.notificationService.warn('Error in getting data...');
          }
        )

  }


  SaveData() {
    this.loading = true;
    let url = this.hardfunc.BaseAPIUrl + "api/UsersAPI/SaveProfile";
    var headers = new HttpHeaders({ 'content-Type': 'application/json' });
    //this.DataForm.ImageStr = '';

    this._http.post(url, JSON.stringify(this.DataForm.value), { headers: headers })
      .subscribe(data => {
        this.loading = false;
        this.notificationService.success(' Profile updated ... ');
      },
      err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          console.log(errstr);
          this.notificationService.warn('Error - '+errstr);
      });

      this.DashData.map(m=> {
        if (m.Status===true)
        {
          m.Permission = 'YY'
        }
        else
        {
          m.Permission = 'YN'
        }
      });
     
      this.adminservice.SavePermissionbyUser(this.hardfunc.UserName, this.DashData)
        .subscribe(data => {
          this.loading=false;
          this.notificationService.success(" Data saved Successfully... ");
        },
        err=> {
          this.loading = false;
          let errstr =  this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
        });
  }
  onClear(){
    
  }
  createFormGroup() {
    return new FormGroup({
      UserName: new FormControl('', Validators.required),
      Email: new FormControl('', [, Validators.required, Validators.email]),
      EmailPwd: new FormControl(''),
      PhoneNumber: new FormControl('', [Validators.required, Validators.minLength(8)]),
      ImageStr: new FormControl(''),
      image: new FormControl('')
    });
  }

  initializeFormGroup() {

  }

  onClose() {
    this.DataForm.reset();
    this.initializeFormGroup();
  }


  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is ' + max_size / 1000 + 'Mb';

        return false;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          console.log(img_height, img_width);

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              'Maximum dimentions allowed ' +
              max_height +
              '*' +
              max_width +
              'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.DataForm.patchValue({ ImageStr: imgBase64Path });
            this.isImageSaved = true;
            // this.previewImagePath = imgBase64Path;
          }
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }

}
