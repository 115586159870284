import { Injectable } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailserviceService {

  getheaders;
  postheaders;
  constructor(public HardFunc: HardFuncService,
              public Http: HttpClient) { }

  SaveData(data: any){
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
		
    data.Firm = this.HardFunc.Firmx;
    data.Yrx = this.HardFunc.Yrx;
    let url = this.HardFunc.BaseAPIUrl + "api/Common/EmailAPI/SaveData"
    return this.Http.post(url, data, { headers:headers });
  }
  GetDatabyTprod(id: number){
    let url = this.HardFunc.BaseAPIUrl + "api/Sales/tproductsAPI/GetDatabyId?id="+id
    return this.Http.post(url, null, { headers:this.HardFunc.PostHeaders() });
  }
  GetGrpBalance(data: any){
    let url = this.HardFunc.BaseAPIUrl + "api/SordersAPI/GrpBalance"
    return this.Http.post(url, data, { headers: this.HardFunc.PostHeaders() });
  }
}
