import { Injectable } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PrdctnService {

  constructor(private hardfunc: HardFuncService,
              private http: HttpClient) { }
  
  GetDatabyId(Facmast_id: number,TranType: string) {
    console.log(TranType);
		let data: any = {};
		data.Facmast_id = Facmast_id;
		let url = this.hardfunc.BaseAPIUrl + "api/Sales/factransAPI/GetDatabyId?id="+Facmast_id + 
                                         "&Firmx="+this.hardfunc.Firmx + "&Yrx="+this.hardfunc.Yrx + "&TranType=" + TranType;
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
  SaveData(data: any){
    data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		let url = this.hardfunc.BaseAPIUrl + "api/Sales/factransAPI/SaveData";
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  getDatas(data: any){
    console.log(data);
    data.Fdate = data.Fdate;
    data.Tdate = data.Tdate;
    data.Firmx = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    let url = this.hardfunc.BaseAPIUrl+"api/Sales/factransAPI/GetDatas"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }
  DeleteFactrans(Facmast_id: number){
    let Firmx = this.hardfunc.Firmx;
    let Yrx = this.hardfunc.Yrx;
    let url = this.hardfunc.BaseAPIUrl+"api/Sales/factransAPI/DeleteData?id="+Facmast_id + "&Firmx="+ Firmx + "&Yrx=" + Yrx;
    return this.http.post(url,null,  { headers: this.hardfunc.PostHeaders() });
  }
}
