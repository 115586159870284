import { Component, OnInit, Optional, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray, MaxLengthValidator } from '@angular/forms';
import { EnqService } from '../Enq.service';
import { MatTableDataSource} from '@angular/material/table';
import { PendordAttachComponent } from '../../pendord/pendord-attach/pendord-attach.component';
import { PendordService } from '../../pendord/pendord.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { DmsService } from 'src/app/Services/Common/dms.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';

@Component({
  selector: 'app-enqpartydetail',
  templateUrl: './enqpartydetail.component.html',
  styleUrls: ['./enqpartydetail.component.css']
})
export class EnqpartydetailComponent implements OnInit {

  loading: boolean = false;
  DataForm: FormGroup;
  datalist: any = [];
	datasource:any = [];
	dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  Dom_For:string = 'D';
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private fb: FormBuilder,
              private enqservice: EnqService,
              private _snackBar: MatSnackBar,
              private dialog: MatDialog,
              private HardFunc: HardFuncService,
			  private dmsservice : DmsService,
              private PendordService: PendordService,
			  private notificationService: NotificationService) { }

  ngOnInit() {
    this.DataForm = this.createFormGroup();
    this.DataForm.controls.PARTY_NAME.patchValue(this.ReceivedData.Party_name.trim());
  }
  DisplayCol() {
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		if (this.Dom_For == 'D')
		{
		  if (isSm)
		  {
			this.displayedColumns = ['PARTY_NAME','Action'];
		  }
		  else
		  {
			this.displayedColumns = ['SRNO', 'DORG','Oref','PARTY_NAME','Items','Contact_details','Action'];
		  }
		}
		else
		{
		  this.displayedColumns = ['SRNO', 'DORG','Oref','PARTY_NAME','Items','Contact_details','Action'];
		}
		if(this.ReceivedData)
		{
			this.displayedColumns = ['SRNO','Oref','PARTY_NAME','Contact_details','Action'];
		}
	  }
    openAttachment(SRNO: number, Enq_id: number, Vtype: string): void {
      const dialogRef = this.dialog.open(PendordAttachComponent, {
        width: '925px',
        height: '600px',
        data: { Ordno: SRNO, id: Enq_id, Oloc: 1, Vtype: Vtype }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
      }
  getData() {
		this.loading = true;
		this.datalist = [];		
		this.enqservice.EnqPartyDetails(this.DataForm.value)
			.subscribe(
				(data: any) => {
					console.log(data);
					this.datalist = data;
					this.loading = false;
					this.datalist.map(m => {

						if(m.DocuList != null)
						{
							m.DocuList.map(x => {
									x.FileType = "OTH";
							});
						}
					});
					this.dataSource = new MatTableDataSource(<any>this.datalist);
					this.DisplayCol();
          			this.getAttachment();
					this.dataSource.paginator = this.paginator;
				},
				err => {
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description,"dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
					}
				}
			)	
	}
	LoadImagebyId(objFile: any){
		this.loading = true;
		this.dmsservice.LoadImagebyId(objFile.Docu_id)
		.subscribe(
		  (data1: any) => {
			objFile.ImageStr = 'data:image/jpeg;base64,'+data1;
			this.loading = false;
		  },
			err => {
			  this.loading = false;
			  let errstr = this.HardFunc.getError(err);
			  this.notificationService.warn("Error in image loading "+errstr);
			}
		  )
	  }
  // 7. api/DMSAPI/DocuSumbyOrdno -- API For -- Document Sum by Order No.
	getAttachment() {
		this.PendordService.GetDatabyAttach(this.HardFunc.Firmx, 'EN')
			.subscribe(
				(data: any) => {
					data.map(w => {
						if (w) {
							var obj: any = this.datalist.filter((x: any) => x.Srno === w.Ordno && x.Oloc === x.Oloc)[0];
							if (obj) {
								obj.No_of_docu = w.No_of_docu;
							}
						}
					});
				}
			);
	}
  openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
		  duration: 2000,
		});
	  }
  createFormGroup() {
    return this.fb.group({
      PARTY_NAME: new FormControl('',Validators.maxLength(50))
    });
  }

}
