import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-copyrights',
  templateUrl: './copyrights.component.html',
  styleUrls: ['./copyrights.component.css']
})
export class CopyrightsComponent implements OnInit {

  constructor(private AdminService: AdminService) { }

  datalist: any = [];

  ngOnInit() {
    // this.GetData();
  }
  // GetData(){
    
  // }
}
