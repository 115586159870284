import { Component, OnInit, ViewChild, Optional, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray, FormControlName } from '@angular/forms';
import { Sort, MatTableDataSource } from '@angular/material';
import { C, COMMA, ENTER, X } from '@angular/cdk/keycodes';
import { ElementRef } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EmdListComponent } from '../emd-list/emd-list.component';
import { EmdService } from '../emd.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { PendordService } from '../../pendord/pendord.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { MatChipInputEvent } from '@angular/material/chips';
import {map, startWith} from 'rxjs/operators';

export interface Fruit {
  name: string;
}
@Component({
  selector: 'app-emd-editnote',
  templateUrl: './emd-editnote.component.html',
  styleUrls: ['./emd-editnote.component.css']
})
export class EmdEditnoteComponent implements OnInit, OnDestroy {

  DataForm: FormGroup;
  loading: boolean = false;
  datasource:any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  datalist: any = [];
  initialValues : any [];
  filteredUsers: any = [];
  Users: string[] = [];
  UserData: any = [];
  uloading: boolean = false;
  visible = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('UserInput', {static: false}) UserInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              @Optional() public dialogRef: MatDialogRef<EmdListComponent>,
              private EmdService: EmdService,
              private fb: FormBuilder,
              private hardfunc: HardFuncService,
              private notificationService: NotificationService,
              private pendordservice: PendordService,
              private commonservice: CommonService) { }

  ngOnInit() {
    this.GetUser();
    this.GetData();
    this.DataForm = this.createFormGroup();
    this.initialValues = this.DataForm.value;
    this.filteredUsers = this.DataForm.get('UserCtrl').valueChanges.pipe(
      startWith(null),
      map((User: string | null) => User ? this._filter(User) : this.UserData.slice()));
  }
  DisplayCol() {	
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['Remarks'];
	}
ngOnDestroy(): void {
  console.clear();
}
  //1. api/Common/notessAPI/GetDatasbyInvnoOnly
  GetData() {
    this.loading = true;
		this.datalist = [];

    if (this.ReceivedData) {
      this.EmdService.GetDatabyNotes(this.ReceivedData.Invno)
        .subscribe(
          (data1: any) => {
            this.datalist = data1;
            this.loading = false;
            this.DataForm.patchValue(data1);
            this.dataSource = new MatTableDataSource(<any>data1);
            this.DisplayCol();
          },
            err => {
              let errstr = this.hardfunc.getError(err);
              this.notificationService.warn(errstr);
            }
          )
    }
    else {
      this.ReceivedData = {};
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.Users.push(event.option.viewValue);
    this.UserInput.nativeElement.value = '';
    this.DataForm.get('UserCtrl').setValue(null);
  }

  //2. api/UsersAPI/GetAllUsers
  GetUser() {
    this.uloading = true;
    this.commonservice.getAllUsers()
      .subscribe(
        (data: any) => {
          this.UserData = data;
          this.UserData = this.UserData.map((x) => {
            return {
              nm: x.UserName,
              id: x.Id
            }
          });
          this.uloading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
          this.uloading = false;
        });
  }

  //4. api/Common/notessAPI/SaveData
  SaveData() {
    this.loading = true;
    let to_whom:string = "";
    this.Users.map((w:any)=>
      {
         (this.DataForm.controls['SubData'] as FormArray).push(this.createItem(w));
         to_whom+= w+",";
      });
      this.DataForm.controls['To_whom'].patchValue(to_whom);
    this.EmdService.SaveNotes(this.DataForm.value,this.ReceivedData.Invno,this.ReceivedData.Firm,this.ReceivedData.Pmst_cod,this.ReceivedData.party_name)
      .subscribe(
        data => {
          this.loading = false;
          this.notificationService.success(' Data Saved Successfully... ');
          this.GetData();
          this.ResetData();
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  Edit_note(Notes_id: number,Remarks:string): void{
    this.DataForm.controls["Remarks"].patchValue(Remarks);
    this.DataForm.controls["Notes_id"].patchValue(Notes_id);
  }
  
  //4. api/Common/notessAPI/DeleteData
  Delete_note(Notes_id: number): void{
    this.loading = true;
    const dialogRef = this.pendordservice.Delete_note(Notes_id)
    .subscribe(
      (data:any)=>{
      this.loading = false;
      this.notificationService.warn("Data deleted succefully");
      this.GetData();
      },
      err => {
      this.loading = false;
      let errstr =  this.hardfunc.getError(err);
      console.log(err);
      this.notificationService.warn(errstr);
      }
    )
}
add(event: MatChipInputEvent): void {
  if (!this.matAutocomplete.isOpen) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.Users.push(value.trim());
    }

    if (input) {
      input.value = '';
    }

    this.DataForm.get('UserCtrl').setValue(null);
  }
}

remove(User: string): void {
  const index = this.Users.indexOf(User);

  if (index >= 0) {
    this.Users.splice(index, 1);
  }
}
  ResetData() {      
    this.DataForm.reset();
    this.DataForm.patchValue(this.initialValues);
}
  createFormGroup() {
    return this.fb.group({
      Notes_id: new FormControl(-1),
      Remarks: new FormControl(''),
      UserCtrl: new FormControl(''),
      To_whom: new FormControl(''),
      SubData: this.fb.array([]), 
    });
  }
  createItem(user:string ): FormGroup {
    return this.fb.group({
      Username:[user]
    });
  }
  private _filter(value: string): string[] {
    
    const filterValue = value.toLowerCase();

    return this.UserData.filter(User => User.nm.toLowerCase().indexOf(filterValue) === 0);
  }
}
