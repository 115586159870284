import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SalesService } from '../sales.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { Sort, MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import * as moment from 'moment'
import * as   XLSX from 'xlsx';

@Component({
  selector: 'app-sales-moniter',
  templateUrl: './sales-moniter.component.html',
  styleUrls: ['./sales-moniter.component.css']
})
export class SalesMoniterComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  DataForm: FormGroup;
  tt_bsc: number;
  filename:string= 'InvChkList.xlsx';
  OrdData: any = [];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private SalesService: SalesService,
              private hardfunc: HardFuncService,
              private _snackBar: MatSnackBar,
              private fb: FormBuilder) { }

  ngOnInit() {
    // this.getData();
	this.loading = false;
    this.DataForm = this.createFormGroup();
  }
  ngOnDestroy(): void {
	console.clear();
  }
  DisplayCol() {
	let isSm = window.matchMedia(`(max-width: 960px)`).matches;
	this.displayedColumns = ['Invnox','Party', 'Cg','Items','Invtot'];
  }
  
  //1. api/PurCheckListAPI/InvChkList
  getData() {
		this.loading = true;
		this.datalist = [];
		let Fdate = this.hardfunc.hsConvDt(this.DataForm.controls.Fdate.value);
		let Tdate = this.hardfunc.hsConvDt(this.DataForm.controls.Tdate.value);
		this.DataForm.controls.Fdate.patchValue(Fdate);
		this.DataForm.controls.Tdate.patchValue(Tdate);
		this.SalesService.getDatas(this.DataForm.value)
			.subscribe(
			(data: any) => {
				let tt_bsc = 0;
				let tt_ExcAmt = 0;
				let tt_TaxAmt = 0;
				let tt_AtAmt = 0;
				data.map(m=> {
					
					let t_bsc = 0;
					let t_ExcAmt = 0;
					let t_TaxAmt = 0;
					let t_AtAmt = 0;
					
					// Sub Data 
					m.InvSubdata.map(l=>{
						l.Header = 0;
						t_bsc+= l.Bsc;			
						t_ExcAmt += l.ExcAmt;
						t_TaxAmt += l.TaxAmt;
						t_AtAmt += l.AtAmt;

						tt_bsc+= l.Bsc;			
						tt_ExcAmt += l.ExcAmt;
						tt_TaxAmt += l.TaxAmt;
						tt_AtAmt += l.AtAmt;
					});
					m.InvSubdata.push({Header: 1,
						Bsc:t_bsc,
						ExcAmt: t_ExcAmt,
						TaxAmt: t_TaxAmt,
						AtAmt: t_AtAmt,
					});		
					
				});
				data.push({
					InvSubdata:[{
					Bsc:tt_bsc,
					Header: 2,
					ExcAmt: tt_ExcAmt,
					TaxAmt: tt_TaxAmt,
					AtAmt: tt_AtAmt}]
				});	

				this.datalist = data;
				this.loading = false;
				this.dataSource = new MatTableDataSource(<any>this.datalist);
				this.DisplayCol();
				this.dataSource.paginator = this.paginator;
			},
			err => {
				this.loading = false;
				if (err.error.error_description) {
					this.openSnackBar(err.error.error_description,"dismiss");
				}
				else {
					this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
				}
			}
		)
	}

// getData() {
//     this.loading = true;
// 	this.datalist = [];
// 	let Fdate = this.hardfunc.hsConvDt(this.DataForm.controls.Fdate.value);
// 	let Tdate = this.hardfunc.hsConvDt(this.DataForm.controls.Tdate.value);
// 	this.DataForm.controls.Fdate.patchValue(Fdate);
// 	this.DataForm.controls.Tdate.patchValue(Tdate);
// 	this.SalesService.getDatas(this.DataForm.value)
// 		.subscribe(
// 		(data: any) => {
//           this.loading = false;  
//           this.OrdData = [];
//           let P_name = '###';
// 			let tt_bsc = 0;
// 			let tt_ExcAmt = 0;
// 			let tt_TaxAmt = 0;
// 			let tt_AtAmt = 0;
//           data.map(m => {
//             if (P_name !== m.Party) {
//               if (P_name !== '###')
//               {
//                 this.OrdData.push({ Header: 2, BscTotal: tt_bsc, ExcTotal: tt_ExcAmt, TaxAmtTotal: tt_TaxAmt, AtamtTotal: tt_AtAmt  });
//               }
//               tt_bsc = 0;
//               tt_ExcAmt = 0;
//               tt_TaxAmt = 0;
// 			  tt_AtAmt = 0;

//               this.OrdData.push({ Header:1, Hcode: m.Hcode, Product_id: m.Product_id, Short_nm: m.Short_nm, Qnty: m.Qnty,Rate: m.Rate,
// 				Bsc: m.Bsc, Excpc: m.Excpc, ExcAmt: m.ExcAmt, Taxpc: m.Taxpc, TaxAmt: m.TaxAmt, Atpc: m.Atpc, AtAmt: m.AtAmt });
//               P_name = m.Hcode, m.Product_id, m.Short_nm, m.Qnty, m.Rate, m.Bsc, m.Excpc, m.ExcAmt, m.Taxpc, m.TaxAmt, m.Atpc, m.AtAmt
//             }

//             m.Header = 0;
//             { this.OrdData.push(m); }

//             tt_bsc += m.Bsc;    
//             tt_ExcAmt += m.ExcAmt;
//             tt_TaxAmt += m.TaxAmt;
// 			tt_AtAmt += m.AtAmt;
//            });
//       },
//         err => {
//           console.log(err);
//           this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
//           this.loading = false;
//         });
//   }
  createFormGroup() {
		return this.fb.group({
		  Fdate: new FormControl(new Date()),
		  Tdate: new FormControl(new Date()),
		  CG: new FormControl("A")
		});
	  }
  openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}
	
}
