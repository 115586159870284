import { Component, OnInit, Inject } from '@angular/core';
import { AdminService } from '../../admin.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { ModuleManagementComponent } from '../../module-management/module-management.component';

@Component({
  selector: 'app-role-module',
  templateUrl: './role-module.component.html',
  styleUrls: ['./role-module.component.css']
})
export class RoleModuleComponent implements OnInit {
  
  loading: boolean = false;
  datalist: any = []; 
  datasource: any = [];
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<unknown>;
  Filter:string = "";
   
  constructor(
    private adminservice: AdminService,
    private hardfunc: HardFuncService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<ModuleManagementComponent>,
    @Inject(MAT_DIALOG_DATA) public ReceivedData: any,) { }

  ngOnInit() {
    this.getData();
  }
  getData() {
    this.loading = true;
    this.datalist = [];
    this.GetModulePermissionbyRole_id();
  }
  
  DisplayCol() {
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['name','Email','Permission'];
  }

  GetModulePermissionbyRole_id(){
    this.adminservice.GetModulePermissionbyRole_id(this.ReceivedData.id)
    .subscribe(
      (data:any) => {
        this.datalist = JSON.parse(data);
        this.datalist.map(m=> {
          m.PermList = [];
          let str = (m.Permission ? m.Permission.substring(0,6)   : 'NNNNNN');
          for (var i = 0; i <  str.length; i++) {
            let vals = str.substring(i,i+1) ==='Y' ? true : false;
            m.PermList.push({chk:vals})
          }  
        });
        this.DisplayCol();
        this.loading = false;
      },
      err => {
        this.loading = false;
        let errstr =  this.hardfunc.getError(err);
        console.log(err);
        this.notificationService.warn(errstr);
        
      }
    )
  }
  SaveData(){
    
  }
}
