import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit, OnDestroy {

  constructor(@Inject(MAT_DIALOG_DATA)public data,
  public dialogRef: MatDialogRef<DialogComponent>) { }

  ngOnInit() {
  }
  closeDialog(){
    this.dialogRef.close(false);
  }
  ngOnDestroy(): void {
    console.clear();
  }
}
