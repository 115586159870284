import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from 'src/app/Services/Common/notification.service';

@Component({
  selector: 'app-gunmtl-scrapitem',
  templateUrl: './gunmtl-scrapitem.component.html',
  styleUrls: ['./gunmtl-scrapitem.component.css']
})
export class GunmtlScrapitemComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  
  constructor(private hardfunc: HardFuncService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private _http: HttpClient,
              private NotificationService: NotificationService) { }

  ngOnInit() {
    this.loading = true;
    this.GetDatas();
  }
  DisplayCol(){
    this.displayedColumns = ['PRODUCT_ID','Short_nm'];
  }
  GetDatas(){
    this.loading = true;
    let id = this.ReceivedData.id;
    let firm = this.hardfunc.Firmx;
    let Outwardno = this.ReceivedData.OUTWARDNO;

    let url = this.hardfunc.BaseAPIUrl + "api/InventoryDashboard/ji_tran1byJwtr_id?id=" + id + "&Firm=" + firm + "&Outwardno=" + Outwardno;
    this._http.post(url, null)
      .subscribe((data: any) => {
        this.datalist = data;
        console.log(this.datalist);
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DisplayCol();
        this.loading = false;
      },err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.NotificationService.warn(errstr);
        this.loading = false;
      });
  }
}
