import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-umodule-list',
  templateUrl: './umodule-list.component.html',
  styleUrls: ['./umodule-list.component.css']
})
export class UmoduleListComponent implements OnInit {
  loading: boolean = false;
  datalist: any = [];
  constructor(private adminservice: AdminService) { }

  ngOnInit() {
    this.getAllUsers();
  }
  getAllUsers(){
    this.loading = true;
    this.datalist = [];
    this.adminservice.getAllModuleUsers()
      .subscribe(
        (data: any) => {
          this.datalist = data;
          console.log(this.datalist);
        }
      )
  }
}
