import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';
import { CommonService } from 'src/app/Services/Common/common.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { AdminService } from '../admin.service';
import { MatDialog, MatDialogRef, MatOptionSelectionChange, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MatTableDataSource } from '@angular/material/table';
import { RoleCrud1Component } from './role-crud1/role-crud1.component';
import { Router } from '@angular/router';
import { DialogComponent } from './dialog/dialog.component';
import { analyzeAndValidateNgModules } from '@angular/compiler';
//import { UserListComponent } from '../user-list/user-list.component';
import { ModuleListComponent } from './module-list/module-list.component';
import { RoleUserComponent } from './role-user/role-user.component';
import { RoleModuleComponent } from './role-module/role-module.component';

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.css']
})
export class RoleManagementComponent implements OnInit, OnDestroy {


  massage: string;
  datalist: any = [];
  loading: boolean = false;
  repmodel: any = {};
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  filtertext: string;

  constructor(
    public dialog: MatDialog,
    private _http:HttpClient, 
    private service: CommonService,
    private notificationService : NotificationService,
    private adminservice: AdminService,
    private _snackBar: MatSnackBar,
    private hardfunc: HardFuncService

  ) { }

  ngOnInit() {
  }
ngOnDestroy(): void {
  console.clear();
}
  DisplayCol() {
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['name','Action'];
	  }
  getData() {
    this.loading = true;
    this.datalist = [];

    this.adminservice.getAllRoles()
    .subscribe(
      (data:any) => {
        this.datalist = JSON.parse(data);
        this.loading = false;
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DisplayCol();
      },
      err => {
        this.loading = false;
        let errstr =  this.hardfunc.getError(err);
        console.log(err);
        this.notificationService.warn(errstr);
        
      }
    )
  }
  openDialog(id:number ): void {
    const dialogRef = this.dialog.open(RoleCrud1Component, {
      width: '528px',
      data: {id:id}   
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
  
  openDeleteRole(id: string): void{      

    this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
    .afterClosed().subscribe(res =>{
      if(res){
        this.loading = true;
        const dialogRef = this.adminservice.DeleteRole(id)
        .subscribe(
          (data:any)=>{
            this.loading = false;
            this.notificationService.success("Data deleted succefully");
            this.getData();
          },
          err => {
            this.loading = false;
            let errstr =  this.hardfunc.getError(err);
            console.log(err);
            this.notificationService.warn(errstr);
          }
        )
        
       // this.notificationservice('Deleted Successfully');
      }
    });

    }
    
    openUserDialog(Role_id:string, Role_nm:string ): void {
      let isSm = window.matchMedia(`(max-width: 960px)`).matches;
      let w = '90%';
      let h = '80%';
      if (isSm )
      {
         w = '100%';
         h = '100%';
      }
      const dialogRef = this.dialog.open(RoleUserComponent, {
        width: w,
        maxWidth:w,
        height: h,
        data: { type: 'R', id:Role_id, nm: Role_nm}
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }

    openModuleDialog(Role_id:string, Role_nm:string): void {
      let isSm = window.matchMedia(`(max-width: 960px)`).matches;
      let w = '90%';
      let h = '80%';
      if (isSm )
      {
         w = '100%';
         h = '100%';
      }
      const dialogRef = this.dialog.open(RoleModuleComponent, {
        width: w,
        maxWidth:w,
        height: h,
        data: {type: 'R', id:Role_id, nm: Role_nm }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }

  openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
		  duration: 2000,
		});
	  }
}
