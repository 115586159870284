import { Component, OnInit, ViewChild, Optional, Inject } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material'
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-party-help',
  templateUrl: './party-help.component.html',
  styleUrls: ['./party-help.component.css']
})
export class PartyHelpComponent implements OnInit {

  PartyList: any = [];
  DataForm: FormGroup;
  repmodel: any = {};
  Firmx:string="";
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  msgFromChild1 = [];
  loading: boolean = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  
  constructor(private hardfunc: HardFuncService,
              private _http: HttpClient,
              private fb: FormBuilder,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any) { }

  ngOnInit() {
    this.loading = true;
    this.GetPartyList();
    this.DataForm = this.HelpFormGroup();
  }
  DisplayCol() {	
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['id','nm','Party_city','Party_Stat','Party_adr1'];
	}
  GetPartyList() {
    this.loading = true;
    this.PartyList = [];
    let data: any = {};
    if (this.ReceivedData.Firmx)
    {
      data.Firmx = this.ReceivedData.Firmx;
    }else{
      data.Firmx =  this.hardfunc.Firmx;
    }
    if (this.ReceivedData.Yrx)
    {
      data.Yrx = this.ReceivedData.Yrx;
    }else{
      data.Yrx =  this.hardfunc.Yrx;
    }
    this.Firmx= data.firmx;
    data.Cond = this.ReceivedData.Cond;

    let url = this.hardfunc.BaseAPIUrl + "api/FinanceAPI/GetPartyList";
    this._http.post(url, data, this.repmodel)
      .subscribe((data1: any) => {
        this.loading = false;
        this.PartyList = JSON.parse(data1);
        console.log(this.PartyList);
        this.dataSource = new MatTableDataSource(<any>this.PartyList);
        // this.dataSource.paginator = this.paginator;
        this.DisplayCol();        
      }, err => {
        console.log(err);
      });
  }
  msgToParent(id: number, nm: string) {
    this.msgFromChild1.push(id);
    this.msgFromChild1.push(nm);
    this.msgFromChild1.push(this.ReceivedData)
  }
  HelpFormGroup() {
    return this.fb.group({
      Product_id: new FormControl(),
      Product_nm: new FormControl(''),
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
