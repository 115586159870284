import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { SalesService } from '../../sales.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { RcListComponent } from '../rc-list/rc-list.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PartyHelpComponent } from 'src/app/Views/shared/party-help/party-help.component';
import { ProductHelpComponent } from 'src/app/Views/shared/product-help/product-help.component';

@Component({
  selector: 'app-rc-mast',
  templateUrl: './rc-mast.component.html',
  styleUrls: ['./rc-mast.component.css']
})
export class RcMastComponent implements OnInit {

  loading: boolean = false;
  DataForm: FormGroup;
  DirectorList: any = [];
  repmodel: any = {};
  Productlist: any = [];
  title: string;
  initialValues: any[];
  public editor = ClassicEditor;

  constructor(private fb: FormBuilder,
              private hardfunc: HardFuncService,
              private _http: HttpClient,
              private SalesService: SalesService,
              private NotificationService: NotificationService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              @Optional() public dialogRef: MatDialogRef<RcListComponent>,
              private dialog: MatDialog) { }

  ngOnInit() {
    // this.loading = true;
    this.DataForm = this.CreateFormGroup();    
    this.initialValues = this.DataForm.value;
    this.getHandles();
    this.GetData();
  }

  GetData(){
    // this.loading = true;
    if (this.ReceivedData) {
      if (this.ReceivedData.id == -1) {
        this.title = "RC Entry";
      }
      else {
        console.log('test');
        // this.loading = true;
        this.SalesService.GetRcById(this.ReceivedData.id)
          .subscribe((data: any) => {
            console.log(data);
            this.title = "RC Editing";
            if(this.ReceivedData.Status === 'R'){
              data.Revise = "R"; 
            }else{
              data.Revise = 'E';
            }
            this.DataForm.patchValue(data);   
            const formarr = new FormArray([]);
              data.SubDatas.map(m => {
                formarr.push(
                  this.fb.group({
                    Rctr_id: m.Rctr_id,
                    Rc_id: m.Rc_id,
                    Product_id: m.Product_id,
                    PartyItcode: m.PartyItcode,
                    Item_Desc: "",
                    Rate: m.Rate,
                    Entry_dt: m.Entry_dt,
                  })
                );
              });
              this.DataForm.setControl("SubDatas", formarr);
                                        
              this.title = "RC Editing";
              // this.loading = false;
              return false;
              
          },
            err => {
              // this.loading = false;
              let errstr = this.hardfunc.getError(err);
              this.NotificationService.warn(errstr);
            }
          )
      }
    }
    else {
      this.ReceivedData = {};
      this.ReceivedData.id = -1;
      this.title = "RC Entry";
    }
  }
  SaveData(){        
    this.loading = true;
    let Fdate = this.hardfunc.hsConvDt(this.DataForm.controls.Fdate.value);
    let Tdate = this.hardfunc.hsConvDt(this.DataForm.controls.Tdate.value);
    this.DataForm.get("Fdate").patchValue(Fdate);
    this.DataForm.get("Tdate").patchValue(Tdate);    
    this.SalesService.SaveRc(this.DataForm.value)
      .subscribe((data: any) => {
        console.log(data);
        this.NotificationService.success("Data Saved Successfully... ");

        // let headers = this.hardfunc.PostHeaders();
        
        // let url = this.hardfunc.BaseAPIUrl + "api/Sales/rc_mastsAPI/CreateRcPdf";
        // this._http.post(url, data, {headers: headers, responseType: 'blob' as 'json'})
        //   .subscribe((data1: any) => {     
        //       console.log(data1);
                        
        //       const data = window.URL.createObjectURL(data1);

        //       var link = document.createElement('a');
        //       link.href = data;
        //       link.target = "_blank";
        //       var CurrDate = new Date();
              
        //       link.download = "CR.pdf";
        //       link.click();
        //       setTimeout(function () {
        //         // For Firefox it is necessary to delay revoking the ObjectURL
        //         window.URL.revokeObjectURL(data);
        //       }, 100);                                                        
        //     });

        this.loading = false;
        this.ResetData();
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.NotificationService.warn(errstr);
        this.loading = false;
      });     
  }
  PrdHelp(index: number) {
    const dialogRef = this.dialog.open(ProductHelpComponent, {
      width: '970px',
      height: '848px',
      data: { i: index }
    });
    dialogRef.afterClosed().subscribe(result => {
      let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
      let formgroup = (formarray.at(index) as FormGroup);
      formgroup.get('Product_id').patchValue(result.Product_id);
      formgroup.get('Item_Desc').patchValue(result.Short_nm.trim());
      console.log('The dialog was closed');
    });
  }
  ResetData() {
    if (this.ReceivedData.id > 0) {
      this.dialogRef.close();
    }
    else {
    this.DataForm.reset();
    this.DataForm.patchValue(this.initialValues);
    Object.keys(this.DataForm.controls).forEach(key => {
      this.DataForm.get(key).setErrors(null);
    });
    }
  }
  // 8. api/HandlesAPI/GetData -- API For -- Get Directore Type=D
  getHandles() {
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=D";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.DirectorList = data1;
      }, err => {
        console.log(err);
      });
  }
  getProductlist(index: number) {
    console.log(index);
    let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
    let formgroup = (formarray.at(index) as FormGroup);    
    let product_id = formgroup.get('Product_id').value;

    let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/GetProductDetail?product_id=" + product_id;
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.Productlist = data1;
        console.log(this.Productlist);
        this.Productlist.map((x) => {          
          let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
          let formgroup = (formarray.at(index) as FormGroup);
          formgroup.get('Item_Desc').patchValue(x.Product_nm.trim());
        });
      }, err => {
        console.log(err);
      });
  }
  HelpDialog() {
    let data = {
      Firmx: this.hardfunc.Firmx,
      Cond: " a.Grp in  (''B'',''H'' ) "
    }
    const dialogRef = this.dialog.open(PartyHelpComponent, {
      width: '1100px',
      height: '848px',
      data
    });
    dialogRef.afterClosed().subscribe(result => {  
      console.log(result)          ;
      this.DataForm.get('Pmst_code').patchValue(result.id);
      this.DataForm.get('Party_Name').patchValue(result.nm);
      this.DataForm.get('Party_city').patchValue(result.Party_city);
      this.DataForm.get('Party_addr').patchValue(result.Party_adr1.trim()+result.Party_adr2.trim()+result.Party_adr3.trim());
      this.DataForm.get('Party_state').patchValue(result.Party_Stat);
    });
  }
  AddItems(){    
    (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubData())
  }
  DeleteRow(i: number) {
    // if(this.ReceivedData.id > 1)
    // {
    //   //(this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);
    //   let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
    //   let formgroup = (formarray.at(i) as FormGroup);
    //   formgroup.get('Noted').patchValue('D');
    //   this.DoTotal();
      
    // }
    // else{
      (this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);      
    // }
  }
  CreateFormGroup(){
    return this.fb.group({
      Rc_id: new FormControl(-1),
      Pmst_code: new FormControl(''),
      Party_Name: new FormControl(''),
      Party_addr: new FormControl(''),
      Party_city: new FormControl(''),
      Party_state: new FormControl(''),
      Rc_ver: new FormControl(0),
      Item_Desc: new FormControl(''),
      Rc_date: new FormControl(new Date()),
      Fdate: new FormControl(new Date()),
      Tdate: new FormControl(new Date()),      
      Docref_no: new FormControl(''),
      Dir_id: new FormControl(),
      Revise: new FormControl(''),
      Status: new FormControl(''),
      Remarks: new FormControl(''),
      SubDatas: this.fb.array([this.CreateSubData()]),
    });
  }
  CreateSubData(){
    return this.fb.group({
      Rctr_id: new FormControl(-1),
      Rc_id: new FormControl(-1),
      Product_id: new FormControl(),
      PartyItcode: new FormControl(''),
      Item_Desc: new FormControl(''),
      Rate: new FormControl(),
      Entry_dt: new FormControl(new Date())
    });
  }
}
