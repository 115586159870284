import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RouterLinkActive, ActivatedRoute } from '@angular/router';
import { SalesService } from '../sales.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { materialize } from 'rxjs/operators';

@Component({
  selector: 'app-ord-det',
  templateUrl: './ord-det.component.html',
  styleUrls: ['./ord-det.component.css']
})
export class OrdDetComponent implements OnInit {
  msgs: any[] = [];
  newMailData = {}
  xmodel:any={};
  mailForm: FormGroup;
  constructor( private route : ActivatedRoute,
    private salesService : SalesService,
    private notificationService :NotificationService,
    private _snackBar: MatSnackBar
    ) { }

  ngOnInit() {
    this.route.paramMap
    .subscribe(params => {
       this.xmodel = {
          Ordno:params.get('Ordno'),
          Oloc:params.get('Oloc'),
          Firmx:params.get('Firmx'),
          Yrx :params.get('Yrx'),
       };
    })
    
    this.mailForm = new FormGroup({
      To: new FormControl('', [
        Validators.email
      ]),
      Subject: new FormControl('', [
        Validators.required
      ]),
      Message: new FormControl('', [
        Validators.required
      ])
    }) 
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  sendEmail() {
    this.salesService.SendEmailForOrder(this.xmodel,this.mailForm.value)
    .subscribe(
      data => {
        //this.loading = false;
        //this.onClose();
        this.notificationService.success(' Data Saved Successfully... ');
      },
      err => {
        if (err.error.error_description) {
          this.openSnackBar(err.error.error_description,"dismiss");
         // this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err.error.error_description });
        }
        else {
          this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
          //this.msgs.push({ severity: 'error', summary: 'Error Message', detail: "Check server connectivity... " });
        }
      }
    )
    console.log(this.mailForm.value);
  }

}
