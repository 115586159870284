import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-pendord-addfile',
  templateUrl: './pendord-addfile.component.html',
  styleUrls: ['./pendord-addfile.component.css']
})
export class PendordAddfileComponent implements OnInit {
	
  loading: boolean = false;
  DataForm: FormGroup;
  file: any;
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.DataForm = this.createFormGroup();
		this.loading = true;
  }

  createFormGroup() {
		return this.fb.group({
		  Docu_type: new FormControl(''),
		  Docu_detail: new FormControl(''),
      file: new FormControl('')
		});
	  }
    
    GetFile(event){
      this.file = event.target.files[0];
      console.log("file", this.file);
    }

}
