import { Component, OnInit, Inject, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PdcService } from './Pdc.service';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { MatDatepickerModule, MatInputModule, MatNativeDateModule } from '@angular/material';
import { Router } from '@angular/router';
import { PdcCrudComponent } from './Pdc-crud.component';
//import { MatTableExporterModule } from 'mat-table-exporter';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-PdcMan',
  templateUrl: './Pdc-man.component.html',
  styleUrls: ['./Pdc-man.component.css']
})
export class PdcManComponent implements OnInit, OnDestroy {
  msgs: any[] = [];
  datalist: any = [];
  loading: boolean = false;
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  massage: string;
  repmodel: any = {};
  Fdate: Date;
  Tdate: Date;
  duplicateArray = []
  Status: string;
  StateList: any = [];
  bSearch: any = [];
  FieldList: any = [];
  Firmx: any[];
  sort: any;
  chkDate: boolean = false;
  err: any[];
  cols: any[];
  checked: boolean = false;
  Permission:string ="NNNNNNNNNN";

  constructor(private _http: HttpClient,
    public dialog: MatDialog,
    private commonservice: CommonService,
    private notificationservice: NotificationService,
    private pdcservice: PdcService,
    private _snackBar: MatSnackBar,
    private hardfunc: HardFuncService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.loading = false;
    this.repmodel.CG = 'A';
    this.repmodel.Status = 'P';
    this.Fdate = new Date("01-04-2021");
    this.Tdate = new Date("31-03-2021");
    this.Status = "";
    this.duplicateArray = this.datalist
    this.loading = true;
    this.Permission = this.hardfunc.GetLocalPermissionbyModule_id(1040);

    var statuslist = [
      { nm: 'OPEN', id: 'OPEN' },
      { nm: 'PROCESSED', id: 'PROCESSED' },
      { nm: 'VOID', id: 'VOID' }
    ]

    this.FieldList = [
      { field: 'Firm', type: 'String', header: 'Firm' },
      { field: 'Date', type: 'Date', header: 'Date' },
      { field: 'Pdctp', type: 'String', header: 'PDC Type' },
      { field: 'Party_name', type: 'String', header: 'Party Name' },
      { field: 'Amt', type: 'String', header: 'Amount ' },
      { field: 'Duedt', type: 'Date', header: 'Due Date' },
      { field: 'Status', type: 'Dropdown', header: 'Status', items: this.StateList }
    ]

    this.bSearch = []

    this.loading = false;
  }
ngOnDestroy(): void {
  console.clear();
}
  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['Firm', 'Date', 'Pdctp', 'Party_name', 'Amt', 'Invno', 'Duedt', 'Status', 'Action'];
  }
  getData() {
    this.loading = true;
    this.datalist = [];
    var data: any = {};
    let url = this.hardfunc.BaseAPIUrl + "api/PdcAPI/getDatas";
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer' + localStorage.getItem("UserToken")
    })
    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    let cnd = "";
    this.bSearch.map(m => {
      if (m.Operator && m.FieldType && m.Value) {
        if (m.Logical) {
          cnd = cnd + m.Logical + ' ';
        }
        cnd = cnd + m.Field;
        switch (m.Operator) {
          case "eq":
            cnd = cnd + " = ";
            break;
          case "lt":
            cnd = cnd + " = ";
            break;
          case "lteq":
            cnd = cnd + " <= ";
            break;
          case "gt":
            cnd = cnd + " < ";
            break;
          case "gteq":
            cnd = cnd + " >= ";
            break;
          case "cont":
          case "start":
          case "end":
            cnd = cnd + " like ";
            break;
          default:
            cnd = cnd + " = ";
            break;
        }
        if (m.FieldType === "String") {
          switch (m.Operator) {
            case "cont":
              cnd = cnd + "'%" + m.Value + "%'    ";
              break;
            case "start":
              cnd = cnd + "'" + m.Value + "%'    ";
              break;
            case "end":
              cnd = cnd + "'%" + m.Value + "'    ";
              break;
            default:
              cnd = cnd + "'" + m.Value + "'    ";
              break;
          }

        }
        if (m.FieldType === "Dropdown") {
          cnd = cnd + "'" + m.Value + "'    ";
        }
        if (m.FieldType === "Date") {
          cnd = cnd + " convert(datetime,'" + moment(m.Value).format('DD-MM-YYYY') + "',103)    ";
        }
      }
    });
    this.repmodel.cond = cnd;

    //this.pdcservice.getDatas(data)
    this._http.post(url, this.repmodel, { headers: headers })
      .subscribe((data1: any) => {
        this.datalist = JSON.parse(data1);
        this.loading = false;
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        //this.dataSource.sort = this.sort;
        //this.dataSource.paginator = this.paginator;
        this.DisplayCol();
      },
        err => {
          console.log(err);
          if (err.error.error_description) {
            this.openSnackBar("saverity: 'error', summary: 'Error' Message', detail: err.error.error_description", "dismiss");
          }
          else {
            this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          }
        }
      )
  }

  addSearch() {
    let validrecords: boolean = true;
    let i = 0;
    this.bSearch.map(m => {
      if (!m.Logical && i > 0) {
        validrecords = false;
      }
      if (!m.Field) {
        validrecords = false;
      }
      if (!m.Operator) {
        validrecords = false;
      }
      if (!m.Value) {
        validrecords = false;
      }
      i++;
    });
    if (validrecords) {
      if (i === 0) {
        this.bSearch.push({});
      }
      else {
        this.bSearch.push({ Logical: 'and' });
      }
    }
    else {
      alert("Incomplete conditions");

    }


  }

  FldChange(obj: any) {

    var filter = this.FieldList.filter(m => m.field === obj.Field);
    if (filter) {
      obj.FieldType = filter[0].type;
      obj.Items = filter[0].items;
    }
    else {
      obj.FieldType = "";
    }
  }

  deleteRow(validrecords) {
    let index: string = this.datasource.findIndex(i => i === validrecords);
    this.bSearch.splice(index, 1)
    this.dataSource = new MatTableDataSource(<any>index);
  }

  openDialog(Pk_id: number): void {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    let w = '90%';
    let h = '80%';
    if (isSm )
    {
       w = '100%';
       h = '100%';
    }
    
    const dialogRef = this.dialog.open(PdcCrudComponent, {
      width: w,
      maxWidth:w,
      height: h,
      data: { id: Pk_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  DeleteRcd(Pk_id: number): void {
    this.datalist = [];
    var data: any = {};
    if (confirm("Are you sure you want to delete this ?")) {
      this.pdcservice.DeleteRecrod(Pk_id).subscribe(() => {
        this.massage = "Deleted Successfully";
        this.getData();
      });
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}


/* PDC Rights
0 -> View
1 -> Add
2 -> Edit
3 -> Delete
4 -> Dashboard 
5 -> Dashboard-Edit
6 -> Report
7 -> Report - Download


*/
