import { Component, OnInit, Optional, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { IndentListComponent } from '../indent-list.component';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray, MaxLengthValidator } from '@angular/forms';
import { PurchaseService } from '../../purchase.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import {map, startWith} from 'rxjs/operators';
import { ElementRef } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { C, COMMA, ENTER, X } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Sort, MatTableDataSource } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-indent-dialog',
  templateUrl: './indent-dialog.component.html',
  styleUrls: ['./indent-dialog.component.css']
})
export class IndentDialogComponent implements OnInit {

  DataForm: FormGroup;
  IndeterForm: FormGroup;
  loading: boolean = false;
  uloading: boolean =false;
  UserData: any = [];
  filteredUsers: any = [];
  visible = true;
  removable = true;
  addOnBlur = true;
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  Users: string[] = [];
  Prio: any = {};
  public editor = ClassicEditor;
  @ViewChild('UserInput', {static: false}) UserInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              @Optional() public dialogRef: MatDialogRef<IndentListComponent>,
              private fb: FormBuilder,
              private purService: PurchaseService,
              private NotificationService: NotificationService,
              private HardFunc: HardFuncService,
              private CommonService: CommonService,
              private MatSnackBar: MatSnackBar) { }

  ngOnInit() {
    this.loading = true;
    this.GetUser();    
    this.DataForm = this.createFormGroup();
    console.log(this.ReceivedData.Remark);
    this.DataForm.get("Remark1").patchValue(this.ReceivedData.Remark1);
    this.DataForm.get("Purpose1").patchValue(this.ReceivedData.Purpose1);
    //this.DataForm.get("Qnty1").patchValue(this.ReceivedData.Qnty1);
    this.DataForm.get("Aqnty").patchValue(this.ReceivedData.Aqnty);
    this.filteredUsers = this.DataForm.get('UserCtrl').valueChanges.pipe(
      startWith(null),
      map((User: string | null) => User ? this._filter(User) : this.UserData.slice()));
  }
  SaveData(){
    this.Users.map((w:any)=>
    {
        (this.DataForm.controls['SubData'] as FormArray).push(this.createItem(w));
        console.log(w);
        this.DataForm.controls['To_whom'].patchValue(w);         
    });   
    console.log(this.DataForm.value);
    this.purService.SaveData(this.DataForm.value,this.ReceivedData.Slip_no)
      .subscribe(
        data => {
          console.log(data);
          this.loading = false;
          this.NotificationService.success(' Data Saved Successfully... ');
          this.dialogRef.close();
        },
        err => {
          let errstr = this.HardFunc.getError(err);
          console.log(err);
          this.NotificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  //2. api/UsersAPI/GetAllUsers
  GetUser() {
    this.uloading = true;
    this.CommonService.getAllUsers()
      .subscribe(
        (data: any) => {
          this.UserData = data;
          this.UserData = this.UserData.map((x) => {
            return {
              nm: x.UserName,
              id: x.Id
            }
          });
          this.uloading = false;
        },
        err => {
          let errstr = this.HardFunc.getError(err);
          this.NotificationService.warn(errstr);
          this.uloading = false;
        });
  }
  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['Remark'];
  }
  // GetData() {
  //   this.loading = true;
	// 	this.datalist = [];

  //   // if (this.ReceivedData) {
  //     this.purService.GetIndentById(this.ReceivedData.Ordno)
  //         .subscribe(
  //           (data: any) => {
  //             console.log(data);
  //             this.loading = false;
  //             this.dataSource = new MatTableDataSource(<any>data);
  //             this.DisplayCol();
  //           },
  //           err => {
  //             let errstr = this.HardFunc.getError(err);
  //             this.NotificationService.warn(errstr);
  //           }
  //         )
  //   // }
  //   // else {
  //   //   this.ReceivedData = {};
  //   // }
  // }

  Delete_note(Ordno: number): void{
    this.loading = true;
    const dialogRef = this.purService.Delete_note(Ordno)
    .subscribe(
      (data:any)=>{
      this.loading = false;
      this.NotificationService.warn("Data deleted succefully");
      },
      err => {
      this.loading = false;
      let errstr =  this.HardFunc.getError(err);
      console.log(err);
      this.NotificationService.warn(errstr);
      }
    )
}
  // Edit_note(Indent_id: number,Remark:string, Qnty1: number): void{
  //   this.DataForm.controls["Remark"].patchValue(Remark);
  //   //this.DataForm.controls["Qnty1"].patchValue(Qnty1);
  //   this.DataForm.controls["Indent_id"].patchValue(Indent_id);
  // }
  
  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;
  
      if ((value || '').trim()) {
        this.Users.push(value.trim());
      }
  
      if (input) {
        input.value = '';
      }
  
      this.DataForm.get('UserCtrl').setValue(null);
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.Users.push(event.option.viewValue);
    this.UserInput.nativeElement.value = '';
    this.DataForm.get('UserCtrl').setValue(null);
  }
  remove(User: string): void {
    const index = this.Users.indexOf(User);
  
    if (index >= 0) {
      this.Users.splice(index, 1);
    }
  }
  createFormGroup() {
    return this.fb.group({
      Indent_id: new FormControl(this.ReceivedData.Ordno),
      Purpose1: new FormControl('',Validators.maxLength(100)),
      Remark1: new FormControl('',Validators.maxLength(250)),
      //Qnty1: new FormControl(),
      Aqnty: new FormControl(),
      UserCtrl: new FormControl(''),
      Username: new FormControl(this.ReceivedData.Indt_nm.trim()),
      Indt_nm: new FormControl(this.ReceivedData.Indt_nm),
      Indt_id: new FormControl(this.ReceivedData.Indt_id),
      Srno: new FormControl(this.ReceivedData.Ordno),
      Prio: new FormControl(this.Prio),
      To_whom: new FormControl(this.Users),
      SubData: this.fb.array([])
    });
  }
  createItem(user:string ): FormGroup {
    return this.fb.group({
      Username:[user]
    });
  }
  IndenterFormGroup() {
    return this.fb.group({
      Indt_id: new FormControl(),
      Indt: new FormControl(''),
      Indt_nm: new FormControl(''),
      Username: new FormControl(''),
    });
  }
                                                                     
  openSnackBar(message: string, action: string) {
    this.MatSnackBar.open(message, action, {
      duration: 2000,
    });
  }
  private _filter(value: string): string[] {
    
    const filterValue = value.toLowerCase();

    return this.UserData.filter(User => User.nm.toLowerCase().indexOf(filterValue) === 0);
  }
}
