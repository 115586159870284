import { Component, OnInit ,Inject } from '@angular/core';
import { PendordComponent } from '../pendord/pendord.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SalesService } from '../sales.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-order-inv',
  templateUrl: './order-inv.component.html'

})
export class OrderInvComponent implements OnInit {
  invlist:any = [];
  constructor( public dialogRef: MatDialogRef<PendordComponent>,
    @Inject(MAT_DIALOG_DATA) public receiveddata: any,
    private sal_service : SalesService,
    private _snackBar: MatSnackBar
    ) { }

  ngOnInit() {
  this.sal_service.GetInv_by_Ordno(this.receiveddata)
      .subscribe(
          data => {
              this.invlist = data;
          },
          err => {
            if(err.error.Message){
            this.openSnackBar(err.error.Message,"dismiss");
            //alert("Error in getting data "+err.error.Message);
            }
            else{
            this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
            //alert ("Error in getting data , Please check server connectivity ...");
            }
            console.log(err);
          }
      )
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
