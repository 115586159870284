import { Component, OnInit, Inject, Optional } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { AdminService } from '../admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MatTableDataSource } from '@angular/material';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-fmodule',
  templateUrl: './fmodule.component.html',
  styleUrls: ['./fmodule.component.css']
})
export class FmoduleComponent implements OnInit {
  loading: boolean = false;
  DataForm: FormGroup;
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  title: string;

  constructor(private fb: FormBuilder,
              private adminservice: AdminService,
              private notificationservice: NotificationService,
              private hardfunc: HardFuncService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any) { }

  ngOnInit() {
   
    this.DataForm = this.createFormGroup();
    this.GetData();
  }
  
  SaveData(){
    this.loading = true;
    this.datalist = [];
    console.log(this.DataForm.value);
    this.adminservice.SaveFmodule(this.DataForm.value)
      .subscribe(data => {
          this.loading = false;
          this.notificationservice.success("Data Saved Successfully...");
        });
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationservice.warn(errstr);
          this.loading = false;
        }
  }
  
  GetData() {
   
    if (this.ReceivedData) {
      
      if (this.ReceivedData.id == -1) {
        this.title = "ADDITION";
      }
      else {
        this.adminservice.GetDatafmodule(this.ReceivedData.id)
          .subscribe(
            (data: any) => {
              data.Grp = data.Grp.trim();
              //data.Descr = data.Descr.trim();
            
              this.DataForm.patchValue(data);
              this.title = "EDITING";
              return false;
            },
            err => {
              this.loading = false;
              let errstr = this.hardfunc.getError(err);
              this.notificationservice.warn(errstr);
            }
          )
      }
    }
    else {
      this.ReceivedData = {};
      this.ReceivedData.id = -1;
      this.title = "ADDITION";
    }
  }    
  createFormGroup(){
    return this.fb.group({
      Pk_id: new FormControl(-1),
      Module_id: new FormControl('',Validators.maxLength(100)),
      Grp: new FormControl('', Validators.maxLength(20)),
      Do_event: new FormControl('',Validators.maxLength(40)),
      Descr: new FormControl('', Validators.maxLength(100)),
      chk1: new FormControl('',Validators.maxLength(6)),
      chk2: new FormControl('',Validators.maxLength(6)),
      chk3: new FormControl('',Validators.maxLength(6)),
      chk4: new FormControl('',Validators.maxLength(6)),
      chk5: new FormControl('',Validators.maxLength(6)),
      chk6: new FormControl('',Validators.maxLength(6)),
      V_type: new FormControl('')
    });
  }
}
