import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators, FormControlName } from '@angular/forms';
import { MatDialog, MatTableDataSource, MAT_DIALOG_DATA, MatSnackBar, MatDialogRef, MatPaginator } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { EnqService } from '../Enq/Enq.service';

@Component({
	selector: 'app-enq-help',
	templateUrl: './enq-help.component.html',
	styleUrls: ['./enq-help.component.css']
})
export class EnqHelpComponent implements OnInit {

	loading: boolean = false;
	datalist: any = [];
	dataSource: MatTableDataSource<unknown>;
	displayedColumns: string[] = [];
	DataForm: FormGroup;
	msgFromChild1 = [];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	constructor(private _snackBar: MatSnackBar,
		private hardfunc: HardFuncService,
		private http: HttpClient,
		private fb: FormBuilder,
		private enqservice: EnqService) { }

	ngOnInit() {
		this.loading = true;
		this.DataForm = this.createFormGroup();
		this.GetData();
	}
	DisplayCol() {
		this.displayedColumns = ['Srno', 'Party_name'];
	}
	GetData() {
		let data: any = {};
		data.Fdate = this.hardfunc.hsConvDt(this.DataForm.controls["Fdate"].value);
		data.Tdate = this.hardfunc.hsConvDt(this.DataForm.controls["Tdate"].value);
		data.Option = this.DataForm.controls["Option"].value;
		this.enqservice.GetEnqHelp(data)
			.subscribe((data: any) => {
				this.datalist = data;
				this.dataSource = new MatTableDataSource(<any>this.datalist);
				this.DisplayCol();
				this.loading = false;
				this.dataSource.paginator = this.paginator;
			},
				err => {
					this.loading = false;
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description, "dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					}
				}
			)
	}
	msgToParent(Srno: number, Party_name: string) {
		this.msgFromChild1.push(Srno);
		this.msgFromChild1.push(Party_name);
	}
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
	createFormGroup() {
		return this.fb.group({
			Fdate: new FormControl(this.hardfunc.Fdate),//this.hardfunc.Fdate
			Tdate: new FormControl(new Date()),
			Option: new FormControl("P")
		});
	}
	openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}
}
