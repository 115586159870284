import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './Views/dashboard/dashboard.component';
import { AdminLayoutComponent } from './Components/common/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './Components/common/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './Services/auth/auth.guard';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: 'src/app/Views/sessions/sessions.module#SessionsModule',
        data: { title: 'Session' }
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    //  canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { title: 'Dashboard', breadcrumb: 'DASHBOARD' }
      },
      {
        path: 'gst',
        loadChildren: 'src/app/Views/gst/gst.module#GstModule',
        data: { title: 'GST' }
      },
      {
        path: 'admin',
        loadChildren: 'src/app/Views/admin/admin.module#AdminModule',
        data: { title: 'GST' }
      },

      {
        path: 'sales',
        loadChildren: 'src/app/Views/sales/sales.module#SalesModule',
        data: { title: 'GST' }
      },
      {
        path: 'finance',
        loadChildren: 'src/app/Views/finance/finance.module#FinanceModule',
        data: { title: 'Finance' }
      },

      {
        path: 'manufacturing',
        loadChildren: 'src/app/Views/manufacturing/manufacturing.module#ManufacturingModule',
        data: { title: 'GST' }
      },
      {
        path: 'general',
        loadChildren: 'src/app/Views/general/general.module#GeneralModule',
        data: { title: 'General' }
      },
      {
        path: 'purchase',
        loadChildren: 'src/app/Views/purchase/purchase.module#PurchaseModule',
        data: { title: 'purchase' }
      },
      {
        path: 'master',
        loadChildren: 'src/app/Views/master/master.module#MasterModule',
        data: { title: 'master' }
      }]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
