import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { PurchaseService } from '../../purchase.service';
import { PoGenComponent } from '../po-gen.component';


@Component({
  selector: 'app-po-party-list',
  templateUrl: './po-party-list.component.html',
  styleUrls: ['./po-party-list.component.css']
})
export class PoPartyListComponent implements OnInit {

  PartyList: any = [];  

  constructor(private PurchaseService: PurchaseService,
              private dialog: MatDialog ) { }

  ngOnInit() {
    this.GetData();
    // this.DataForm.get('Party_name').valueChanges.subscribe(response => {
    //   this._filter(response);
    // }); 
  }
  GetData(){
    this.PurchaseService.PoPartyList()
      .subscribe((data: any) => {
        console.log(data);
        this.PartyList = data;
      });
  }
  POGenerate(data: any, Status: string){
    const dialogRef = this.dialog.open(PoGenComponent, {
      // width: '1300px',
      height: '700px',
      data: { data: data, Status: Status }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  
}
