import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { DbdetailComponent } from '../dbdetail/dbdetail.component';
import { DatePipe } from '@angular/common'
import { AdminService } from '../admin/admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import * as   XLSX from 'xlsx';
import { EnqListComponent } from '../sales/Enq/Enq-list.component';
import * as moment from 'moment'
import { PieChartComponent } from '../shared/pie-chart/pie-chart.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit, OnDestroy {
  DashData: any = [];
  loading: boolean;
  datalist: any = [];
  repmodel: any = {};
  OrdData: any = [];
  IndentData: any = [];
  SalData: any = [];
  Purdata: any = [];
  OrdGrp: any = [];
  OsData: any = [];
  Paymrecd: any = [];
  Paympaid: any = [];
  DirectorList: any = [];
  HideOrdGrp: boolean = true;
  xAmt: number = 0;
  Last_refresh: Date;
  TotStat: any = [];
  Totspkdt: any = [];
  Ptpenord: any = [];
  TotSal: number;
  TotspSal: number;
  xtot: number;
  Spkdata: any = [];
  EnqData: any = [];
  Statordata: any = [];
  Ppurordata = [];
  Totsal1: number;
  PtpenordAll: any = [];
  FinanYear: any = [];
  Dir_id: number = 0;
  Finyr: number = 0;
  headers: any = {};
  FinyrList: any = [];
  DataForm: FormGroup;
  TotDispatch: number;
  SalesTitle: string;
  DispTitle: string;
  CommonTbl: any = [{ value: 'Date' }, { value: 'Nos of Order' }, { value: 'Amount' }];
  DispTbl: any = [{ value: 'Date' }, { value: 'Domestic' }, { value: 'Export' }];

  constructor(
    private _http: HttpClient,
    private hardfunc: HardFuncService,
    private commonservice: CommonService,
    private _cservice: CommonService,
    private adminservice: AdminService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public notificationservice: NotificationService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })

    this.loading = true;
    this.adminservice.getModulesbyUserAndParent_id(this.hardfunc.UserName, 1057)
      .subscribe(
        (data: any) => {
          data = JSON.parse(data);
          this.DashData = data.filter(w => w.Permission.substring(1, 2) === 'Y');
          this.RefreshDashboard();
          
          setInterval(() => {
            this.RefreshDashboard();
          }, 30 * 60 * 1000);
        },
        err => {
          this.notificationservice.warn('Error in getting data...');
        }
      )
    this.getFinyr();
    //this.DataForm = this.createFormGroup();
  }
  ngOnDestroy(): void {
    console.clear();
  }

  RefreshDashboard() {
    this.loading = true;
    this.datalist = [];
    this.hardfunc.LastRefresh = this.datepipe.transform(new Date(), 'HH:mm');
    //this.hardfunc.LastRefresh = new Date().toDateString();

    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    this.repmodel.Ac_typ = 'T';
    this.getHandles(this.headers);



    // let url9 = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/SalesDatewise";
    // this._http.post(url1, this.repmodel, { headers: this.headers })
    // .subscribe((data1: any) => {
    //   this.loading = false;

    //   this.SalData = [];
    //   let l_Grp_nm = '###';
    //   data1.map(m => {
    //     if (l_Grp_nm !== m.Grp_nm) {
    //       this.SalData.push({ Header: 1, Grp_nm: m.Grp_nm });
    //       l_Grp_nm = m.Grp_nm
    //     }
    //     m.Header = 0;
    //     { this.SalData.push(m); }
    //   });

    // }, err => {
    //   console.log(err);

    // });

    let url2 = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/Indent";
    this._http.post(url2, this.repmodel, { headers: this.headers })
      .subscribe((data1: any) => {
        this.loading = false;

        this.IndentData = data1;
      }, err => {
        console.log(err);

      });


    // Sales Outstanding
    let url3 = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/Dash_Os_Summary";
    this._http.post(url3, this.repmodel, { headers: this.headers })
      .subscribe((data1: any) => {        
        this.loading = false;
        this.xAmt = 0
        let l_firm = '###';
        this.OsData = [];
        data1.map(xyz => {   // Do While .not. eof()
          if (l_firm !== xyz.Firm) {
            if (l_firm !== '###') {
              this.OsData.push({ Header: 2, Amt: this.xAmt });
              this.xAmt = 0;
            }
            this.OsData.push({ Header: 1, Firm: xyz.Firm });
            l_firm = xyz.Firm;
          }
          xyz.Header = 0;
          this.OsData.push(xyz);
          this.xAmt += xyz.Amt;
        });  // Enddo
        if (l_firm !== '###') {
          this.OsData.push({ Header: 2, Amt: this.xAmt });
          this.xAmt = 0;
        }
      }, err => {
        console.log(err);

      });
    let url4 = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/dash_paymrecd";
    this._http.post(url4, this.repmodel, { headers: this.headers })
      .subscribe((data1: any) => {        
        this.loading = false;
        let l_firm = '###';
        this.Paymrecd = [];
        data1.map(xyz => {   // Do While .not. eof()
          if (l_firm !== xyz.Firm) {
            if (l_firm !== '###') {
              this.Paymrecd.push({ Header: 2, Amt: this.xAmt });
              this.xAmt = 0;
            }
            this.Paymrecd.push({ Header: 1, Firm: xyz.Firm });
            l_firm = xyz.Firm;
          }
          xyz.Header = 0;
          this.Paymrecd.push(xyz);
          this.xAmt += xyz.Amt;
          
        });  // Enddo
        if (l_firm !== '###') {
          this.Paymrecd.push({ Header: 2, Amt: this.xAmt });
          this.xAmt = 0;
        }

      }, err => {
        console.log(err);

      });

    let url5 = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/dash_sales_statewise";
    this._http.post(url5, this.repmodel, { headers: this.headers })
      .subscribe((data1: any) => {
        this.loading = false;
        this.TotStat = data1;
        this.TotSal = 0
        data1.map(xyz => {   // Do While .not. eof()
          this.TotSal += xyz.Value;
        });  // Enddo
      }, err => {
        console.log(err);

      });


    // Pending Order Party wise
    // this.dash_Order_Partywise();
    this.SalesOrder();
    this.SalesDatewise();
    this.pending_order();
    this.dash_Pending_PurOrder();
    this.dash_paympaid();
    this.dash_Purchase();
    this.Enq_Dashboard_Dir();
  }
  dash_order_mfwise(data: any) {
    const dialogRef = this.dialog.open(PieChartComponent, {
      width: '1600px',
      height: '600px',
      data: { data: data }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed ');
    });
  }

  // Sales order
  SalesOrder() {
    this.Statordata = [];
    this.Spkdata = [];
    this.SalesTitle = "Sales Order";
    this.CommonTbl = [{ value: 'Date' }, { value: 'Nos of Order' }, { value: 'Amount' }];
    let url = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/OrderDatewise";
    this._http.post(url, this.repmodel, { headers: this.headers })
      .subscribe((data1: any) => {        
        this.loading = false;
        this.OrdData = [];
        let l_Grp_nm = '###';
        data1.map(m => {
          if (l_Grp_nm !== m.Grp_nm) {
            this.OrdData.push({ Header: 1, Grp_nm: m.Grp_nm, Sorder: 'so' });
            l_Grp_nm = m.Grp_nm
          }
          m.Header = 0;
          m.Sorder = "so";
          { this.OrdData.push(m); }
        });
      }, err => {
        console.log(err);

      });
  }

  // Sprinkler-Flexible SO
  SpkSalesOrder() {
    this.OrdData = [];
    this.Statordata = [];
    this.SalesTitle = "Sprinkler-Flexible Sales Order";
    this.CommonTbl = [{ value: 'Date' }, { value: 'Nos' }, { value: 'Qnty' }, { value: 'Amt' }];
    let url6 = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/SpkOrderDatewise";
    this._http.post(url6, this.repmodel, { headers: this.headers })
      .subscribe((data1: any) => {
        this.loading = false;
        this.Spkdata = [];
        let l_Grp_nm = '###';
        data1.map(m => {
          if (l_Grp_nm !== m.Grp_nm) {
            this.Spkdata.push({ Header: 1, Grp_nm: m.Grp_nm });
            l_Grp_nm = m.Grp_nm
          }
          m.Header = 0;
          { this.Spkdata.push(m); }
        });
      }, err => {
        console.log(err);
      });
  }

  // Pending Order Partywise
  dash_Order_Partywise() {
    this.OrdData = [];
    this.Spkdata = [];
    this.SalesTitle = "Statewise Sales Order";
    this.CommonTbl = [{ value: 'State Name' }, { value: 'Amt' }];
    let url7 = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/dash_Order_statewise";
    this._http.post(url7, this.repmodel, { headers: this.headers })
      .subscribe((data1: any) => {
        this.loading = false;
        this.xtot = 0
        data1.map(xyz => {   // Do While .not. eof()
          this.xtot += xyz.Amt;
        });  // Enddo
        this.Statordata = [];
        let l_Grp_nm = '###';
        data1.map(m => {
          if (l_Grp_nm !== m.Grp_nm) {
            //this.Statordata.push({ Header: 1, Grp_nm: m.Grp_nm });
            l_Grp_nm = m.Grp_nm,
              m.Statewise = 'State'
          }
          m.Header = 0;
          m.Statewise = "State";
          { this.Statordata.push(m); }
        });

      }, err => {
        console.log(err);

      });
  }

  // Goods Dispatched
  SalesDatewise() {
    this.Totspkdt = [];
    this.DispTitle = "Goods Dispatched";
    this.DispTbl = [{ value: 'Date' }, { value: 'Domestic' }, { value: 'Export' }];
    let url1 = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/SalesDatewise";
    this._http.post(url1, this.repmodel, { headers: this.headers })
      .subscribe((data1: any) => {

        let data = JSON.parse(data1);
        this.TotDispatch = 0;
        data.map(m => {
          if (m.Domestic != null) {

            this.TotDispatch += m.Domestic + m.Export
          }
        });
        // console.log(this.TotDispatch);
        this.SalData = data;
        this.loading = false;
        // let l_Grp_nm = '###';

        // data.map(m => {
        //   if (l_Grp_nm !== m.Grp_nm) {
        //     this.SalData.push({ Header: 1, Grp_nm: m.Grp_nm });
        //     l_Grp_nm = m.Grp_nm
        //   }
        //   m.Header = 0;
        //   { this.SalData.push(m); }
        // });

      }, err => {
        console.log(err);

      });
  }

  // Spk Flexible Dispatched
  SpkDispatch() {
    this.SalData = [];
    this.DispTitle = "Sprinklar-Flaxibale Dispatched";
    this.DispTbl = [{ value: 'Date' }, { value: 'Nos' }, { value: 'Value' }];
    let url8 = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/Salespkdatewise";
    this._http.post(url8, this.repmodel, { headers: this.headers })
      .subscribe((data1: any) => {
        this.loading = false;
        this.Totspkdt = [];
        this.TotspSal = 0
        let l_Grp_nm = '###';
        data1.map(m => {
          if (l_Grp_nm !== m.Pname) {
            this.Totspkdt.push({ Header: 1, Grp_nm: m.Pname });
            data1.map(xyz => {   // Do While .not. eof()
              this.TotspSal += xyz.Amt;
            });  // Enddo
            l_Grp_nm = m.Pname
          }
          m.Header = 0;
          { this.Totspkdt.push(m); }
        });
      }, err => {
        console.log(err);
      });
  }


  // Pending Purchase Order Partywise
  dash_Pending_PurOrder() {

    let url10 = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/PendPurOrdParty";
    this._http.post(url10, this.repmodel, { headers: this.headers })
      .subscribe((data1: any) => {
        this.loading = false;
        this.xtot = 0
        this.Ppurordata = [];
        let l_Pname = '###';
        data1.map(m => {
          if (l_Pname !== m.Pname) {
            this.Ppurordata.push({ Header: 1, Pname: m.Pname, Pmst_cod: m.Pmst_cod });
            l_Pname = m.Pname
          }
          m.Header = 0;
          { this.Ppurordata.push(m); }
        });

      }, err => {
        console.log(err);

      });
  }

  pending_order() {

    let option: number;

    let url9 = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/PendOrderParty";
    this._http.post(url9, this.repmodel, { headers: this.headers })

      .subscribe((data1: any) => {
        this.loading = false;
        this.PtpenordAll = data1;
        this.Ptpenord = [];
        let l_Grp_nm = '###';
        this.Totsal1 = 0;
        data1.map(m => {
          if (l_Grp_nm !== m.Grp_nm) {
            this.Ptpenord.push({ Header: 1, Grp_nm: m.Grp_nm, Pmst_cod: m.Pmst_cod, Lyr_clbal: m.Lyr_clbal });

            l_Grp_nm = m.Grp_nm
          }
          this.Totsal1 += m.Amt;
          m.Header = 0;
          { this.Ptpenord.push(m); }
        });
      }, err => {
        console.log(err);
      });

  }
  getFinyr() {
    this.loading = true;
    this.commonservice.CodeList('FINYEAR')
      .subscribe(
        (data: any) => {
          this.FinyrList = JSON.parse(data);
          this.loading = false;
          this.FinanYear = data;
          this.FinanYear = [];
          let l_Finyr = '###';
          this.Totsal1 = 0;
          this.FinanYear.map(m => {
            if (l_Finyr !== m.Finyr) {
              this.FinanYear.push({ Header: 1, Finyr: m.Finyr, Pmst_cod: m.Pmst_cod });

              l_Finyr = m.Finyr
            }
            this.Totsal1 += m.Amt;
            m.Header = 0;
            { this.FinanYear.push(m); }
          });
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
        }

      );
  }
  // Payment Paid data
  dash_paympaid() {
    let url11 = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/dash_paympaid";
    this._http.post(url11, this.repmodel, { headers: this.headers })
      .subscribe((data1: any) => {
        this.loading = false;
        this.Paympaid = data1;        
      }, err => {
        console.log(err);

      });
  }

  dash_Purchase() {
    let url12 = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/PurchaseDatewise";
    this._http.post(url12, this.repmodel, { headers: this.headers })
      .subscribe((data1: any) => {
        this.loading = false;

        this.Purdata = [];
        let l_Grp_nm = '###';
        data1.map(m => {
          if (l_Grp_nm !== m.Grp_nm) {
            this.Purdata.push({ Header: 1, Grp_nm: m.Grp_nm });
            l_Grp_nm = m.Grp_nm
          }
          m.Header = 0;
          { this.Purdata.push(m); }
        });        
      }, err => {
        console.log(err);

      });
  }

  Enq_Dashboard_Dir() {
    let url12 = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/Enq_Dashboard_Dir1";
    let l_Asnto = "###"
    this._http.post(url12, this.repmodel, { headers: this.headers })
      .subscribe((data1: any) => {        
        this.loading = false;
        this.EnqData = [];
        data1.map(w => {
          if (l_Asnto != w.Asnto) {
            this.EnqData.push({ 'Band': "GH", 'Asnto': w.Asnto, 'Pending_enq': w.Pending_enq })
            l_Asnto = w.Asnto;
          }
          w.Band = "D";
          this.EnqData.push(w);
        })
      }, err => {
        console.log(err);

      });
  }
  OpenEnqbyDate(Type: string, Asnto: string, Offered: string) {

    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    let mydata: any = {};
    this.repmodel = {};
    this.repmodel.Dorg = Type;
    this.repmodel.Asnto = Asnto;
    this.repmodel.Offered = Offered;
    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    let w = '80%';
    let h = '80%';

    if (isSm) {
      w = '100%';
      h = '100%';
    }
    const dialogRef = this.dialog.open(EnqListComponent, {
      width: w,
      maxWidth: w,
      height: h,
      data: this.repmodel

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  pending_orderFilter() {

    this.Ptpenord = [];
    let l_Grp_nm = '###';
    this.Totsal1 = 0;
    this.PtpenordAll.map(m => {
      if (this.Dir_id == -1 || m.Dir_id == this.Dir_id) {
        //debugger;
        if (l_Grp_nm !== m.Grp_nm) {
          this.Ptpenord.push({ Header: 1, Grp_nm: m.Grp_nm, Pmst_cod: m.Pmst_cod });
          l_Grp_nm = m.Dir_id
        }
        this.Totsal1 += m.Amt;
        m.Header = 0;
        { this.Ptpenord.push(m); }
      }
    });
  }

  financial_yr() {

    this.Ptpenord = [];
    let l_Grp_nm = '###';
    this.Totsal1 = 0;
    this.PtpenordAll.map(m => {
      if (m.Finyr == this.Finyr) {
        if (l_Grp_nm !== m.Grp_nm) {
          this.Ptpenord.push({ Header: 1, Grp_nm: m.Grp_nm, Pmst_cod: m.Pmst_cod });
          l_Grp_nm = m.Grp_nm
        }
        this.Totsal1 += m.Amt;
        m.Header = 0;
        { this.Ptpenord.push(m); }
      }
    });
  }
  // createFormGroup() {
  // 	return this.fb.group({
  // 	  Handle_id: new FormControl(-1)
  // 	});
  //   }
  OpenModal(Type: string, param2: string, param3: string = '#') {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    let mydata: any = {};
    this.repmodel = {};
    this.repmodel.Type = Type;
    this.repmodel.Tdates = param2;
    this.repmodel.Fyear = param2;
    this.repmodel.State_no = param2;
    this.repmodel.Pmst_cod = param2;
    this.repmodel.Orddt = param3;
    this.repmodel.Grp_nm = param3;
    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    if (param3) {
      this.repmodel.Grp_id = (param3.substring(0, 1) === 'S') ? 61 : 67;  /// IIf()
      this.repmodel.DF = param3 ? param3.substring(0, 1) : '';
    }
    let w = '50%';
    let h = '80%';
    if (isSm) {
      w = '100%';
      h = '100%';
    }
    const dialogRef = this.dialog.open(DbdetailComponent, {
      width: w,
      maxWidth: w,
      height: h,
      data: this.repmodel

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
    });

  }

  OpenModal1(Type: string, str2: string, str3: string, str4: string, Lyr_clbal: number) {
    //debugger;
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    let mydata: any = {};
    this.repmodel = {};
    this.repmodel.Type = Type;
    this.repmodel.Tdates = str2;
    this.repmodel.Fyear = str2;
    this.repmodel.State_no = str2;
    this.repmodel.Pmst_cod = str2;
    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    this.repmodel.Grp_nm = str3;
    this.repmodel.Orddt = str4;
    this.repmodel.Lyr_clbal = Lyr_clbal;
    let w = '50%';
    let h = '80%';

    if (isSm) {
      w = '100%';
      h = '100%';
    }
    const dialogRef = this.dialog.open(DbdetailComponent, {
      width: w,
      maxWidth: w,
      height: h,
      data: this.repmodel

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
    });

  }

  OpenModal2(Type: string, Firmx: string, Yrx: string) {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    let mydata: any = {};
    this.repmodel = {};
    this.repmodel.Type = Type;
    this.repmodel.Fyear = Yrx;
    this.repmodel.Tdates = Yrx;
    this.repmodel.Firmx = Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    let w = '70%';
    let h = '80%';

    if (isSm) {
      w = '100%';
      h = '100%';
    }
    const dialogRef = this.dialog.open(DbdetailComponent, {
      width: w,
      maxWidth: w,
      height: h,
      data: this.repmodel

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
    });

  }

  getHandles(headers: any) {
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=D";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.DirectorList = data1;
      }, err => {
        console.log(err);
      });

  }

  getUserPref(wmodule_id: number) {
    let ShowDashboard = false;
    let pref = this.DashData.filter(w => w.wmodule_id === wmodule_id)
    if (pref.length > 0) {
      ShowDashboard = true;
    }
    return ShowDashboard;
  }
  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.Grp_nm.filter = filterValue.trim().toLowerCase();
  // }

}
