import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MatDialog, MatDialogRef, MatOptionSelectionChange, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AdminService } from '../../admin.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MatTableDataSource } from '@angular/material/table';
import { ModuleManagementComponent } from '../../module-management/module-management.component';

@Component({
  selector: 'app-role-user',
  templateUrl: './role-user.component.html',
  styleUrls: ['./role-user.component.css']
})
export class RoleUserComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  datalist: any = []; 
  datasource: any = [];
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<unknown>;
  Filter: string;

  constructor(
    public dialog: MatDialog,
    private _http: HttpClient, 
    private service: CommonService,
    private adminservice: AdminService,
    private hardfunc: HardFuncService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<ModuleManagementComponent>,
    @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
  ) { }

  ngOnInit() {
    this.getData();
  }
ngOnDestroy(): void {
  console.log();
}
  getData() {
    this.loading = true;
    this.datalist = [];
    this.GetUserPermissionbyRole_id();
  }

  DisplayCol() {
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['name','Email','Permission'];
 }


GetUserPermissionbyRole_id(){
  this.adminservice.GetUserPermissionbyRole_id(this.ReceivedData.id)
  .subscribe(
    (data:any) => {
      this.datalist = JSON.parse(data);
      this.datalist.map(m=> {
        m.PermList = [];
        let str = (m.Permission ? m.Permission.substring(0,6)   : 'NNNNNN');
        for (var i = 0; i <  str.length; i++) {
          let vals = str.substring(i,i+1) ==='Y' ? true : false;
          m.PermList.push({chk:vals})
        }  
      });
      this.DisplayCol();
      this.loading = false;
    },
    err => {
      this.loading = false;
      let errstr =  this.hardfunc.getError(err);
      console.log(err);
      this.notificationService.warn(errstr);
      
    }
  )
}
SaveData(){
  
}
}
