import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit, PipeTransform } from '@angular/core';
//import { Subscription } from "rxjs/Subscription";
import { MatSidenav } from '@angular/material';

// Topbar Imports
import { ThemeService } from '../../../../services/theme/theme.service';

// Navigation
//import { NavigationService } from "../../../../services/navigation/navigation.service";

import { Router } from '@angular/router';
import { GeneralModule } from 'src/app/Views/general/general.module';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { debug } from 'util';
import { STRING_TYPE } from '@angular/compiler/src/output/output_ast';
import { EnqreportsComponent } from 'src/app/Views/sales/enqreports/enqreports.component';
import { title } from 'process';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { AdminLayoutService } from './admin-layout.service';
import { VERSION } from '@angular/material';
import { withLatestFrom } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, FormControlDirective } from '@angular/forms';
import * as moment from 'moment'
import { MatTableDataSource } from '@angular/material/table';
import { EmailComponent } from 'src/app/Views/shared/email/email.component';
//import {NavItem} from './nav-item';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.template.html',
  styleUrls: ['./admin-layout.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class AdminLayoutComponent implements OnInit, AfterViewInit, PipeTransform {
  private isMobile;
  loading: boolean = false;
  isSidenavOpen: Boolean = false;
  isModuleLoading: Boolean = true;
  // moduleLoaderSub: Subscription;
  menuItems: any[];
  menuItems1: any[];
  hasIconTypeMenuItem;
  iconTypeMenuTitle: string;
  server: string;
  firmx: string;
  yrx: string;
  username:string;
  SessionStartTime:Date;  
  Login_id: string;
  UserImage:string;
  @ViewChild('sidenav', {static: true}) sidenav: ElementRef;
  version = VERSION;
  datalist: any = [];
  Noti_Array: any = [];
  no_of_msg: number = 0;
  no_of_SO: number = 0;
  DataForm: FormGroup;
  repmodel: any = {};
  TrialBal: any = [];
  SOAutoRights: any = [];
  TrialBalRights: string;
  SORegMailRights: string;

  filterMetadata = { count: 0 };
  Rights:any = {
    Domestic:false,
    Foreign:false,
    CriGrp:false,
    Chat :false,
    Att: false,
    Corr: false,
    OrderDetail:false
  }
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  filtertext: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  transform(value: string): string {
    const splitBy = '#&#'
    const splittedText = value.split( splitBy );
    return `${ splittedText[0] } ${ splitBy } <br> <b>${ splittedText[1] }</b>`;
  }
  // Topbar
  @Input() notificPanel;
  @Output() onLangChange = new EventEmitter<any>();
  currentLang = 'en';
  availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Hindi',
    code: 'es',
  }]
  egretThemes;

  constructor(
    private themeService: ThemeService,
    public router: Router,
    private hardfunc: HardFuncService,
    private adminservice:AdminService,
    private notificationService : NotificationService,
    private adminlayoutservice: AdminLayoutService,
    private _http: HttpClient,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    //this.getData(1);
    
    this.adminservice.getIP()
    .subscribe(
      (data:any) => {
        this.hardfunc.Ip_Addr=data.ip;
      },
      err => {}
    )
    
          
    // Topbar Init
    this.egretThemes = this.themeService.egretThemes;
    this.username = this.hardfunc.UserName;
    this.SessionStartTime = this.hardfunc.SessionStartTime;
    this.Login_id = this.hardfunc.Login_id;
    if (this.username) {
      this.username = this.username.toUpperCase();
      this.adminservice.getUserbyUsername(this.username)
      .subscribe(
        (data:any) =>{          
          this.UserImage = data.ImageStr;
          this.hardfunc.Dir = data.Dir;
          this.hardfunc.Mkt = data.Mkt;
          //this.hardfunc.UserImage;
          this.getNotification();
        },
        err => {}

      );
    }
    this.SOAutoRights = ['ALPESH','HMS','HARDIK','JIMIT'];
    this.SOAutoRights.forEach(element => {      
      if(this.username === element){                   
        this.SORegMailRights = element;
      }
  });
    this.TrialBal = ['ALPESH','HMS','HARDIK','JIMIT','SHETUL','SUJAL','AMS','MMS','ASHOK','SAGAR'];
    this.TrialBal.forEach(element => {      
        if(this.username === element){                   
          this.TrialBalRights = element;
        }
    });
    let myItem = localStorage.getItem("Server") || "";
    if (myItem != "null" && myItem.length > 0 )  {
      this.server = myItem;
    }
    else{
      this.server = '3';
    }
    this.hardfunc.ChangeServer(myItem);

    let myItem1 = localStorage.getItem("Firmx") || "";
    if (myItem1 != "null" && myItem1.length > 0) {
      this.firmx = myItem1;
    } else{
      this.firmx =  "NFF"; 
      
    }

    this.hardfunc.ChangeFirm(this.firmx);
    let myItem2 = localStorage.getItem("Yrx") || "";     
    
    if (myItem2 != null && myItem2.length > 0) {      
      this.yrx = myItem2;      
    }
    else{
      this.yrx = "2324";
      console.log(this.yrx);
    }       
    this.hardfunc.ChangeYear(this.yrx);

    if (!this.username) {
      return this.router.navigate(['/sessions/signin']);
    }

    // Check with session time out using SessionStartTime with current time, 
    // if login time before 8 AM or 8 PM then logout and redirect to login page
    const sessionStartTime = localStorage.getItem("SessionStartTime");
    if (sessionStartTime) {
      const sessionStartTimeMoment = moment(sessionStartTime);
      const currentTime = moment();
      const diff = currentTime.diff(sessionStartTimeMoment, 'minutes');
      if (diff > 480 || diff < 0) {
        this.openSnackBar("Session timeout , please login again ...  ","dismiss"); 

        this.router.navigate(['/sessions/signin']);
      }
    }else{
      this.openSnackBar("Session timeout (1) , please login again ...  ","dismiss"); 
      this.router.navigate(['/sessions/signin']);
    }
    this.adminservice.getAllModulesbyUser(this.username)
    .subscribe(
      (data:any) => {

        data = JSON.parse(data);
        this.menuItems = data.filter(w => w.Parent_id === 0  );
        
        this.hardfunc.SetPermittedModules(data);
        this.menuItems.map(w => {
             w.show = false;
             w.Items = data.filter(i => i.Parent_id === w.wmodule_id );
            }
          ) 
        this.isModuleLoading = false;
        //this.getNotification();
      },
      err => {
        let errstr =  this.hardfunc.getError(err);
        console.log(err);
        this.notificationService.warn(errstr);
        this.isModuleLoading = false;
      }
    );
    
    
  }
  
  openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}
  
  LoadMenu(str: string,module_id:number) {
    let url = this.hardfunc.BaseAPIUrl + "api/Admin/sessionsAPI/SaveData?Module_id="+module_id+"&Username="+this.username+"&Login_id="+this.hardfunc.Login_id ;
    this._http.get(url).subscribe(
      data => {
        this.router.navigate([str]);
        
      }
    )
  }
  isNavOver() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;

    // Disable collapsed menu in small screen
    // if(isSm && domHelper.hasClass(document.body, 'collapsed-menu')) {
    //   domHelper.removeClass(document.body, 'collapsed-menu')
    // }
    return isSm;
  }

  // Topbar Methods //
  setLang() {
    //    this.onLangChange.emit(this.currentLang);
  }
  changeTheme(theme) {
    this.themeService.changeTheme(theme);
  }

  ServerSelection(event: any) {
    alert("On changing server , user requires to be login again ... ");
    localStorage.setItem("Server",this.server );   
   
    this.hardfunc.ChangeServer(this.server);
    this.router.navigate(['/sessions/signin']);
  }

  FirmSelection(event: any) {
    localStorage.setItem("Firmx",this.firmx);
    this.hardfunc.ChangeFirm(this.firmx);
    window.location.reload();
  }

  YearSelection(event: any) {  
    console.log(event);
    localStorage.setItem("Yrx",this.yrx );
    this.hardfunc.ChangeYear(this.yrx);
    window.location.reload();
  }

  toggleNotific() {
    // this.notificPanel.toggle();
  }
  toggleSidenav() {
    //this.sidenav.toggle();
    this.isSidenavOpen = !this.isSidenavOpen;
  }
  toggleCollapse() {
    let appBody = document.body;
    // domHelper.toggleClass(appBody, 'collapsed-menu');
    // domHelper.removeClass(document.getElementsByClassName('has-submenu'), 'open');
    // // Fix for sidebar
    // if(!domHelper.hasClass(appBody, 'collapsed-menu')) {
    //   (<HTMLElement>document.querySelector('mat-sidenav-content')).style.marginLeft = '240px'
    // }
  }
  ngAfterViewInit() {
    this.adminlayoutservice.sidenav = this.sidenav;
  }
  NotificationForm(){
    
  }
  EmailSend(){
    const dialogRef = this.dialog.open(EmailComponent, {
      width: '1140px',
      height: '645px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    })
  }
  TodaySOmail(){
    
    this.adminservice.TodaySOAutomail()
      .subscribe((data: any) => {
        console.log(data);        
        this.notificationService.success("Mail Sent Successfully...");
      });    
  }
  getNotification() {
    this.Noti_Array = [];
    this.adminservice.getNotificationforDash()
      .subscribe(
        (data: any) => {
          this.Noti_Array = data;          
          this.no_of_msg = 0;
          this.Noti_Array.map(m => {
						if (m.Vtype != null) {
							this.no_of_msg += 1; 
						}
					});
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
        }
      )
  }
  Delete_Noti(Vtype: string): void{
    console.log(Vtype);
		this.loading = true;
		this.adminservice.DeleteNotibyType(Vtype)
		.subscribe(
		  (data:any)=>{
		  this.loading = false;
		//   this.getNotification();
		  },
		  err => {
		  this.loading = false;
		  let errstr =  this.hardfunc.getError(err);
		  console.log(err);
		  }
		)
	}
  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.menuItems.filter = filterValue.trim().toLowerCase();
  // }
  createFormGroup() {
    return this.fb.group({
      Party_name: new FormControl(''),
      OrdDate: new FormControl(null),
      fdate: new FormControl(''),
      tdate: new FormControl(''),
      grp_nm: new FormControl('*All*'),
      Dname: new FormControl('*All*'),
      Mname: new FormControl('*All*'),
      State: new FormControl('Domestic'),
      Status: new FormControl('P'),
      Remarks: new FormControl(''),
      Ord_Status: new FormControl('*All*'),
      Status_change: new FormControl('*All*'),
      Insp: new FormControl('*All*'),
      Ordno :new FormControl(0),
      Oloc :new FormControl(0),
     
    });
  }
}
