import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ManufacturingService } from '../manufacturing.services';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment'
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { PrdHistoryComponent } from '../stk-repo/prd-history/prd-history.component';
import { JwSummaryComponent } from '../../shared/jw-summary/jw-summary.component';
import { JwSummaryItemComponent } from '../../shared/jw-summary-item/jw-summary-item.component';
import { GunmtlScrapitemComponent } from '../../shared/gunmtl-scrapitem/gunmtl-scrapitem.component';
import { CommonService } from 'src/app/Services/Common/common.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { Console } from 'console';

@Component({
  selector: 'app-dashboard-inventory',
  templateUrl: './dashboard-inventory.component.html',
  styleUrls: ['./dashboard-inventory.component.css']
})
export class DashboardInventoryComponent implements OnInit {

  DataForm: FormGroup;
  repmodel: any = {};
  Dept_nm: any = [];
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  displayedCol: string[] = [];
  Numbers: any = [1, 2, 3, 4, 9];
  title: any = [];
  idx: any = [1, 2, 3, 4, 9];
  Username: string;
  JwOutTable: any = [];
  JwInTable: any = [];
  @Input('Option') Option;
  IndDept: any = [];
    
  constructor(private manufacSerivce: ManufacturingService,
              private fb: FormBuilder,
              private hardfunc: HardFuncService,
              private _http: HttpClient,
              private MatSnackbar: MatSnackBar,
              private dialog: MatDialog,
              private commonservice: CommonService,
              private NotificationService: NotificationService) { }

  ngOnInit() {
    this.getDeptName();
    this.DataForm = this.createFormGroup();
    this.GetData(1);
    this.GetData(2);
    this.GetData(3);
    this.GetData(4);
    this.GetData(9);
    this.GetIndDepartment();
    this.Jb_OutTable();
    this.Jw_InTable();
    this.title[1] = "(1) MAIN STORE";
    this.title[2] = "(2) WORK SHOP";
    this.title[3] = "(3) ASSEMBLY";
    this.title[4] = "(4) PACKING";
    this.title[9] = "(5) HOSE REEL";
    this.Username = this.hardfunc.UserName;
  }

  DisplayCol() {
    this.displayedColumns = ['OUTWARDNO', 'Party_name', 'Items'];
  }
  DisplayJwIn() {
    this.displayedCol = ['OUTWARDNO', 'Items'];
  }
  GetData(idx: number) {
    let data = {
      Fdate :  this.hardfunc.hsConvDt(this.DataForm.controls['Fdate'].value),
      Tdate :  this.hardfunc.hsConvDt(this.DataForm.controls['Tdate'].value),
      Dept_id : idx,
      Category: this.DataForm.controls['Category'].value,
      Division: this.DataForm.controls['Division'].value
    }
    this.manufacSerivce.GetInvendashoardData(data)
      .subscribe((data: any) => {        
        var data1 = data;                     
        this.datalist[idx] = [];
        data1.map(m => {         
          m.Header = 1;          
          m.Op_Stock = Math.round(m.Stock + m.Issue - m.Receipt);
          this.datalist[idx].push(m);          
        });        
      },
        err => {
          if (err.error.error_description) {
            this.opensnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.opensnackBar("Error in getting data, Please Check Server Connectivity...", "dismiss")
          }
        })
  }

  //JOBWORK OUT
  Jb_OutTable() {
    console.log(this.DataForm.controls['Tdate'].value);
    let data = {
      Fdate :  this.hardfunc.hsConvDt(this.DataForm.controls['Fdate'].value),
      // Tdate :  this.hardfunc.hsConvDt(this.DataForm.controls['Tdate'].value),
      Categroy: this.DataForm.controls['Category'].value,
      Division: this.DataForm.controls['Division'].value
    }
    this.manufacSerivce.JwOutDashboard(data)
      .subscribe((data: any) => {
        var data1 = data;
        this.JwOutTable = data1;        
        this.dataSource = new MatTableDataSource(<any>this.JwOutTable);
        this.DisplayCol();
      },
        err => {
          if (err.error.error_description) {
            console.log(err);
            this.opensnackBar(err.error.error_description, "dismiss");
          }
          else {
            console.log(err);
            this.opensnackBar("Error in getting data, Please Check Server Connectivity...", "dismiss")
          }
        })
  }

  //JOBWORK IN
  Jw_InTable() {
    let data = {
      Fdate :  this.hardfunc.hsConvDt(this.DataForm.controls['Fdate'].value),
      Tdate :  this.hardfunc.hsConvDt(this.DataForm.controls['Fdate'].value),
      Categroy: this.DataForm.controls['Category'].value,
      Division: this.DataForm.controls['Division'].value
    }
    this.manufacSerivce.JwInDashboard(data)
      .subscribe((data: any) => {        
        var data1 = data;
        this.JwInTable = data1;
      },
        err => {
          if (err.error.error_description) {
            this.opensnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.opensnackBar("Error in getting data, Please Check Server Connectivity...", "dismiss")
          }
        })
  }
  jwSummary(Pmst_cod: string,Party_name: string){
    const dialogRef = this.dialog.open(JwSummaryComponent, {
      width: '800px',
      data: { id: Pmst_cod, Party_name: Party_name }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  jwSummaryItem(PRODUCT_ID: number,ITEM_NM: string){
    const dialogRef = this.dialog.open(JwSummaryItemComponent, {
      width: '800px',
      data: { id: PRODUCT_ID,Item_nm: ITEM_NM }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  GunMetalScrap(JWTR_ID: number, OUTWARDNO: number,PRODUCT_ID: number,ITEM_NM: string){    
    const dialogRef = this.dialog.open(GunmtlScrapitemComponent, {
      width: '800px',
      data: { id: JWTR_ID, OUTWARDNO: OUTWARDNO, PRODUCT_ID: PRODUCT_ID, ITEM_NM: ITEM_NM }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  PDFDownload(idx: number) {
    
    let data = {
      Fdate :  this.hardfunc.hsConvDt(this.DataForm.controls['Fdate'].value),
      Tdate :  this.hardfunc.hsConvDt(this.DataForm.controls['Tdate'].value),
      Dept_id : idx,
      Category: this.DataForm.controls['Category'].value,
      Division: this.DataForm.controls['Division'].value
    }
    this.manufacSerivce.PdfDownloadInvdDash(data)
      .subscribe((data: any) => {
        const data1 = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = data1;
        link.target = "_blank";
        link.download = "Inventory Dashboard.pdf";
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      },
        err => {
          if (err.error.error_description) {
            this.opensnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.opensnackBar("Error in getting data, Please Check Server Connectivity...", "dismiss")
          }
        })
  }
  JwOutdownPDF() {
    //this.DataForm.get("Dept_id").patchValue(idx);
    let data = {
      Fdate :  this.hardfunc.hsConvDt(this.DataForm.controls['Fdate'].value),
      Tdate :  this.hardfunc.hsConvDt(this.DataForm.controls['Tdate'].value),
    }
    this.manufacSerivce.PDFJwOutdown(data)
      .subscribe((data: any) => {
        const data1 = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = data1;
        link.target = "_blank";
        link.download = "Inventory Dashboard.pdf";
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      },
        err => {
          if (err.error.error_description) {
            this.opensnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.opensnackBar("Error in getting data, Please Check Server Connectivity...", "dismiss")
          }
        })
  }
  JwIndownPDF() {
    //this.DataForm.get("Dept_id").patchValue(idx);
    let data = {
      Fdate :  this.hardfunc.hsConvDt(this.DataForm.controls['Fdate'].value),
      Tdate :  this.hardfunc.hsConvDt(this.DataForm.controls['Tdate'].value),
    }
    // let Tdate = this.DataForm.get("Fdate").value;
    // console.log(Tdate);
    this.manufacSerivce.JwIndownPDF(data)
      .subscribe((data: any) => {
        const data1 = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = data1;
        link.target = "_blank";
        link.download = "Inventory Dashboard.pdf";
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      },
        err => {
          if (err.error.error_description) {
            this.opensnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.opensnackBar("Error in getting data, Please Check Server Connectivity...", "dismiss")
          }
        })
  }
  OpenDialog(Product_id: number, Product_nm: string, Selected: number) {
    const dialogRef = this.dialog.open(PrdHistoryComponent, {
      width: '1200px',
      data: { id: Product_id, Short_nm: Product_nm, Dept_id: Selected }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  RefreshGetData() {
    this.GetData(1);
    this.GetData(2);
    this.GetData(3);
    this.GetData(4);
    this.GetData(9);
    this.Jb_OutTable();
    this.Jw_InTable();
  }
  CreatePDF(Selected) {
    this.GetData(Selected);
  }
  opensnackBar(message: string, action: string) {
    this.MatSnackbar.open(message, action, {
      duration: 2000,
    });
  }

  getDeptName() {
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/deptssAPI/GetDatas";
    this._http.post(url, this.repmodel)
      .subscribe((data1: any) => {
        this.Dept_nm = data1;
      }, err => {
        console.log(err);
      });
  }
  GetIndDepartment() {    
    this.commonservice.CodeList('Ind_Dept')
      .subscribe(
        (data: any) => {
          console.log(data);
          this.IndDept = JSON.parse(data);          
        },
        err => {          
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn(errstr);
        }
      );
  }
  createFormGroup() {
    return this.fb.group({
      Fdate: new FormControl(new Date()),
      Tdate: new FormControl(new Date()),
      Dept_id: new FormControl(1),
      Category: new FormControl("*All*"),
      Division: new FormControl("*All*")
      // IndDept: new FormControl()
    });
  }
}

