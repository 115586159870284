import { Injectable } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class Os1Service {

  constructor(private http: HttpClient,
              private hardfunc: HardFuncService,
              private dialog:MatDialog) { }

// GetDatabyId(Osd_id: number) {
// 	let data: any = {};
// 	data.Firmx = this.hardfunc.Firmx;
// 	data.Yrx = this.hardfunc.Yrx;
// 	data.Osd_id = Osd_id;
// 	let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/GetDatabyId?id="+Osd_id;
// 	return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
// }
SaveNotes(data: any, Firm:string, Fyear: string,PMST_COD: string) {
	data.Firm =  Firm;
	data.Fyear = Fyear;
	data.Notes_type = 'I1';
	data.Exr = data.Exr;
	data.Invno = data.Invno;
	data.PMST_COD = PMST_COD;
	let url = this.hardfunc.BaseAPIUrl + "api/Common/notessAPI/SaveData"
	return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
}
GetDatabyId(Osd_id: number, Exr: string, Invno: number,Firm:string, Fyear: string ) {
	let data: any = {};
	data.Firm =  Firm;
	data.Fyear = Fyear;
	//data.Osd_id = Osd_id;
	data.Notes_type = 'I1';
	data.Exr = Exr;
	data.Invno = Invno;
	let url = this.hardfunc.BaseAPIUrl + "api/Common/notessAPI/GetDatasbyInvno";
	return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
}
GetInvRecord2(Exr: string, Invno: number){
	let data: any = {};
	data.Firm = this.hardfunc.Firmx;
	data.Yrx = this.hardfunc.Yrx;
	data.Exr = Exr;
	data.Invno = Invno;
	let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/InvoiceRecord2";
	return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
}
UpdateSorder1(data: any){
	data.Yrx = this.hardfunc.Yrx;
	let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/SavepartialSorder1"
	return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
}
OS_DueDtExpired(){
	let data: any = {};
	data.Firmx = this.hardfunc.Firmx;
	data.Yrx = this.hardfunc.Yrx;
	//data.Pmst_cod = data1.PMST_COD;
	let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/OS_DueDtExpired"
	return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
}
OS_AdvEmail(){
	let data: any = {};
	data.Firmx = this.hardfunc.Firmx;
	data.Yrx = this.hardfunc.Yrx;
	data.Pmst_cod = "A7171";
	let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/OS_AdvEmail"
	return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
}
UpdatePartymst(Pmst_cod: string, DueRem: string){
	let data: any = {};
	data.Firmx = this.hardfunc.Firmx;
	data.Pmst_cod = Pmst_cod;
	data.Duerem = DueRem;
	let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/UpdatePartymst"
	return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
}
UpdateMtkPartial(ORDNO: number, OLOC: number, Handle_id: number){
	let data: any = {};
	data.Firmx = this.hardfunc.Firmx;
	data.Ordno = ORDNO;
	data.Oloc = OLOC;
	data.Mkt_id = Handle_id;
	let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/UpdateMktPartial"
	return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
}
}
