import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ManufacturingService } from '../../manufacturing.services';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray, FormControlName } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator } from '@angular/material';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import * as moment from 'moment';
import * as   XLSX from 'xlsx';
import { PrdHistoryComponent } from '../prd-history/prd-history.component';
import { BomstockComponent } from '../../bomstock/bomstock.component';
import { BOMComponent } from '../../bom/bom.component';
import { BomUtibyComponent } from '../bom-utiby/bom-utiby.component';

//api/ProductsAPI/StockRepAll -- API For --All Stock Report
//api/PrdGroupsAPI/GetPrdGroup -- API For --Product Group

@Component({
  selector: 'app-stkrepall',
  templateUrl: './stkrepall.component.html',
  styleUrls: ['./stkrepall.component.css']
})
export class StkrepallComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  datalist: any = [];
  DataForm: FormGroup;
  dataSource: MatTableDataSource<unknown>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [];
  repmodel: any = {};
  PrdGroupList: any = [];
  filename:string= 'All-Stock-Report.xlsx';
  S_Total: number = 0;
  productlist: any = [];
  paragrouplist: any = [];

  constructor(private ManufacturingService: ManufacturingService,
              private fb: FormBuilder,
              private HardFunc: HardFuncService,
              private NotificationService: NotificationService,
              private _cservice: CommonService,
              private _snackBar: MatSnackBar,
              private dialog: MatDialog,
              private _http: HttpClient) { }

  ngOnInit() {
    this.DataForm = this.createFormGroup();
    // this.GetData();
    this.ItemGroup();
    this.loading = true;
  }
  DisplayCol(){
    this.displayedColumns = ['Product_id','Short_nm','Grp_nm','Loca1','Loca2','Loca3','Loca4','Loca8','Total']
  }
  ngOnDestroy(): void {
    console.clear();
  }
  //1. api/ProductsAPI/StockRepAll
  GetData() {
    this.loading = true;
		this.datalist = [];
      let AsOnDate1 = this.HardFunc.hsConvDt(this.DataForm.controls.AsOnDate.value);
      this.DataForm.controls.AsOnDate.patchValue(AsOnDate1);
      this.ManufacturingService.StockRepAll(this.DataForm.value)
        .subscribe(
          (data1: any) => {
            console.log(data1);
            this.S_Total = 0;
            this.datalist = data1;
            let t_Loca1 = 0;
            let t_Loca2 = 0;
            let t_Loca3 = 0;
            let t_Loca4 = 0;
            let t_Loca5 = 0;
            let t_Loca7 = 0;
            let t_Total = 0;
            this.datalist.map(x => {
              x.Total = Math.round(x.Loca1 + x.Loca2 + x.Loca3 + x.Loca4 + x.Loca8);
              t_Loca1 += x.Loca1;
              t_Loca2 += x.Loca2;
              t_Loca3 += x.Loca3;
              t_Loca4 += x.Loca4;
              t_Loca5 += x.Loca5;
              t_Loca7 += x.Loca7;
              t_Total += x.Total;
            });
            this.datalist.push({
              Loca1: t_Loca1,
              Loca2: t_Loca2,
              Loca3: t_Loca3,
              Loca4: t_Loca4,
              Loca5: t_Loca5,
              Loca7: t_Loca7,
              Total: t_Total,

            });
            this.dataSource = new MatTableDataSource(<any>data1);
            this.dataSource.paginator = this.paginator;
            this.DisplayCol();
            this.loading = false;
          },
            err => {
              this.loading = false;
              let errstr = this.HardFunc.getError(err);
              this.NotificationService.warn(errstr);
            }
          )
  }
  
  openDialog(Product_id: number,Short_nm: string){
    const dialogRef = this.dialog.open(BomstockComponent, {
      width: '1150px',
      height: '500px',
      data: { product_id: Product_id, Product_nm: Short_nm }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
    }
  PrdHistoryDialog(Product_id: number, Loca: number, Short_nm: string, Store: string): void {
		const dialogRef = this.dialog.open(PrdHistoryComponent, {
			width: '1200px',
			data: { id: Product_id, Dept_id: Loca, Short_nm: Short_nm, Store: Store }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log(result);
		});
	}
  BomUtilise(Product_id: number,Short_nm: string){
    console.log(Product_id);
    const dialogRef = this.dialog.open(BomUtibyComponent, {
			width: '1100px',
      height: '500px',
			data: { id: Product_id, Product_nm: Short_nm }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log(result);
		});
  }
  disableField(checked) {
    if (!checked) {
      //this.DataForm.value.Prodgrp_id = 0;
      this.DataForm.controls["Prodgrp_id"].patchValue("All");
    } else {
      this.DataForm.value.Prodgrp_id;
    }
}
  //2. api/PrdGroupsAPI/GetPrdGroupAll
  ItemGroup(){
    this.repmodel.CG = 'A';
    this.repmodel.Status = 'P';
    this._cservice.PrdGroupListAll()
      .subscribe(data => {
        console.log(data);
        this.PrdGroupList = data;
        this.loading = false;
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }
  // ParabyGroup_id(grp_id: number){
  //   console.log(grp_id);
  //   this.ManufacturingService.parabygroup_id(grp_id)
  //     .subscribe((data: any) => {
  //       console.log(data);
  //       this.paragrouplist = data;
  //     });
  // }
  exportexcel():void{
		// Option-2 Entire JSON
		const ws1:XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.datalist);

		this.datalist.map(m =>{
			m.Offer_dt = moment(m.Offer_dt).format('DD/MM/yyyy');
			m.Issue_dt = moment(m.Issue_dt).format('DD/MM/yyyy');
		})

		// Option-3 Selected fields from JSON
		let newData = this.datalist.map(({Product_id,Short_nm,Grp_nm,Loca1,Loca2,Loca3,Loca4,Loca6,Loca7,Total}) =>
										                ({Product_id,Short_nm,Grp_nm,Loca1,Loca2,Loca3,Loca4,Loca6,Loca7,Total}));
		const ws:XLSX.WorkSheet=XLSX.utils.json_to_sheet(newData);
		
		const wb:XLSX.WorkBook=XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws,'Sheet1');
	
		XLSX.writeFile(wb,this.filename);
	  }
  createFormGroup() {
    return this.fb.group({
      Opstock: new FormControl(''),
      Prodgrp_id: new FormControl(0),
      //Paragrp_id: new FormControl(0),
      AsOnDate: new FormControl(new Date())
    });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
