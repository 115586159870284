import { Component, OnInit, Optional, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { PendordService } from '../pendord.service';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { PendordComponent } from '../pendord.component';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/Services/Common/common.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { C, COMMA, ENTER, X } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// 1. api/SordersAPI/ChangeOrdStatus -- API For --Change Order Status.
// 2. api/UsersAPI/GetAllUsers -- API For -- Get All User.
// 3. api/Common/notessAPI/SaveData -- API For -- Save Notes.
// 4. api/Common/notessAPI/GetDatasbyOrdno -- API For --Get Data By Order NO.
// 5. api/SordersAPI/GetOrderbyOrdno -- API For --Get Order By Order No.
// 6. api/Common/notessAPI/DeleteData -- API For --Delete Note
// 7. api/Common/CodemastsAPI/GetCodeListbyCodenm --API For --Order Status

declare var webkitSpeechRecognition: any;

export interface Fruit {
  name: string;
}
@Component({
  selector: 'app-pendord-dialog',
  templateUrl: './pendord-dialog.component.html',
  styleUrls: ['./pendord-dialog.component.css']
})

export class PendordDialogComponent implements OnInit {
  loading: boolean = false;
  datalist: any = [];
  repmodel: any = {};
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  DataForm: FormGroup;
  OrdForm: FormGroup;
  initialValues: any[];
  displayedColumns: string[] = [];
  Ord_Status: any = [];
  filteredUsers: any = [];
  Users: string[] = [];
  UserData: any = [];
  uloading: boolean = false;
  visible = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  SubData: any = [];
  recognition: any = {};

  @ViewChild('UserInput', { static: false }) UserInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  @ViewChild('textArea', {static: false}) textArea: ElementRef<HTMLInputElement>;

  constructor(public hardfunc: HardFuncService,
    private _http: HttpClient,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<PendordComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    //@Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    private pendordservice: PendordService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private router: Router,
    private commonservice: CommonService,
    private notificationservice: NotificationService) { }

  ngOnInit() {
    this.DataForm = this.createFormGroup();
    this.GetData();  // Notes
    this.getOrdDetails();  // Status
    this.initialValues = this.DataForm.value;
    this.loading = true;
    this.OrdForm = this.Ord_FormGroup();
    this.getOrd_Status();  // To get status list
    this.GetUser(); // To get user list
    this.initSpeechRecognition();
    this.filteredUsers = this.DataForm.get('UserCtrl').valueChanges.pipe(
      startWith(null),
      map((User: string | null) => User ? this._filter(User) : this.UserData.slice()));
  }

  UpdateData() {
    console.log(this.OrdForm.value);
    this.pendordservice.UpdateData(this.OrdForm.value)
      .subscribe(
        (data: any) => {
          this.notificationService.success(' Data Saved Successfully... ');
          this.loading = false;
          this.GetData();
          this.ResetData();
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        this.Users.push(value.trim());
      }

      if (input) {
        input.value = '';
      }

      this.DataForm.get('UserCtrl').setValue(null);
    }
  }

  remove(User: string): void {
    const index = this.Users.indexOf(User);

    if (index >= 0) {
      this.Users.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.Users.push(event.option.viewValue);
    this.UserInput.nativeElement.value = '';
    this.DataForm.get('UserCtrl').setValue(null);
  }

  GetUser() {
    this.uloading = true;
    this.commonservice.getAllUsers()
      .subscribe(
        (data: any) => {
          this.UserData = data;
          this.UserData = this.UserData.map((x) => {
            return {
              nm: x.UserName,
              id: x.Id
            }
          });
          this.uloading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
          this.uloading = false;
        });
  }

  SaveData() {
    this.loading = true;
    let to_whom: string = "";
    this.Users.map((w: any) => {
      (this.DataForm.controls['SubData'] as FormArray).push(this.createItem(w));
      to_whom += w + ",";
    });
    this.DataForm.controls['To_whom'].patchValue(to_whom);
    console.log(this.DataForm.value);
    this.pendordservice.SaveData(this.DataForm.value)
      .subscribe(
        data => {
          this.loading = false;
          this.notificationService.success(' Data Saved Successfully... ');
          this.GetData();
          this.ResetData();
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  Ord_FormGroup() {
    return this.fb.group({
      //Status: new FormControl(''),
      Pi_status: new FormControl(''),
      Firm: new FormControl(this.hardfunc.Firmx),
      Ordno: new FormControl(this.ReceivedData.Ordno),
      Oloc: new FormControl(this.ReceivedData.Oloc),
      Yrx: new FormControl(this.hardfunc.Yrx),
      Prio: new FormControl('L')
    });
  }

  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['Remarks'];
  }

  GetData() {
    this.loading = true;
    this.datalist = [];
    console.log(this.ReceivedData.Ordno);
    if (this.ReceivedData) {
      this.pendordservice.GetDatabyId(this.hardfunc.Firmx, this.ReceivedData.Notes_type, this.ReceivedData.Ordno, this.ReceivedData.Oloc)
        .subscribe(
          (data1: any) => {
            console.log(data1);
            //this.datalist = data1;
            this.loading = false;
            this.dataSource = new MatTableDataSource(<any>data1);
            this.DisplayCol();
          },
          err => {
            let errstr = this.hardfunc.getError(err);
            this.notificationService.warn(errstr);
          }
        )
    }
    else {
      this.ReceivedData = {};
    }
  }

  getOrdDetails() {
    this.pendordservice.GetDatabyOrd(this.hardfunc.Firmx, this.ReceivedData.Ordno, this.ReceivedData.Oloc)
      .subscribe(
        (data: any) => {
          console.log(data);
          //data.Status = data.Status.trim();
          data.Prio = data.Prio;
          data.Pi_status = data.Pi_status;

          //this.OrdForm.controls['Status'].patchValue(data.Status);
          this.OrdForm.controls['Prio'].patchValue(data.Prio);
          this.OrdForm.controls['Pi_status'].patchValue(data.Pi_status);
        }
      )
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  Edit_note(Notes_id: number, Remarks: string): void {
    this.DataForm.controls["Remarks"].patchValue(Remarks);
    this.DataForm.controls["Notes_id"].patchValue(Notes_id);
  }

  Delete_note(Notes_id: number): void {
    this.loading = true;
    const dialogRef = this.pendordservice.Delete_note(Notes_id)
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.notificationService.warn("Data deleted succefully");
          this.GetData();
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
        }
      )
  }
  ResetData() {
    this.DataForm.reset();
    this.DataForm.patchValue(this.initialValues);
  }
  getOrd_Status() {
    this.loading = true;
    this.commonservice.CodeList('Ord_Status')
      .subscribe(
        (data: any) => {
          this.Ord_Status = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
        }
      );
  }
  createFormGroup() {
    return this.fb.group({
      Notes_id: new FormControl(-1),
      Notes_type: new FormControl(this.ReceivedData.Notes_type),
      Remarks: new FormControl(''),
      Firm: new FormControl(this.hardfunc.Firmx),
      Ordno: new FormControl(this.ReceivedData.Ordno),
      Oloc: new FormControl(this.ReceivedData.Oloc),
      UserCtrl: new FormControl(''),
      To_whom: new FormControl(''),
      SubData: this.fb.array([]),
    });
  }

  createItem(user: string): FormGroup {
    return this.fb.group({
      Username: [user]
    });
  }

  private _filter(value: string): string[] {

    const filterValue = value.toLowerCase();

    return this.UserData.filter(User => User.nm.toLowerCase().indexOf(filterValue) === 0);
  }


  initSpeechRecognition() {
    if ('webkitSpeechRecognition' in window) {
      this.recognition = new webkitSpeechRecognition();
      this.recognition.continuous = false;
      this.recognition.interimResults = false;
      this.recognition.lang = 'en-US';

      this.recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        this.textArea.nativeElement.value = transcript;
      };

      this.recognition.onerror = (event) => {
        console.error('Speech recognition error', event);
      };

      this.recognition.onend = () => {
        console.log('Speech recognition ended');
      };
    } else {
      console.error('Speech recognition not supported in this browser');
    }
  }

  startSpeechToText() {
    if (this.recognition) {
      this.recognition.start();
    }
  }
}
