import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { Sort, MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { EmdComponent } from '../emd.component';
import { EmdService } from '../emd.service';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray, NgForm } from '@angular/forms';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import * as   XLSX from 'xlsx';
import { PendordAttachComponent } from '../../pendord/pendord-attach/pendord-attach.component';
import { PendordService } from '../../pendord/pendord.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment'
import { EmdEditnoteComponent } from '../emd-editnote/emd-editnote.component';

@Component({
	selector: 'app-emd-list',
	templateUrl: './emd-list.component.html',
	styleUrls: ['./emd-list.component.css']
})
export class EmdListComponent implements OnInit, OnDestroy {

	datalist: any = [];
	loading: boolean = false;
	dataSource: MatTableDataSource<unknown>;
	displayedColumns: string[] = [];
	massage: string;
	DataForm: FormGroup;
	Bank_Name: any[];
	filteredOptions: any = [];
	sort: any;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@Input('mode') mode;
	//@ViewChild(MatSort) sort: MatSort;
	filename: string = 'EMD-List.xlsx';
	Paid_Total: number;
	date: string;
	Noti_Array: any = [];
	no_of_msg: number = 0;
	
	constructor(
		private _snackBar: MatSnackBar,
		private dialog: MatDialog,
		//private notificationservice : NotificationService,
		private emdService: EmdService,
		private adminservice: AdminService,
		private hardfunc: HardFuncService,
		private fb: FormBuilder,
		private commonservice: CommonService,
		private notificationService: NotificationService,
		private pendordservice: PendordService,
		private notificationservice: NotificationService,
		private datePipe: DatePipe) { this.dataSource = this.datalist.slice(); }

	ngOnInit() {
		//this.getData();
		this.getBank_Name();
		this.DataForm = this.createFormGroup();
		this.loading = false;
		this.getAttachment();
		this.getNotification();
		
	}
	ngOnDestroy(): void {
		console.clear();
	}
	DisplayCol() {
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['Pk_id','Srno','Firm', 'Type',  'Bidopendt', 'Party_name', 'Party_city', 'Tender_no',
			'Pmode', 'Paid_amt', 'Paid_bank_nm', 'Instru_no', 'Issue_dt', 'Reqby', 'Dir_id', 'Mature_dt', 'Remarks', 'Action'];
	}

	//1. api/Sales/EMDsAPI/GetDatas
	getData() {
		this.loading = true;
		this.datalist = [];
		var data: any = {};
		let cnd = " 1=1 ";
		if (this.DataForm.value.Firm != "*All*") {
			cnd += " and Firm = ''" + this.DataForm.value.Firm + "''";
		}
		if (this.DataForm.value.Party_name.length > 0) {
			cnd += " and Pmst_cod + Party_name like ''%" + this.DataForm.value.Party_name + "%'' ";
		}		
		if (this.DataForm.value.Tender_no.length > 0) {
			cnd += " and Tender_no like ''%" + this.DataForm.value.Tender_no + "%'' ";
		}
		if (this.DataForm.value.Pmode != "*All*") {
			cnd += " and Pmode = ''" + this.DataForm.value.Pmode + "''";
		}
		if (this.DataForm.value.Paid_bank != "*All*") {
			cnd += " and a.Paid_bank = ''" + this.DataForm.value.Paid_bank + "''";
		}
		if (this.DataForm.value.Status != "*All*") {
			if (this.DataForm.value.Status == "*PT*") {
				cnd += " and a.Status in (''P'',''T'') ";
			}
			else {
				cnd += " and a.Status = ''" + this.DataForm.value.Status + "''";
			}
		}
		if (this.DataForm.value.Reqby.length > 0) {
			cnd += " and Reqby like ''%" + this.DataForm.value.Reqby + "%'' ";
		}
		data.cond = cnd;
		this.emdService.getDatas(data)
			.subscribe(
				data => {
					console.log(data);
					this.datalist = data;
					this.Paid_Total = 0;
					this.datalist.map(m => {
						this.Paid_Total += m.Paid_amt;
						m.FilterId = "id#"+m.Pk_id.toString();
					})
					this.loading = false;
					this.dataSource = new MatTableDataSource(<any>this.datalist);
					this.DisplayCol();
					this.getAttachment();
					this.getNumberOfchats();
					this.dataSource.paginator = this.paginator;
					
				},
				err => {
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description, "dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					}
				}
			)
	}

	//2. api/Common/notificationssAPI/GetNotificationbyUserandType
	getNotification() {
		this.Noti_Array = [];
		this.adminservice.getNotificationbyType('EM')
			.subscribe(
				(data: any) => {
					this.Noti_Array = data;
					this.no_of_msg = 0;
					this.Noti_Array.map(m => {
						if (m.Msg != null) {
							this.no_of_msg += 1;
							let x = m.Msg.split('#&#');
							m.MsgHeader = x[0];
							m.MsgBody = x[1];
							m.MsgFooter = x[2];
						}
					});
				},
				err => {
					let errstr = this.hardfunc.getError(err);
					console.log(err);
					this.notificationservice.warn(errstr);
				}
			)
	}

	OpenInvoice(Invno: number) {
		//this.party_name1 = Pmst_cod;
		//this.dataSource.filter = "J3107";
		//this.datalist.filter(f=> f.Pk_id == Invno);
		this.dataSource.filter = "id#"+Invno.toString();
	}

	//3. api/Common/notificationssAPI/DeleteData
	Delete_Noti(Notification_id: number): void {
		this.loading = true;
		this.adminservice.Delete_notification(Notification_id)
			.subscribe(
				(data: any) => {
					this.loading = false;
					this.getNotification();
				},
				err => {
					this.loading = false;
					let errstr = this.hardfunc.getError(err);
					console.log(err);
				}
			)
	}
	EditNote(Pk_id: number, Firm: string, Pmst_cod: string, party_name: string) {
		const dialogRef = this.dialog.open(EmdEditnoteComponent, {
			width: '840px',
			height: '510px',
			data: { Invno: Pk_id, Firm: Firm, Pmst_cod: Pmst_cod, party_name: party_name }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}

	//4. api/Common/notessAPI/NotesSumbyInvno
	getNumberOfchats() {
		this.emdService.getNumberOfchats('EM')
		  .subscribe(
			(data: any) => {
			  data.map(w => {
				if (w)
				{
					var obj: any = this.datalist.filter((x: any) => x.Pk_id === w.Invno )[0];
					if (obj) {
						obj.No_of_chats = w.Cnt;
					}
				}
			  });
			}
		  );
	  }

	//5. api/DMSAPI/DocuSumbyOrdno
	getAttachment() {
		this.pendordservice.GetDatabyAttach(this.hardfunc.Firmx, 'EM')
			.subscribe(
				(data: any) => {
					data.map(w => {
						if (w) {
							var obj: any = this.datalist.filter((x: any) => x.Pk_id === w.Ordno && x.Oloc === x.Oloc)[0];
							if (obj) {
								obj.No_of_docu = w.No_of_docu;
							}
						}
					});
				}
			);
	}
	exportexcel(): void {
		// Option-2 Entire JSON
		const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.datalist);

		this.datalist.map(m => {
			m.Offer_dt = moment(m.Offer_dt).format('DD/MM/yyyy');
			m.Issue_dt = moment(m.Issue_dt).format('DD/MM/yyyy');
			m.Mature_dt = m.Mature_dt ? moment(m.Mature_dt).format("DD/MM/yyyy") : "";
			m.Paid_bank_nm = m.Paid_bank_nm +"/"+ m.Pmode +"/"+ m.Instru_no;
		});

		// Option-3 Selected fields from JSON
		let newData = this.datalist.map(({ Firm, Type, Pmst_cod, Party_name, Party_city, Tender_no, Offer_no, Offer_dt, Paid_amt, Paid_bank_nm, Instru_no, Issue_dt, Mature_dt, Remarks }) =>
			({ Firm, Type, Pmst_cod, Party_name, Party_city, Tender_no, Offer_no, Offer_dt, Paid_amt, Paid_bank_nm, Instru_no, Issue_dt, Mature_dt, Remarks }));
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData);

		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

		XLSX.writeFile(wb, this.filename);
	}


	openDialog(Pk_id: number): void {
		const dialogRef = this.dialog.open(EmdComponent, {
			width: '900px',
			data: { id: Pk_id }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}

	openAttachment(Pk_id: number, Enq_id: number, Vtype: string): void {
		const dialogRef = this.dialog.open(PendordAttachComponent, {
			width: '925px',
			height: '600px',
			data: { Ordno: Pk_id, Oloc: 1, id:Enq_id, Vtype: Vtype, Wmodule_id: 1133 }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}

	//6. api/Sales/EMDsAPI/DeleteData
	DeleteRcd(Pk_id: number): void {
	this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
		.afterClosed().subscribe(res => {
			if (res) {
				this.loading = true;
				const dialogRef = this.adminservice.DeleteEMD(Pk_id)
					.subscribe(
						(data: any) => {
							this.loading = false;
							this.notificationService.warn("Data deleted succefully");
							this.getData();
						},
						err => {
							this.loading = false;
							let errstr = this.hardfunc.getError(err);
							console.log(err);
							this.notificationService.warn(errstr);
						}
					)

				// this.notificationService('Deleted Successfully');
			}
		});
	}
	
	//7. api/Common/CodemastsAPI/GetCodeListbyCodenm
	getBank_Name() {
		this.loading = true;
		this.commonservice.CodeList('BANK_NAME')
			.subscribe(
				(data: any) => {
					this.Bank_Name = JSON.parse(data);
					this.loading = false;
				},
				err => {
					this.loading = false;
					let errstr = this.hardfunc.getError(err);
					console.log(err);
					this.notificationService.warn(errstr);
				}

			);
	}
	
	createFormGroup() {
		return this.fb.group({
			Firm: new FormControl('*All*'),
			Party_name: new FormControl(''),
			Tender_no: new FormControl(''),
			Pmode: new FormControl('*All*'),
			Paid_bank: new FormControl('*All*'),
			Reqby: new FormControl(''),
			Status: new FormControl('*PT*')
		});
	}

	sortData(sort: Sort) {
		const data = this.datalist.slice();
		if (!sort.active || sort.direction === '') {
			this.dataSource = data;
			return;
		}

		this.dataSource = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			//const isDes = sort.direction === 'desc';
			switch (sort.active) {
				case 'Party_name': return compare(a.Party_name, b.Party_name, isAsc);
				case 'Srno': return compare(a.Srno, b.Srno, isAsc);
				case 'Offer_dt': return compare(a.Offer_dt, b.Offer_dt, isAsc);
				case 'Id': return compare(a.Pk_id, b.Pk_id, isAsc);
				default: return 0;
			}
		});
	}

	openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}
}
function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


export interface EmdModel {
	Pk_id:number,
	Firm:string,
	Type:string,
	Srno:number, 
	Bidopendt:Date,
	Party_name:string, 
	Party_city:string, 
	Tender_no:string,
	Pmode:string,
	Paid_amt:string,  
	Paid_bank_nm:string ,
	Instru_no:string , 
	Issue_dt:string , 
	Reqby:string , 
	Dir_id:string,  
	Mature_dt:string,  
	Remarks:string
  }