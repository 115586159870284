import { Component, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PrdctnService } from './prdctn.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { Sort, MatDialog, MatTableDataSource, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-prdctn',
  templateUrl: './prdctn.component.html',
  styleUrls: ['./prdctn.component.css']
})
export class PrdctnComponent implements OnInit {
  
  loading: boolean = false;
  DataForm: FormGroup;
  repmodel: any = {};
  Dept_nm: any = [];
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  @Output() fUnit: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    //this.DataForm = this.SelectUnitFormGroup();
    this.loading = true;
  }
  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['Slip_no','Tdate','Product_id','Product_nm','Tran_from', 'Issue_to', 'Qnty', 'Unit', 'Remarks','Action'];
  }
  // SelectUnitFormGroup(){
  //   return this.fb.group({
  //     fUnit: new FormControl('L')
  //   });
  // }
}
