import { Component, OnInit } from '@angular/core';
import { SalesService } from '../sales.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MatTabChangeEvent, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-rejection-mng',
  templateUrl: './rejection-mng.component.html',
  styleUrls: ['./rejection-mng.component.css']
})
export class RejectionMngComponent implements OnInit { 
  
  loading: boolean = false;
  DataForm: FormGroup;
  RejData: any = [];
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  constructor(private SalesService: SalesService,
             private fb: FormBuilder,
             private hardfunc: HardFuncService,
             private NotificationService: NotificationService,
             private router: Router,
             private route: ActivatedRoute
            ) { }

  ngOnInit() {
    this.loading = true;
    this.DataForm = this.CreateFormGroup();
    this.GetData();
  }
  GetRejection(){
    this.SalesService.GetRejection(this.DataForm.value)
      .subscribe((data: any) => {
        this.RejData = data;
        console.log(this.RejData);
      }); 
  }
  // RejSave(data: any){
  //   if(data.Rejection_id === null){
  //     data.Rejection_id = -1;
  //   }else{
  //     data.Rejection_id = data.Rejection_id;
  //   }
  //   console.log(data);
  //   this.SalesService.RejSave(data)
  //     .subscribe((data: any) => {
  //       console.log(data);
  //       this.NotificationService.success("Data Saved Successfully... ");
  //     },
  //     err => {
  //       let errstr = this.hardfunc.getError(err);
  //       console.log(err);
  //       this.NotificationService.warn(errstr);
  //     });
  // }
  GetData(){
    this.loading = false;
    this.SalesService.RejList()
      .subscribe((data: any) => {  
        this.datalist = data;
        console.log(this.datalist);
        // this.datalist.map(r => {
        //   if(r.Notes){
        //     let remark = r.Notes.replace("#$#","\n");
        //     remark = remark.replace("#$#","\n");
        //     console.log(remark);
        //     r.Notes = remark;
        //     console.log(r.Notes);
        //   }                    
        // });        
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        // this.DisplayCol();
        this.loading = true;
      },
      err => {
        this.loading = true;
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.NotificationService.warn(errstr);
      });
  }
  // onTabChange(event: MatTabChangeEvent) {
  //   const index = event.index;
  //   this.router.navigate(
  //     // replace with your own route URL
  //     ['http://localhost:4200/sales/rejection-mng'],
  //     { relativeTo: this.route, queryParams: { tab: index }
  //   });
  // }
  CreateFormGroup(){
    return this.fb.group({      
      Exr: new FormControl('G'),
      Invno: new FormControl(3680),      
    })
  }
}
