import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSnackBar, MatPaginator, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ManufacturingService } from '../../manufacturing.services';
import { OpenstockComponent } from '../openstock/openstock.component';
import { JobworkDetailComponent } from '../jobwork-detail/jobwork-detail.component';
@Component({
	selector: 'app-prd-history',
	templateUrl: './prd-history.component.html',
	styleUrls: ['./prd-history.component.css']
})
export class PrdHistoryComponent implements OnInit {

	loading: boolean = false;
	datalist: any = [];
	DataForm: FormGroup;
	datasource: any = [];
	dataSource: MatTableDataSource<unknown>;
	displayedColumns: string[] = [];
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	Receipt_Total: number;
	Issue_Total: number;
	RecIssue: number;
	balance: number;
	isClicked = false;
	constructor(private HardFunc: HardFuncService,
		private fb: FormBuilder,
		private MatSnackbar: MatSnackBar,
		@Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
		private ManufacturingService: ManufacturingService,
		private dialog: MatDialog) { }

	ngOnInit() {		
		this.loading = true;
		this.DataForm = this.createFormGroup();
		if (this.ReceivedData.id > 0) {
			this.getData();
		}
	}
	DisplayCol() {
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['TranType', 'Slip_no', 'Tdate', 'Receipt', 'Issue', 'Balance', 'Unit', 'Remarks'];
	}

	//1. api/Sales/factransAPI/GetDatas
	getData() {
		this.loading = true;
		this.datalist = [];
		var data: any = {};
		let Fdate = this.HardFunc.hsConvDt(this.DataForm.get('Fdate').value);
		let Tdate = this.HardFunc.hsConvDt(this.DataForm.get('Tdate').value);
		this.ManufacturingService.getDatas(Fdate, Tdate, this.ReceivedData.id, this.ReceivedData.Dept_id)
			.subscribe(
				data => {							
					this.datalist = data;					
					this.loading = false;
					this.Receipt_Total = 0;
					this.Issue_Total = 0;
					this.balance = 0;
					this.datalist.map(m => {
						this.Receipt_Total += m.Receipt;
						this.Issue_Total += m.Issue;
						m.TranType = m.TranType.trim();
						this.RecIssue = Math.round(this.Receipt_Total) - Math.round(this.Issue_Total);
						this.balance += m.Receipt - m.Issue;
						m.balance = Math.round(this.balance);
					})
					this.dataSource = new MatTableDataSource(<any>this.datalist);
					this.DisplayCol();
					this.dataSource.paginator = this.paginator;
				},
				err => {
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description, "dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					}
				}
			)
	}
	DownloadPDF() {
		this.loading = true;
		this.datalist = [];
		var data: any = {};
		let Fdate = this.HardFunc.hsConvDt(this.DataForm.get('Fdate').value);
		let Tdate = this.HardFunc.hsConvDt(this.DataForm.get('Tdate').value);
		this.ManufacturingService.GeneratePDF(Fdate, Tdate, this.ReceivedData.id, this.ReceivedData.Dept_id)
			.subscribe(
				(data: any) => {
					this.loading = false;
					 console.log(data);
					 const data1 = window.URL.createObjectURL(data);

					var link = document.createElement('a');
					link.href = data1;
					link.target = "_blank";
					link.download = "Product-History.pdf";
					link.click();
					setTimeout(function () {
						// For Firefox it is necessary to delay revoking the ObjectURL
						window.URL.revokeObjectURL(data1);
					}, 100);
				},
				err => {
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description, "dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					}
				}
			)
	}
	createFormGroup() {
		return this.fb.group({
			Fdate: new FormControl(this.HardFunc.Fdate),
			Tdate: new FormControl(this.HardFunc.Tdate)
		});
	}
	JobworkDetail(TranType: string, Ref_id: number, Remarks: string) {
		const dialogRef = this.dialog.open(JobworkDetailComponent, {
			width: '800px',
			data: { TranType: TranType, Ref_id: Ref_id, Remarks: Remarks }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}
	editStock(Facmast_id: number) {
		console.log(Facmast_id);
		this.loading = true;
		this.ManufacturingService.EditStock(Facmast_id)
			.subscribe(
				data => {
					this.loading = false;
				},
				err => {
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description, "dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					}
				}
			)
	}
	editStock1(Facmast_id, Qnty: number): void {
		const dialogRef = this.dialog.open(OpenstockComponent, {
			width: '650px',
			height: '200px',
			data: { id: Facmast_id, Qnty: Qnty }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log(result);
		});
	}
	openSnackBar(message: string, action: string) {
		this.MatSnackbar.open(message, action, {
			duration: 2000,
		});
	}
}
