import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Optional } from "@angular/core";
import { Component, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material";
import { HardFuncService } from "src/app/Services/Common/hardfunc.service";
import { Chart } from 'chart.js';

// import { ChartComponent } from 'ng-apexcharts';
// import { ApexNonAxisChartSeries, ApexResponsive, ApexChart } from 'ng-apexcharts';

// export type ChartOptions = {
//   series: ApexNonAxisChartSeries;
//   chart: ApexChart;
//   responsive: ApexResponsive[];
//   labels: any;
// };

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit {
  
  repmodel: any = {};
  headers: any = {};
  datalist: any = [];
  InfoFld: string = "";
  Linechart = [];  
  Handle_nm: any = [];
  HandleNm: any = [];
  OrdValue:any = []; 
  labeldata: any[] = [];
  Colordata: any = [];

  // @ViewChild("chart", {static: true}) chart: ChartComponent;
  // public chartOptions: Partial<ChartOptions>;
  

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private hardfunc: HardFuncService,
              private _http: HttpClient,              
            ) { }
  ngOnInit() {
    this.GetData();    
  }

  GetData(){
    this.headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    });
    
    this.repmodel.Firmx = this.hardfunc.Firmx;
      this.repmodel.Yrx = this.hardfunc.Yrx;
      this.repmodel.Fdates = this.ReceivedData.data.Dt;      
      this.repmodel.Tdates = this.ReceivedData.data.orddtsort;            
      let url9 = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/dash_order_mfwise";
      this._http.post(url9, this.repmodel, { headers: this.headers })
        .subscribe((data: any) => {          
          this.datalist = data;          
          let idx = 0;
          this.datalist.map(d => {            
            this.Handle_nm[idx] =  d.handle_nm;
            this.OrdValue[idx] = d.ordvalue;                                   
            idx++;                        
          });                           
          this.ShowChart(this.Handle_nm, this.OrdValue);    
        });
  }
  ShowChart(Handle_nm: any, OrdValue: any){    
    this.Linechart = new Chart('canvas', {  
      type: 'bar',        
      data: { 
        labels: Handle_nm,  
        datasets: [  
          {  
            // labels: '# of Votes',
            data: OrdValue,  
            borderColor: '#000000',  
            // backgroundColor: Colorcode,     
            backgroundColor: [  'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                                'rgba(255, 205, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(201, 203, 207, 0.2)',
                                'rgba(153,102,255,0.2)',
                                'rgba(255,159,64,0.2)',  
                                '#eff70a',
                                '#03b8ff',
                                '#b103fc',
                                'rgb(102, 153, 153)',
                                'rgb(0, 153, 204)',
                                'rgb(102, 102, 51)',
                                'rgb(153, 0, 204)',
                                'rgb(102, 153, 255)',
                                'rgb(255, 153, 0)',
                                'rgb(102, 102, 153)',
                                'rgb(153, 0, 255)',
                                'rgb(102, 255, 255)',
                                'rgb(204, 102, 153)',
                                'rgb(204, 51, 0)',
                                'rgb(255, 204, 255)',
                                'rgb(204, 204, 0)',
                                'rgb(0, 51, 102)',
                                'rgb(0, 102, 102)',
                                'rgb(255, 153, 153)',
                                'rgb(153, 204, 255)',
                                'rgb(204, 204, 255)'
                                ],
              borderWidth: 1
          }  
        ]  
      },  
      options: {         
        // legend: {  
          
        // },  
        
        scales: {  
          xAxes: [{  
            display: true  
          }],  
          yAxes: [{  
            display: true
          }],            
          // y: {
          //   beginAtZero: true
          // }
        }  
      }  
    });  
  }

  }

 