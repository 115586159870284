import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppLoaderService } from 'src/app/Services/app-loader/app-loader.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MatSnackBar, MatSort } from '@angular/material';
import { FormControl, Validators, FormBuilder, FormGroup} from '@angular/forms';
import { NotificationService } from 'src/app/Services/Common/notification.service';
@Component({
  selector: 'app-change-pwd',
  templateUrl: './change-pwd.component.html',
  styleUrls: ['./change-pwd.component.css']
})
export class ChangePwdComponent implements OnInit {
  hide = true;
  hide1= true;
  isValid:boolean = true;
  NewEvent:any = {};
  msgs: any[] = [];
  loading: boolean = false;
  // xyz: FormGroup;
  DataForm: FormGroup;
  

  constructor(
    private hardfunc: HardFuncService,
    private _http: HttpClient,
    private apploaderservice : AppLoaderService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private notificationservice: NotificationService
  ) { }

  ngOnInit() {
    this.NewEvent.UserName = this.hardfunc.UserName;

    this.DataForm = this.CreateFormGroup();
  }

  // email = new FormControl('', [Validators.required, Validators.email]);
  OPass = new FormControl('',[Validators.required])
  NPass = new FormControl('',[Validators.required])
  CPass = new FormControl('',[Validators.required])
 
  getErrorOPass(){
    if (this.OPass.hasError('required')) {
      return 'You must enter a Old Password';
    }
  }

  getErrorNPass(){
    if (this.NPass.hasError('required')) {
      return 'You must enter a New Password';
    }
  }

  getErrorCPass(){
    if (this.CPass.hasError('required')) {
      return 'You must enter a Confirm New Password';
    }
  }

  onPasswordInput(pass1,pass) {
    this.isValid = false;
    if(pass1 == pass){
      this.isValid = true;
    }
    else{
      this.isValid = false;
    }
  }

  SaveData(obj:any ) {
    console.log(obj);
    this.apploaderservice.open();
    let url =  this.hardfunc.BaseAPIUrl+"api/UsersAPI/ResetPwd1";
    var headers = new HttpHeaders({ 'content-Type':'application/json' });
    this._http.post(url, obj, { headers: headers })
      .subscribe(data => {
        console.log("Change Password Success");
        this.apploaderservice.close();
        this.notificationservice.success('Password Updated Successfully');
      },
        err => {
          console.log(err);
         // this.msgs.push({ severity: 'error', summary: 'Error', detail: `Error in Saving data , please check server connectivity...  ` });
          this.openSnackBar("Error in Saving data , please check server connectivity...  ","dismiss");
          this.apploaderservice.close();
        });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  CreateFormGroup() {
    return this.fb.group({
      Username: new FormControl(''),
      OPassword: new FormControl(''),
      NPassword: new FormControl(''),
      CPassword: new FormControl('')
    });
  }

}
