import { Component, OnInit, Optional, Inject } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@angular/material';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { TproductComponent } from '../tproduct/tproduct.component';
import { EmailserviceService } from '../email/emailservice.service';
import { SalesService } from '../../sales/sales.service';
import { CommonService } from 'src/app/Services/Common/common.service';

@Component({
  selector: 'app-tprod-additem',
  templateUrl: './tprod-additem.component.html',
  styleUrls: ['./tprod-additem.component.css']
})
export class TprodAdditemComponent implements OnInit {

  loading: boolean = false;
  public editor = ClassicEditor;
  repmodel: any = {};
  title: string;
  TprodData: any = [];
  file: any;
  imageError: string;
  cardImageBase64: string;
  isImageSaved: boolean = false;
  TgroupList: any = [];
  PrdFamily: any = [];

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private hardfunc: HardFuncService,
              private _http: HttpClient,
              private NotificationService: NotificationService,
              @Optional() public dialogRef: MatDialogRef<TproductComponent>,
              private emailservice: EmailserviceService,
              private salesservice: SalesService,
              private _snackBar: MatSnackBar,
              private commonservice: CommonService) { }

  ngOnInit() {
    this.loading = true;
    if(this.ReceivedData.id < 0){
      this.repmodel.Product_nm = "<b>" +this.ReceivedData.Grp_nm+ "</b><br>" + this.ReceivedData.Product_nm;
    }
    console.log(this.ReceivedData.data);
    this.GetData();
    this.GetTgroup();
    this.GetPrdFamily();
  }
  GetData() {
    this.repmodel.Short_nm = "";
    if(this.ReceivedData.id > 0)
      this.emailservice.GetDatabyTprod(this.ReceivedData.id)
      .subscribe(
        (data: any) => {
          console.log(data);

          this.loading = true;
          this.repmodel.Prodgrp_id = data.Prodgrp_id;
          this.ReceivedData.Grp_nm = data.Prodgrp_nm.trim();
          this.repmodel.Product_nm = data.Product_nm.trim();
          this.ReceivedData.id = data.Tproduct_id;
          this.ReceivedData.Product_id = data.Product_id;
              
          this.repmodel.Short_nm = data.Short_nm.trim();          
          this.cardImageBase64 = data.ImgStr;
          this.isImageSaved = true;
          return false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn(errstr);
        }
      )
    
  }  
  GetTgroup(){
    this.loading = true;
    this.salesservice.GetTgroup()
      .subscribe(data => {
        console.log(data);
        this.TgroupList = data;        
        this.loading = false;
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }
  SaveTempmast() {
    console.log(this.cardImageBase64);
    this.loading = true;
    var data: any = {};
    
    data.Prodgrp_id = this.repmodel.Prodgrp_id;
    data.Prodgrp_nm = this.ReceivedData.Grp_nm.trim();
    data.Product_nm = this.repmodel.Product_nm.trim();
    data.Tproduct_id = this.ReceivedData.id;
    data.Product_id = this.ReceivedData.Product_id;
        
    data.Short_nm = this.repmodel.Product_nm.trim();
    data.Vtype = "TC";
    data.Fldname = "T_gst";
    data.ImgStr = this.cardImageBase64;

    console.log(data);
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/tproductsAPI/SaveData"      
    var headers = new HttpHeaders({ 'content-Type': 'application/json' })
		this._http.post(url, JSON.stringify(data), { headers:headers })    
      .subscribe((data: any) => {
        console.log(data);
        this.loading = false;
        this.onClose();
        this.NotificationService.success("Data Saved Successfully...");
      },err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.NotificationService.warn(errstr);
        this.loading = false;
      });
  }
  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is ' + max_size / 1000 + 'Mb';

        return false;
      }

      // if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
      //     this.imageError = 'Only Images are allowed ( JPG | PNG )';
      //     return false;
      // }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          console.log(img_height, img_width);

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              'Maximum dimentions allowed ' +
              max_height +
              '*' +
              max_width +
              'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            console.log(this.cardImageBase64);
            // this.repmodel.ImgStr.patchValue({ ImageStr: imgBase64Path });
            // console.log(this.repmodel.ImgStr);
            this.isImageSaved = true;            
            // this.previewImagePath = imgBase64Path;
          }
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
  GetPrdFamily() {
    this.loading = true;
    this.commonservice.CodeList('ProdFam')
      .subscribe(
        (data: any) => {
          this.PrdFamily = JSON.parse(data);
          console.log(this.PrdFamily);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn(errstr);
        }
      );
  }
  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }
  onClose() {
    this.initializeFormGroup();
    if (this.ReceivedData.id > 0) {
      this.dialogRef.close();
    }
    // else {
    //   this.DataForm.reset();
    //   Object.keys(this.DataForm.controls).forEach(key => {
    //     this.DataForm.get(key).setErrors(null);
    //   });
    //   Object.keys(this.InvDatas.controls).forEach(key => {
    //     this.InvDatas.get(key).setErrors(null);
    //   });
    // }
  }
  initializeFormGroup() {

  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
