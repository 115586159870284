 import { Injectable } from '@angular/core';
 import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
//import {rxjs/add/operator/do} ;

 @Injectable()
 export class AuthInterceptor implements HttpInterceptor {
    debugger;
     constructor(
         //private authenticationService: AuthenticationService,
        
         private router: Router
         
         ) {}
         intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            return next.handle(request).pipe(
              catchError(error => {
                if (error.status === 0) {  // Assuming 0 represents session timeout
                  
                  //this.router.navigate(['/sessions/signin']); // Redirect to login page
                }
                return throwError(error);
              })
            );
          }
//      intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//             if (request.headers.get('No-Auth') == "True")
//             {
//                 return next.handle(request.clone());
//             }

//             if (localStorage.getItem('UserToken') != null)
//             {
//                 const clonereq = request.clone({
//                       headers: request.headers.set("Authorisation", "Bearer "+localStorage.getItem('UserToken'))  
//                 });
//                 return next.handle(request);
//                 // .do(
//                 //    succ => {},
//                 //    err => {
//                 //     if (err.status == 401)
//                 //         this.router.navigateByUrl('/login');
//                 //    }     
//                 //    );
//             }


// //         // add authorization header with jwt token if available
// //         let currentUser = this.authenticationService.currentUserValue;
// //         if (currentUser && currentUser.token) {
// //             request = request.clone({
// //                 setHeaders: { 
// //                     Authorization: `Bearer ${currentUser.token}`
// //                 }
// //             });
// //         }

// //         return next.handle(request);
//      }
 }