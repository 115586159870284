import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { RouterLinkActive, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-pdfviewer1',
  templateUrl: './pdfviewer1.component.html'
  
})
export class Pdfviewer1Component implements OnInit {
  src:string = "";
  ErrMsg:string = "";
  state$: Observable<object>;
  
  constructor(
    private _http: HttpClient,
    private hardfunc: HardFuncService,
    private _cservice: CommonService,
    private route : ActivatedRoute
  ) {   }

  ngOnInit() {
    //this.state$ = this.route.paramMap
    //.pipe(map(() => window.history.state))
    // this.route.paramMap
    // .subscribe(params => {
    //   this.src = window.history.state;
    // })

  }
}
