import { Component, OnInit, Optional, Inject } from '@angular/core';
import {  MAT_DIALOG_DATA } from '@angular/material';
import { ManufacturingService } from '../manufacturing.services';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-ppara-create',
  templateUrl: './ppara-create.component.html',
  styleUrls: ['./ppara-create.component.css']
})
export class PparaCreateComponent implements OnInit {

  title: string;
  DataForm: FormGroup;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private mnfservice: ManufacturingService,
              private hardfunc: HardFuncService,
              private notificationService: NotificationService,
              private fb: FormBuilder) { }

  ngOnInit() {
    this.GetData();
    this.DataForm = this.CreateFormGroup();
  }
  GetData() {
    if (this.ReceivedData) {
      if (this.ReceivedData.Ppara_id == -1) {
        this.title = "Add Ppara";
      }
      else {
        this.mnfservice.GetParabyId(this.ReceivedData.Ppara_id)
          .subscribe(
            (data: any) => {
              this.DataForm.patchValue(data);
              this.title = this.ReceivedData.Pgroup_id +"-"+ this.ReceivedData.Grp_nm +","+ this.ReceivedData.Ppara_id +"-"+ this.ReceivedData.Para_nm;
              return false;
            },
            err => {
              let errstr = this.hardfunc.getError(err);
              console.log(err);
              this.notificationService.warn(errstr);
            }
          )
      }
    }
    else {
      this.ReceivedData = {};
      this.ReceivedData.Ppara_id = -1;
      this.title = "Add Ppara";
    }
  }
  SaveData(){
    console.log(this.DataForm.value);
    this.notificationService.success("Data Saved Successfully... ");
    // this.mnfservice.SavePpara(this.DataForm.value)
    //   .subscribe((data: any) => {
    //     console.log(data);
    //     this.notificationService.success("Data Saved Successfully... ");
    //   });
  }
  CreateFormGroup(){
    return this.fb.group({
      Pgroup_id: new FormControl(-1),
      Grp_nm: new FormControl(""),
      Ppara_id: new FormControl(-1),      
      Para_nm: new FormControl('')
    });
  }
}