import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-wrapper-dialog',
  templateUrl: './wrapper-dialog.component.html',
  styleUrls: ['./wrapper-dialog.component.css']
})
export class WrapperDialogComponent implements OnInit {

  constructor(
    //public dialogRef: MatDialogRef<EnqListComponent>,
    @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
  ) { }

  ngOnInit() {
  }

}
