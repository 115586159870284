import { Component, OnInit, Optional, Inject, ViewChild } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource, MatPaginator, MatSnackBar } from '@angular/material';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ManufacturingService } from '../../manufacturing/manufacturing.services';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TprodAdditemComponent } from '../tprod-additem/tprod-additem.component';
import { CommonService } from 'src/app/Services/Common/common.service';
import { PendordAttachComponent } from '../../sales/pendord/pendord-attach/pendord-attach.component';
import { DmsService } from 'src/app/Services/Common/dms.service';
import { SalesService } from '../../sales/sales.service';
import { AdminService } from '../../admin/admin.service';

class Timestamp extends Plugin {
  init() {
      console.log( 'Timestamp was initialized.' );
  }
}

@Component({
  selector: 'app-tproduct',
  templateUrl: './tproduct.component.html',
  styleUrls: ['./tproduct.component.css']
})
export class TproductComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  msgFromChild1 = [];
  repmodel: any = {};
  public editor = ClassicEditor;
  isClicked=false;
  check=false;
  QuotePrd: any = [];
  Checkbox: boolean = false;
  TgroupList: any = [];
  Username: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private hardfunc: HardFuncService,
              private _http: HttpClient,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private manufacturingservice: ManufacturingService,
              private NotificationService: NotificationService,
              private dialog: MatDialog,
              private commonservice: CommonService,
              private dmsservice: DmsService,
              private SalesService: SalesService,
              private _snackBar: MatSnackBar,
              private adminservice: AdminService) { }

  ngOnInit() {
    this.loading = true;
    this.GetDatas();
    this.GetQuoteProduct(); 
    this.Username = this.hardfunc.UserName;
  }

  
  DisplayCol(){
    this.displayedColumns = ['Product_nm','Attachment','Document'];
  }
  GetDatas(){
    this.loading = true;
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/tproductsAPI/GetDatas?id=" + this.ReceivedData.Grp_id
    this._http.post(url, null)
      .subscribe((data: any) => {        
        this.loading = false;
        console.log(data);
        // data.map(m => {
        //   m.Short_nm = this.ReceivedData.Grp_id + this.ReceivedData.Grp_nm.substring(0,2) +"_" + (m.Tproduct_id + 1000).toString().substring(1);
        //   m.Product_nm = m.Short_nm +""+ m.Product_nm;
        // });  
              
        this.datalist = data;
        this.datalist.map(m => {
          m.Doculist.map(w => {
            w.Checked = false;
          });
        })
        this.dataSource = new MatTableDataSource(<any>this.datalist); 
        this.DisplayCol();
        this.LoadImages();
      },err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.NotificationService.warn(errstr);
        this.loading = false;
      });
  } 
  GetDescbyId(){
    console.log(this.repmodel.Product_id);
    let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/GetProductDetail?product_id="+this.repmodel.Product_id;
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        console.log(data1);
        // this.productlist = data1;
        // this.productlist = this.productlist.map((x) => {
        //     let formarray = (this.DataForm.controls['Items'] as FormArray);
        //     let formgroup = (formarray.at(index) as FormGroup);
        //     formgroup.get('Product_nm').patchValue(x.Short_nm);   
            
        // });
      }, err => {
        console.log(err);
      });
  } 
  GetQuoteProduct() {
    this.loading = true;
    this.commonservice.CodeList('Quote_Prd')
      .subscribe(
        (data: any) => {
          this.QuotePrd = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn(errstr);
        }
      );
  }
  DeleteTproduct(Tproduct_id: number, Vtype: string){
    this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
		.afterClosed().subscribe(res =>{
		  if(res){
			this.loading = true;
			const dialogRef = this.adminservice.DeleteTproduct(Tproduct_id, Vtype)
			.subscribe(
			  (data:any)=>{
				this.loading = false;
				this.NotificationService.warn("Data deleted successfully");
			  },
			  err => {
				this.loading = false;
				let errstr =  this.hardfunc.getError(err);
				console.log(err);
				this.NotificationService.warn(errstr);
			  }
			)
			
			// this.notificationService('Deleted Successfully');
		  }
		});
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  OnClick(){
    this.isClicked=true;
    // this.TemplateForm.controls["Remarks"].patchValue(Remarks);
    // this.TemplateForm.controls["Docu_id"].patchValue(Docu_id);
  }
    
  LoadImages() {    
    this.datalist.map(m=> 
      {                   
        m.Doculist.map(x=>{
          if(!x.ExtenalUrl){
            x.FileType = "OTH";
          }				  
			  });
      }  
    )
  }

  LoadImagebyId(objFile: any){
    this.loading = true;
    this.dmsservice.LoadImagebyId(objFile.Docu_id)
    .subscribe(
      (data1: any) => {
        objFile.ImageStr = 'data:image/jpeg;base64,'+data1;
        this.loading = false;
      },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn("Error in image loading "+errstr);
        }
      )
  }
  AddItem(Tproduct_id: number){
    const dialogRef = this.dialog.open(TprodAdditemComponent, {
      width: '1200px',
      data: {Grp_id: this.ReceivedData.Grp_id, id: Tproduct_id, Grp_nm: this.ReceivedData.Grp_nm.trim(), Product_nm: this.ReceivedData.Product_nm}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed ');
      this.GetDatas();
    });
  }
  EditTproduct(Tproduct_id: number,Vtype: string){
    const dialogRef = this.dialog.open(TprodAdditemComponent, {
      width: '1200px',
      data: {Grp_id: this.ReceivedData.Grp_id, Grp_nm: this.ReceivedData.Grp_nm.trim(), Product_nm: this.ReceivedData.Product_nm,
             Tproduct_id: Tproduct_id, Vtype: Vtype }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed ');
      this.GetDatas();
    });
  }
  ItemDocuAtt(Tproduct_id: number, Vtype: string){
    const dialogRef = this.dialog.open(PendordAttachComponent, {
		  width: '925px',
		  height: '600px',
		  data: { Ordno: Tproduct_id, Oloc: 1, Vtype: Vtype, Grp_id: this.ReceivedData.Grp_id, Grp_nm: this.ReceivedData.Grp_nm, Wmodule_id: 1134 }
		});
		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
      this.GetDatas();
		});
  }
  SaveData(data: any){
    console.log(data);
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/tproductsAPI/UpdateShortName"
    this._http.post(url, data)
      .subscribe((data: any) => {
        this.NotificationService.success("Data Saved Successfully... ");
        //this.GetDatas();
      },err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.NotificationService.warn(errstr);
        this.loading = false;
      });
  }
  msgToParent(Tproduct_id: number, Product_nm: string) {
    this.msgFromChild1.push(Tproduct_id);
    this.msgFromChild1.push(Product_nm);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
}