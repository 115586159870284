import { Injectable } from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import { MatFormFieldModule, MatBadgeModule } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class AdminLayoutService {

  public sidenav: any;
  public currentUrl = new BehaviorSubject<string>(undefined);
  constructor(private router: Router) { 
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  public closeNav() {
    this.sidenav.close();
  }

  public openNav() {
    this.sidenav.open();
  }
}
