import { Component, OnInit } from '@angular/core';
import { SalesService } from '../../sales.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment'

@Component({
  selector: 'app-rej-entry',
  templateUrl: './rej-entry.component.html',
  styleUrls: ['./rej-entry.component.css']
})
export class RejEntryComponent implements OnInit {

  loading: boolean = false;
  DataForm: FormGroup;
  RejData: any = [];
  initialValues : any [];
  PartyName: string;
  Transport: string;
  Lrno: string;
  Lrdt: string;
  Packages: string;
  repmodel: any = {};

  constructor(private SalesService: SalesService,
              private fb: FormBuilder,
              private hardfunc: HardFuncService,
              private NotificationService: NotificationService,
              private _http: HttpClient) { }

  ngOnInit() {
    this.loading = true;
    this.DataForm = this.CreateFormGroup();
    this.initialValues = this.DataForm.value;    
  }
  GetRejection(){
    let exr = this.DataForm.get("Exr").value;
    let Invno = this.DataForm.get("Invno").value;    
    if(this.DataForm.get("Invno").value == null){
      this.NotificationService.warn("Please Enter Invoice Number ");
      return;
    }
    this.loading = false;
    this.SalesService.GetRejection(this.DataForm.value)
      .subscribe((data: any) => {
        this.loading = true;
        console.log(data);
        this.RejData = data;             
        this.RejData.map(d => {          
          this.PartyName = "Party Name - " +d.Party;
          this.Transport = "Transport Name - " +d.Trans;
          this.Lrno = "LR No - " +d.Mtrno + ", ";
          this.Lrdt = "LR Dt - " +d.Mtrdt + ", ";
          this.Packages = "Pack - "+d.Pack;
          if(d.Short_qnt > 0 || d.Reject_qnt > 0){
            this.repmodel.AssignTo = d.AssignTo;
            this.repmodel.Lrno = d.Lrno;
            this.repmodel.Transport = d.Transport;
            this.repmodel.RcvdDt = d.RcvdDt;
            this.repmodel.Remarks = d.Remarks;
            this.repmodel.Reject_yn = d.Reject_yn;
          }          
        })
      },
      err => {
        this.loading = true;
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.NotificationService.warn(errstr);
      });
  }
  RejSave(){   
    let data: any = {}; 
    this.RejData.map(e => {
      if(e.Short_qnt > 0 || e.Reject_qnt > 0){   
        // e.Rejection_id = e.Rejection_id;     
        e.Firm = this.DataForm.get("Firm").value;    
        e.Yrx = this.DataForm.get("Year").value;    
        e.InvYear = this.DataForm.get("Year").value;  
        e.RcvdPoint = this.DataForm.get("Firm").value;
        e.AssignTo = this.repmodel.AssignTo;
        e.Lrno = this.repmodel.Lrno;
        e.Transport = this.repmodel.Transport;
        e.RcvdDt = this.repmodel.RcvdDt;
        e.Remarks = this.repmodel.Remarks;
        e.Party = e.Party;
        e.Pro_detail = e.Pro_detail;    
        e.Reject_yn = this.repmodel.Reject_yn; 
        e.Rej_type = "R";        
      }           
      if(e.Rejection_id === null){
        e.Rejection_id = -1;
      }else{
        e.Rejection_id = e.Rejection_id;
      }
    });    
    this.SalesService.RejSave(this.RejData)
      .subscribe((data: any) => {                    
        //Rejection Email
        this.SalesService.RejListEmail(data)       
          .subscribe((data1: any) => {                            
             this.NotificationService.success("Email Sent Successfully...");
          });
        this.NotificationService.success("Data Saved Successfully... ");
        this.ResetData();
        this.RejData = [];
        window.location.reload();        
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.NotificationService.warn(errstr);
      });
  }
  ResetData() {        
      this.DataForm.reset();
      this.DataForm.patchValue(this.initialValues);      
  }
  
  CreateFormGroup(){
    return this.fb.group({    
      Firm: new FormControl('NFF'),
      Year: new FormControl('2425'),
      Exr: new FormControl("G"),
      Invno: new FormControl(),      
    })
  }
}
