import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ManufacturingService } from '../manufacturing.services';
import { Sort, MatTableDataSource, MatPaginator, MatSnackBar } from '@angular/material';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { AdminService } from '../../admin/admin.service';
import {ThemePalette} from '@angular/material/core';


export interface Task {
  name: string;
  Checkbox: boolean;
  color: ThemePalette;
}
@Component({
  selector: 'app-gatepass-import',
  templateUrl: './gatepass-import.component.html',
  styleUrls: ['./gatepass-import.component.css']
})
export class GatepassImportComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  DataForm: FormGroup;

  constructor(private Manufacturing: ManufacturingService,
              private _snackBar: MatSnackBar,
              private fb: FormBuilder,
              private hardfunc: HardFuncService,
              private notificationService: NotificationService,
              private adminservice: AdminService) { }

  ngOnInit() {
    this.DataForm = this.createFormGroup();
    this.GatepassReceived();
    this.loading = true;
  }
  DisplayCol() {	
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['Product_id','Item_nm','Outwardno','Amount','Nos','Qnt','Unit','Dept_nm','Descri','Action'];
	}
  ngOnDestroy(): void {
    console.clear();
  }
  task: Task = {
    name: 'Select All',
    Checkbox: false,
    color: 'primary',
  };

  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete = this.datalist != null && this.datalist.every(t => t.Checkbox);
  }

  someComplete(): boolean {
    if (this.datalist == null) {
      return false;
    }
    return this.datalist.filter(t => t.Checkbox).length > 0 && !this.allComplete;
  }

  setAll(Checkbox: boolean) {
    this.allComplete = Checkbox;
    if (this.datalist == null) {
      return;
    }
    this.datalist.forEach(t => (t.Checkbox = Checkbox));
  }
  SaveGatePass(){
    this.loading = true;
    let data = this.datalist.filter(f=> f.Checkbox === true);    
    data.map(m=> {
         console.log(m);
         m.Firmx = this.hardfunc.Firmx;
         m.Yrx = this.hardfunc.Yrx;
         m.InOut = this.DataForm.get("InOut").value;
    });
    this.Manufacturing.SaveGatePass(data)
     .subscribe(data => {
       this.GatepassReceived();
       this.loading=false;
       this.notificationService.success(" Data saved Successfully... ");
     },
     err=> {
       this.loading = false;
       let errstr =  this.hardfunc.getError(err);
       console.log(err);
       this.notificationService.warn(errstr);
       
     }); 
   }
    //1. api/JwReportAPI/JwReport
    GatepassReceived() {
      this.loading = true;
      this.datalist = [];
      let Fdate = this.hardfunc.hsConvDt(this.DataForm.controls.Fdate.value);
      let Tdate = this.hardfunc.hsConvDt(this.DataForm.controls.Tdate.value);
      this.DataForm.controls.Fdate.patchValue(Fdate);
      this.DataForm.controls.Tdate.patchValue(Tdate);
      this.Manufacturing.GatepassReceived(this.DataForm.value)
        .subscribe(
          (data:any) => {
            console.log(data);
            data.map(f=> { 
              if (f != null){
                f.Checkbox = false;
              }
            });
        this.datalist = data;      
        this.loading = false;
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DisplayCol();
        this.dataSource.paginator = this.paginator;
      },
      err => {
        if (err.error.error_description) {
          this.openSnackBar(err.error.error_description,"dismiss");
        }
        else {
          this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
        }
      }
      )
      }	 

      IgnoreGatepass(Product_id:number,str1:string){
        this.adminservice.openConfirmDialog("Are you sure you want to Ignore this ?")
        .afterClosed().subscribe(res =>{
          if(res){
            this.loading = true;
            const dialogRef = this.Manufacturing.IgnoreGatepass(Product_id,str1)
            .subscribe(
              data => {
                this.notificationService.success(" Data saved Successfully... ");
                this.GatepassReceived();
              },
              err => {
                console.log(err);
                this.notificationService.warn("Error in deleting data ...");
              }
            )
          }
        });
      }


    

    createFormGroup() {
      return this.fb.group({
        Status: new FormControl('P'),
        InOut: new FormControl('O'),
        Fdate: new FormControl(new Date()),
        Tdate: new FormControl(new Date())
      });
    }
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    openSnackBar(message: string, action: string) {
      this._snackBar.open(message, action, {
      duration: 2000,
      });
    }

}
