import { Component, OnInit, Inject, Optional } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Os1Service } from '../os1.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
@Component({
  selector: 'app-sorder1-edit',
  templateUrl: './sorder1-edit.component.html',
  styleUrls: ['./sorder1-edit.component.css']
})
export class Sorder1EditComponent implements OnInit {

  loading: boolean = false;
  DataForm: FormGroup;
  repmodel: any = {};
  DirectorList: any = [];
  MarketingList: any = [];
  Sorderdetails: any = [];

  constructor(private fb: FormBuilder,
              private hardfunc: HardFuncService,
              private _http: HttpClient,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private OsService: Os1Service,
              private notificationservice: NotificationService) { }

  ngOnInit() {
    this.loading = true;
    this.getMarketing();
    this.getHandles();
    this.DataForm = this.createFormGroup();    
    this.GetInvRec2();
  }
  SaveData(){
    this.loading = true;
    this.OsService.UpdateSorder1(this.DataForm.value)
      .subscribe((data: any) => {
        this.loading = false;
        this.notificationservice.success("Data Saved Successfully...")
      },
      err => {
        console.log(err);
      });      
  }
  GetInvRec2(){
    this.OsService.GetInvRecord2(this.ReceivedData.Exr,this.ReceivedData.Invno)
      .subscribe((data: any) => {
        this.Sorderdetails = JSON.parse(data);
        console.log(this.Sorderdetails);
        this.Sorderdetails.map(m => {
          this.DataForm.controls.Ordno.patchValue(m.Ordno);
          this.DataForm.controls.Oloc.patchValue(m.OLOC);
          this.DataForm.controls.Exr.patchValue(m.Exr);
          this.DataForm.controls.Invno.patchValue(m.Invno);
          this.DataForm.controls.Person.patchValue(m.Person);
          this.DataForm.controls.Email.patchValue(m.Email.trim().toLowerCase());
          this.DataForm.controls.Phone.patchValue(m.Phone);
          this.DataForm.controls.Dir_id.patchValue(m.Dir_id);
          this.DataForm.controls.Person1.patchValue(m.Person1);
          this.DataForm.controls.Email1.patchValue(m.Email1);
          this.DataForm.controls.Phone1.patchValue(m.Phone1);
          this.DataForm.controls.Mkt_id.patchValue(m.Mkt_id);
          
          let formarray = (this.DataForm.controls['Subdatas'] as FormArray);
          let formgroup = (formarray.at(0) as FormGroup);        
          
          formgroup.get('DueDates').patchValue(m.DUEDATE);
          formgroup.get('Exr').patchValue(m.EXR);
          formgroup.get('Invno').patchValue(m.INVNO);          

          // let formarray1 = (this.DataForm.controls['Items'] as FormArray);
          // let formgroup1 = (formarray1.at(0) as FormGroup);
          // formgroup1.get('Pmst_cod').patchValue(m.PMST_COD);
          // formgroup1.get('Duerem').patchValue(m.Duerem);
        });
      },
      err => {
        console.log(err);
      });      
  }
  
  // 8. api/HandlesAPI/GetData -- API For -- Get Marketing Type=M
  getMarketing() {
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=M";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.MarketingList = data1;
      }, err => {
        console.log(err);
      });
  }
  // 8. api/HandlesAPI/GetData -- API For -- Get Directore Type=D
  getHandles() {
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=D";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.DirectorList = data1;
      }, err => {
        console.log(err);
      });
  }
  createFormGroup(){
    return this.fb.group({
      Ordno: new FormControl(''),
      Oloc: new FormControl(''),
      Exr: new FormControl(''),
      Invno: new FormControl(''),
      Firmx: this.hardfunc.Firmx,
      DueRam: new FormControl('N'),
      Person: new FormControl(''),
      Email: new FormControl('',[Validators.required,Validators.pattern(
        /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})(; ?\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3}))*$/)]),
      Phone: new FormControl(),
      Dir_id: new FormControl(''),
      //Duedt: new FormControl(),
      Person1: new FormControl(''),
      Email1: new FormControl(''),
      Phone1: new FormControl(),
      Mkt_id: new FormControl(''),
      Subdatas: this.fb.array([this.SubItems()]),
      //Items: this.fb.array([this.Items()])
    });
  }
  SubItems(): FormGroup {
    return this.fb.group({  
      Exr: new FormControl(''),
      Invno: new FormControl(),
      DueDates: new FormControl()
    });
  }
  // Items(): FormGroup {
  //   return this.fb.group({  
  //     Pmst_cod: new FormControl(''),
  //     Duerem: new FormControl('N'),
  //   });
  // }
}
