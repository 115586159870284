import { Component, OnInit, Inject } from '@angular/core';

import {MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA} from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { ModalData } from '../codemast/codemast.component';



export interface Modaldata{
  id:number
}

@Component({
  selector: 'app-gstmast',
  templateUrl: './gstmast.component.html',
  styleUrls: ['./gstmast.component.css']
})
export class GstmastComponent implements OnInit {

  GetDatas1:any = [];
  wmodel:any = {};
  loading:boolean = false;
  p:number= 0;
  //Message:any = "";

  constructor(public dialog: MatDialog , private _http:HttpClient  , private service: CommonService,
    private hardfunc : HardFuncService,
    private _snackBar: MatSnackBar
    ) {}  

    ngOnInit(){

      this.loading=true;
      let url =  this.hardfunc.BaseAPIUrl+"api/Pharmacy/GstmastsAPI/GetDatas1";
      var headers = new HttpHeaders({ 'content-Type':'false','process-Data':'false'});
      this._http.get(url,{headers:headers})
        .subscribe(data1 => {
          this.loading=false;
          this.GetDatas1 = data1;
      },
      err=> {
        console.log(err);
        this.loading=false;
       // alert("Error in data gettings... !!! ");
       this.openSnackBar("Error in getting data   ","dismiss");
      });
   }

   openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }


   openDialog(id:number ): void {
    // Open Dialog
   
    const dialogRef = this.dialog.open(GstmastCRUDDialog, {
      width: '700px',
      data: {id:id}
        
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
    //this.animal = result;
  });
}

}



 //////////////////////////////////////////
//////////////C R U D ///////////////////////
 //////////////////////////////////////////



 @Component({
  selector: 'gstmast-crud-dialog',
  templateUrl: './gstmast-crud-dialog.html',
  styleUrls: ['./gstmast.component.css']
})
export class GstmastCRUDDialog {
  NewEvent:any = {};
  msgs: any[]= [];
  loading:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<GstmastCRUDDialog>,
    @Inject(MAT_DIALOG_DATA) public ModalData:Modaldata ,
    private _http : HttpClient,
    private hardfunc : HardFuncService,
    private _snackBar: MatSnackBar
    ) {}

    Cancel(): void {
    this.dialogRef.close();
  }
 
  ngOnInit(){
    this.msgs.push({ severity: 'info', summary: 'Success', detail: "Data saved successfully" });       

    if (this.ModalData.id== -1){
      this.NewEvent = {
        SaveData:[]
      };
    }
    else
    {
      let url =  this.hardfunc.BaseAPIUrl+"api/Pharmacy/GstmastsAPI/GetDatabyId?id="+this.ModalData.id;
      var headers = new HttpHeaders({ 'content-Type':'false' });
      this._http.get(url,{headers:headers})
        .subscribe(data1 => {
          this.loading=false;
          this.NewEvent = data1;
      },
      err=> {
        console.log(err);
        this.loading=false;
      //  alert("Error in data getting... !!! ");
      this.openSnackBar("Error in getting data   ","dismiss");
      });
    }
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

//numeric valiation allowed with contact number
numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

////////for adding data/////////


Save () {
  let url =  this.hardfunc.BaseAPIUrl+"api/Pharmacy/GstmastsAPI/SaveData";
  var headers = new HttpHeaders({ 'content-Type':'application/json' });
  
  this._http.post(url,JSON.stringify(this.NewEvent),{headers:headers})
    .subscribe(data => {
      this.loading=false;
      this.msgs.push({ severity: 'info', summary: 'Success', detail: "Data saved successfully" }); 
      this.openSnackBar("Data saved successfully ","dismiss");      
      //alert("Data saved successfully ");
      this.dialogRef.close();
    },
    err=> {
      console.log(err);
      this.loading=false;
     // this.msgs.push({ severity: 'error', summary: 'Error', detail: "Error in data getting" });       
      //alert("Error in data getting... !!! ");
      this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
    });
}


  AddNewItem() {
    this.NewEvent.SaveData.push({ Gst_no: '', Party_add: '', Party_name: '', Contact_no: '' });
  }
}