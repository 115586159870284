import { Component, OnInit, ViewChild } from '@angular/core';
import { ManufacturingService } from '../manufacturing.services';
import { MatSnackBar, MatTableDataSource, MatDialog } from '@angular/material';
import { MatPaginator } from '@angular/material';
import { CommonService } from 'src/app/Services/Common/common.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { ProductMasterComponent } from './product-master/product-master.component';
@Component({
  selector: 'app-fix-stock',
  templateUrl: './fix-stock.component.html',
  styleUrls: ['./fix-stock.component.css']
})
export class FixStockComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  PrdGroupList: any = [];
  repmodel: any = {};
  Permission:string = "NNNNNNNNNN";
  filtertext:string = "";
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private manufacturingservice: ManufacturingService,
              private _cservice: CommonService,
              private _snackBar: MatSnackBar,
              private notificationservice: NotificationService,
              private hardfunc: HardFuncService,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.Permission = this.hardfunc.GetLocalPermissionbyModule_id(1105);
    this.loading = false;
    this.GetData();
    this._cservice.PrdGroupListAll()
    .subscribe(data => {
      this.PrdGroupList = data;
      this.loading = false;
    },
    err => {
      console.log(err);
      this.openSnackBar("Error in Getting product group , please check connection.","dismiss");
      this.loading = false;
    });
  }
  DisplayCol(){    
    this.displayedColumns = [];
    if (this.Permission.substring(4,5)==='Y')  
    {
      this.displayedColumns.push('product_id','Short_nm','Unit','Min_stock','Max_stock','Prod_code','Action');
    }else{

      this.displayedColumns = ['product_id','Short_nm','Unit','Min_stock','Max_stock','Action'];
    }
  }
  GetData(){
    this.loading = true;
    var data: any = {};
    let cnd = "  ";
    if (this.repmodel.Grp_id != "*All*") {
      cnd += " and e.Prodgrp_id = " + this.repmodel.Grp_id;
    }
    data.cnd;
    this.manufacturingservice.ProductDetails(this.repmodel.Grp_id)
      .subscribe((data: any) => {
        this.datalist = data;
        console.log(this.datalist);
        // this.datalist.map(m => {
        //   m.Prod_code.trim();
        // });
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DisplayCol();
        this.loading = false;
        this.dataSource.paginator = this.paginator;
      });
  }
  OpenDialog(){
    const dialogRef = this.dialog.open(ProductMasterComponent, {
      width: '1150px',
		  height: '850px',
      //data: { id: Facmast_id, product_id: Product_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  FixStock(product_id: number, Min_stock: number, Max_stock: number,Prod_code: string){
    this.loading = true;
    this.manufacturingservice.FixStock(product_id, Min_stock,Prod_code)
      .subscribe((data: any) => {
        this.loading = false;
        this.notificationservice.success("Data Saved Sucessfully...");
      },
      err => {
        console.log(err);
        this.openSnackBar("Error in Getting product group , please check connection.","dismiss");
        this.loading = false;
      });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
