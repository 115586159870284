import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { CommonService } from 'src/app/Services/Common/common.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { PdcService } from '../Pdc.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import * as   XLSX from 'xlsx';
import { PdcCrudComponent } from '../Pdc-crud.component';
import { AdminService } from 'src/app/Views/admin/admin.service';

@Component({
  selector: 'app-pdc-edit',
  templateUrl: './pdc-edit.component.html',
  styleUrls: ['./pdc-edit.component.css']
})
export class PdcEditComponent implements OnInit {

  msgs: any[] = [];
	datalist: any = [];
	loading: boolean = false;
	datasource: any = [];
	dataSource: MatTableDataSource<unknown>;
	displayedColumns: string[] = [];
	massage: string;
	repmodel: any = {};
	Fdate: Date;
	Tdate: Date;
	duplicateArray = []
	Status: string;
	StatusList: any = [];
	bSearch: any = [];
	FieldList: any = [];
	Firmx: any[];
	sort: any;
	chkDate: boolean = false;
	err: any[];
	cols: any[];
	checked: boolean = false;
	status_list: any = [];
	DataForm: FormGroup;
	CustodyList: any = [];
	filteredOptions: any = [];
	ploading: boolean = false;
	PartyData: any = [];
	title: any = [];
	Permission:string = "NNNNNNNNNN";
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	filename:string= 'pdc-list.xlsx';
	party: any = {};

  constructor(private _http: HttpClient,
		public dialog: MatDialog,
		private commonservice: CommonService,
		private notificationservice: NotificationService,
		private pdcservice: PdcService,
		private _snackBar: MatSnackBar,
		private hardfunc: HardFuncService,
		private fb: FormBuilder,
		private _router: Router,
		private adminservice: AdminService) { }

    ngOnInit() {
		//this.getData(1);
      this.Permission = this.hardfunc.GetLocalPermissionbyModule_id(1040);
      this.getCustodyList()
      this.getCodeList();
      this.title = "All";
      this.loading = false;
      this.repmodel.CG = 'A';
      this.repmodel.Status = 'P';
      this.Fdate = new Date("01-04-2021");
      this.Tdate = new Date("31-03-2021");
      this.Status = "All";
      this.duplicateArray = this.datalist
      
      this.loading = true;
  
      this.StatusList = [];
  
      this.FieldList = [
        { field: 'Firm', type: 'String', header: 'Firm' },
        { field: 'Party_name', type: 'String', header: 'Party Name' },
        { field: 'Pdctp', type: 'String', header: 'Chq. Type' },
        { field: 'Chq_depo_dt', type: 'Number', header: 'Chq. Deposit Date ' },
        { field: 'Chqdt', type: 'Date', header: 'Cheque Date'},
        { field: 'Amt', type: 'Number', header: 'Amount ' },
        { field: 'Invno', type: 'String', header: 'Inv No ' },
        { field: 'Status', type: 'Dropdown', header: 'Status', items: this.StatusList },
        { field: 'Custody', type: 'String', header: 'Custody'}
      ]
  
      this.bSearch = []
  
      this.loading = false;
      
      this.DataForm = this.createFormGroup();
      this.loading = true;
  
      this.DataForm.get('Party_name').valueChanges.subscribe(response => {
      this._filter(response);
      });
  
  
      this.DataForm.get('Firm').valueChanges.subscribe(response => {
      // this._filter(response);
      });
      this.GetParty(this.hardfunc.Firmx, this.hardfunc.Yrx);
  
    }
	status: boolean = false;
    
	DisplayCol() {
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = [];
		this.FieldList.map(m=> this.displayedColumns.push(m.field));
		if (this.Permission.substring(2,3)==='Y' || this.Permission.substring(3,4)==='Y' )  
		{
 			this.displayedColumns.push('Action');
		}
	}
  

  getData(option:number) {
		this.loading = true;
		this.datalist = [];
		var data: any = {};
		let url = this.hardfunc.BaseAPIUrl + "api/PdcAPI/getDatas";
		var headers = new HttpHeaders({
			'content-Type': 'application/json', 
			'process-Data': 'false',
			'Authorization': 'Bearer' + localStorage.getItem("UserToken")
		})
		this.repmodel.Firmx = this.hardfunc.Firmx;
		this.repmodel.Yrx = this.hardfunc.Yrx;
		//this.repmodel.Grp_id = id;
		let cnd = " 1=1 ";
		if (option === 1) // Normal Search
		{
			if (this.DataForm.value.Party_name.length > 0)
			{
				cnd += " and Party_name like ''%"+this.DataForm.value.Party_name+"%'' ";	
			}
			if (this.DataForm.value.Custody != "*All*")
			{
				cnd += " and Custody = ''"+this.DataForm.value.Custody+"''";	
			}
			if (this.DataForm.value.Status != "*All*")
			{
				cnd += " and Status = ''"+this.DataForm.value.Status+"''";	
			}
			if (this.DataForm.value.Invno.length > 0)
			{
				cnd += " and Invno = ''%"+this.DataForm.value.Invno+"%''";	
			}
		}
		else // Advance Search
		{
			this.bSearch.map(m => {
				if (m.Operator && m.FieldType && m.Value) {
					if (m.Logical) {
						cnd = cnd + m.Logical + ' ';
					}
					cnd = cnd + m.Field;
					switch (m.Operator) {
						case "eq":
							cnd = cnd + " = ";
							break;
						case "lt":
							cnd = cnd + " < ";
							break;
						case "lteq":
							cnd = cnd + " <= ";
							break;
						case "gt":
							cnd = cnd + " > ";
							break;
						case "gteq":
							cnd = cnd + " >= ";
							break;
						case "cont":
						case "start":
						case "end":
							cnd = cnd + " like ";
							break;
						default:
							cnd = cnd + " = ";
							break;
					}
					if (m.FieldType === "String") {
						switch (m.Operator) {
							case "cont":
								cnd = cnd + "'%" + m.Value + "%'    ";
								break;
							case "start":
								cnd = cnd + "'" + m.Value + "%'    ";
								break;
							case "end":
								cnd = cnd + "'%" + m.Value + "'    ";
								break;
							default:
								cnd = cnd + "'" + m.Value + "'    ";
								break;
						}

					}
					if (m.FieldType === "Number") {
						cnd = cnd + m.Value;
					}
					if (m.FieldType === "Dropdown") {
						cnd = cnd + "'" + m.Value + "'    ";
					}
					if (m.FieldType === "Date") {
						cnd = cnd + " convert(datetime,'" + moment(m.Value).format('DD-MM-YYYY') + "',103)    ";
					}
				}
			});
		}
		this.repmodel.cond = cnd;
		this._http.post(url, this.repmodel, { headers: headers })
			.subscribe((data1: any) => {
				this.datalist = JSON.parse(data1);
				this.loading = false;				
				this.dataSource = new MatTableDataSource(<any>this.datalist);
				this.DisplayCol();
				this.dataSource.paginator = this.paginator;
			},
				err => {
					this.loading = false;
					if (err.error.error_description) {
						this.openSnackBar("saverity: 'error', summary: 'Error' Message', detail: err.error.error_description", "dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					}
				}
			)
	}

  //1. api/FinanceAPI/GetPartyList -- Get Party List
  GetParty(Firm: string, Yr: string) {
		this.ploading = true;
		this.commonservice.GetPartyList(Firm, Yr)
		  .subscribe(
			(data: any) => {
			  this.PartyData = JSON.parse(data);
			  this.PartyData = this.PartyData.map((x) => {
				return {
				  nm: x.nm,
				  id: x.id
				}
			  });
			  this.ploading = false;
			},
			err => {
			  let errstr = this.hardfunc.getError(err);
			  this.notificationservice.warn(errstr);
			  this.ploading = false;
			});
	
	  }

	//2. api/Common/CodemastsAPI/GetCodeListbyCodenm --Get PDC State 
    getCodeList() {
      this.loading = true;
      this.commonservice.CodeList('PDC_STAT')
        .subscribe(
        (data: any) => {
          this.status_list = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
        }
    
        );
    
      }
      
	  //3. api/Common/CodemastsAPI/GetCodeListbyCodenm --Get PDC Custody
      getCustodyList() {
        this.loading = true;
        this.commonservice.CodeList('PDC_CUST')
          .subscribe(
          (data: any) => {
            this.CustodyList = JSON.parse(data);
            this.loading = false;
          },
          err => {
            this.loading = false;
            let errstr = this.hardfunc.getError(err);
            this.notificationservice.warn(errstr);
          }
      
          );
        }
        createFormGroup() {
          return this.fb.group({
            Firm: new FormControl(''),
            Party_name: new FormControl(''),
            Custody: new FormControl('*All*'),
            Status: new FormControl('*All*'),
            Invno: new FormControl('')
          });
          }
          FldChange(obj: any) {
            var filter = this.FieldList.filter(m => m.field === obj.Field);
            if (filter) {
              obj.FieldType = filter[0].type;
              obj.Items = filter[0].items;
            }
            else {
              obj.FieldType = "";
            }
          }
          exportexcel():void{
            let element = document.getElementById('excel-table');
            const ws:XLSX.WorkSheet=XLSX.utils.table_to_sheet(element);
          
            const wb:XLSX.WorkBook=XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws,'Sheet1');
          
            XLSX.writeFile(wb,this.filename);
            }
            deleteRow(validrecords) {
              let index: string = this.datasource.findIndex(i => i === validrecords);
              this.bSearch.splice(index, 1)
              this.dataSource = new MatTableDataSource(<any>index);
            }
            openDialog(Pk_id: number): void {
              const dialogRef = this.dialog.open(PdcCrudComponent, {
                width: '700px',
                data: { id: Pk_id }
              });
              dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed');
              });
            }

			//4. api/PdcAPI/DeleteData --Delete PDC
            DeleteRcd(Pk_id: number): void {
				this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
				.afterClosed().subscribe(res =>{
				  if(res){
					this.loading = true;
					const dialogRef = this.adminservice.DeletePdc(Pk_id)
					.subscribe(
					  (data:any)=>{
						this.loading = false;
						this.notificationservice.warn("Data deleted succefully");
						this.getData(1);
					  },
					  err => {
						this.loading = false;
						let errstr =  this.hardfunc.getError(err);
						console.log(err);
						this.notificationservice.warn(errstr);
					  }
					)
					
					// this.notificationService('Deleted Successfully');
				  }
				});
			  }

            applyFilter(event: Event) {
              const filterValue = (event.target as HTMLInputElement).value;
              this.dataSource.filter = filterValue.trim().toLowerCase();
            }
            private _filter(value: string) {
              if (!value)
              {
                this.filteredOptions = [];
                return;
              }
              if (value.length < 3) {
                this.filteredOptions = [];
                return;
              }
              const filterValue = value.toLowerCase();
              this.filteredOptions = this.PartyData.filter(option => option.nm.toLowerCase().includes(filterValue));
              }
              
			  //6. api/PdcAPI/SavePartialData --Change Status
              cngStatus(Pk_id: number, Status: string) {
                var data: any = {
                  Pk_id: Pk_id,
                  Status: Status
                };
                this.pdcservice.SavePartialData(data)
                  .subscribe(
                    data => {
                      this.loading = false;
                      this.notificationservice.success(' Status updated ');
                    },
                    err => {
                      let errstr = this.hardfunc.getError(err);
                      this.notificationservice.warn(errstr);
                    }
                  )
              }
  openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}
}
