import { Component, OnInit, Optional, Inject } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { MatTableDataSource } from '@angular/material';
import { JwHistoryComponent } from '../../shared/jw-history/jw-history.component';

@Component({
  selector: 'app-jw-summary',
  templateUrl: './jw-summary.component.html',
  styleUrls: ['./jw-summary.component.css']
})
export class JwSummaryComponent implements OnInit {

  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];

  constructor(private hardfunc: HardFuncService,
              private _http: HttpClient,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private _snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.GetJwSummary();
  }
  DisplayCol(){
    this.displayedColumns = ['Product_id','Short_nm','Issue','Receipt','Balance'];
  }
  GetJwSummary(){
    let url = this.hardfunc.BaseAPIUrl + "api/InventoryDashboard/Jw_Summary?id=" + this.ReceivedData.id;
    this._http.post(url, null)
      .subscribe((data: any) => {
        this.datalist = data;
        console.log(this.datalist);
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DisplayCol();
      },
      err => {
        if (err.error.error_description) {
          this.openSnackBar(err.error.error_description, "dismiss");
        }
        else {
          this.openSnackBar("Error in getting data, Please Check Server Connectivity...", "dismiss")
        }
      });
  }
  JwHistory(Product_id: number,Short_nm: string) {
    const dialogRef = this.dialog.open(JwHistoryComponent, {
      width: '800px',
      height: '500px',
      data: { id: Product_id,Item_nm: Short_nm, Pmst_cod: this.ReceivedData.id }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
		  duration: 2000,
		});
	  }
}
