import { Injectable } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class EmdService {

  postheaders;
  constructor(
    private http: HttpClient,
    private hardfunc: HardFuncService,
    private dialog:MatDialog) {}

  SaveData(data: any){
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		let url = this.hardfunc.BaseAPIUrl + "api/Sales/EMDsAPI/SaveData"
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
  getDatas(data:any) {
    data.Firmx = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    let url = this.hardfunc.BaseAPIUrl+"api/Sales/EMDsAPI/GetDatas"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }
  GetDatabyId(pk_id: number) {
		let data: any = {};
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		data.pk_id = pk_id;
		let url = this.hardfunc.BaseAPIUrl + "api/Sales/EMDsAPI/GetDatabyId?id="+pk_id;
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
  SaveNotes(data: any, Invno: number, Firm: string, Pmst_cod: string, party_name: string){
    
    data.Firm =  Firm;
    data.Notes_type = 'EM';
    data.Invno = Invno;
    data.Pmst_cod = Pmst_cod;
    data.party_name = party_name;
    let url = this.hardfunc.BaseAPIUrl + "api/Common/notessAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  GetDatabyNotes(Invno: number){
    let data: any = {};
    //data.Firm =  Firm;
    data.Notes_type = 'EM';
    data.Invno = Invno;
    // data.Pmst_cod = Pmst_cod;
    // data.party_name = party_name;

    let url = this.hardfunc.BaseAPIUrl + "api/Common/notessAPI/GetDatasbyInvnoOnly";
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  getNumberOfchats(Notes_type: string){
		let data: any = {};
		//data.Firm = this.hardfunc.Firmx;
		data.Vtype = Notes_type;

		let url = this.hardfunc.BaseAPIUrl + "api/Common/notessAPI/NotesSumbyInvno";
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
}
