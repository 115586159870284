import {Component, OnInit , Inject, OnDestroy} from '@angular/core';
import {MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA} from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';


export interface ModalData{
  id:number
}

@Component({
  selector: 'app-codemast',
  templateUrl: './codemast.component.html',
  styleUrls: ['./codemast.component.css']
})
export class CodemastComponent implements OnInit, OnDestroy {
  datalist:any = [];
  wmodel:any = {};
  codelist:any ;
  loading:boolean = false;
  p:number = 0;

  constructor(public dialog: MatDialog , private _http:HttpClient  , private service: CommonService,
    private hardfunc : HardFuncService,
    private _snackBar: MatSnackBar
    ) {}

  ngOnInit(){

      this.loading=true;
      let url =  this.hardfunc.BaseAPIUrl+"/api/Common/CodemastsAPI/GetDatas";
      var headers = new HttpHeaders({ 'content-Type':'false' });
      this._http.get(url,{headers:headers})
        .subscribe(data1 => {
          console.log(data1);
          this.loading=false;
          this.datalist = data1;
      },
      err=> {
        console.log(err);
        this.loading=false;
      //  alert("Error in data getting... !!! ");
      this.openSnackBar("Error in data getting... !!!","dismiss");
      });
   }
   ngOnDestroy(): void {
     console.clear();
   }
   
openSnackBar(message: string, action: string) {
  this._snackBar.open(message, action, {
    duration: 2000,
  });
}
 
   openDialog(id:number ): void {
      // Open Dialog
     
      const dialogRef = this.dialog.open(CodemastCRUDDialog, {
        width: '900px',
        height: '700px',
        data: {id:id}
          
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //this.animal = result;
    });
  }
}


  //////////////////////////////////////////
//////////////C R U D ///////////////////////
 //////////////////////////////////////////



@Component({
  selector: 'codemast-crud-dialog',
  templateUrl: 'Codemast-Crud-Dialog.html',
  styleUrls: ['./codemast.component.css']
})
export class CodemastCRUDDialog {
  NewEvent:any = {};
  Message:any = "";
  loading:boolean = false;
  p:number=0;
  
  constructor(
    public dialogRef: MatDialogRef<CodemastCRUDDialog>,
    @Inject(MAT_DIALOG_DATA) public ModalData: ModalData ,
    private _http : HttpClient,
    private hardfunc : HardFuncService,
    private _snackBar: MatSnackBar
    ) {}

    Cancel(): void {
    this.dialogRef.close();
  }
 
  ngOnInit(){
    if (this.ModalData.id== -1){
      this.NewEvent = {
        SubData:[]
      };
    }
    else
    {
      let url =  this.hardfunc.BaseAPIUrl+"/api/Common/CodemastsAPI/GetDatabyId?id="+this.ModalData.id;
      var headers = new HttpHeaders({ 'content-Type':'false' });
      this._http.get(url,{headers:headers})
        .subscribe(data1 => {
          this.loading=false;
          this.NewEvent = JSON.parse(data1.toString());
          console.log(this.NewEvent);
      },
      err=> {
        console.log(err);
        this.loading=false;
        //alert("Error in data getting... !!! ");
        this.openSnackBar("Error in data getting... !!!" + err,"dismiss");
      });
    }
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  Save () {
    let url =  this.hardfunc.BaseAPIUrl+"/api/Common/CodemastsAPI/SaveData";
    var headers = new HttpHeaders({ 'content-Type':'application/json' });
    
    this._http.post(url,JSON.stringify(this.NewEvent),{headers:headers})
      .subscribe(data => {
        this.loading=false;
        alert("Data saved successfully ");
        this.dialogRef.close();
      },
      err=> {
        console.log(err);
        this.loading=false;
       // alert("Error in data getting... !!! ");
       this.openSnackBar("Error in data getting... !!!" + err,"dismiss");
      });
  }

  // Delete function  for entire master - not implemented.
  Delete () {
    //this.dialogRef.close({ event: this.NewEvent, operation: 'Delete' });
  }

  AddNewItem() {
      this.NewEvent.SubData.push({ Cd: '', Vals: '' });
  }

  RemoveRow  (index) {
      // remove the row specified in index
      this.NewEvent.SubData.splice(index, 1);
      // if no rows left in the array create a blank array
     // if (this.NewEvent.SubData.length() === 0) {
      //    this.NewEvent.SubData = [];
      //}
  };
}
