import { Component, OnInit, ViewChild, Optional } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SalesService } from '../../sales.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { QuoteComponent } from '../../quote/quote.component';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { DmsService } from 'src/app/Services/Common/dms.service';
import { QuoteMailComponent } from '../../quote/quote-mail/quote-mail.component';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { QuoteHistoryComponent } from '../../quote-history/quote-history.component';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material';

@Component({
  selector: 'app-quote-list',
  templateUrl: './quote-list.component.html',
  styleUrls: ['./quote-list.component.css']
})
export class QuoteListComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  DataForm: FormGroup;
  EnqMailAtt: any = []; 
  @ViewChild(MatTable, { static: false }) Qnt!: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private SalesService: SalesService,
              private dialog: MatDialog,
              private hardfunc: HardFuncService,
              private NotificationService: NotificationService,
              private dmsservice: DmsService,
              private fb: FormBuilder,
              private _http: HttpClient) { }

  ngOnInit() {
    this.loading = true;
    this.DataForm = this.createFormGroup();
    this.GetDatas();
    this.GetQuote1Data();    
  }
  DisplayCol() {
    this.displayedColumns = ['Quot_no','Quot_dt','Party_name','Items','Netrate', 'Action'];
  }
  
  GetDatas() {
    this.loading = true;
    this.SalesService.QuoteList(this.DataForm.value)
      .subscribe((data: any) => {
        console.log(data);
        this.datalist = data;
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.dataSource.paginator = this.paginator;
        this.DisplayCol();        
        this.loading = false;
      },
      err => {
        this.loading = false;
        let errstr = this.hardfunc.getError(err);
        this.NotificationService.warn(errstr);
      });
  }
  QuoteRevised(Quote_id: number, Status: string){
    const dialogRef = this.dialog.open(QuoteComponent, {
      width: '1400px',
      height: '800px',
      data: { id: Quote_id, Status: Status }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed ');
    });
  }
  SendMail(element: any){  
    // console.log(element);    
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    let w = '90%';
    let h = '80%';
    if (isSm )
    {
       w = '100%';
       h = '100%';
    }
    this.SalesService.SendEmail(element.Quote_id)
      .subscribe((data1: any) => {                
        const dialogRef = this.dialog.open(QuoteMailComponent, {
          width: w,
          maxWidth:w,
          height: h,
          data: { data: data1, Atts: data1, Type: 'Quote' }
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        });
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        this.NotificationService.warn(errstr);
      });
  }
  
  HistoryDialog(Qot_no: number, Party_name: string, Party_city: string, State: string){
    const dialogRef = this.dialog.open(QuoteHistoryComponent, {
      width: '1400px',
      height: '800px',
      data: { Qot_no: Qot_no, PartyName: Party_name, City: Party_city, State: State }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed ');
    });
  }
  GetQuote1Data() {
    this.SalesService.Quote1List()
      .subscribe((data: any) => {
        // this.datalist = data;
        // this.dataSource = new MatTableDataSource(<any>this.datalist);
        // this.DisplayCol();
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        this.NotificationService.warn(errstr);
      });
  }
  QuoteEdit(Quote_id: number, Status: string){
    const dialogRef = this.dialog.open(QuoteComponent, {
      width: '1600px',
      height: '800px',
      data: { id: Quote_id, Status: Status }
    });
    dialogRef.afterClosed().subscribe(result => {      
      // this.GetDatas();
      console.log('The dialog was closed ');      
      // this.datalist.map(d => {
      //   if(d.Quote_id == Quote_id){
      //     console.log(d);
      //     this.Qnt.renderRows();
      //   }
      // });
    });
  }  
  
  LoadImages() {
    this.datalist.map(m=> 
      {  
        m.SubDatas.map(w => {
          w.Doculist.map(d => {
            d.FileType = "OTH";       
          });          
        });
      }  
    )
    //For Enquiry 
    // this.datalist.map(m=> 
    //   {  
    //     m.EnqMail.map(w => {
    //       w.FileType = "OTH";       
    //     });
    //   }  
    // )
  }

  LoadImagebyId(objFile: any){
    this.loading = true;
    this.dmsservice.LoadImagebyId(objFile.Docu_id)
    .subscribe(
      (data1: any) => {
        objFile.ImageStr = 'data:image/jpeg;base64,'+data1;
        this.loading = false;
      },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn("Error in image loading "+errstr);
        }
      )
  }
  createFormGroup() {
    return this.fb.group({
      Status: new FormControl('D'),     
      Party_name: new FormControl(''),
      Qot_no: new FormControl(),
    });
  }
}
