import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';

@Injectable({
  providedIn: 'root'
})
export class BankEntryService {
  
  getheaders;
  postheaders;
  constructor(private hardfunc: HardFuncService,
              private http: HttpClient) { }
  
  SaveData(data: any){
    data.Firmx = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    let url = this.hardfunc.BaseAPIUrl + "api/Finance/gl_transAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  
}
