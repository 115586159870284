import { Component, OnInit, Optional, Inject } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PurchaseService } from '../../purchase/purchase.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';

@Component({
  selector: 'app-ratehelp',
  templateUrl: './ratehelp.component.html',
  styleUrls: ['./ratehelp.component.css']
})
export class RatehelpComponent implements OnInit {
  
  loading: boolean = false;
  Ratehelp: any = [];
  repmodel: any = {};
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];

  constructor(private hardfunc: HardFuncService,
              private _http: HttpClient,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private PurService: PurchaseService,
              private notificationservice: NotificationService) { }

  ngOnInit() {
    this.loading = true;
    this.getData();
    console.log(this.ReceivedData.Product_id);
  }
  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['firm','party','pono','podts','itcode','rate','qnty','runit','pqnty','podtx'];
  }
  getData() {
    this.loading = true;
    let Product_id = this.ReceivedData.Product_id;
    this.PurService.retehelp(Product_id)
      .subscribe((data: any) => {
        this.loading = false;        
        this.Ratehelp = JSON.parse(data);
        console.log(this.Ratehelp);
        this.dataSource = new MatTableDataSource(<any>this.Ratehelp);
        this.DisplayCol();
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.notificationservice.warn(errstr);
      });
    }
  //   let Product_id = this.ReceivedData.Product_id;
  //   this.Ratehelp = [];
  //   let url = this.hardfunc.BaseAPIUrl + "api/indentsAPI/RateHelp?Product_id="+Product_id;
  //   this._http.post(url, this.repmodel)
  //     .subscribe((data1: any) => {
  //       console.log(data1);
  //       this.Ratehelp = data1;
  //       this.dataSource = new MatTableDataSource(<any>this.Ratehelp);
  //       //this.dataSource.paginator = this.paginator;
  //       //this.DisplayCol();
  //     }, err => {
  //       console.log(err);
  //     });
  // }
}
