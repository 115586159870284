import { Component, OnInit, Inject } from '@angular/core';
import { AdminService } from '../../admin.service';
import { MAT_DIALOG_DATA} from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';

@Component({
  selector: 'app-fmodule-rights',
  templateUrl: './fmodule-rights.component.html',
  styleUrls: ['./fmodule-rights.component.css']
})
export class FmoduleRightsComponent implements OnInit {

  loading: boolean = false;
  datalist: any = []; 
  datasource: any = [];
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<unknown>;
  Filter: string;
  modulelist: any = [];

  constructor(private adminservice: AdminService,
              private hardfunc: HardFuncService,
              private notificationservice: NotificationService,
              @Inject(MAT_DIALOG_DATA) public ReceivedData: any) { }

  ngOnInit() {
    this.loading = true;
    this.getData();
    this.getModule();
  }
  getData() {
    this.loading = true;
    this.datalist = [];
    this.GetUserPermissionbyModule_id();
  }
  DisplayCol() {
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['name','Email','Permission'];
  }
  GetUserPermissionbyModule_id(){
    
    this.adminservice.GetUserPermissionbyFModule_id(this.ReceivedData.id)
    .subscribe(
      (data:any) => {
        console.log(data);
        this.datalist = data;
        this.datalist.map(m=> {
          m.PermList = [];
          let str = (m.Permission ? m.Permission.substring(0,6)   : 'NNNNNN');
          for (var i = 0; i <  str.length; i++) {
            let vals = str.substring(i,i+1) ==='Y' ? true : false;
            m.PermList.push({chk:vals})
          }  
        });
        this.DisplayCol();
        this.loading = false;
      },
      err => {
        this.loading = false;
        let errstr =  this.hardfunc.getError(err);
        console.log(err);
        this.notificationservice.warn(errstr);
        
      }
    )
  }
  SaveData() {
    this.loading = true;    
    this.datalist.map(m=> {
      m.Permission = "";
      m.PermList.map(w=>{
          if (w.chk===true)
          {m.Permission=m.Permission+'Y'}
          else
          {m.Permission=m.Permission+'N'}
      });
    });
    console.log(this.datalist);
    this.adminservice.SavePermissionbyFModule_id(this.ReceivedData.id,this.datalist)
      .subscribe(data => {
        this.getData();
        this.loading=false;
        this.notificationservice.success(" Data saved Successfully... ");
      },
      err=> {
        this.loading = false;
        let errstr =  this.hardfunc.getError(err);
        console.log(err);
        this.notificationservice.warn(errstr);
        
      });
  }
  getModule(){
    this.loading = true;
    this.adminservice.GetFmoduleData()
      .subscribe((data:any) => {
        console.log(data);
        this.loading = false;
        this.modulelist = [];
        let P_name = '###';
        let checkbox1: string;
        let checkbox2: string;
        let checkbox3: string;
        let checkbox4: string;
        let checkbox5: string;
        let checkbox6: string;
        data.map(x => {
          if (P_name !== x.Module_id) {
              this.modulelist.push({
                Header: 2, 
                checkbox1: x.chk1,
                checkbox2: x.chk2,
                checkbox3: x.chk3,
                checkbox4: x.chk4,
                checkbox5: x.chk5,
                checkbox6: x.chk6
              });
            
            
          }
        });
      }); 
      err => {
        this.loading = false;
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.notificationservice.warn(errstr);
      }
  }
}
