import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AfterViewInit,Component, OnInit,ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { AppLoaderService } from 'src/app/Services/app-loader/app-loader.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { SalesService } from '../sales.service';


export interface AcgrpData {
  Acgrp_id : number;
  Acgrp_nm : string;
  Group_cod: string;
  Grp_os : number ;
  Grplimit: number;
  Gstno: string;
  Pan:string;
  Block:boolean;
  Party_name: string;
  Party_nos: number;
  Pmst_cod: string;
  Remark: string;
}

@Component({
  selector: 'app-acgrp',
  templateUrl: './acgrp.component.html',
  styleUrls: ['./acgrp.component.css']
})
export class AcgrpComponent implements OnInit,AfterViewInit {
  loading: boolean = false;
  
 datalist: any = [];
  cols: any[];
  element: any = {};
  dataSource:any = [];
  msgs: any[] = [];
  displayedColumns: string[] = [];
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  

  constructor(
    private hardfunc: HardFuncService,
    private _http: HttpClient,
    private apploaderservice : AppLoaderService,
    private salesservice : SalesService,
    private _snackBar: MatSnackBar
  ) { }
  ngAfterViewInit(): void {
    
    this.dataSource.sort = this.sort;
    
  }

  ngOnInit() {
    this.displayedColumns = ['Acgrp_id', 'Acgrp_nm', 'GrpBal','GrpLim','SpInst','Blk', 'Action'];
    
   
  }

  getData() {
    this.loading = true;
    this.datalist = [];

    this.salesservice.getacgrp()
    .subscribe(
      data1 => {
        this.datalist = data1;
        this.dataSource = new MatTableDataSource(<any>data1);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      },
      err => {
        this.loading = false;
        if (err.error.error_description)
        {
          this.openSnackBar(err.error.error_description,"dismiss");
        }
        else
        {
          this.openSnackBar("Check Server Connectivity ","dismiss");
        }
      });
  }


  SaveData(obj:any ) {
    this.loading = true;
    let url =  this.hardfunc.BaseAPIUrl+"api/AcgrpsAPI/PutAcgrp";
    var headers = new HttpHeaders({ 'content-Type':'application/json' });
    this._http.post(url, obj, { headers: headers })
      .subscribe(data => {
        this.openSnackBar("Insert Data Success ","dismiss");
        this.loading = false;
        
      },
        err => {
          console.log(err);
          this.msgs.push({ severity: 'error', summary: 'Error', detail: `Error in Saving data , please check server connectivity...  ` });
          this.loading = false;
        });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
