import { Component, OnInit, Optional, Inject } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AdminService } from '../../admin/admin.service';

@Component({
  selector: 'app-tempmast',
  templateUrl: './tempmast.component.html',
  styleUrls: ['./tempmast.component.css']
})
export class TempmastComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  msgFromChild1 = [];
  price: string;
  repmodel: any = {};
  Tempmast: any = [];
  file: any;
  public isReadMore:boolean = true;
  public editor = ClassicEditor;

  constructor(private hardfunc: HardFuncService,
              private _http: HttpClient,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private NotificationService: NotificationService,
              private _snackbar: MatSnackBar,
              private adminservice: AdminService) { }

  ngOnInit() {
    this.loading = true;
    this.GetDatas();
  }
  DispalyCol() {
    this.displayedColumns = ['Tempstr','Action'];
  }
  
  GetDatas() {
    this.loading = true;
    let vtype = this.ReceivedData.Vtype;
    let fldname = this.ReceivedData.Fldname;
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/tempmastsAPI/GetDatas?Vtype=" + vtype + "&Fldname=" + fldname;
    this._http.post(url, null)
      .subscribe((data: any) => {
        this.loading = false;
        this.datalist = data;
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DispalyCol();
      },
        err => {
          if (err.error.error_description) {
            this.openSnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.openSnackBar("Error in getting data, Please Check Server Connectivity...", "dismiss")
          }
        });
  }
  SaveTempmast() {
    var data: any = {};
    data.Tempstr = this.repmodel.Tempstr;
    data.TempTitle = this.repmodel.TempTitle;
    data.Tempmast_id = this.repmodel.Tempmast_id;
    data.Vtype = this.ReceivedData.Vtype;
    data.Fldname = this.ReceivedData.Fldname;
    data.Firmx = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    // data.append("Files",this.file);
    // data.append("Data",JSON.stringify(data));
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/tempmastsAPI/SaveData"
    this._http.post(url, data)
      .subscribe((data: any) => {
        console.log(data);
        this.NotificationService.success("Data Saved Successfully...");
        this.GetDatas();
      },
        err => {
          if (err.error.error_description) {
            this.openSnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.openSnackBar("Error in getting data, Please Check Server Connectivity...", "dismiss")
          }
        });
  }
  
  GetFile(event){
    this.file = event.target.files[0];
    console.log("files", this.file);
  }
  EditTemp(Tempmast_id: number,Tempstr: string,TempTitle: string){
    console.log(Tempmast_id);
    console.log(Tempstr);
    this.repmodel.Tempmast_id = Tempmast_id;
    this.repmodel.Tempstr = Tempstr;
    this.repmodel.TempTitle = TempTitle;
  }
  msgToParent(Tempmast_id: number, Tempstr: string) {
    this.msgFromChild1.push(Tempmast_id);
    this.msgFromChild1.push(Tempstr);
    this.msgFromChild1.push(this.ReceivedData)
  }
  openSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, {
      duration: 2000,
    });
  }
}
