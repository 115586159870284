import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { Sort, MatDialog, MatTableDataSource, MatSnackBar } from '@angular/material';
import { FacTransCrudComponent } from '../fac-trans-crud.component';
import { FactransService } from '../factrans.service';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { MatPaginator } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';

@Component({
  selector: 'app-factrans-list',
  templateUrl: './factrans-list.component.html',
  styleUrls: ['./factrans-list.component.css']
})
export class FactransListComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  DataForm: FormGroup;
  repmodel: any = {};
  Dept_nm: any = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  Stock_type: any = [];
  @Input('Type') Type;

  constructor(private _snackBar: MatSnackBar,
        private factransservice: FactransService,
		private dialog: MatDialog,
		private adminservice: AdminService,
		private notificationService: NotificationService,
		private hardfunc: HardFuncService,
		private commonservice: CommonService,
		private fb: FormBuilder,
		private _http: HttpClient) { }

  ngOnInit() {
    this.loading = true;
	this.getDeptName();
	this.getStock_Type();
	this.DataForm = this.createFormGroup();
	if(this.Type === 'CONS'){
		this.DataForm.controls.TranType.patchValue("CONS");
	}
  }
  DisplayCol() {
	let isSm = window.matchMedia(`(max-width: 960px)`).matches;
	this.displayedColumns = ['Facmast_id','Slip_no','Tdate','Product_id','Product_nm', 'Rcvr_dept','TranType', 'Qnty', 'Unit', 'Remarks','Action'];
 }
 ngOnDestroy(): void {
	console.clear();
 }
 //1. api/Sales/factransAPI/GetDatas
  getData() {
	this.loading = true;
	this.datalist = [];
	var data: any = {};
	this.DataForm.controls.Fdate.patchValue(this.hardfunc.hsConvDt(this.DataForm.controls.Fdate.value));
	this.DataForm.controls.Tdate.patchValue(this.hardfunc.hsConvDt(this.DataForm.controls.Tdate.value));
	let cnd = " 1=1 ";
	if (this.DataForm.value.Dept_id != "*All*")
	{
		cnd += " and Dept_id = '"+this.DataForm.value.Dept_id+"'";	
	}
	if (this.DataForm.value.TranType != "All")
	{
		cnd += " and TranType like ''%"+this.DataForm.value.TranType+"%'' ";	
	}
	// data.cond = cnd;
	this.DataForm.value.cond = cnd;
	this.factransservice.getDatas(this.DataForm.value)
		.subscribe(
			data => {
			this.datalist = data;
			console.log(this.datalist);
			this.loading = false;
			this.dataSource = new MatTableDataSource(<any>this.datalist);
			this.DisplayCol();
			this.dataSource.paginator = this.paginator;
			},
			err => {
				if (err.error.error_description) {
					this.openSnackBar(err.error.error_description, "dismiss");
					this.loading = false;
				}
				else {
					this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					this.loading = false;
				}
			}
		)
	}
	openDialog(Facmast_id: number,TranType: string){
		const dialogRef = this.dialog.open(FacTransCrudComponent, {
			width: '1150px',
			height: '458px',
			data: { id: Facmast_id, TranType: TranType }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}

	//2. api/Sales/factransAPI/DeleteData
	DeleteRcd(Facmast_id: number): void {
		this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
			.afterClosed().subscribe(res => {
			if (res) {
				this.loading = true;
				const dialogRef = this.factransservice.DeleteFactrans(Facmast_id)
					.subscribe(
						(data: any) => {
							this.loading = false;
							this.notificationService.warn("Data deleted succefully");
							this.getData();
						},
						err => {
							this.loading = false;
							let errstr = this.hardfunc.getError(err);
							console.log(err);
							this.notificationService.warn(errstr);
						}
					)
			}
			});
	}
	getDeptName() {
		let url = this.hardfunc.BaseAPIUrl + "api/Sales/deptssAPI/GetDatas";
		this._http.post(url, this.repmodel)
		  .subscribe((data1: any) => {
			this.Dept_nm = data1;
		  }, err => {
			console.log(err);
		  });
	  }
	
	//3. api/Common/CodemastsAPI/GetCodeListbyCodenm
	getStock_Type() {
	this.loading = true;
	this.commonservice.CodeList('Stock_type')
		.subscribe(
		(data: any) => {
			this.Stock_type = JSON.parse(data);
			this.loading = false;
		},
		err => {
			this.loading = false;
			let errstr = this.hardfunc.getError(err);
			this.notificationService.warn(errstr);
		}
		);
	}
	createFormGroup() {
		return this.fb.group({
			Firmx: this.hardfunc.Firmx,
			Yrx: this.hardfunc.Yrx,
			Fdate: new FormControl(new Date()),
			Tdate: new FormControl(new Date()),
			TranType: new FormControl('All'),
			Dept_id: new FormControl('',Validators.required)
		});
	  }
	applyFilter(event: Event) {
	const filterValue = (event.target as HTMLInputElement).value;
	this.dataSource.filter = filterValue.trim().toLowerCase();
	}
  openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}

}
