import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FactransService } from '../factrans.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { Sort, MatDialog, MatTableDataSource, MatSnackBar } from '@angular/material';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-factrans-man',
  templateUrl: './factrans-man.component.html',
  styleUrls: ['./factrans-man.component.css']
})
export class FactransManComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  DataForm: FormGroup;
  repmodel: any = {};
  Dept_nm: any = [];
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  fUnit: string; 
  Type: string;
  ParentName: string="";
  funit = [
    {value: 'L', name: 'LMS'},
    {value: 'I', name: 'INDUSTRIES'},
    {value: 'H', name: 'HOSE'},
    {value: 'A', name: 'ARMTEX'},
    {value: 'N', name: 'NK FIRE'},
    {value: 'F', name: 'FIRE SOLU'},
  ];
  constructor(private fb: FormBuilder,
              private hardfunc: HardFuncService,
              private _http: HttpClient,
              private factransservice: FactransService,
              private notificationService: NotificationService,
              private _snackBar: MatSnackBar,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.Type = params.get('Type');
    });
    
    //this.DataForm = this.SelectUnitFormGroup();
    this.loading = true;
    //this.getDeptName();
    //this.getData();
  }
  selectUnit(){
    console.log(this.repmodel.fUnit);
  }
  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['Slip_no','Tdate','Product_id','Product_nm','Tran_from', 'Issue_to', 'Qnty', 'Unit', 'Remarks','Action'];
  }
  ngOnDestroy(): void {
    
  }


  // getData() {
	// 	this.loading = true;
	// 	this.datalist = [];
	// 	var data: any = {};

	// 	this.factransservice.getDatas(data)
	// 		.subscribe(
	// 			data => {
	// 			this.datalist = data;
	// 			this.loading = false;
	// 			this.dataSource = new MatTableDataSource(<any>this.datalist);
	// 			this.DisplayCol();
	// 			},
	// 			err => {
	// 				if (err.error.error_description) {
	// 					this.openSnackBar(err.error.error_description, "dismiss");
	// 				}
	// 				else {
	// 					this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
	// 				}
	// 			}
	// 		)
	// }
  openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}
  // Unit(){
  //   this.factransservice.SaveData(this.DataForm.value)
  //     .subscribe(
  //       data => {
  //         this.loading = false;
  //         //this.ResetData();
  //         // this.GetData();
  //         this.notificationService.success(' Data Saved Successfully... ');
  //       },
  //       err => {
  //         let errstr = this.hardfunc.getError(err);
  //         console.log(err);
  //         this.notificationService.warn(errstr);
  //         this.loading = false;
  //       }
  //     )
  // }
  // getDeptName() {
  //   let url = this.hardfunc.BaseAPIUrl + "api/Sales/deptssAPI/GetDatas";
  //   this._http.post(url, this.repmodel)
  //     .subscribe((data1: any) => {
  //       this.Dept_nm = data1;
  //     }, err => {
  //       console.log(err);
  //     });
  // }
  // SelectUnitFormGroup(){
  //   return this.fb.group({
  //     fUnit: new FormControl('L')
  //   });
  // }
}
