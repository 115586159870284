import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { IndentApprovedComponent } from '../indent-approved/indent-approved.component';
import { MAT_DIALOG_DATA } from '@angular/material';
import { PurchaseService } from '../../purchase.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';

@Component({
  selector: 'app-po-item',
  templateUrl: './po-item.component.html',
  styleUrls: ['./po-item.component.css']
})
export class PoItemComponent implements OnInit {

  DataForm: FormGroup;
  msgFromChild1 = [];
  loading: boolean = false;
  Indenters: any = [];
  UnitsList: any = [];

  constructor(private fb: FormBuilder,
              private dialog: MatDialog,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private PurchaseService: PurchaseService,
              private MatSnackBar: MatSnackBar,
              private commonservice: CommonService,
              private hardfunc: HardFuncService,
              private NotificationService: NotificationService) { }

  ngOnInit() {
    this.DataForm = this.createFormGroup();
    this.DataForm.get("Srno").patchValue(this.ReceivedData.index);
    this.GetIndeter();
    this.GetUnits();
    this.GetData();
    
    console.log(this.ReceivedData);
  }

  GetData(){
    if(this.ReceivedData.Type === "E"){
      this.DataForm.get("Pono").patchValue(this.ReceivedData.Pono);
      this.DataForm.get("Srno").patchValue(this.ReceivedData.index);
      this.DataForm.get("Slip_no").patchValue(this.ReceivedData.data.Slip_no);
      this.DataForm.get("ItCode").patchValue(this.ReceivedData.data.ItCode);
      this.DataForm.get("Item").patchValue(this.ReceivedData.data.ItemDescr);      
      this.DataForm.get("Qnt1").patchValue(this.ReceivedData.data.Qnty || 0);
      this.DataForm.get("Qnt2").patchValue(this.ReceivedData.data.Qnty2 || 0);
      this.DataForm.get("Rate").patchValue(this.ReceivedData.data.Rate || 0);
      this.DataForm.get("Basic").patchValue(this.ReceivedData.data.Bsc || 0);
      this.DataForm.get("Disc").patchValue(this.ReceivedData.data.Disc || 0);
      this.DataForm.get("DiscTot").patchValue(this.ReceivedData.data.Disc1 || 0);
      this.DataForm.get("Examt").patchValue(this.ReceivedData.data.Exc || 0);
      this.DataForm.get("IgstTot").patchValue(this.ReceivedData.data.Exc1 || 0);
      this.DataForm.get("Taxamt").patchValue(this.ReceivedData.data.Tax || 0);
      this.DataForm.get("SgstTot").patchValue(this.ReceivedData.data.Tax1 || 0);
      this.DataForm.get("Atamt").patchValue(this.ReceivedData.data.Atax || 0);
      this.DataForm.get("CgstTot").patchValue(this.ReceivedData.data.Atax1 || 0);
      this.DataForm.get("ItemTot").patchValue(this.ReceivedData.data.Ittot || 0);
      this.DataForm.get("Runit").patchValue(this.ReceivedData.data.Runit);
    }
  }
  SaveData(){    
    this.msgFromChild1.push(this.DataForm.value);   
  }
  AddItemOrder(){
    const dialogRef = this.dialog.open(IndentApprovedComponent, {
			width: '1240px',
			height: '710px',
			data: {  }
		});
		dialogRef.afterClosed().subscribe(result => {
      console.log(result);      
      this.DataForm.get("Slip_no").patchValue(result.Slip_no);
      this.DataForm.get("ItCode").patchValue(result.Product_id);
      this.DataForm.get("Indenter").patchValue(result.Indt_id);
      this.DataForm.get("Unit1").patchValue(result.Unit1);
      this.DataForm.get("Unit2").patchValue(result.Unit2);
      this.DataForm.get("Qnt1").patchValue(result.Aqnty);
      this.DataForm.get("Qnt2").patchValue(0);
      this.DataForm.get("Rate").patchValue(500);
      this.DataForm.get("Basic").patchValue(0);
      this.DataForm.get("Disc").patchValue(0);
      this.DataForm.get("Examt").patchValue(0);
      this.DataForm.get("Taxamt").patchValue(0);
      this.DataForm.get("Atamt").patchValue(0);
      this.DataForm.get("Item").patchValue(result.Short_nm);
      this.DataForm.get("ItemTot").patchValue(0);
      this.DataForm.get("Srno").patchValue(result.Srno);
      this.DataForm.get("Slip_srno").patchValue(result.Srno);      
      // this.DataForm.get("Runit").patchValue(result.value.Runit);

			console.log('The dialog was closed');      
		});
  }
  
  DiscCal(){    
    let basictot = this.DataForm.get("Basic").value * this.DataForm.get("Disc").value / 100;
    console.log(basictot);
    this.DataForm.get("DiscTot").patchValue(basictot);
  }
  BasicCal(){
    this.DataForm.get("Basic").patchValue(this.DataForm.get("Qnt2").value * this.DataForm.get("Rate").value);
  }
  GstCal(Type: string){
    if(Type === "I"){
      let BasicTot = this.DataForm.get("Basic").value - this.DataForm.get("DiscTot").value;
      let Total = BasicTot * this.DataForm.get("Examt").value / 100;
      this.DataForm.get("IgstTot").patchValue(Total);
    }else if(Type === "S"){
      console.log(this.DataForm.get("DiscTot").value);
      let BasicTot = this.DataForm.get("Basic").value - this.DataForm.get("DiscTot").value;
      let Total = BasicTot * this.DataForm.get("Taxamt").value / 100;
      this.DataForm.get("SgstTot").patchValue(Total);
    }else{
      let BasicTot = this.DataForm.get("Basic").value - this.DataForm.get("DiscTot").value;
      let Total = BasicTot * this.DataForm.get("Atamt").value / 100;
      this.DataForm.get("CgstTot").patchValue(Total);
    }
  }
  ItemTotCal(){
    let a = this.DataForm.get("Basic").value - this.DataForm.get("DiscTot").value;
    let c = a * 12 / 100;
    this.DataForm.get("ItemTot").patchValue(a + c);    
  }

  GetUnits() {
    this.loading = true;
    this.commonservice.CodeList('Units')
      .subscribe(
        (data: any) => {
          this.UnitsList = JSON.parse(data);
          console.log(this.UnitsList);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn(errstr);
        }
      );
  }

  GetIndeter() {
    this.loading = true;
    this.Indenters = [];
    
    this.PurchaseService.getIndenterList()
      .subscribe(
        (data: any) => {          
          this.Indenters = data;                 
          this.loading = false;
        },
        err => {
          this.loading = false;
          if (err.error.error_description) {
            this.openSnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          }
        }
      )
  }


  createSubData(){
    return this.fb.group({
      Pk_id: new FormControl(-1),
      PoNo: new FormControl(),
      Srno: new FormControl(),
      Slip_no: new FormControl(),
      ItCode: new FormControl(),
      Indenter: new FormControl(''),
      Unit1: new FormControl(''),
      Unit2: new FormControl(''),
      Qnt1: new FormControl(),
      Qnt2: new FormControl(),
      Rate: new FormControl(),
      BscTot: new FormControl(),
      DiscAmt: new FormControl(),
      ExAmt: new FormControl(),
      TaxAmt: new FormControl(),
      AtAmt: new FormControl(),
      Despatch: new FormControl(),
      Payment: new FormControl(),
      Inspection: new FormControl(),
      Freight: new FormControl(),
      OrdBy: new FormControl(),
      ConvRate: new FormControl(),
      Purref: new FormControl(),
      Handle_by: new FormControl(),
      Condition: new FormControl(),
      ReqDt: new FormControl(),
      Dir_id: new FormControl(),
      Revno: new FormControl(),      
      // Igst: new FormControl(),
      // Sgst: new FormControl(),
      // Gst: new FormControl(),
      // ItemTot: new FormControl(),
      // Grp_id: new FormControl(),
      // Item: new FormControl(''),
      // ItemDesc: new FormControl(''),
      // PurRate: new FormControl(),
      // LastDt: new FormControl(),
      // Curr: new FormControl(),
      // ConvRate: new FormControl(),      
    });
  }

  createFormGroup(){
    return this.fb.group({
      Pono: new FormControl(),
      Srno: new FormControl(),
      Slip_no: new FormControl(),
      Slip_srno: new FormControl(),
      ItCode: new FormControl(),
      Indenter: new FormControl(''),
      Unit1: new FormControl(''),
      Unit2: new FormControl(''),
      Qnt1: new FormControl(),
      Qnt2: new FormControl(),
      Rate: new FormControl(),
      Runit: new FormControl(''),
      Basic: new FormControl(),
      Disc: new FormControl(),
      DiscTot: new FormControl(),
      Examt: new FormControl(),
      IgstTot: new FormControl(),
      Taxamt: new FormControl(),
      SgstTot: new FormControl(),
      Atamt: new FormControl(),
      CgstTot: new FormControl(),
      ItemTot: new FormControl(),
      Grp_id: new FormControl(),
      Item: new FormControl(''),
      ItemDesc: new FormControl(''),
      PurRate: new FormControl(),
      LastDt: new FormControl(),
      Curr: new FormControl(),
      ConvRate: new FormControl(),      
    });
  }
  openSnackBar(message: string, action: string) {
    this.MatSnackBar.open(message, action, {
      duration: 2000,
    });
  }
}
