import { Component, OnInit,AfterViewInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { Observable } from 'rxjs';
import { debug } from 'util';
import { Router } from '@angular/router';
import { SalesService } from '../sales.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { OrderInvComponent } from '../order-inv/order-inv.component';
import { OsdetailComponent } from '../osdetail/osdetail.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar, MatSort } from '@angular/material';
import {MatTableDataSource} from '@angular/material/table';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

export interface OsData {
  Pmst_cod : string;
  Party_name : string;
  Party_city: string;
  Party_stat : string ;
  BalAmt: number;
  Party_os: number;
}

@Component({
  selector: 'app-os',
  templateUrl: './os.component.html',
  styleUrls: ['./os.component.css']
})
export class OsComponent implements OnInit,AfterViewInit  {

  loading: boolean = false;
  datalist: any = [];
  cols: any[];
  repmodel: any = {};
  ProdList: any = [];
  PrdGroupList: any = [];
  PrdStockList: any =[];
  msgs: any[] = [];
  rowGroupMetadata: any;
  src: any;
  dataSource:any = [];
  displayedColumns: string[] = [];
  Dom_For:string = 'D';
  DataForm: FormGroup;
  
 // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private _http: HttpClient,
    private hardfunc: HardFuncService,
    private _cservice: CommonService,
    private sanitizer: DomSanitizer,
    private _router:Router,
    private sal_service: SalesService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    //this.DataForm = this.createFormGroup();
    
    this.loading = true;
    this.repmodel.CG = 'A';
    this.repmodel.Status = 'P';

    this.cols = [
      { field: 'PMST_COD', header: 'Party Code' },
      { field: 'party_name', header: 'Party Name ' },
      { field: 'party_city', header: 'Party City ' },
      { field: 'party_bal', header: 'Balance' },
    ];

   
    this.loading = false;
  }
  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    if (this.Dom_For == 'D')
    {
      if (isSm)
      {
        this.displayedColumns = [ 'Party', 'BalAmt','Action'];
      }
      else
      {
        this.displayedColumns = ['Pmst_cod', 'Party_name', 'Party_city','Party_stat', 'BalAmt','Action'];
      }
    }
    else
    {
      this.displayedColumns = ['Pmst_cod', 'Party_name', 'Party_city', 'BalAmt','c_BalAmt','Action'];
    }
  }

  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    
  }
isGroup(index, item): boolean{
    return item.isGroupBy;
  }
  getData() {
    this.DisplayCol();
    this.loading = true;
    this.datalist = [];
    let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/GetSummary";
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    this.repmodel.DF = this.Dom_For; // D-> Domestic , F->Foreign
    this._http.post(url, this.repmodel, { headers: headers })
      .subscribe(data1 => {
        this.datalist = data1;
        this.loading = false;
        this.dataSource = new MatTableDataSource(<any>data1);
        this.dataSource.sort = this.sort;
      },
        err => {
          console.log(err);
         // this.msgs.push({ severity: 'error', sticky:'false',life:'100', summary: 'Error', detail: `Error in getting data , please check server connectivity...  ` });
          this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
          this.loading = false;
      });
  }

  GetInv_by_Ordno(ordno:number,oloc:number){
    let mydata:any = {};
    this.repmodel = {};
    this.repmodel.ordno = ordno;
    this.repmodel.oloc = oloc;  
   
      const dialogRef = this.dialog.open(OrderInvComponent, {
        width: '250px',
        data: this.repmodel
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }
    getAcLed(pmst_cod:string){
      //this.apploaderservice.open();
      this.src = "";
      this.repmodel =
      {
        Pmst_cod: pmst_cod,
        Fdate: new Date(Date.UTC(2020, 3, 1)),
        Tdate: new Date(),
        Output: 'PDF',
        Firmx:this.hardfunc.Firmx,
        Yrx:this.hardfunc.Yrx
      }

      this._cservice.AcLedger(this.repmodel)
        .subscribe(
          data => {
              let base64String = btoa(new Uint8Array(data).reduce((data, byte) => {
                return data + String.fromCharCode(byte);
              }, ''));
              //this.stateService.data = {b64:base64String} ;
              this.src = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + base64String);
              let pdfWindow = window.open("")
              pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(base64String) + "'></iframe>")
          //  this.apploaderservice.close();
          },
          err => {
            console.log(err);
            if (err.error.error_description)
            {
              this.openSnackBar(err.error.error_description,"dismiss");
              //this.msgs.push({ severity: 'error', summary: 'Error Message', detail:  err.error.error_description });
            }
            else
            {
              this.openSnackBar("Check Server Connectivity ","dismiss");
              //this.msgs.push({ severity: 'error', summary: 'Error Message', detail:  "Check server connectivity... " });
            }
            //this.apploaderservice.close();
          }
        )
  

    }

    getDetails(Party:any){
      
      let mydata:any = {};
      this.repmodel = {};
      this.repmodel = Party;
     
        const dialogRef = this.dialog.open(OsdetailComponent, {
          width: '1000px',
          data: this.repmodel
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        });
      }

  // createFormGroup(){
  //   return this.fb.group({
  //     Ordno : new FormControl('OR'),
  //     Party_Ordno : new FormControl(''),
  //     Inv_no : new FormControl(''),
  //     Inv_dt : new FormControl(''),
  //     Inv_total : new FormControl(''),
  //     Receipt_Amt : new FormControl(''),
  //     Balance_os : new FormControl(''),
  //     Due_dt : new FormControl(''),
  //     Pay_terms : new FormControl(''),
  //     Dir_Mkt : new FormControl(''),
  //     Remarks : new FormControl('')
  //   });
  // }      

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
