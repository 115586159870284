import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from '../../admin.service'; 
import { MAT_DIALOG_DATA } from '@angular/material';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';

@Component({
  selector: 'app-module-user',
  templateUrl: './module-user.component.html',
  styleUrls: ['./module-user.component.css']
})
export class ModuleUserComponent implements OnInit {
  loading: boolean = false;
  datalist: any = []; 
  datasource: any = [];
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<unknown>;
  Filter: string;
  
  constructor(
    private adminservice : AdminService,
    private hardfunc : HardFuncService,
    private notificationService : NotificationService,
    @Inject(MAT_DIALOG_DATA) public ReceivedData: any
  ) { }

  ngOnInit() {
    
    this.getData();
  }
  getData() {
    this.loading = true;
    this.datalist = [];
    this.GetUserPermissionbyModule_id();
  }

  DisplayCol() {
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['name','Email','Permission'];
  }
  GetUserPermissionbyModule_id(){
    
    this.adminservice.GetUserPermissionbyModule_id(this.ReceivedData.id)
    .subscribe(
      (data:any) => {
        this.datalist = data;
        this.datalist.map(m=> {
          m.PermList = [];
          let str = (m.Permission ? m.Permission.substring(0,6)   : 'NNNNNN');
          for (var i = 0; i <  str.length; i++) {
            let vals = str.substring(i,i+1) ==='Y' ? true : false;
            m.PermList.push({chk:vals})
          }  
        });
        this.DisplayCol();
        this.loading = false;
      },
      err => {
        this.loading = false;
        let errstr =  this.hardfunc.getError(err);
        console.log(err);
        this.notificationService.warn(errstr);
        
      }
    )
  }

  SaveData() {
    this.loading = true;    
    this.datalist.map(m=> {
      m.Permission = "";
      m.PermList.map(w=>{
          if (w.chk===true)
          {m.Permission=m.Permission+'Y'}
          else
          {m.Permission=m.Permission+'N'}
      });
    });
   
    this.adminservice.SavePermissionbyModule_id(this.ReceivedData.id,this.datalist)
      .subscribe(data => {
        this.loading=false;
        this.notificationService.success(" Data saved Successfully... ");
      },
      err=> {
        this.loading = false;
        let errstr =  this.hardfunc.getError(err);
        console.log(err);
        this.notificationService.warn(errstr);
        
      });
  }

}
