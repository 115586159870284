import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gat-inw',
  templateUrl: './gat-inw.component.html',
  styleUrls: ['./gat-inw.component.css']
})
export class GatInwComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
