import { Component, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';

@Component({
  selector: 'app-template-driven',
  templateUrl: './template-driven.component.html',
  styleUrls: ['./template-driven.component.css']
})
export class TemplateDrivenComponent implements OnInit {
  registerForm: NgForm;

  constructor() { }

  ngOnInit() {
  }
  user = {
    firstname:'',
    lastname:'',
    email:'',
    password:'',
    confirm:'',

  };
  onSubmit(user){
    console.log(this.user);
  }
}


