import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PurchaseService } from '../purchase.service';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import * as moment from 'moment'
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { Sort, MatTableDataSource } from '@angular/material';
import { PurdialogComponent } from './purdialog/purdialog.component';

@Component({
  selector: 'app-prchk-list',
  templateUrl: './prchk-list.component.html',
  styleUrls: ['./prchk-list.component.css']
})
export class PrchkListComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  datalist: any = [];
  DataForm: FormGroup;
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  OrdData: any = [];
  PtpenordAll: any= [];
  Ptpenord: any= [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private purchaseService: PurchaseService,
              private _snackBar: MatSnackBar,
			  private fb: FormBuilder,
			  private hardfunc: HardFuncService,
			  private dialog: MatDialog) { }

  ngOnInit() {
    //this.getData();
	this.DataForm = this.createFormGroup();
	this.loading = false;
  }
  DisplayCol() {
	let isSm = window.matchMedia(`(max-width: 960px)`).matches;
	this.displayedColumns = ['Srno','Party', 'Invno', 'Invdt','Dept','Bsctot','ExAmt','TaxAmt','AtAmt','Frlbr','Invtot','TdsAmt','Items'];
  }
  ngOnDestroy(): void {
	console.clear();
  }
  getData() {
	this.loading = true;
	this.datalist = [];
	var data: any = {};
	let Fdate = this.hardfunc.hsConvDt(this.DataForm.get('Fdate').value);
	let Tdate = this.hardfunc.hsConvDt(this.DataForm.get('Tdate').value);
	// let cnd = " ";
	//console.log(this.DataForm.value.Dept);
	
	// if (this.DataForm.value.Dept)
	// {
	// 	cnd += " and Dept = ''" + this.DataForm.value.Dept + "''";
	// }
	// console.log(cnd);
	// data.Cond = cnd;
	// console.log(data.Cond);
	data.Fdate = Fdate;
	data.Tdate = Tdate;
	this.purchaseService.getDatas(data)
		.subscribe(
		(data: any) => {
			console.log(data);
			this.datalist = data;
			this.loading = false;
			this.dataSource = new MatTableDataSource(<any>this.datalist);
			this.DisplayCol();
			this.dataSource.paginator = this.paginator;
		},
		err => {
			if (err.error.error_description) {
				this.openSnackBar(err.error.error_description,"dismiss");
			}
			else {
				this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
			}
		}
	)
	}
	PurBySrnoDialog(Srno: number): void {
		const dialogRef = this.dialog.open(PurdialogComponent, {
			width: '1200px',
			data: { id: Srno }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}
	// PurBySrno(Srno: number) {
	// 	this.loading = true;
	// 	this.datalist = [];
	// 	this.purchaseService.PurBySrno(Srno)
	// 		.subscribe(
	// 			data => {
	// 				this.loading = false;
	// 			},
	// 			err => {
	// 				if (err.error.error_description) {
	// 					this.openSnackBar(err.error.error_description, "dismiss");
	// 				}
	// 				else {
	// 					this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
	// 				}
	// 			}
	// 		)
	// }
	createFormGroup() {
		return this.fb.group({
		  Fdate: new FormControl(new Date()),
		  Tdate: new FormControl(new Date()),
		  //Dept: new FormControl('*All*')
		});
	  }
  openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}
}
