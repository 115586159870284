import { Component, OnInit, Optional, Inject } from '@angular/core';
import { PurchaseService } from '../../purchase.service';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-indent-approved',
  templateUrl: './indent-approved.component.html',
  styleUrls: ['./indent-approved.component.css']
})
export class IndentApprovedComponent implements OnInit {

  datalist: any = [];
  msgFromChild1 = [];
    
  constructor(private purchaseService: PurchaseService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any) { }

  ngOnInit() {
    this.GetData();
  }
  DisplayCol() {	
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		// this.displayedColumns = ['Slip_no','Srno','Tdate','Firm','Product_id','ItemName','Aqnty','Unit1','Indt_nm','Unit1','Party',
    //                          'Purpose','Unit2','Remark','Hold'];
	}
  GetData() {
    this.purchaseService.IndApproved()
      .subscribe((data: any) => {
        this.datalist = data;
        console.log(this.datalist);
        // this.dataSource = new MatTableDataSource(<any>this.datalist);        
        // this.DisplayCol();   
      },
        err => {
          console.log(err);
        });
  }
  msgToParent(data) {    
    this.msgFromChild1.push(data);    
  }
  

}
