import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MatSnackBar } from '@angular/material';
import { CommonService } from 'src/app/Services/Common/common.service';
import { SalesService } from 'src/app/Views/sales/sales.service';
import { FinanceService } from 'src/app/Views/finance/finance.service';
import { Console } from 'console';

@Component({
  selector: 'app-docu-rights',
  templateUrl: './docu-rights.component.html',
  styleUrls: ['./docu-rights.component.css']
})
export class DocuRightsComponent implements OnInit {

  datalist: any = [];
  loading: boolean = false;
  ReceivedData: any;
  Docu_List: any = [];
  Userlist: any = [];
  chk: string;
  repmodel: any = {};
  isClicked: boolean = false;
  docudata: any = {};
  DocuRightsList: any = [];

  constructor(private adminservice: AdminService,
    private hardfunc: HardFuncService,
    private notificationService: NotificationService,
    private _snackBar: MatSnackBar,
    private commonservice: CommonService,
    private financeService: FinanceService) { }

  ngOnInit() {
    this.loading = true;
    this.repmodel.Menu = 1125;
    this.GetDocuList();
    this.GetDocuRights();
    // this.GetTrialBalance();
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  // For Single Documents Rights
  SaveData(data: any, UserName: string) {
    this.loading = true;
    console.log(data);
    if (!data.Chk) {
      data.Permission = 'Y';
    } else {
      data.Permission = 'N';
    }    
    // Module 
    var Wmodule_id: number = 1125;
    var Codemast_id: number = 10;
    if (this.repmodel.Menu === 'SO') {
      Wmodule_id = 1125;
      Codemast_id = 10;
    } else if (this.repmodel.Menu === 'EN') {
      Wmodule_id = 1136;
      Codemast_id = 17;
    } else if (this.repmodel.Menu === 'QT') {
      Wmodule_id = 1134;
      Codemast_id = 19;
    } else if (this.repmodel.Menu === 'EM') {
      Wmodule_id = 1133;
      Codemast_id = 12;
    } else if (this.repmodel.Menu === 'PA') {
      Wmodule_id = 1135;
      Codemast_id = 25;
    } else if (this.repmodel.Menu === 'TB') {
      Wmodule_id = 1137;
      Codemast_id = 26;
    } else if (this.repmodel.Menu === 'BG') {
      Wmodule_id = 1138;
      Codemast_id = 13;
    } else if (this.repmodel.Menu === 'CR') {
      Wmodule_id = 1144;
      Codemast_id = 32;
    }
    data.UserName = UserName;
    data.Wmodule_id = Wmodule_id;
    this.adminservice.SaveDocuRights(data)
      .subscribe((data: any) => {        
        this.GetDocuRights();
        this.loading = false;
      },
      err => {
        this.loading = false;
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.notificationService.warn(errstr);
      });
  }

  // For All Select Documents Rights
  SaveDataAll(data: any, UserName: string) {
    this.loading = true;    
    this.DocuRightsList = [];
    // Module 
    var Wmodule_id: number = 1125;
    var Codemast_id: number = 10;
    if (this.repmodel.Menu === 'SO') {
      Wmodule_id = 1125;
      Codemast_id = 10;
    } else if (this.repmodel.Menu === 'EN') {
      Wmodule_id = 1136;
      Codemast_id = 17;
    } else if (this.repmodel.Menu === 'QT') {
      Wmodule_id = 1134;
      Codemast_id = 19;
    } else if (this.repmodel.Menu === 'EM') {
      Wmodule_id = 1133;
      Codemast_id = 12;
    } else if (this.repmodel.Menu === 'PA') {
      Wmodule_id = 1135;
      Codemast_id = 25;
    } else if (this.repmodel.Menu === 'TB') {
      Wmodule_id = 1137;
      Codemast_id = 26;
    } else if (this.repmodel.Menu === 'BG') {
      Wmodule_id = 1138;
      Codemast_id = 13;
    } else if (this.repmodel.Menu === 'CR') {
      Wmodule_id = 1144;
      Codemast_id = 32;
    }

    this.datalist.map(d => {
      if (d.UserName === data.UserName) {
        d.DocuRights.map(s => {
          if (!s.Chk) {            
            s.Permission = 'Y';
            s.Wmodule_id = Wmodule_id;
            s.UserName = UserName;
          } else {            
            s.Permission = 'N';
            s.Wmodule_id = Wmodule_id;
            s.UserName = UserName;
          }
          this.DocuRightsList.push(s);          
        });
      }
    });
    console.log(this.DocuRightsList);

    this.adminservice.AllSaveDocuRights(this.DocuRightsList)
      .subscribe((data: any) => {        
        this.GetDocuRights();
        this.loading = false;
      },
      err => {
        this.loading = false;
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.notificationService.warn(errstr);
      });
  }
  GetDocuRights() {
    var Wmodule_id: number = 1125;
    var Codemast_id: number = 10;
    var Omodule_id: number = 0;
    if (this.repmodel.Menu === 'SO') {
      Wmodule_id = 1125;
      Codemast_id = 10;
      Omodule_id = 0;
    } else if (this.repmodel.Menu === 'EN') {
      Wmodule_id = 1136;
      Codemast_id = 17;
      Omodule_id = 1136;
    } else if (this.repmodel.Menu === 'QT') {
      Wmodule_id = 1134;
      Codemast_id = 19;
      Omodule_id = 1134;
    } else if (this.repmodel.Menu === 'EM') {
      Wmodule_id = 1133;
      Codemast_id = 12;
      Omodule_id = 1133;
    } else if (this.repmodel.Menu === 'PA') {
      Wmodule_id = 1135;
      Codemast_id = 25;
      Omodule_id = 1135;
    } else if (this.repmodel.Menu === 'TB') {
      Wmodule_id = 1137;
      Codemast_id = 26;
      Omodule_id = 1137;
    } else if (this.repmodel.Menu === 'BG') {
      Wmodule_id = 1138;
      Codemast_id = 13;
      Omodule_id = 1138;
    } else if (this.repmodel.Menu === 'CR') {
      Wmodule_id = 1144;
      Codemast_id = 32;
      Omodule_id = 1144;
    }

    this.loading = true;
    this.adminservice.GetDocuRightsbyId(Wmodule_id, Codemast_id, Omodule_id)
      .subscribe(
        (data: any) => {    
          console.log(data);
          this.datalist = data;
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);

        }
      )
  }

  GetDocuList() {
    this.loading = true;
    var str = "DocuType";
    if (this.repmodel.Menu === 'SO') {
      str = "DocuType";
    } else if (this.repmodel.Menu === 'EN') {
      str = "Enq_docu";
    } else if (this.repmodel.Menu === 'QT') {
      str = "Quote_Prd";
    } else if (this.repmodel.Menu === 'EM') {
      str = "EMD";
    } else if (this.repmodel.Menu === 'PA') {
      str = "PROD_ATT";
    } else if (this.repmodel.Menu === 'TB') {
      str = "BLSHT";
    } else if (this.repmodel.Menu === 'BG') {
      str = "BG";
    } else if (this.repmodel.Menu === 'CR') {
      str = "Cr_Docu";
    }
    this.commonservice.CodeList(str)
      .subscribe(
        (data: any) => {
          this.Docu_List = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
        }
      );
  }
}
