import { Component, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { EmdService } from './emd.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EmdListComponent } from './emd-list/emd-list.component';
import { PartyHelpComponent } from '../../shared/party-help/party-help.component';
import { PartyListComponent } from '../../shared/party/party';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-emd',
  templateUrl: './emd.component.html',
  styleUrls: ['./emd.component.css']
})
export class EmdComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  DataForm: FormGroup;
  initialValues : any [];
  Bank_Name: any [];
  title: string;
  repmodel: any = {};
  Dept_nm: any = [];
  DirectorList : any = [];
  MarketingList: any = [];
  constructor(
    private emdService: EmdService,
    private notificationService: NotificationService,
    private _http: HttpClient,
    private hardfunc: HardFuncService,
    private fb: FormBuilder,
    private commonservice: CommonService,
    @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    @Optional() public dialogRef: MatDialogRef<EmdListComponent>,
    private dialog: MatDialog) {}

  ngOnInit() {
    this.DataForm = this.createFormGroup();
    this.initialValues = this.DataForm.value;
    this.loading = true;
    this.getBank_Name();
    this.GetData(); 
    this.getHandles();
    this.getMarketing();
  }
 ngOnDestroy(): void {
   console.clear();
 }
  //1. api/FinanceAPI/GetPartyDetail
  GetPartyDetail(){
   
    this.commonservice.GetPartyDetail(this.DataForm.get("Pmst_cod").value, this.DataForm.get("Firm").value )
    .subscribe(
        (data:any) => {
            console.log(data);
            var data1 = data[0];
            this.DataForm.get("Party_name").patchValue(data1.PARTY_NAME.trim());
            this.DataForm.get("Party_city").patchValue(data1.PARTY_CITY.trim());
            this.DataForm.get("Party_Stat").patchValue(data1.PARTY_STAT.trim());
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
        }
    )

  }

  //2. api/Sales/EMDsAPI/GetDatabyId
  GetData() {
    if (this.ReceivedData) {
      
      if (this.ReceivedData.id == -1) {
        this.title = "ADD";
      }
      else {
        this.emdService.GetDatabyId(this.ReceivedData.id)
          .subscribe(
            (data: any) => {
              console.log(data);
              this.DataForm.patchValue(data);
              this.title = "EDIT";
              return false;
            },
            err => {
              let errstr = this.hardfunc.getError(err);
              console.log(err);
              this.notificationService.warn(errstr);
            }
          )
      }
    }
    else {
      this.ReceivedData = {};
      this.ReceivedData.id = -1;
      this.title = "ADD";
    }
  }

  //3. api/Sales/EMDsAPI/SaveData
  SaveData() {
    this.loading = true;
    this.emdService.SaveData(this.DataForm.value)
      .subscribe(
        data => {
          this.loading = false;
          this.ResetData();
          this.notificationService.success(' Data Saved Successfully... ');
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  HelpDialog(){
    let data = {
      Firmx:this.DataForm.get("Firm").value,
      Cond:" a.Grp in  (''B'',''H'' ) "
    }
    const dialogRef = this.dialog.open(PartyHelpComponent, {
			width: '970px',
			height: '848px',
      data
		});
		dialogRef.afterClosed().subscribe(result => {
      this.DataForm.get('Pmst_cod').patchValue(result.id);
      this.DataForm.get('Party_name').patchValue(result.nm.trim());
      this.DataForm.get('Party_city').patchValue(result.Party_city.trim());
      this.DataForm.get('Party_Stat').patchValue(result.Party_Stat.trim());
			 console.log('The dialog was closed');
		});
  }
  onClear(){
    
  }
  ResetData() {
    if (this.ReceivedData.id > 0) {
      this.dialogRef.close();
    }
    else {
      this.DataForm.reset();
      this.DataForm.patchValue(this.initialValues);
      Object.keys(this.DataForm.controls).forEach(key => {
        this.DataForm.get(key).setErrors(null);
      });
    }
  }
  
  //4. api/Common/CodemastsAPI/GetCodeListbyCodenm
  getBank_Name() {
    this.loading = true;
    this.commonservice.CodeList('BANK_NAME')
      .subscribe(
        (data: any) => {
          this.Bank_Name = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
        }

      );
  }
  getHandles(){
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=D";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.DirectorList = data1;
      }, err => {
        console.log(err);
      });

  }
  getMarketing() {
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=M";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.MarketingList = data1;
      }, err => {
        console.log(err);
      });
  }

  createFormGroup() {
    return this.fb.group({
      Pk_id: new FormControl(0),
      Firm: new FormControl('NFF', Validators.required),
      Srno: new FormControl('', Validators.required),
      Reqdt: new FormControl(new Date(), Validators.required),
      Reqby: new FormControl('', Validators.required),
      Type: new FormControl('', Validators.required),
      Bidopendt: new FormControl(new Date()),
      Pmst_cod: new FormControl('', Validators.required),
      Party_name: new FormControl('', Validators.required),
      // Party_add: new FormControl('', Validators.required),
      Tender_no: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      Tender_items: new FormControl('', Validators.required),
      Offer_no: new FormControl('', Validators.maxLength(35)),
      Offer_dt: new FormControl(new Date(), Validators.required),
      Party_bank: new FormControl(''),
      Party_contact: new FormControl(''),
      Party_email: new FormControl(''),
      Party_mobile: new FormControl(''),
      Paid_amt: new FormControl('', Validators.required),
      Paid_bank: new FormControl('', Validators.required),
      Pmode: new FormControl('', Validators.maxLength(10)),
      Instru_no: new FormControl(''),
      Issue_dt: new FormControl(new Date()),
      Mature_dt: new FormControl(new Date()),
      Ordno: new FormControl(''),
      Oloc: new FormControl(''),
      Status: new FormControl('P'),
      Remarks : new FormControl(''),
      Party_city: new FormControl(''),
      Party_Stat: new FormControl(''),
     Dir_id: new FormControl(0),
     Mkt_id: new FormControl(0)
    });
  }

}
