import { Component, OnInit } from '@angular/core';
import { SalesService } from '../sales.service';
import { MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-sales-statistics',
  templateUrl: './sales-statistics.component.html',
  styleUrls: ['./sales-statistics.component.css']
})
export class SalesStatisticsComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  SubData: any = [];

  constructor(private SalesService: SalesService,
              private Dialog: MatDialog,
              private _snackBar: MatSnackBar) { }

  ngOnInit() {    
    this.GetData();
    this.loading = true;
  }
  GetData(){
    this.loading = true;
    this.SalesService.GetStatistics()
      .subscribe((data: any) => {
        console.log(data);        
        this.datalist = data;      
        this.datalist.map(d => {
          d.SubDatas = [];
        })
        this.loading = false;
      },err => {
        this.loading = false;
        if (err.error.error_description) {
          this.openSnackBar(err.error.error_description,"dismiss");
        }
        else {
          this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
        }
      });
 }
 GetDatabyItem(Prodgrp_id: number){
  this.loading = true;
  this.SalesService.GetsalesDatabyItem(Prodgrp_id)
    .subscribe((data: any) => {      
      this.datalist.map(d => {
        if(d.Prodgrp_id === Prodgrp_id){
          d.SubDatas = data;  
          d.Expand = !d.Expand;      
          } 
        });            
      this.loading = false;
    },err => {
      this.loading = false;
      if (err.error.error_description) {
        this.openSnackBar(err.error.error_description,"dismiss");
      }
      else {
        this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
      }
    });
}

openSnackBar(message: string, action: string) {
  this._snackBar.open(message, action, {
    duration: 2000,
  });
}

}
