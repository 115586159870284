import { Component, OnInit } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';

@Component({
  selector: 'app-enq',
  templateUrl: './enq.component.html',
  styleUrls: ['./enq.component.css']
})
export class EnqComponent implements OnInit {
 
  Permission:string ="NNNNNNNNNN";
  Rights={"Add":"N",
    "Edit":"N",
    "Delete":"N",
    "Dashboard":"N"
  }

  constructor(
    private hardfunc: HardFuncService
  ) {
    
   }

  ngOnInit() {
    this.Permission = this.hardfunc.GetLocalPermissionbyModule_id(1036);

    this.Rights.Add = this.Permission.substring(1,2);
    this.Rights.Edit = this.Permission.substring(2,3);
    this.Rights.Delete = this.Permission.substring(3,4);
    this.Rights.Dashboard = this.Permission.substring(4,5);

  }

}




/* ENQ Rights
0 -> View
1 -> Add
2 -> Edit
3 -> Delete
4 -> Dashboard 
5 -> Dashboard-Edit
6 -> Report
7 -> Report - Download


*/