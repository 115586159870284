import { M } from '@angular/cdk/keycodes';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { ManufacturingService } from '../../manufacturing.services';

@Component({
  selector: 'app-prdgrp-by-spara',
  templateUrl: './prdgrp-by-spara.component.html',
  styleUrls: ['./prdgrp-by-spara.component.css']
})
export class PrdgrpBySparaComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  isClicked: false;
  Prod_cd: string;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    private mnfservice: ManufacturingService,
    private hardfunc: HardFuncService,
    private notificationservice: NotificationService) { }

  ngOnInit() {
    this.loading = true;
    this.ParabyGroup_id();
  }
  DisplayCol() {
    this.displayedColumns = ['Product_id', 'Short_nm', 'Prod_code', 'Prod_code1', 'Prod_code2', 'Prod_code3', 'Action'];
  }

  ParabyGroup_id() {
    let grp_id = this.ReceivedData.Spara_id;
    let filterdata = [];
    this.mnfservice.ProductbySpara_id(grp_id)
      .subscribe((data: any) => {
        this.datalist = data;
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DisplayCol();
      },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationservice.warn(errstr);
        }
      );
  }
  SetValue(data) {
    console.log(data);
    this.loading = true;

    if (data.Chk === true) {
      data.Prod_code2 = "0" + data.Prod_code2;
    }
    this.mnfservice.FixStock1(data.Product_id, data.Prod_code1, data.Prod_code2, data.Prod_code3)
      .subscribe((data: any) => {
        this.loading = false;
        this.notificationservice.success("Data Saved Sucessfully...");
      },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationservice.warn(errstr);
        }
      );
  }
  FixStock(product_id: number, Prod_code1: string, Prod_code2: string, Prod_code3: string) {
    this.loading = true;


    this.mnfservice.FixStock1(product_id, Prod_code1, Prod_code2, Prod_code3)
      .subscribe((data: any) => {
        this.loading = false;
        this.notificationservice.success("Data Saved Sucessfully...");
      },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationservice.warn(errstr);
        }
      );
  }
  SparaNewCode() {

    this.loading = true;
    this.mnfservice.SparaCode(this.Prod_cd, this.ReceivedData.Spara_id)
      .subscribe((data: any) => {
        this.loading = false;
        this.ParabyGroup_id();
        this.notificationservice.success("Data Saved Sucessfully...");
      },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationservice.warn(errstr);
        }
      );
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
