import { Component, OnInit, ViewChild, Optional, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-product-help',
  templateUrl: './product-help.component.html',
  styleUrls: ['./product-help.component.css']
})
export class ProductHelpComponent implements OnInit {

  loading: boolean = false;
  DataForm: FormGroup;
  repmodel: any = {};
  HelpList: any = [];
  filteredOptions: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

   msgFromChild1 = [];

  constructor(private fb: FormBuilder,
              private hardfunc: HardFuncService,
              private _http: HttpClient,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,) { }

  ngOnInit() {
    this.DataForm = this.HelpFormGroup();
    this.loading = true;
    this.GetProductById();
  }
  msgToParent(Product_id: number, Product_nm: string) {
    this.msgFromChild1.push(Product_id);
    this.msgFromChild1.push(Product_nm);
    this.msgFromChild1.push(this.ReceivedData)
  }
  SaveHelp() {
    
  }
  DisplayCol() {	
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['Product_id','Short_nm'];
	}
  GetProductById() {
    this.HelpList = [];
    let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/GetProductList";
    this._http.post(url, this.repmodel)
      .subscribe((data1: any) => {
        this.HelpList = data1;
        this.dataSource = new MatTableDataSource(<any>this.HelpList);
        this.dataSource.paginator = this.paginator;
        this.DisplayCol();
      }, err => {
        console.log(err);
      });
  }
  
  HelpFormGroup() {
    return this.fb.group({
      Product_id: new FormControl(),
      Product_nm: new FormControl(''),
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
