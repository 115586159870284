import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatTableDataSource, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';

@Component({
  selector: 'app-jw-history',
  templateUrl: './jw-history.component.html',
  styleUrls: ['./jw-history.component.css']
})

export class JwHistoryComponent implements OnInit {
  
  loading: boolean = false;
  repmodel: any = {};
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  Balance: number;
  Totbal: number = 0;
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private _http: HttpClient,
              private hardfunc: HardFuncService,
              private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.loading = true;
    this.GetData();
  }
  DisplayCol(){
    this.displayedColumns = ['Outwardno','Tdate','Party_name','Receipt','Issue','Balance'];
  }
  GetData(){
    this.loading = true;
    let url = this.hardfunc.BaseAPIUrl + "api/InventoryDashboard/Jw_Ledger?id=" + this.ReceivedData.id + "&Pmst_cod=" + this.ReceivedData.Pmst_cod;
    this._http.post(url, null, (this.repmodel))
      .subscribe((data: any)=> {        
        this.datalist = JSON.parse(data);
        console.log(this.datalist);
        this.Balance = 0;
        var je = 0;
        var ie = 0;        
        this.datalist.map(w => {
          if(w.Vtype === "JE"){
            je = 0;
            je = w.Qnt;
          }
          if(w.Vtype === "IE"){
            ie = 0;
            ie = w.Qnt;
          }
          console.log(je);
          console.log(ie);
          w.Balance = je - ie;  

          console.log(this.Balance);
        });
        
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DisplayCol();
        this.loading = false;
      },
      err => {
        this.loading = false;
        if (err.error.error_description) {
          this.openSnackBar(err.error.error_description,"dismiss");
        }
        else {
          this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
        }
      }
      );
  }
  openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
		  duration: 2000,
		});
	  }
}
