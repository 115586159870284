import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ModalData } from '../../general/codemast/codemast.component';
import { UsrregComponent } from '../usrreg/usrreg.component';
import { AdminService } from '../admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { AppLoaderService } from 'src/app/Services/app-loader/app-loader.service';
import { ResetPwdComponent } from '../reset-pwd/reset-pwd.component';
import { MatTableDataSource } from '@angular/material/table';
import { UserModuleComponent } from './user-module/user-module.component';
import { UserRoleComponent } from './user-role/user-role.component';
import { DomSanitizer } from '@angular/platform-browser';

export interface Modaldata{
  id:number
}

@Component({
  selector: 'app-usrlst',
  templateUrl: './usrlst.component.html',
  styleUrls: ['./usrlst.component.css']
})
export class UsrlstComponent implements OnInit, OnDestroy {
  msgs: any[] = [];
  datalist: any = [];
  loading:boolean = false;
  repmodel: any = {};
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  filtertext:string = "";
  thumbnail: any;
  
  constructor( 
    public dialog: MatDialog,
    private _http:HttpClient, 
    private service: CommonService,
    private hardfunc : HardFuncService,
    private notificationservice : NotificationService,
    private apploaderservice : AppLoaderService,
    private adminservice : AdminService,
    private sanitizer: DomSanitizer,
    private _snackBar: MatSnackBar)
    { }

  ngOnInit() {
    //this.getData();
  }
ngOnDestroy(): void {
  console.clear();
}
  openDialog(UserName:string ): void {
    const dialogRef = this.dialog.open(UsrregComponent, {
      width: '700px',
      height: '800px',
      data: {UserName:UserName}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }
  UserAccess(UserName: string,element : any) {
      this.adminservice.getUserbyUsername(UserName)
        .subscribe(
          (data1:any) => {
            if (!data1.OutAccess){
              data1.OutAccess = "X";
            }else if(data1.OutAccess === 'Y'){
              data1.OutAccess = "N";
            }else if(data1.OutAccess === 'N'){
              data1.OutAccess = "X";
            }else if(data1.OutAccess === 'X'){
              data1.OutAccess = 'Y';
            }

            let url = this.hardfunc.BaseAPIUrl + "api/UsersAPI/SaveUser";
            var headers = new HttpHeaders({ 'content-Type': 'application/json' });
            
            this._http.post(url, JSON.stringify(data1), { headers: headers })
              .subscribe(data => {
                this.loading = false;
                element.OutAccess = data1.OutAccess;
                this.notificationservice.success(' Access changed  Successfully... ');
                this.msgs.push({ severity: 'info', summary: 'Success', detail: "Data saved successfully" });
              },
                err => {
                  this.loading = false;
                  console.log(err);
                  this.notificationservice.warn('Error in saving...');
                  this.msgs.push({ severity: 'error', summary: 'Error', detail: "Error in data getting" });
                  this.openSnackBar("Error in saving... , please check server connectivity...  ", "dismiss");
                });
          },
          err => {
            this.notificationservice.warn('Error in getting data...');
            this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          }
        );
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  openModuleUserDialog(userid:string,username:string) {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    let w = '90%';
    let h = '80%';
    if (isSm )
    {
       w = '100%';
       h = '100%';
    }
    const dialogRef = this.dialog.open(UserModuleComponent, {
      width: w,
      maxWidth:w,
      height: h,
      data: { username: username }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openUserRoleDialog(userid:string,username:string) {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    let w = '90%';
    let h = '80%';
    if (isSm )
    {
       w = '100%';
       h = '100%';
    }
    const dialogRef = this.dialog.open(UserRoleComponent, {
      width: w,
      maxWidth:w,
      height: h,
      data: { username: username }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  
  openResetPwd(UserName:string ): void {
    const dialogRef = this.dialog.open(ResetPwdComponent, {
      width: '700px',
      data: {UserName:UserName}
        
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }

  getData() {
    this.apploaderservice.open();
    this.datalist = [];
    // this.loading = true;
    this.adminservice.getAllUsers()
    .subscribe(
      (data:any) => {   
        console.log(data);
        this.datalist = data;
        let objectURL = 'data:image/jpeg;base64,' + data.image;
        this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.loading = false;
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DisplayCol();
        this.apploaderservice.close();
      },
      err => {
        this.apploaderservice.close();  
        console.log(err);
        this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
        //  this.msgs.push({ severity: 'error', summary: 'Error', detail: `Error in getting data , please check server connectivity...  ` });
      });
  }
  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['Username','Email','PhoneNumber','Action'];
  }
  
  DeleteUsername(username: string): void {
    this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
    .afterClosed().subscribe(res =>{
      if(res){
        this.loading = true;
        const dialogRef = this.adminservice.deleteUserbyUsername(username)
        .subscribe(
          data => {
            this.notificationservice.warn("Record Deleted Succefully...");
            this.getData();
          },
          err => {
            console.log(err);
            this.notificationservice.warn("Error in deleting data ...");
          }
        )
        
        // this.notificationService('Deleted Successfully');
      }
    });
  }
  
}

