import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators, FormControlName } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { PartyHelpComponent } from '../../shared/party-help/party-help.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar, MatTableDataSource } from '@angular/material';
import { ManufacturingService } from '../../manufacturing/manufacturing.services';
import { HttpClient } from '@angular/common/http';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EnqHelpComponent } from '../enq-help/enq-help.component';
import { TproductComponent } from '../../shared/tproduct/tproduct.component';
import { SalesService } from '../sales.service';
import { TempmastComponent } from '../../shared/tempmast/tempmast.component';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { CrComponent } from '../cr/cr.component';
import { PendordAttachComponent } from '../pendord/pendord-attach/pendord-attach.component';
import { RcPartyHelpComponent } from '../../shared/rc-party-help/rc-party-help.component';

@Component({
  selector: 'app-cr-entry',
  templateUrl: './cr-entry.component.html',
  styleUrls: ['./cr-entry.component.css']
})
export class CrEntryComponent implements OnInit {

  loading: boolean = false;
  DataForm: FormGroup;
  filteredOptions: any = [];
  datalist: any = [];
  repmodel: any = {};
  StateList: any = [];
  DirectorList: any = [];
  MarketingList: any = [];
  FieldMKtList: any = [];
  TotalAmt: number;
  TaxTotal: number;
  TotalPOvalue: number;
  public editor = ClassicEditor;
  WithoutTax: number;
  WithTaxTot: number;
  docu: string;
  idx: any = [1, 2, 3, 4];
  docuAtt: any = [];
  datasheet: string;
  drawing: string;
  TgroupList: any = [];
  title: string;
  initialValues: any[];
  TermsCons: any = [];
  AddiTerms: any = [];
  
  constructor(private fb: FormBuilder,
    private hardfunc: HardFuncService,
    private dialog: MatDialog,
    private MnfSerivce: ManufacturingService,
    private _http: HttpClient,
    @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    private SalesService: SalesService,
    private _snackBar: MatSnackBar,
    private NotificationService: NotificationService,
    @Optional() public dialogRef: MatDialogRef<CrComponent>,
    private commonservice: CommonService) { }

  ngOnInit() {    
    this.DataForm = this.createFormGroup();
    this.initialValues = this.DataForm.value;    
    this.loading = true;
    
    this.GetTermsCond();
    this.GetTgroup();
    this.getMarketing();
    this.GetFieldMkt();
    this.getHandles();
    this.GetDatas();
  }

  GetQuote() {
    console.log(this.DataForm.get("Qot_no").value);
    if (this.DataForm.get("Qot_no").value === "") {
      this.NotificationService.warn("Please Enter Quatation No...!!");
      this.loading = false;
      return;
    } else {
      this.loading = true;
      this.SalesService.GetQuotation(this.DataForm.get("Qot_no").value, this.DataForm.get("Qot_ver").value)
        .subscribe((data: any) => {
          // data.SelectPDF = "S";
          this.DataForm.controls['Firm'].patchValue(data.Firm);
          this.DataForm.controls['Party_name'].patchValue(data.Party_name);
          this.DataForm.controls['Party_addr'].patchValue(data.Party_add);
          this.DataForm.controls['Party_city'].patchValue(data.Party_city);
          this.DataForm.controls['Party_state'].patchValue(data.Party_state);    
          this.DataForm.controls['Price_Basic'].patchValue(data.T_price);    
          this.DataForm.controls['Payment_Term'].patchValue(data.T_paym);    
          this.DataForm.controls['Delivery_Period'].patchValue(data.T_deli);    
          this.DataForm.controls['GST'].patchValue(data.T_gst);    
          this.DataForm.controls['Party_ref'].patchValue(data.Party_ref);              

          // let ContName: string;
          // let ContEmail: string;
          // let Phone: string;
          // let Design: string;
          // data.QuoteConts.map(d => {
          //   ContName = d.Cname;
          //   ContEmail = d.Email;
          //   Phone = d.Phone;
          //   Design = d.Desgauthor;
          // });
          // this.DataForm.controls.Con_person.patchValue(ContName);
          // this.DataForm.controls.Con_Email.patchValue(ContEmail);
          // this.DataForm.controls.Con_Phone.patchValue(Phone);
          // this.DataForm.controls.Con_Desg.patchValue(Design);

          console.log(this.DataForm.value);
          const formarr = new FormArray([]);
          data.SubDatas.map(m => {
            console.log(m);
            formarr.push(
              this.fb.group({
                Item: m.Item,
                Qnty: m.Qnt,
                Rate: m.Basic,
                Gst_per: m.Gst_per,
                Netrate: m.Netrate,
                Product_id: 0,
                Unit: "NOS.",
                Disc_per: 0,
                Prodgrp_id: m.Prodgrp_id,
                Tproduct_id: 11, //m.Tproduct_id 
              })
            );
          });
          this.DataForm.setControl("SubDatas", formarr);

          const formarr1 = new FormArray([]);
          data.QuoteConts.map(m => {
            formarr1.push(
              this.fb.group({
                Pk_id: m.Pk_id,
                Quote_id: m.Quote_id,                
                Person: m.Cname,
                Phone: m.Phone,
                Desg: m.Desgauthor,
                Email: m.Email,
              })
            );
          });
          this.DataForm.setControl("QuoteConts", formarr1);
          this.loading = false;
          return false;
        },
          err => {
            this.loading = false;
            let errstr = this.hardfunc.getError(err);
            this.NotificationService.warn(errstr);
          }
        )
    }
  }


  GetDatas() {
    this.loading = true;
    // console.log(this.ReceivedData.Status);
    if (this.ReceivedData) {
      if (this.ReceivedData.id == -1) {
        this.title = "CR Entry";
      }
      else {
        this.loading = true;        
        this.SalesService.GetCRbyId(this.ReceivedData.id)
          .subscribe((data: any) => {                  
            this.title = "CR Editing";           
            data.Firm = "NFF";
            if(this.ReceivedData.Status === 'R'){
              data.EditRevise = "R"; 
            }else{
              data.EditRevise = 'E';
            }
            this.DataForm.patchValue(data);
            const formarr = new FormArray([]);
            data.SubDatas.map(m => {              
              formarr.push(
                this.fb.group({                  
                  Item: m.Item,
                  Qnty: m.Qnty,
                  Rate: m.Rate,
                  Gst_per: m.Gst_per,
                  Disc_per: m.Disc_per,
                  Netrate: m.Netrate,
                  Conrw_id: m.Conrw_id,
                  Conrw_item_id: m.Conrw_item_id,
                  Product_id: m.Product_id,
                  Prodgrp_id: m.Prodgrp_id,
                  Tproduct_id: m.Tproduct_id,
                  DocuAtt: m.DocuAtt,
                  Hsncode: "",
                  DocuName: "",
                  Noted: "",
                  Unit: "NOS."
                })
              );
            });
            this.DataForm.setControl("SubDatas", formarr);

            // const formarr2 = new FormArray([]);
            // data.ConTerms.map(m => {
            //   formarr2.push(
            //     this.fb.group({
            //       Conrw_terms_id: m.Conrw_terms_id,
            //       Conrw_id: m.Conrw_id,
            //       Srno: m.Srno,
            //       Cond_id: m.Cond_id,
            //       Cond_descr: m.Cond_descr,
                  
            //     })
            //   );
            // });
            // this.DataForm.setControl("ConTerms", formarr2);

            const formarr1 = new FormArray([]);
            data.QuoteConts.map(m => {
              formarr1.push(
                this.fb.group({
                  Conrw_cont_id: m.Conrw_cont_id,
                  Conrw_id: m.Conrw_id,
                  Person: m.Person,
                  Email: m.Email,
                  Phone: m.Phone,
                  Desg: m.Desgauthor
                })
              );
            });
            this.DataForm.setControl("QuoteConts", formarr1);
            this.title = "CR Editing";
            this.loading = false;
            return false;

          },
            err => {
              this.loading = false;
              let errstr = this.hardfunc.getError(err);
              this.NotificationService.warn(errstr);
            }
          )
      }
    }
    else {
      this.ReceivedData = {};
      this.ReceivedData.id = -1;
      this.title = "CR Entry";
    }
  }

  SaveData() {

    this.loading = true;           

    this.SalesService.SaveConRw(this.DataForm.value)
      .subscribe((data: any) => {       
        console.log(data.Qot_no);
        if (this.ReceivedData.id == -1) {  
        const dialogRef = this.dialog.open(PendordAttachComponent, {
          width: '925px',
          height: '600px',
          data: { Ordno: data.Qot_no, Oloc: 1, id: data.Conrw_id, Vtype: 'CR', Wmodule_id: 1144 }
        });
          
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        });
      }
        let headers = this.hardfunc.PostHeaders();        
        
        let url = this.hardfunc.BaseAPIUrl + "api/Sales/conrwsAPI/CreateConrwPdf";
        this._http.post(url, data, {headers: headers, responseType: 'blob' as 'json'})
          .subscribe((data1: any) => {     
              console.log(data1);
                        
              const data = window.URL.createObjectURL(data1);

              var link = document.createElement('a');
              link.href = data;
              link.target = "_blank";
              var CurrDate = new Date();
              
              link.download = "CR.pdf";
              link.click();
              setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
              }, 100);                                                        
            });
        this.loading = false;
        this.NotificationService.success("Data Saved Sucessfully...");
        // this.ResetData();        
      },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.NotificationService.warn(errstr);
          this.loading = false;
        }
      );
  }

  EnqHelp() {
    const dialogRef = this.dialog.open(EnqHelpComponent, {
      width: '900px',
      //data: {T_price: T_price}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      // this.DataForm.get('Qot_no').patchValue(result.Srno);
      // this.DataForm.get('Pmst_cod').patchValue(result.Pmst_cod);
      this.DataForm.get('Party_name').patchValue(result.Party_name.trim());
      this.DataForm.get('Party_addr').patchValue(result.Party_addr);
      this.DataForm.get('Party_city').patchValue(result.Party_city.trim());
      this.DataForm.get('Party_state').patchValue(result.Party_state.trim());

      //Contact Detail 
      const formarr2 = new FormArray([]);
      formarr2.push(
        this.fb.group({
          Person: result.Poc,
          Email: result.Email_id,
          Phone: result.Cell,
          Desg: result.Desgauthor
        })
      );
      this.DataForm.setControl("QuoteConts", formarr2);

      //Item
      const formarr3 = new FormArray([]);
      result.Subdatas.map(m => {
        formarr3.push(
          this.fb.group({
            Prodgrp_id: 0,
            Item: m.Prodname,
            Qnty: m.Qnt,
            Rate: m.Basic,
            Hsncode: "",
            DocuName: "",
            Gst_per: 0,
            // DocuAtt: "",
            Netrate: 0
          })
        );
      });
      this.DataForm.setControl("SubDatas", formarr3);

    });
  }
  // 6. api/Common/CodemastsAPI/GetCodeListbyCodenm --API For --Order Status
  GetTermsCond() {
    this.loading = true;
    this.commonservice.CodeList('TermCond')
      .subscribe(
        (data: any) => {
          this.TermsCons = JSON.parse(data);          
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn(errstr);
        }
      );
  }
  
  AddCons() {
    (this.DataForm.controls['QuoteConts'] as FormArray).push(this.ContactDetailsForm());
  }
  addItems() {
    (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubdata());
  }
  AddTerms() {
    (this.DataForm.controls['ConTerms'] as FormArray).push(this.ConrwTermsForm());    
  }
  DeleteRow(i: number) {
    (this.DataForm.controls['QuoteConts'] as FormArray).removeAt(i);
  }
  DeleteTerm(i: number) {
    (this.DataForm.controls['ConTerms'] as FormArray).removeAt(i);
  }
  DeleteItem(i: number) {    
    if(this.ReceivedData.id > 1)
    {
      //(this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);
      let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
      let formgroup = (formarray.at(i) as FormGroup);
      formgroup.get('Noted').patchValue('D');           
    }
    else{
      (this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);      
    }
  }
  ContDetremove(i: number) {
    (this.DataForm.controls['QuoteConts'] as FormArray).removeAt(i);
  }

  GetTgroupbyId(Tgroup_id: number, i: number) {
    this.loading = true;

    this.SalesService.GetTgroupbyId(Tgroup_id)
      .subscribe((data: any) => {
        let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
        let formgroup = (formarray.at(i) as FormGroup);
        formgroup.get('Gst_per').patchValue(data.Gst_per);

        // formgroup.get('Hsncode').patchValue(data.Hsn_code);

        this.loading = false;
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }
  GetTgroup() {
    this.loading = true;
    this.SalesService.GetTgroup()
      .subscribe(data => {
        this.TgroupList = data;
        this.loading = false;
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }
  TempmastDialog(Vtype: string, Fldname: string) {
    let width = '1000px';
    let height = '500px';
    if (Vtype === 'ET') {
      width = '1000px';
      height = '300px';
    }
    const dialogRef = this.dialog.open(TempmastComponent, {
      width: width,
      height: height,
      data: { Vtype: Vtype, Fldname: Fldname }
    });
    dialogRef.afterClosed().subscribe(result => {            
      if (result.Fldname.trim() === 'T_price') {
        this.DataForm.controls['Price_Basic'].patchValue(result.Tempstr);    
      }
      if (result.Fldname.trim() === 'T_payment') {
        this.DataForm.controls['Payment_Term'].patchValue(result.Tempstr);    
      }      
    });
  }
  HelpDialog() {
    let data = {
      Firmx: this.hardfunc.Firmx,
      Cond: " a.Grp in  (''B'',''H'' ) "
    }
    const dialogRef = this.dialog.open(PartyHelpComponent, {
      width: '970px',
      height: '848px',
      data
    });
    dialogRef.afterClosed().subscribe(result => {      
      this.DataForm.get('Party_name').patchValue(result.id + '-' + result.nm.trim());
      this.DataForm.get('Party_addr').patchValue(result.Party_adr1.trim() + '-' + result.Party_adr2.trim() + '-' + result.Party_adr3.trim()
        + '-' + result.Party_city.trim() + '-' + result.Party_Stat.trim());
      this.DataForm.get('Party_city').patchValue(result.Party_city.trim());
      this.DataForm.get('Party_state').patchValue(result.Party_Stat.trim());
    });
  }
  
  HelpDialog2() {
    let data = {
      Firmx: this.hardfunc.Firmx,
      Cond: " a.Grp in  (''B'',''H'' ) "
    }
    const dialogRef = this.dialog.open(PartyHelpComponent, {
      width: '970px',
      height: '848px',
      data
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.DataForm.get('Pmst_cod').patchValue(result.id);
      console.log(result);
      this.DataForm.get('Cons_code').patchValue(result.id);
      this.DataForm.get('Cons_name').patchValue(result.id + '-' + result.nm.trim());
      this.DataForm.get('Cons_addr').patchValue(result.Party_adr1.trim() + '-' + result.Party_adr2.trim() + '-' + result.Party_adr3.trim()
        + '-' + result.Party_city.trim() + '-' + result.Party_Stat.trim());
      this.DataForm.get('Cons_city').patchValue(result.Party_city.trim());
      this.DataForm.get('Cons_state').patchValue(result.Party_Stat.trim());
    });
  }

  RcDialog() {
    let data = {
      Firmx: this.hardfunc.Firmx,
      Cond: " a.Grp in  (''B'',''H'' ) "
    }
    const dialogRef = this.dialog.open(RcPartyHelpComponent, {
      width: '1400px',
      height: '848px',
      data
    });
    dialogRef.afterClosed().subscribe(result => {           
      this.commonservice.GetPartyDetail(result.Pmst_code, this.hardfunc.Firmx)
    .subscribe(
        (data:any) => {            
            var data1 = data[0];                        
            this.DataForm.get("Party_name").patchValue(data1.PARTY_NAME.trim());
            this.DataForm.get("Party_addr").patchValue(data1.PARTY_ADR1.trim()+ " " +data1.PARTY_ADR2+ " " +data1.PARTY_ADR3);
            this.DataForm.get("Party_city").patchValue(data1.PARTY_CITY.trim());
            this.DataForm.get("Party_state").patchValue(data1.PARTY_STAT.trim());
        },
        err => {
            
        }
    )
    this.DataForm.get('Pmst_code').patchValue(result.Pmst_code);
      const formarr = new FormArray([]);
      result.SubDatas.map(m => {    
        console.log(m);
        if(m.chk === true)    
        {
          formarr.push(
            this.fb.group({
              Item: m.Short_nm + "<br>" + "<b>" + "HSN Code -" + m.Hcode + "</b>",
              Qnty: m.Qnt,
              Rate: m.Rate,
              Gst_per: m.Vat || m.Atax || m.Excise,
              Netrate: 0,
              Product_id: 0,
              Unit: "NOS.",
              Disc_per: 0,
              Prodgrp_id: "",
              Tproduct_id: 0, //m.Tproduct_id 
            })
          );
        }
      });
      this.DataForm.setControl("SubDatas", formarr);
      
      //Testing purpose <-------------==============------------->
      // let aFormArray: FormArray = this.DataForm.controls['SubDatas'] as FormArray;
      // for (let c of aFormArray.controls) {      
      //   let d: FormGroup = c as FormGroup;
              
      //   let QntyVal = Number(d.controls["Qnt"].value) as number;
      //   let UnitPriceVal = Number(d.controls["Basic"].value) as number;
      //   let IgstVal = Number(d.controls["Gst_per"].value) as number;      
 
      //   this.TotalAmt += QntyVal * UnitPriceVal || 0;
      //   this.TaxTotal += QntyVal * UnitPriceVal * IgstVal / 100 || 0;    
      //   d.controls['Netrate'].patchValue(this.TotalAmt + this.TaxTotal);           
      // } 

      // this.DataForm.get('Party_name').patchValue(result.id + '-' + result.nm.trim());
      // this.DataForm.get('Party_addr').patchValue(result.Party_adr1.trim() + '-' + result.Party_adr2.trim() + '-' + result.Party_adr3.trim()
      //   + '-' + result.Party_city.trim() + '-' + result.Party_Stat.trim());
      // this.DataForm.get('Party_city').patchValue(result.Party_city.trim());
      // this.DataForm.get('Party_state').patchValue(result.Party_Stat.trim());
    });
  }

  DoTotal() {
    let aFormArray: FormArray = this.DataForm.controls['SubDatas'] as FormArray;
    this.WithoutTax = 0;
    this.WithTaxTot = 0;
    this.TotalAmt = 0;
    this.TaxTotal = 0;

    for (let c of aFormArray.controls) {
      let d: FormGroup = c as FormGroup;

      let QntyVal = Number(d.controls["Qnt"].value) as number;
      let UnitPriceVal = Number(d.controls["Basic"].value) as number;
      let IgstVal = Number(d.controls["Gst_per"].value) as number;

      this.TotalAmt += QntyVal * UnitPriceVal || 0;
      this.TaxTotal += QntyVal * UnitPriceVal * IgstVal / 100 || 0;
      d.controls['Netrate'].patchValue(this.TotalAmt + this.TaxTotal);
    }
  }
  TproductDialog(grp_id: number, grp_nm: string, i: number) {
    const dialogRef = this.dialog.open(TproductComponent, {
      width: '1200px',
      height: '700px',
      data: { Grp_id: grp_id, Grp_nm: grp_nm }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
      let formgroup = (formarray.at(i) as FormGroup);
      formgroup.get('Item').patchValue(result.Product_nm);
      formgroup.get('Tproduct_id').patchValue(result.Tproduct_id);

      this.docu = "";
      this.datasheet = "";
      this.drawing = "";
      result.Doculist.map(e => {
        if (e.Checked === true) {
          this.docu += e.Docu_id + ",";
          if (e.Docu_type === 142) {
            this.datasheet = "Data Sheet";
          }
          if (e.Docu_type === 141) {
            this.drawing = "UL/FM Certificate";
          }
        }

      });
      console.log(this.datasheet + this.drawing);

      // formgroup.get('DocuAtt').patchValue(this.docu);
      // formgroup.get('DocuName').patchValue(this.datasheet + "," + this.drawing);

      //formgroup.get('Document').patchValue(this.datasheet + " , " + this.drawing);

      console.log('The dialog was closed');
    });
  }
  GetRcData() {
    let data = {
      Firmx: this.hardfunc.Firmx,
      Cond: " a.Grp in  (''B'',''H'' ) "
    }
    const dialogRef = this.dialog.open(RcPartyHelpComponent, {
      width: '970px',
      height: '848px',
      data: this.DataForm.get('Pmst_code').value,
    });
    dialogRef.afterClosed().subscribe(result => {     
      console.log(result);
    //   this.commonservice.GetPartyDetail(result.Pmst_code, this.hardfunc.Firmx)
    // .subscribe(
    //     (data:any) => {
    //         var data1 = data[0];                        
    //         this.DataForm.get("Party_name").patchValue(data1.PARTY_NAME.trim());
    //         this.DataForm.get("Party_addr").patchValue(data1.PARTY_ADR1.trim()+ " " +data1.PARTY_ADR2+ " " +data1.PARTY_ADR3);
    //         this.DataForm.get("Party_city").patchValue(data1.PARTY_CITY.trim());
    //         this.DataForm.get("Party_state").patchValue(data1.PARTY_STAT.trim());
    //     },
    //     err => {
            
    //     }
    // )
  });
  }
  // 8. api/HandlesAPI/GetData -- API For -- Get Marketing Type=M
  getMarketing() {
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=M";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.MarketingList = data1;
      }, err => {
        console.log(err);
      });
  }
  GetFieldMkt() {
    let url = this.hardfunc.BaseAPIUrl + "api/UsersAPI/getAllUsersSelectedMkt?Mkt=Y";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.FieldMKtList = data1;
      }, err => {
        console.log(err);
      });
  }
  // 8. api/HandlesAPI/GetData -- API For -- Get Directore Type=D
  getHandles() {
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=D";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.DirectorList = data1;
      }, err => {
        console.log(err);
      });
  }

  ResetData() {
    if (this.ReceivedData.id > 0) {
      this.dialogRef.close();
    }
    else {
      this.DataForm.reset();
      this.DataForm.patchValue(this.initialValues);
      Object.keys(this.DataForm.controls).forEach(key => {
        this.DataForm.get(key).setErrors(null);
      });
    }
  }
  createFormGroup() {
    return this.fb.group({
      Conrw_id: new FormControl(-1),
      Firm: new FormControl('',Validators.required),
      Cr_no: new FormControl(Validators.required),
      Cr_ver: new FormControl(0),
      Cr_dt: new FormControl(new Date()),
      Fld_id: new FormControl(Validators.required),
      Mkt_id: new FormControl(Validators.required),
      Dir_id: new FormControl(Validators.required),
      Pmst_code: new FormControl(''),
      Party_name: new FormControl('',[Validators.required,Validators.maxLength(50)]),
      Party_addr: new FormControl('',[Validators.required,Validators.maxLength(500)]),
      Party_city: new FormControl('',[Validators.required,Validators.maxLength(50)]),
      Party_state: new FormControl('',[Validators.required,Validators.maxLength(50)]),
      Party_gst: new FormControl(''),
      Cons_code: new FormControl(''),
      Cons_name: new FormControl(''),
      Cons_addr: new FormControl(''),
      Cons_city: new FormControl(''),
      Cons_state: new FormControl(''),
      Cons_gst: new FormControl(''),
      Party_po_no: new FormControl(''),
      Party_po_dt: new FormControl(new Date()),
      Party_ref: new FormControl(''),
      FinConDet: new FormControl(''),
      Quote_id: new FormControl(-1),
      Qot_no: new FormControl(""),
      Qot_ver: new FormControl(0),

      Price_Basic: new FormControl('Ex-works basis'),
      Payment_Term: new FormControl('Advance along with the Purchase Order'),
      Delivery_Period: new FormControl(''), //If input any value disp. on paragragh
      Remarks: new FormControl(''),      
      Inspection: new FormControl('Not required'),
      GST: new FormControl('Extra as above'),
      Docu: new FormControl('Not required'),
      PBG: new FormControl('Not required'),
      SD: new FormControl('Not required'),
      Sample_Approval: new FormControl('Not required'),
      LD_Clause: new FormControl('Not required'),
      Enclosures: new FormControl(''),
      Rpfpc: new FormControl(''),      
      Status: new FormControl(''),
      EditRevise: new FormControl(''), // Only For Revise
      SubDatas: this.fb.array([this.CreateSubdata()]),
      QuoteConts: this.fb.array([this.ContactDetailsForm()]),      
    })
  }
  CreateSubdata(): FormGroup {
    return this.fb.group({
      Conrw_item_id: new FormControl(-1),
      Conrw_id: new FormControl(),
      Prodgrp_id: new FormControl(-1),      
      Product_id: (123),
      Tproduct_id: new FormControl(),
      Item: (''),
      Qnty: new FormControl('', Validators.required),
      Rate: new FormControl('', Validators.required),
      Unit: new FormControl(''),
      Gst_per: new FormControl(0, Validators.required),
      Disc_per: new FormControl(0, Validators.required),
      Netrate: new FormControl(0),
      Noted: new FormControl(''),
    });
  }
  ContactDetailsForm(): FormGroup {
    return this.fb.group({
      Conrw_cont_id: new FormControl(-1),
      Conrw_id: new FormControl(),
      Person: new FormControl(''),
      Phone: new FormControl(),
      Desg: new FormControl(''),
      Email: new FormControl('', [Validators.pattern(
        /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})(; ?\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3}))*$/)]),
    });
  }
  ConrwTermsForm(): FormGroup {
    return this.fb.group({
      Conrw_terms_id: new FormControl(-1),
      Conrw_id: new FormControl(-1),
      Srno: new FormControl(1),
      Cond_id: new FormControl(501),
      Cond_descr: new FormControl(''),      
      Price_Basic: new FormControl('Ex-works basis'),
      Payment_Term: new FormControl('Advance along with the Purchase Order'),
      Delivery_Period: new FormControl(''),      
      Remarks: new FormControl(''),      
      Inspection: new FormControl(''),
      GST: new FormControl(''),
      Docu: new FormControl(''),
      PBG: new FormControl(''),
      SD: new FormControl(''),
      Sample_Approval: new FormControl(''),
      LD_Clause: new FormControl(''),
    });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
