import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatTableDataSource, MatSnackBar } from '@angular/material';
import { ManufacturingService } from '../../manufacturing.services';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import * as moment from 'moment';

@Component({
  selector: 'app-jobwork-detail',
  templateUrl: './jobwork-detail.component.html',
  styleUrls: ['./jobwork-detail.component.css']
})
export class JobworkDetailComponent implements OnInit {

  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  Srno: number = 0;
  party_nm: string;
  outward_no: string;
  outward_dt: string;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private manufacturingservice: ManufacturingService,
              private hardfunc: HardFuncService,
              private _SnackBar: MatSnackBar) { }

  ngOnInit() {
    this.GetData();
  }
  DisplayCol(){
    this.displayedColumns = ['Sr','ITEM_CODE','ITEM_NM','QNT','UNIT','Orig_gpno','Orig_gpdt'];
  }
  GetData(){
    this.manufacturingservice.GatepassDetail(this.ReceivedData.TranType, this.ReceivedData.Ref_id)
      .subscribe(
        (data: any) => {
        this.datalist = data;
        console.log(this.datalist);
        this.datalist.map(w => {
          w.Orig_gpdt = moment(w.Orig_gpdt).format("DD/MM/yyyy");
          this.party_nm = w.Party_name;
          this.outward_no = w.OUTWARDNO;
          this.outward_dt = moment(w.TDATE).format("DD/MM/yyyy");
        });

        this.dataSource = new MatTableDataSource(<any>this.datalist);
        console.log(this.dataSource);
        this.DisplayCol();
      },
      err => {
				if (err.error.error_description) {
					this.openSnackBar(err.error.error_description, "dismiss");
				}
				else {
					this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
				}
			}
      );
  }
  openSnackBar(message: string, action: string) {
		this._SnackBar.open(message, action, {
			duration: 2000,
		});
	}
}
