import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/Services/Common/common.service';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { ManufacturingService } from '../../manufacturing.services';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';

@Component({
  selector: 'app-product-master',
  templateUrl: './product-master.component.html',
  styleUrls: ['./product-master.component.css']
})
export class ProductMasterComponent implements OnInit {

  loading: boolean = false;
  PrdGroupList: any = [];
  repmodel: any = {};
  paragrouplist: any = [];
  dataSource: MatTableDataSource<unknown>;
  SparaGrplist: any = [];
  Product_code: string;
  Subdata: any = [];
  secondaryTable = [];
  filtertext: string = "";

  constructor(private _cservice: CommonService,
              private _snackBar: MatSnackBar,
              private mnfservice: ManufacturingService,
              private hardfunc: HardFuncService,
              private notificationservice: NotificationService) { }

  ngOnInit() {
    
    
    this.loading = false;
    this._cservice.PrdGroupListAll()
    .subscribe(data => {
      console.log(data);
      this.PrdGroupList = data;
      this.loading = false;
    },
    err => {
      console.log(err);
      this.openSnackBar("Error in Getting product group , please check connection.","dismiss");
      this.loading = false;
    });
  }
  SaveProduct(){
    console.log(this.SparaGrplist);
    this.SparaGrplist.map(w => {
      console.log(w);
      // if(w.Selected ){
      //   console.log(w.Selected);
      // }
    })
  }
  PparabyGroup_id() {
    let grp_id = this.repmodel.Grp_id;
    this.mnfservice.Pparabygroup_id(grp_id)
      .subscribe((data: any) => {  
        console.log(data);
        // data.map(m =>{
        //   m.isClicked = false;
        // });
        this.paragrouplist = data; 

      });
  }
  sparabyPpara_id(p: any,index:number) {    
    console.log(p);
    this.mnfservice.GetSpara(p.Ppara_id)
      .subscribe((data: any) => {
        console.log(data);
        p.SparaGrplist = data;
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.notificationservice.warn(errstr);
      }
      );
  }
  
  ProductCode(){
    this.Product_code = "";
    this.paragrouplist.map(p=> {      
      p.SparaGrplist.map(w => {
        if(p.Selected === w.SPARA_ID){
          this.Product_code += w.SPARA_CD.trim();
        }
      })
    });
  }
  // ProductCode(){
  //   this.Product_code = "";
  //   this.paragrouplist.map(p=> {
  //     console.log(p);
  //     p.SparaGrplist.map(w => {
  //       if(p.Selected === w.SPARA_ID){
  //         this.Product_code += w.SPARA_CD.trim();
  //       }
  //     })
  //   });
  // }
  // ProductCode(){
  //   this.Product_code = "";
  //   this.paragrouplist.map(p=> {      
  //     p.SparaGrplist.map(w => {
  //       if(p.Selected === w.SPARA_ID){
  //         this.Product_code += w.SPARA_CD.trim();
  //       }
  //     })
  //   });
  // }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
