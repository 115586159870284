	import { Injectable } from '@angular/core';
	import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
	import { HttpClient, HttpHeaders } from '@angular/common/http';
	import { Observable } from 'rxjs';
	import * as moment from 'moment';
	
	@Injectable({
	  providedIn: 'root'
	})
	export class EnqService {

	  constructor(private hardfunc:HardFuncService ,
	      private http:HttpClient
	    ) { }

	    getDatas(data:any) {
	        data.Firmx = this.hardfunc.Firmx;
	        data.Yrx = this.hardfunc.Yrx;
	        let url = this.hardfunc.BaseAPIUrl+"api/EnqAPI/getDatas"
	        return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
	    }

		GetPDFdata(data:any) {

			let headers = this.hardfunc.PostHeaders();
	        data.Firmx = this.hardfunc.Firmx;
	        data.Yrx = this.hardfunc.Yrx;
	        let url = this.hardfunc.BaseAPIUrl+"api/EnqAPI/PDFDownload1"
	        return this.http.post(url, data, { headers: headers,  responseType: 'blob' as 'json'  });
	    }

		UserGetData(data: any) {
	        data.Firmx = this.hardfunc.Firmx;
	        data.Yrx = this.hardfunc.Yrx;
	        let url = this.hardfunc.BaseAPIUrl+"api/EnqAPI/EnqDashboard"
	        return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
	    }
		GetEnqHelp(data: any){
			data.Firmx = this.hardfunc.Firmx;
	        data.Yrx = this.hardfunc.Yrx;
	        let url = this.hardfunc.BaseAPIUrl+"api/Sales/quotesAPI/EnquiryHelp"
	        return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
		}

		GetUserPDFData(data: any) {
			
			let headers = this.hardfunc.PostHeaders();
	        data.Firmx = this.hardfunc.Firmx;
	        data.Yrx = this.hardfunc.Yrx;
	        let url = this.hardfunc.BaseAPIUrl+"api/EnqAPI/UserDownloadPDF"
	        return this.http.post(url, data, { headers: headers,  responseType: 'blob' as 'json'  });
	    }
		
	    GetDatabyId(Enq_id:number) { 			
			let data:any = {};
	        data.Firmx = this.hardfunc.Firmx;
	        data.Yrx = this.hardfunc.Yrx;
	        data.Enq_id = Enq_id;
	        let url = this.hardfunc.BaseAPIUrl+"api/EnqAPI/GetDatabyId?id="+Enq_id;
	        return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
	    }

		GetEnq1DatabyId(Srno:number){
			let data:any = {};
	        data.Firmx = this.hardfunc.Firmx;
	        data.Yrx = this.hardfunc.Yrx;
	        data.Srno = Srno;
	        let url = this.hardfunc.BaseAPIUrl+"api/EnqAPI/GetEnq1DatabyId?srno="+Srno;
	        return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
		}
		SendEnqEmail(Srno: number){
			let data : any = {};
			data.Srno = Srno;
			data.Firmx = this.hardfunc.Firmx;
	        data.Yrx = this.hardfunc.Yrx;
	        let url = this.hardfunc.BaseAPIUrl+"api/EnqAPI/EnquiryEmail?Srno="+Srno;
	        return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
		}
		GetForPendordAttEmail(Ordno: number){
			let data : any = {};
			data.Ordno = Ordno;
			data.Firmx = this.hardfunc.Firmx;
	        data.Yrx = this.hardfunc.Yrx;
	        let url = this.hardfunc.BaseAPIUrl+"api/EnqAPI/EnquiryEmail?Srno="+Ordno;
	        return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
		}
		
		GetPartialdata(Ordno:number) {
			console.log(Ordno);
			let data:any = {};
	        data.Firmx = this.hardfunc.Firmx;
	        data.Yrx = this.hardfunc.Yrx;
	        data.Ordno = Ordno;
	        let url = this.hardfunc.BaseAPIUrl+"api/EnqAPI/GetEnqDatabySrno?srno="+Ordno;
	        return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
	    }
		GetEnqSrno(){
			let url = this.hardfunc.BaseAPIUrl+"api/EnqAPI/getEnqsrno"
	        return this.http.post(url, null, { headers: this.hardfunc.PostHeaders()});
		}

	    SaveData(data:any) {
	        data.Firmx = this.hardfunc.Firmx;
	        data.Yrx = this.hardfunc.Yrx;
	        let url = this.hardfunc.BaseAPIUrl+"api/EnqAPI/SaveData"
	        return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
	    }
	    SavePartialData(data:any){
			data.Firmx = this.hardfunc.Firmx;
	        data.Yrx = this.hardfunc.Yrx;
	        let url = this.hardfunc.BaseAPIUrl+"api/EnqAPI/Savepartialdata"
	        return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
		}
	    DeleteData(data:any) {
	        
	        data.Firmx = this.hardfunc.Firmx;
	        data.Yrx = this.hardfunc.Yrx;
	        let url = this.hardfunc.BaseAPIUrl+"api/EnqAPI/DeleteData"
	        return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
	    }
		EnqPartyDetails(data:any) {
			data.Fdates = '01/04/2020';
			data.Tdates = moment(new Date()).format("DD/MM/yyyy");
	        data.Firmx = this.hardfunc.Firmx;
	        data.Yrx = this.hardfunc.Yrx;
	        let url = this.hardfunc.BaseAPIUrl+"api/EnqAPI/EnqPartyDetail"
	        return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
	    }
		RegretEnq(Srno: number, Type: string){
			let url = this.hardfunc.BaseAPIUrl + "api/EnqAPI/RegretEnqEmail?Srno="+Srno +"&Type="+ Type
			return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
		}
		SendRegretEmail(Srno: number){
			let url = this.hardfunc.BaseAPIUrl + "api/EnqAPI/GetEnqDetail?Srno="+Srno;
			return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
		}
	}
