import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatTableDataSource, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ManufacturingService } from '../../manufacturing.services';

@Component({
  selector: 'app-bom-utiby',
  templateUrl: './bom-utiby.component.html',
  styleUrls: ['./bom-utiby.component.css']
})


export class BomUtibyComponent implements OnInit {
  
  loading: boolean = false;
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private manufacturingservice: ManufacturingService,
              private _snackBar: MatSnackBar) { }

  ngOnInit() {    
    this.GetData();
    this.loading = false;
  }
  DisplayCol(){
    this.displayedColumns = ['Product_id','Short_nm','Grp_nm','Loca1','Loca3','Loca4','Total'];
  }
  GetData(){
    this.loading = true;
    this.manufacturingservice.BomUtiby(this.ReceivedData.id)
      .subscribe((data: any) => {
        this.datalist = data;
        this.datalist.map(d => {          
          d.Total = d.Loca1 || 0 + d.Loca2 || 0 + d.Loca3 || 0 + d.Loca4 || 0;
        });        
        this.dataSource = new MatTableDataSource(<any>data);                   
        this.loading = false;
        this.DisplayCol();
      },
      err => {
        this.loading = false;
        if (err.error.error_description) {
          this.openSnackBar(err.error.error_description,"dismiss");
        }
        else {
          this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
        }
      }
    );
  }
  openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
		  duration: 2000,
		});
	  }
}
