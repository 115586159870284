import { Component, OnInit, ViewChild, OnDestroy, SimpleChanges } from '@angular/core';
import { ManufacturingService } from '../manufacturing.services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatDialog } from '@angular/material';
import { MatPaginator } from '@angular/material';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/Services/Common/common.service';
import { StoreProdComponent } from '../../sales/store-prod/store-prod.component';
import { OpenstockComponent } from './openstock/openstock.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment'
import * as   XLSX from 'xlsx';
import { PrdHistoryComponent } from './prd-history/prd-history.component';

@Component({
  selector: 'app-stk-repo',
  templateUrl: './stk-repo.component.html',
  styleUrls: ['./stk-repo.component.css']
})
export class StkRepoComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  datalist: any [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  DataForm: FormGroup;
  repmodel: any = {};
  PrdGroupList: any = [];
  Dept_nm: any = [];
  formData: any;
  filename:string= 'Stock-Report.xlsx';
  UserName: string;
  isClicked = false;
  MaxClicked = false;
  CatClicked = false;
  StkAdjust: any = [];
  StkAdjRight: string;
  
  constructor(private ManufacService: ManufacturingService,
              private hardfunc: HardFuncService,
              private notificationService: NotificationService,
              private fb: FormBuilder,
              private _cservice: CommonService,
              private _snackBar: MatSnackBar,
              private dialog: MatDialog,
              private _http: HttpClient) { }

  ngOnInit() {
    this.DataForm = this.createFormGroup();
    this.GetData();
    this.ItemGroup();
    this.getDeptName();
    this.repmodel.Type = 'D';
    this.loading = true;
    this.repmodel.Transfer = "N";
    this.UserName = this.hardfunc.UserName.toLowerCase();    
    this.StkAdjust = ['alpesh','hardik','hms','hiren','rahul'];
    this.StkAdjust.forEach(element => {
      if(this.UserName === element){        
        this.StkAdjRight = element;        
      }
    });
  }
  ngOnDestroy(): void {
    console.clear();
  }
  ngOnChanges(changes: SimpleChanges) {
		console.log("on change ");
		if (changes['x'] && changes['x'].currentValue.isClicked) {
		  this.MinClick(changes);
		}
    if (changes['x'] && changes['x'].currentValue.MaxClicked) {
      this.MaxClick(changes);
		}
	  }
  //2. api/PrdGroupsAPI/GetPrdGroupAll
  ItemGroup(){
    this.repmodel.CG = 'A';
    this.repmodel.Status = 'P';
    this._cservice.PrdGroupListAll()
      .subscribe(data => {
        //  console.log(data);
        this.PrdGroupList = data;
        this.loading = false;
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }
  DisplayCol() {	
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    if(this.repmodel.Type === 'T'){
		  this.displayedColumns = ['Product_id','Short_nm','Grp_nm','OpStock','Rcpt','Issue','ClStock','Action'];
    }else{
      this.displayedColumns = ['Product_id','Short_nm','Grp_nm','OpStock','Grn_Rcpt','Prdc_Rcpt','Jwin_Rcpt','Other_Rcpt',
                               'Sl_issue','Cons_issue','Jwou_issue','Other_issue','ClStock','Min_stock','Category','Action'];
    }
	}
  
disableDept(checked) {
  if (!checked) {
    //this.DataForm.value.Store_id = 0;
    this.formData = this.DataForm.controls["Store_id"].patchValue(1);
  } else {
    this.DataForm.value.Store_id;
  }  
} 
//1. api/ProductsAPI/StockRep
GetData() {
    this.loading = true;
		this.datalist = [];
    let FromDate = this.hardfunc.hsConvDt(this.DataForm.controls.Fdate.value);
    let ToDate = this.hardfunc.hsConvDt(this.DataForm.controls.Tdate.value);
    
    this.formData = {
      Prodgrp_id: this.DataForm.get("Prodgrp_id").value,
      Store_id: this.DataForm.get("Store_id").value,
      Fdate: FromDate,
      Tdate: ToDate,     
      StkLevel: this.DataForm.get("StkLevel").value,
      Category: this.DataForm.get("Category").value,
      OnlyTrans: this.repmodel.Transfer,
      Division: this.DataForm.get("Division").value
    }
      this.ManufacService.StockRep(this.formData,)
        .subscribe(
          (data1: any) => {
            console.log(data1);
            this.datalist = data1;
            console.log(this.datalist);
            this.datalist.map(m => {              
              m.OpStock = m.ClStock + m.Issue - m.Rcpt;
              m.Category = m.Category.trim();
              m.check = "";
              m.isClicked = false;
              m.MaxClicked = false;
              m.CatClicked = false;
            });
            this.dataSource = new MatTableDataSource(<any>data1);
            this.dataSource.paginator = this.paginator;
            this.DisplayCol();
            this.loading = false;
          },
            err => {
              let errstr = this.hardfunc.getError(err);
              this.notificationService.warn(errstr);
              this.loading = false;
            }
          )
  }
  OldReport() {
    this.loading = true;
		this.datalist = [];
    let FromDate = this.hardfunc.hsConvDt(this.DataForm.controls.Fdate.value);
    let ToDate = this.hardfunc.hsConvDt(this.DataForm.controls.Tdate.value);
    
    console.log(this.repmodel.chk);
    this.formData = {
      Prodgrp_id: this.DataForm.get("Prodgrp_id").value,
      Store_id: this.DataForm.get("Store_id").value,
      Fdate: FromDate,
      Tdate: ToDate,     
      OnlyTrans: this.repmodel.Transfer,
      Division: this.DataForm.get("Division").value
    }    
      this.ManufacService.StockRep(this.formData)
        .subscribe(
          (data1: any) => {
            console.log(data1);
            this.datalist = data1;
            this.datalist.map(m => {              
              m.OpStock = m.ClStock + m.Issue - m.Rcpt;
              m.check = "";
              console.log(m.Op_Stock);
            });
            this.dataSource = new MatTableDataSource(<any>data1);
            this.dataSource.paginator = this.paginator;
            this.DisplayCol();
            this.loading = false;
          },
            err => {
              let errstr = this.hardfunc.getError(err);
              this.notificationService.warn(errstr);
              this.loading = false;
            }
          )
  }
  
  UpdMinStock(product_id: number, Min_stock: number, Prod_code: string){
    this.loading = true;
    this.ManufacService.FixStock(product_id, Min_stock, Prod_code)
      .subscribe((data: any) => {
        this.datalist.map(d => {
					d.MaxClicked = false;	
          d.isClicked = false;  
          d.CatClicked = false;          
				});
        this.loading = false;
        this.notificationService.success("Data Saved Sucessfully...");
      },
      err => {
        console.log(err);
        this.openSnackBar("Error in Getting product group , please check connection.","dismiss");
        this.loading = false;
      });
  }
  // UpdMaxStock(product_id: number, Min_stock: number, Max_stock: number, Prod_code: string){
  //   this.loading = true;
  //   this.ManufacService.FixStock(product_id, Min_stock, Max_stock, Prod_code)
  //     .subscribe((data: any) => {
  //       this.datalist.map(d => {						
  //           d.MaxClicked = false;
  //           d.isClicked = false; 
  //           d.CatClicked = false; 
	// 			});
  //       this.loading = false;
  //       this.notificationService.success("Data Saved Sucessfully...");
  //     },
  //     err => {
  //       console.log(err);
  //       this.openSnackBar("Error in Getting product group , please check connection.","dismiss");
  //       this.loading = false;
  //     });
  // }
  UpdCategory(product_id: number, Category: string){
    this.ManufacService.CategoryUPD(product_id, Category)
      .subscribe((data: any) => {
        this.datalist.map(d => {						
            d.MaxClicked = false;
            d.isClicked = false;  
            d.CatClicked = false;
				});
        this.loading = false;
        this.notificationService.success("Data Saved Sucessfully...");
      },
      err => {
        console.log(err);
        this.openSnackBar("Error in Getting product group , please check connection.","dismiss");
        this.loading = false;
      });
  }
  MinClick(data:any){
		this.datalist.map(d => {
				d.isClicked = false;
		});
		data.isClicked = true;
	}
  MaxClick(data:any){
		this.datalist.map(d => {
				d.MaxClicked = false;
		});
		data.MaxClicked = true;
	}
  CatClick(data:any){
		this.datalist.map(d => {
				d.CatClicked = false;
		});
		data.CatClicked = true;
	}
  disableField(checked) {
    console.log(checked);
    if (!checked) {
      this.repmodel.chk = 'N';      
    } else {
      this.repmodel.chk = 'Y';
    }
    console.log(this.repmodel.chk);
}
  StkReportPDF(){
    this.loading = true;
		this.datalist = [];
    let FromDate = this.hardfunc.hsConvDt(this.DataForm.controls.Fdate.value);
    let ToDate = this.hardfunc.hsConvDt(this.DataForm.controls.Tdate.value);
    
    this.formData = {
      Prodgrp_id: this.DataForm.get("Prodgrp_id").value,
      Store_id: this.DataForm.get("Store_id").value,
      Fdate: FromDate,
      Tdate: ToDate,     
      StkLevel: this.DataForm.get("StkLevel").value,
      Category: this.DataForm.get("Category").value,
      OnlyTrans: this.repmodel.Transfer,
      Division: this.DataForm.get("Division").value
    }
    this.ManufacService.StkReportPDF(this.formData,this.repmodel.Transfer)
      .subscribe((data: any) => {
        const data1 = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = data1;
        link.target = "_blank";
        link.download = "Stock-Report.pdf";
        link.click();
        this.loading = false;
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
        
      },
        err => {
          this.loading = false;
          if (err.error.error_description) {
            this.openSnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.openSnackBar("Error in getting data, Please Check Server Connectivity...", "dismiss")
          }
        });
  }
  StockUpdate(){
    console.log(this.DataForm.get("Store_id").value);
    // For StockUpdate Other SP used --Old SP was with Store id
    this.ManufacService.StockUpdateData(this.DataForm.get("Store_id").value)
      .subscribe((data: any) => {
        this.notificationService.success('Stock Updated');
      },err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.notificationService.warn(errstr);
        this.loading = false;
      });
  }
  PrdHistoryDialog(Product_id: number, Short_nm: string): void {
		const dialogRef = this.dialog.open(PrdHistoryComponent, {
			width: '1200px',
      data: { id: Product_id, Dept_id: this.DataForm.get("Store_id").value, Short_nm: Short_nm }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}
  createFormGroup() {
    return this.fb.group({
      Opstock: new FormControl(0),
      OpendDept: new FormControl(0),
      Prodgrp_id: new FormControl(0),
      Store_id: new FormControl(1),
      StkLevel: new FormControl("A"),
      Category: new FormControl("All"),
      Fdate: new FormControl(new Date()),
      Tdate: new FormControl(new Date()),
      Division: new FormControl("*All*"),
    });
  }
  exportformat1():void{
		// Option-2 Entire JSON
		const ws1:XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.datalist);

		this.datalist.map(m =>{
			m.Offer_dt = moment(m.Offer_dt).format('DD/MM/yyyy')
			m.Issue_dt = moment(m.Issue_dt).format('DD/MM/yyyy')      
		})
		// Option-3 Selected fields from JSON
		let newData = this.datalist.map(({Product_id,Short_nm,Grp_nm,OpStock,Sl_issue,Cons_issue,Jwou_issue,Other_issue,Grn_Rcpt,Prdc_Rcpt,Jwin_Rcpt,Other_Rcpt,ClStock}) =>
										                ({Product_id,Short_nm,Grp_nm,OpStock,Sl_issue,Cons_issue,Jwou_issue,Other_issue,Grn_Rcpt,Prdc_Rcpt,Jwin_Rcpt,Other_Rcpt,ClStock}));
		const ws:XLSX.WorkSheet=XLSX.utils.json_to_sheet(newData);
		
		const wb:XLSX.WorkBook=XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws,'Sheet1');
	
		XLSX.writeFile(wb,this.filename);
	  }
    exportformat2():void{
    // Option-2 Entire JSON
    const ws1:XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.datalist);

    this.datalist.map(m =>{
      m.Offer_dt = moment(m.Offer_dt).format('DD/MM/yyyy')
      m.Issue_dt = moment(m.Issue_dt).format('DD/MM/yyyy')      
    })
    // Option-3 Selected fields from JSON
    let newData = this.datalist.map(({Product_id,Short_nm,Grp_nm,OpStock,Rcpt,Issue,ClStock}) =>
                                    ({Product_id,Short_nm,Grp_nm,OpStock,Rcpt,Issue,ClStock}));
    const ws:XLSX.WorkSheet=XLSX.utils.json_to_sheet(newData);
    
    const wb:XLSX.WorkBook=XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws,'Sheet1');
  
    XLSX.writeFile(wb,this.filename);
    }
  openDialog(Pk_id: number): void {
    const dialogRef = this.dialog.open(StoreProdComponent, {
			width: '800px',
      height: '300px',
			data: { id: Pk_id }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}
  OpenStock(Product_id: number, Short_nm: string): void {
    
		const dialogRef = this.dialog.open(OpenstockComponent, {
			width: '640px',
      height: '200px',
      data: {Product_id: Product_id,Short_nm: Short_nm, Dept: this.DataForm.get("Store_id").value}
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}
  getDeptName() {
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/deptssAPI/GetDatas";
    this._http.post(url, this.repmodel)
      .subscribe((data1: any) => {
        this.Dept_nm = data1;
      }, err => {
        console.log(err);
      });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
