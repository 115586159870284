import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MatTableDataSource } from '@angular/material';
import { FusermastComponent } from '../fusermast.component';
import { MatDialog } from '@angular/material';
import { FusermastCreateuserComponent } from '../fusermast-createuser/fusermast-createuser.component';
import { CopyrightsComponent } from '../../fmodule/copyrights/copyrights.component';
@Component({
  selector: 'app-fusermast-list',
  templateUrl: './fusermast-list.component.html',
  styleUrls: ['./fusermast-list.component.css']
})
export class FusermastListComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];

  constructor(private adminservice: AdminService,
              private hardfunc: HardFuncService,
              private notificationservice: NotificationService,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.getData();
    this.loading = true;
  }
  DisplayCol(){
    this.displayedColumns = ['Pk_id','Username','Action'];
  }
  getData(){
    this.loading = true;
    this.datalist = [];
    this.adminservice.getFusermastData()
      .subscribe((data: any) => {
          console.log(data);
          this.loading = false;
          this.datalist = data;
          this.dataSource = new MatTableDataSource(<any>this.datalist);
          this.DisplayCol();
        });
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationservice.warn(errstr);
        }
  }
  OpenModule(Pk_id: number, Username: string){
    console.log(Pk_id);
        let isSm = window.matchMedia(`(max-width: 960px)`).matches;
        let w = '70%';
        let h = '80%';
        if (isSm )
        {
          w = '100%';
          h = '100%';
        }
        const dialogRef = this.dialog.open(FusermastComponent, {
          width: w,
          maxWidth:w,
          height: h,
          data: { id: Pk_id, Username: Username }
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        });
  }
  
  CreateUser(Pk_id: number): void {
    console.log(Pk_id)
    const dialogRef = this.dialog.open(FusermastCreateuserComponent, {
      width: '528px',
      data: {id:Pk_id}   
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  EditUser(data: any){
    const dialogRef = this.dialog.open(FusermastCreateuserComponent, {
			width: '528px',
			data: { id: data.Pk_id, Username: data.Username, Password: data.Password, cngpass: data.Passcng_dt }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
  }
  DeleteUser(Pk_id: number){
    this.adminservice.openConfirmDialog("Are you sure, You want to delete this ?")
      .afterClosed().subscribe(res => {
        if(res){
          this.loading = true;
          const dialogRef = this.adminservice.DeleteUserfromfusermast(Pk_id)
            .subscribe((data: any) =>{
              this.loading = false;
              this.notificationservice.warn("Data Deleted Successfully");
              this.getData();
            },
            err => {
              console.log(err);
              this.loading = false;
              let errstr = this.hardfunc.getError(err);
              this.notificationservice.warn(errstr);
            }
            )
        }
      });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
