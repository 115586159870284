import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { CommonService } from 'src/app/Services/Common/common.service';

@Component({
  selector: 'app-from-to-help',
  templateUrl: './from-to-help.component.html',
  styleUrls: ['./from-to-help.component.css']
})
export class FromToHelpComponent implements OnInit {

  loading: boolean = false;
  PrdIdList: any = [];
  repmodel: any = {};
  PrdGroupList: any = [];

  constructor(private _cservice: CommonService,
              private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.loading = true;
    this.ItemGroup();
  }
  ItemGroup(){
    this.loading = true;
    this.repmodel.CG = 'A';
    this.repmodel.Status = 'P';
    this._cservice.PrdGroupListAll()
      .subscribe((data: any) => {
        console.log(data);
        this.PrdGroupList = [];
        data.map(d => {
          if(d.fProd_id){
            this.PrdGroupList.push(d);
          }
        })
        console.log(this.PrdGroupList);
        this.loading = false;
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
