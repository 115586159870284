import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { PartyHelpComponent } from '../../shared/party-help/party-help.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar, MatTableDataSource } from '@angular/material';
import { ManufacturingService } from '../../manufacturing/manufacturing.services';
import { HttpClient } from '@angular/common/http';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EnqHelpComponent } from '../enq-help/enq-help.component';
import { TproductComponent } from '../../shared/tproduct/tproduct.component';
import { SalesService } from '../sales.service';
import { TempmastComponent } from '../../shared/tempmast/tempmast.component';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import * as moment from 'moment'
import { CommonService } from 'src/app/Services/Common/common.service';

@Component({
  selector: 'app-cr',
  templateUrl: './cr.component.html',
  styleUrls: ['./cr.component.css']
})
export class CrComponent implements OnInit {
  
  loading: boolean = false;
  DataForm: FormGroup;
  filteredOptions: any = [];
  datalist: any = [];
  repmodel: any = {};
  StateList: any = [];
  DirectorList: any = [];
  MarketingList: any = [];
  FieldMKtList: any = [];
  TotalAmt: number;
  TaxTotal: number;
  TotalPOvalue: number;
  public editor = ClassicEditor;
  WithoutTax: number;
  WithTaxTot: number;
  docu: string;
  idx: any = [1, 2, 3, 4];
  docuAtt: any = [];
  datasheet: string;
  drawing: string;
  TgroupList: any = [];
  title: string;
  initialValues: any[];
  TermsCons: any = [];
  AddiTerms: any = [];
    
  constructor(private fb: FormBuilder,
              private hardfunc: HardFuncService,
              private dialog: MatDialog,
              private MnfSerivce: ManufacturingService,
              private _http: HttpClient,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private SalesService: SalesService,
              private _snackBar: MatSnackBar,
              private NotificationService: NotificationService,
              @Optional() public dialogRef: MatDialogRef<CrComponent>,
              private commonservice: CommonService) { }

  ngOnInit() {   
  }
  
        
}
