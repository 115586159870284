import { Component, OnInit, Optional, Inject } from '@angular/core';
import { ManufacturingService } from '../../manufacturing.services';
import { MAT_DIALOG_DATA, MatTableDataSource, MatDialog } from '@angular/material';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { PrdgrpBySparaComponent } from '../prdgrp-by-spara/prdgrp-by-spara.component';

@Component({
  selector: 'app-spara-dialog',
  templateUrl: './spara-dialog.component.html',
  styleUrls: ['./spara-dialog.component.css']
})
export class SparaDialogComponent implements OnInit {

  SparaGrplist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];

  constructor(private mnfservice: ManufacturingService,
    @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    private hardfunc: HardFuncService,
    private notificationService: NotificationService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.GetData();
  }
  DisplayCol() {
    this.displayedColumns = ['SPARA_ID', 'SPARA_NM', 'SPARA_CD', 'SPARA_VAL', 'WEIGHT']
  }
  GetData() {
    this.mnfservice.GetSpara(this.ReceivedData.Ppara_id)
      .subscribe((data: any) => {
        console.log(data);
        this.SparaGrplist = data;
        this.dataSource = new MatTableDataSource(<any>this.SparaGrplist);
        this.DisplayCol();
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.notificationService.warn(errstr);
      }
      );
  }
  PrdGrpbySpara(SPARA_ID: number, SPARA_NM: string,SPARA_CD: string){
    const dialogRef = this.dialog.open(PrdgrpBySparaComponent, {
      width: '950px',
      height: '800px',
      data: { Grp_id: this.ReceivedData.Grp_id, Spara_id: SPARA_ID, Spara_nm: SPARA_NM, Spara_cd: SPARA_CD }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  // PrdGrpbySpara(element){
  //   console.log(element);
  //   const dialogRef = this.dialog.open(PrdgrpBySparaComponent, {
  //     width: '850px',
  //     height: '630px',
  //     //data: { Grp_id: this.ReceivedData.Grp_id, Spara_id: SPARA_ID, Spara_nm: SPARA_NM }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(result);
  //   });
  // }
}

