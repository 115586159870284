import { Component, OnInit, ViewChild, OnDestroy, ElementRef, SimpleChanges } from '@angular/core';
import { Sort, MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { GlTranCrudComponent } from './gl-tran-crud/gl-tran-crud.component';
import { GlTransService } from './gl-trans.service';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';

@Component({
	selector: 'app-gl-trans',
	templateUrl: './gl-trans.component.html',
	styleUrls: ['./gl-trans.component.css']
})
export class GlTransComponent implements OnInit, OnDestroy {

	loading: boolean = false;
	datalist: any = [];
	datasource: any = [];
	dataSource: MatTableDataSource<unknown>;
	displayedColumns: string[] = [];
	DataForm: FormGroup;
	TotalAmt: number;
	isClicked = false;
	Clicked = false;
	
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild('myTextarea', { static: false }) textarea: ElementRef;


	constructor(private _snackBar: MatSnackBar,
		private gltransService: GlTransService,
		private hardfunc: HardFuncService,
		private dialog: MatDialog,
		private fb: FormBuilder,
		private notificationservice: NotificationService) { }

	ngOnInit() {
		this.loading = true;
		this.DataForm = this.createFormGroup();
		this.loading = false;
	}

	ngOnChanges(changes: SimpleChanges) {
		console.log("on change ");
		if (changes['x'] && changes['x'].currentValue.isClicked) {
		  this.DescClick(changes);
		}
	  }

	DisplayCol() {
		let isSm = window.matchMedia(`(max-width:500px)`).matches;
		this.displayedColumns = ['Trdate1',   'Items'];
	}
	getData() {
		this.loading = true;
		this.datalist = [];
		var data: any = {};
		this.DataForm.controls.Fdate.patchValue(this.DataForm.controls.Fdate.value);
		this.DataForm.controls.Tdate.patchValue(this.DataForm.controls.Tdate.value);		
		this.gltransService.getDatas(this.DataForm.value)
			.subscribe(
				(data: any) => {
					data.map(d => {
						d.Subdatas.map(x => {
							x.isClicked = false;
						});
					});
					this.datalist = data;
					this.TotalAmt = 0;
					this.dataSource = new MatTableDataSource(<any>this.datalist);
					this.dataSource.paginator = this.paginator;
					this.DisplayCol();
					this.loading = false;
				},
				err => {
					if (err.error.error_description) {
						this.loading = false;
						this.openSnackBar(err.error.error_description, "dismiss");
					}
					else {
						this.loading = false;
						this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					}
				}
			)
	}

	DescClick(x:any){
		this.datalist.map(d => {
			d.Subdatas.map(x => {
				x.isClicked = false;
			});
		});
		x.isClicked = true;
	}

	UpdDesc(Sql_id: number,Descri1: string){	
		this.gltransService.SaveDesc(Sql_id, Descri1)
			.subscribe(
			data => {
				this.loading = false;
				this.notificationservice.success(' Data Saved Successfully... ');
				this.datalist.map(d => {
					d.Subdatas.map(x => {
						x.isClicked = false;
					});
				});
			},
			err => {
				let errstr = this.hardfunc.getError(err);
				console.log(err);
				this.notificationservice.warn(errstr);
				this.loading = false;
			}
			);
	}
	openDialog(Sql_id: number, Descri1: string, Party_name: string, Amt1: number, Pmst_cod1: string, Trdate1: String) {
		const dialogRef = this.dialog.open(GlTranCrudComponent, {
			width: '830px',
			height: '133px',
			data: {
				id: Sql_id,
				Descri1: Descri1,
				Party_name: Party_name,
				Amt1: Amt1,
				Pmst_cod1: Pmst_cod1,
				Trdate1: Trdate1
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
			this.getData();
		});
	}
	createFormGroup() {
		return this.fb.group({
			Fdate: new FormControl(new Date()),
			Tdate: new FormControl(new Date()),
			Trtype1s: new FormControl("", Validators.required),
			Pmst_cod:new FormControl("")
		});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
	openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}
	ngOnDestroy(): void {
		console.clear();
	}
}
