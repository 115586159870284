import { Component, OnInit } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { ManufacturingService } from '../manufacturing.services';
import { NotificationService } from 'src/app/Services/Common/notification.service';

@Component({
  selector: 'app-minstock-dash',
  templateUrl: './minstock-dash.component.html',
  styleUrls: ['./minstock-dash.component.css']
})
export class MinstockDashComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  repmodel: any = {};  

  constructor(private hardfunc: HardFuncService,
              private ManufacService: ManufacturingService,
              private notificationservice: NotificationService) { }

  ngOnInit() {
    this.loading = true;
    // this.repmodel.Status = "P";
    // this.repmodel.StkLevel = "All";
    this.repmodel.Category = "All";
    this.GetData();
  }
  
  //1. api/ProductsAPI/StockRep StkBelowMinLevel
  GetData() {
    this.loading = true;
    let FromDate = this.hardfunc.hsConvDt(new Date());
    let ToDate = this.hardfunc.hsConvDt(new Date());

    let data: any = {
      Prodgrp_id: null,
      Store_id: 1,
      Fdate: FromDate,
      Tdate: ToDate,     
      StkLevel: "B",
      Category: this.repmodel.Category,
      OnlyTrans: null,
      Product_id: null,
      Status: "P",
    }
    this.ManufacService.StkBelowMinLevel(data)
      .subscribe(
        (data1: any) => {   
          console.log(data1);
          data1.map(d => {
            d.Checkbox = false;
            d.Firm = null;
            d.Srno = 1;
            d.Unit1 = 0;
            d.Reqdt = new Date();
            d.Remark = "";
            d.Username = "";
          });
          this.datalist = data1;          
          this.loading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
          this.loading = false;
        }
      )
  }
  SaveIndent(){      
    // this.loading = true;
    // let data1: any = {
    //   Firm: this.hardfunc.Firmx,
    //   Srno: 1,
    //   Tdate: new Date(),
    //   Product_id: data.Product_id,
    //   Short_nm: data.Short_nm,
    //   Qnty1: data.Min_stock,
    //   Unit1: 0,
    //   Reqdt: new Date(),
    //   Remark: "",
    //   Username: this.hardfunc.UserName,
    // }
    // console.log(data1);
    // ---------------------------------------------------------------    

    this.datalist.map(d => {
      d.Firm = this.hardfunc.Firmx;
      d.Srno = 1;
      d.Tdate = new Date();
      d.Qnty1 = d.Min_stock,
      d.Unit1 = 0;
      d.Reqdt = new Date();
      d.Remark = "";
      d.Username = this.hardfunc.UserName;
      d.Product_id = d.Product_id;
    })
    
    let datalist1 = this.datalist.filter(f=> f.Checkbox === true);     
    console.log(datalist1);     
         
    // ---------------------------------------------------------------

    this.ManufacService.AddToIndent(datalist1)
      .subscribe((data: any) => {
        console.log(data);
        this.notificationservice.success("Indent Saved Successfully... ");
        this.loading = false;
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        this.notificationservice.warn(errstr);
        this.loading = false;
      });
  }
  allComplete: boolean = false;
  someComplete(): boolean {
    if (this.datalist == null) {
      return false;
    }
    return this.datalist.filter(t => t.Checkbox).length > 0 && !this.allComplete;
  }
  setAll(Checkbox: boolean) {
    this.allComplete = Checkbox;
    if (this.datalist == null) {
      return;
    }
    this.datalist.forEach(t => (t.Checkbox = Checkbox));
  }
  PendIndentEmail(){
    this.loading = true;
    let FromDate = this.hardfunc.hsConvDt(new Date());
    let ToDate = this.hardfunc.hsConvDt(new Date());
    let data: any = {
      Prodgrp_id: null,
      Store_id: 1,
      Fdate: FromDate,
      Tdate: ToDate,     
      StkLevel: "B",
      Category: this.repmodel.Category,
      OnlyTrans: null,
      Product_id: null,
      Status: "P",
    }
    this.ManufacService.PendIndEmail(data)
      .subscribe((data: any) => {        
        this.notificationservice.success("Mail Sent Successfully... ");
        this.loading = false;
      });
  }
}
