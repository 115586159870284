import { Injectable } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class PendordService {

	getheaders;
	postheaders;

	constructor(private hardfunc: HardFuncService,
		private http: HttpClient) { }

	SaveData(data: any) {
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		let url = this.hardfunc.BaseAPIUrl + "api/Common/notessAPI/SaveData"
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	UpdateData(data: any) {
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/ChangeOrdStatus"
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	SavePriority(Ordno: number,Oloc: number,Prio: any,) {
		let data: any = {};
		data.Prio = Prio;
		data.Ordno = Ordno;
		data.Oloc = Oloc;
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/ChangePriority"
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	OrderCategory(Ordno: number, Oloc: number,Categories: number){
		let data: any = {};
		data.Firmx = this.hardfunc.Firmx;
		data.Ordno = Ordno;
		data.Oloc = Oloc;
		data.Color = Categories;
		data.Username = this.hardfunc.UserName;
		let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/OrderCategory"
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	OrderCat1(Ordno: number, Oloc: number,Cat1: number){
		let data: any = {};
		data.Firmx = this.hardfunc.Firmx;
		data.Ordno = Ordno;
		data.Oloc = Oloc;
		data.Cat1 = Cat1;
		data.Username = this.hardfunc.UserName;
		let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/OrderCat1"
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	OrderCat2(Ordno: number, Oloc: number,Cat2: number){
		let data: any = {};
		data.Firmx = this.hardfunc.Firmx;
		data.Ordno = Ordno;
		data.Oloc = Oloc;
		data.Cat2 = Cat2;
		data.Username = this.hardfunc.UserName;
		let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/OrderCat2"
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	OrderCat3(Ordno: number, Oloc: number,Cat3: number){
		let data: any = {};
		data.Firmx = this.hardfunc.Firmx;
		data.Ordno = Ordno;
		data.Oloc = Oloc;
		data.Cat3 = Cat3;
		data.Username = this.hardfunc.UserName;
		let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/OrderCat3"
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	ExpDispatchDt(data: any) {
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/ExpDispatchDt"
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	SaveRemarks(Notes_id: number) {
		let data: any = {};
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		data.Notes_id = Notes_id;
		//data.Remarks = Remarks;
		let url = this.hardfunc.BaseAPIUrl + "api/Common/notessAPI/SaveData?Notes_id=" + Notes_id;
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	

	GetDatabyId(Firmx: string, Notes_type: string, Ordno: number, Oloc: number) {
		let data: any = {};
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		data.Ordno = Ordno;
		data.Oloc = Oloc;
		data.Notes_type = Notes_type;

		let url = this.hardfunc.BaseAPIUrl + "api/Common/notessAPI/GetDatasbyOrdno";
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	GetDatabyOrd(Firmx: String, Ordno: number, Oloc: number){
		let data: any = {};
		data.Firm = this.hardfunc.Firmx;
		data.Ordno = Ordno;
		data.Oloc = Oloc;

		let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/GetOrderbyOrdno";
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	GetDatabyOrdforprio(Firmx: String, Ordnos: number, Olocs: number){
		let data: any = {};
		data.Firm = this.hardfunc.Firmx;
		data.Ordno = Ordnos;
		data.Oloc = Olocs;

		let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/GetOrderbyOrdno";
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	Delete_note(Notes_id: number) {
		let url = this.hardfunc.BaseAPIUrl + "api/Common/notessAPI/DeleteData?id=" + Notes_id;
		return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
	}

	GetDatabyAttach(Firmx: string, Vtype: string){
		let data: any = {};
		data.Firm = this.hardfunc.Firmx;
		data.Vtype = Vtype;

		let url = this.hardfunc.BaseAPIUrl + "api/DMSAPI/DocuSumbyOrdno";
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	
	SaveDesc(Docu_id: number, Remarks: string){
		let data: any = {};
		data.Docu_id = Docu_id;
		data.Remarks = Remarks;
		let url = this.hardfunc.BaseAPIUrl + "api/DMSAPI/SaveDesc?Docu_id="+Docu_id;
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	// deleteNote(Notes_id: number){
	// 	let data: any = {};
	// 	data.Firm = this.hardfunc.Firmx;
	// 	data.Yrx = this.hardfunc.Yrx;
	// 	data.Notes_id = Notes_id;

	// 	let url = this.hardfunc.BaseAPIUrl + "api/Common/notessAPI/GetDatasbyOrdno";
	// 	return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	// }
}
