import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { AppLoaderService } from 'src/app/Services/app-loader/app-loader.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormControl, Validators, FormBuilder, NgForm, FormGroup, ValidatorFn, AbstractControl, ValidationErrors, FormGroupDirective} from '@angular/forms';
import { AdminService } from '../admin.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { NotificationService } from 'src/app/Services/Common/notification.service';
@Component({
  selector: 'app-change-pwd-new',
  templateUrl: './change-pwd-new.component.html',
  styleUrls: ['./change-pwd-new.component.css']
})

export class ChangePwdNewComponent implements OnInit   {
  // isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
  //   const invalidCtrl = !!(control?.invalid && control?.parent?.dirty);
  //   const invalidParent = !!(control?.parent?.invalid && control?.parent?.dirty);

  //   return invalidCtrl || invalidParent;
  // }
  // model: any = {
    
  // };
  
  // UserName: string;
  // OldPassword: string;
  // NewPassword: string;
  
  // onSubmit1() {
  //   alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.model))
  // }
  DataForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  constructor(
    private hardfunc: HardFuncService,
    private _http: HttpClient,
    private apploaderservice : AppLoaderService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private notificationservice: NotificationService,
    private adminservice: AdminService)
    {
      this.DataForm = this.fb.group({
        Username: new FormControl(''),
        OldPassword: new FormControl('',[Validators.required,Validators.minLength(6)]),
        NewPassword: new FormControl('',[Validators.required,Validators.minLength(6)]),
        ConfirmPassword: new FormControl('',Validators.required)
      }, {validator: this.checkPasswords});
   }

  ngOnInit() {
    //this.model.UserName = this.hardfunc.UserName;
    //this.checkPasswords;
    //this.DataForm = this.CreateFormGroup();
    this.DataForm.get('Username').patchValue(this.hardfunc.UserName);
  }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.NewPassword.value;
    let confirmPass = group.controls.ConfirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }
  onSubmit(regForm: any) {
    console.log(regForm);
    // this.apploaderservice.open();
    // let url =  this.hardfunc.BaseAPIUrl+"api/UsersAPI/ResetPwd1";
    // var headers = new HttpHeaders({ 'content-Type':'application/json' });
    // this._http.post(url, this.model, { headers: headers })
    //   .subscribe(data => {
    //     console.log("Change Password Success");
    //     this.apploaderservice.close();
        
    //   },
    //     err => {
    //       console.log(err);
    //      // this.msgs.push({ severity: 'error', summary: 'Error', detail: `Error in Saving data , please check server connectivity...  ` });
    //       this.openSnackBar("Error in Saving data , please check server connectivity...  ","dismiss");
    //       this.apploaderservice.close();
    //     });
  }
  SaveData() {
    console.log(this.DataForm.value);
    this.adminservice.SaveChangepwd(this.DataForm.value)
      .subscribe((data: any) =>{
        console.log(data);
        this.notificationservice.success('Password Updated Successfully...');
      });
  }
  
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  // CreateFormGroup() {
  //   return this.fb.group({
  //     Username: new FormControl(''),
  //     OldPassword: new FormControl('',[Validators.required,Validators.minLength(6)]),
  //     NewPassword: new FormControl('',[Validators.required,Validators.minLength(6)]),
  //     ConfirmPassword: new FormControl('')
  //   }, {validator: this.checkPasswords});
  // }
  
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

