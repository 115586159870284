import { Injectable, OnInit } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { DialogComponent } from './dialog/dialog.component';

@Injectable({
	providedIn: 'root'
})
export class PdcService {
	
	getheaders;
    postheaders;
	constructor(private hardfunc: HardFuncService,
		private http: HttpClient,
		private dialog: MatDialog
	) { }

	getDatas(data: any) {
		var headers = new HttpHeaders({
			'content-Type': 'application/json',
			'process-Data': 'false',
			'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
		  })
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		let url = this.hardfunc.BaseAPIUrl + "api/PdcAPI/getDatas";
		return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
	}


	GetDatabyId(pk_id: number) {
		let data: any = {};
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		data.pk_id = pk_id;
		let url = this.hardfunc.BaseAPIUrl + "api/PdcAPI/GetDatabyId?id="+pk_id;
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}


	SaveData(data: any) {
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		let url = this.hardfunc.BaseAPIUrl + "api/PdcAPI/SaveData"
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	
	SavePartialData(data: any) {
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		let url = this.hardfunc.BaseAPIUrl + "api/PdcAPI/SavePartialData"
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
	DeleteRecrod(Pk_id: number) {
		let url = this.hardfunc.BaseAPIUrl+"api/PdcAPI/DeleteData?id="+Pk_id;
        return this.http.post(url,null,  { headers: this.hardfunc.PostHeaders() });
	}
}
