import { Component, OnInit, Inject, Optional } from '@angular/core';
import { PurchaseService } from '../../purchase.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-pdf-list',
  templateUrl: './pdf-list.component.html',
  styleUrls: ['./pdf-list.component.css']
})
export class PdfListComponent implements OnInit {

  loading: boolean = false;
  PoList: any = [];
  constructor(private PurchaseService: PurchaseService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private hardfunc: HardFuncService,
              private _http: HttpClient
  ) { }

  ngOnInit() {
    this.GetDatas();
    console.log(this.ReceivedData.data);
  }
  GetDatas(){
    console.log(this.ReceivedData.data.Po_Att);
    this.PoList = this.ReceivedData.data.Po_Att.split(",");
    console.log(this.PoList);
    // this.loading = true;
    // this.PurchaseService.GetPOList()
    //   .subscribe((data: any) => {
    //     this.loading = false;
    //     this.PoList = data;
    //     console.log(data);
    //   });
  }
  DownloadPDF(data: any){
    console.log(data);
    let firm = data.substring(0,5);
    let pono = data.substring(5,8);
    let Revno = data.substring(10,11);
    
    let headers = this.hardfunc.PostHeaders();   

      let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/CreatePoPDF?Pk_id="+ pono + "&Revno=" + Revno
      this._http.post(url, null, {headers: headers, responseType: 'blob' as 'json'})
        .subscribe((data1: any) => {              
            const data = window.URL.createObjectURL(data1);

            var link = document.createElement('a');
            link.href = data;
            link.target = "_blank";
            var CurrDate = new Date();
            
            link.download = "PO"+".pdf";
            link.click();
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);               
        });
  }
}
