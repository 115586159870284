import { Component, OnInit, Optional, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { EmailserviceService } from './emailservice.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { EnqService } from '../../sales/Enq/Enq.service';
import * as moment from 'moment'

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {
  
  loading: boolean = false;
  DataForm: FormGroup;
  public editor=ClassicEditor;
  SelectedFile: File = null;
  Msgdata1: any = [];
  On: any = [];
  SenderName: any = [];
  SenderEmail: any = [];
  Attach: any = [];
  Atts: any = [];
  NewAtts : any = [];
  file: any = [];
  To: any = [];
  r1:any={};
  MsgFile: boolean = false;
  filename: any = [];
  datalist: any = [];
  SrNumber: any;

  url:string = this.Hardfunc.BaseAPIUrl + "api/Common/DocuViewerAPI/GetDocu";
  constructor(public fb: FormBuilder,
              public EmailService: EmailserviceService,
              public NotificationService: NotificationService,
              private Hardfunc: HardFuncService,
              private _http: HttpClient,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private Enqservice: EnqService,
      private snackBar: MatSnackBar) { }

  ngOnInit() {
    console.log(this.ReceivedData.data);
    this.DataForm = this.createFormGroup();
    this.ReceiveData();
    let fn: string = this.r1.Filename || "";
    if (fn.toLowerCase().includes(".msg")) {
      this.MsgFile = true;
      this.OpenMsgFile();
    } else {
      //this.OpenOtherFiles();
    }
    console.log(this.ReceivedData);
    this.loading = true;
  }
 
  SaveData(){
   console.log(this.DataForm.value);
   this.GetEnqData();
    let encodedBody = btoa(this.DataForm.get("Body").value);
    
    let Emails = {
        Email_id: this.DataForm.get("Email_id").value,
        Email_to:this.DataForm.get("Email_to").value,
        Email_cc: this.DataForm.get("Email_cc").value,
        Email_bcc: this.DataForm.get('Email_bcc').value,
        Subject:this.DataForm.get("Subject").value,
        Folder_nm: this.DataForm.get("Folder_nm").value,
        Body:encodedBody,
        Attachment:this.Atts
    }    
    //
    //this.DataForm.get("Emails");
    var formData = new FormData();

    let Counter:number=0;
    
    this.NewAtts.map(m=>{
      formData.append("Files"+Counter,m);
    })

    console.log(Emails);
    formData.append("Data", JSON.stringify(Emails));
    formData.append("AttData", JSON.stringify(this.Atts));
    console.log(this.Atts);
    this.EmailService.SaveData(formData)
      .subscribe(
        data => {
          this.NotificationService.success(" Data Saved Successfully... ");
        },
        err => {
          console.log(err);
          let errstr = this.Hardfunc.getError(err);
          this.NotificationService.warn(errstr);
        }
      )
  }
  ReceiveData(): void{
    this.Msgdata1 = this.ReceivedData.data;    
    console.log(this.Msgdata1);
    let Sub = this.Msgdata1.Subject;
    this.To = this.Msgdata1.Tos;
    this.Attach = this.Msgdata1.Atts;
    this.On = this.Msgdata1.Senton;
    this.SenderName = this.Msgdata1.SenderName;
    this.SenderEmail = this.Msgdata1.SenderEmail;
    this.Atts = [];
    this.Attach.map(m => {
      this.Atts.push( {FileName : m.FileName} );
    });   
    if(this.ReceivedData.Reply === 'R'){
      this.DataForm.get("Email_to").patchValue(this.SenderEmail);
    }
    this.DataForm.get("Body").patchValue(this.Msgdata1.Body);
    this.DataForm.get("Subject").patchValue(this.Msgdata1.Subject);
    this.DataForm.get("Folder_nm").patchValue(this.Msgdata1.Folder_nm);
  }

  OpenMsgFile = function () {
    this.loading = true;
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    this.ErrMsg = "";
    let fn: string = this.r1.Filename || "";
    this.r1.Firmx = this.HardFunc.Firmx;
    let DocuType = "DMS131";
    this.r1.DocuType = DocuType;
    this._http.post(this.url, this.r1, { headers: headers })
      .subscribe((data1: any) => {
        console.log(data1);
        let Ordno: number = this.r1.ordno;
        this.MsgData = [];

        this.MsgData = data1;
        
        //this.MsgData.Subject.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '<br />');
        // console.log(this.MsgData);
        // this.MsgData.map(x => {
        //   console.log(x);
        //   x.Body.map(w => {
        //     console.log(w);
        //   });
        // })
        this.loading = false;
      },
        err => {
          console.log(err);
          if (err.status == 404) {
            this.ErrMsg = "Requested file not found ... ";
          }
          else {
            this.ErrMsg = "Error in getting pdf file " + err.message;
          }
        });
  }

  OpenQuotePDF = function () {
    this.loading = true;
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    this.ErrMsg = "";
    let fn: string = this.r1.Filename || "";
    this.r1.Firmx = this.HardFunc.Firmx;
    let DocuType = "QuoteAtt";
    this.r1.DocuType = DocuType;
    this._http.post(this.url, this.r1, { headers: headers })
      .subscribe((data1: any) => {
        console.log(data1);
        let Ordno: number = this.r1.ordno;
        this.MsgData = [];

        this.MsgData = data1;
                
        this.loading = false;
      },
        err => {
          console.log(err);
          if (err.status == 404) {
            this.ErrMsg = "Requested file not found ... ";
          }
          else {
            this.ErrMsg = "Error in getting pdf file " + err.message;
          }
        });
  }
  

  onFileSelected(event){
    // console.clear();
    // console.log(event.target.files[0]);
    //this.SelectedFile = <File>event.target.files[0];
    
    let file = event.target.files[0];
    console.log(file);
    var at1 = {
        "FileName":file.name,
        "FileType":file.type,
        "RowData": file,
        "Tag":'N'
    }
    this.NewAtts.push(file);
    this.Atts.push(at1);    
  }
  GetEnqData() {
		this.loading = true;
		this.datalist = [];
		var data: any = {};
		
		data.Fdates = moment(this.Hardfunc.hsConvDt(this.Hardfunc.Fdate)).format("DD/MM/yyyy");
    console.log(data.Fdate);
		data.Tdates = moment(this.Hardfunc.hsConvDt(this.Hardfunc.Tdate)).format("DD/MM/yyyy");
		this.Enqservice.getDatas(data)
			.subscribe(
				(data: any) => {
					console.log(data);
					this.datalist = data;	
          this.datalist.map(x => {
            this.SrNumber = x.Srno;
          })				
					//this.LoadImages();
					this.loading = false;
				},
				err => {
					this.loading = false;
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description,"dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
					}
				}
			)
			
	}
  openSnackBar(message: string, action: string) {
		this.snackBar.open(message, action, {
		  duration: 2000,
		});
	  }
  createFormGroup() {
    return this.fb.group({
      Email_id: new FormControl(-1),
      Email_to: new FormControl('',[Validators.required,Validators.pattern(
        /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})(; ?\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3}))*$/)]),
      Email_cc: new FormControl('',[Validators.pattern
        (/^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})(; ?\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3}))*$/)]),
      Email_bcc: new FormControl('',[Validators.pattern(
        /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})(; ?\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3}))*$/)]),
      Subject: new FormControl(''),
      Body: new FormControl(''),
      Vtype: new FormControl('EN'),
      Refno: new FormControl(this.SrNumber),
      Folder_nm : new FormControl(''),
      Attachment: this.fb.array([this.AttachForm()]),
    });
  }
  AttachForm(): FormGroup {
    return this.fb.group({
      Emailatt_id: new FormControl(-1),
      Filename: new FormControl('')
    });
  }

 

}
