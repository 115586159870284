import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsrlstComponent } from '../usrlst/usrlst.component';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatInputModule } from '@angular/material';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { AdminService } from '../admin.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-usrreg',
  templateUrl: './usrreg.component.html',
  styleUrls: ['./usrreg.component.css']
})
export class UsrregComponent {
  DataForm: FormGroup;
  SaveEvent: any = {};
  loading: boolean = false;
  msgs: any = [];
  ConfirmPassword: string = "";
  title: String;
  srcResult: any;
  imageError: string;
  cardImageBase64: string;
  isImageSaved: boolean = false;
  public editor = ClassicEditor;

  constructor(
    private _http: HttpClient,
    private service: CommonService,
    private hardfunc: HardFuncService,
    private notificationService: NotificationService,
    private adminservice: AdminService,
    public dialogRef: MatDialogRef<UsrlstComponent>,
    private matInput: MatInputModule,
    @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    private _snackBar: MatSnackBar
  ) {
    this.DataForm = this.createFormGroup();
    this.GetData();
  }

  GetData() {
    if (this.ReceivedData.UserName == -1) {
      this.title = "ADDITION";
    }
    else {
      this.adminservice.getUserbyUsername(this.ReceivedData.UserName)
        .subscribe(
          (data:any) => {
            console.log(data);
            data["password"] = "";
            this.cardImageBase64 = data.ImageStr;
            this.isImageSaved = true; 
            this.DataForm.patchValue(data);
            this.title = "EDITING";
          },
          err => {
            this.notificationService.warn('Error in getting data...');
            this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          }
        )
    }

  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  SaveData() {
    this.loading = true;
    let url = this.hardfunc.BaseAPIUrl + "api/UsersAPI/SaveUser";
    var headers = new HttpHeaders({ 'content-Type': 'application/json' });
    //this.DataForm.ImageStr = '';
    
    this._http.post(url, JSON.stringify(this.DataForm.value), { headers: headers })
      .subscribe(data => {
        this.loading = false;
        this.onClose();
        this.notificationService.success(' User Created Successfully... ');
        this.msgs.push({ severity: 'info', summary: 'Success', detail: "Data saved successfully" });
      },
        err => {
          this.loading = false;
          console.log(err);
          this.notificationService.warn('Error in saving...');
          this.msgs.push({ severity: 'error', summary: 'Error', detail: "Error in data getting" });
          this.openSnackBar("Error in saving... , please check server connectivity...  ", "dismiss");

        });
  }

  createFormGroup() {
    return new FormGroup({
      UserName: new FormControl('', Validators.required),
      Pname: new FormControl(''),
      password: new FormControl(''),
      Email: new FormControl('', Validators.required),
      PhoneNumber: new FormControl('', [Validators.required, Validators.minLength(8)]),
      Dir: new FormControl('N'),
      Mkt: new FormControl('N'),
      Fld: new FormControl('N'),
      ImageStr: new FormControl(''),
      image: new FormControl(''),
      Emailsign: new FormControl(''),
      OutAccess: new FormControl('N'),
      EmailPwd: new FormControl('')
    });
  }

  initializeFormGroup() {

  }

  onClose() {
    this.DataForm.reset();
    this.initializeFormGroup();
    this.dialogRef.close();
  }


  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is ' + max_size / 1000 + 'Mb';

        return false;
      }

      // if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
      //     this.imageError = 'Only Images are allowed ( JPG | PNG )';
      //     return false;
      // }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          console.log(img_height, img_width);

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              'Maximum dimentions allowed ' +
              max_height +
              '*' +
              max_width +
              'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.DataForm.patchValue({ ImageStr: imgBase64Path });
            this.isImageSaved = true;
            // this.previewImagePath = imgBase64Path;
          }
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }

  onClear(){

  }

}
