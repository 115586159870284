import { Component, OnInit } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { ManufacturingService } from '../manufacturing.services';
import { NotificationService } from 'src/app/Services/Common/notification.service';

@Component({
  selector: 'app-ind-order-list',
  templateUrl: './ind-order-list.component.html',
  styleUrls: ['./ind-order-list.component.css']
})
export class IndOrderListComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  repmodel: any = {};
  constructor(private hardfunc: HardFuncService,
              private ManufacService: ManufacturingService,
              private notificationservice: NotificationService) { }

  ngOnInit() {
    this.loading = true;
    // this.repmodel.Status = "P";
    // this.repmodel.StkLevel = "All";
    this.repmodel.Category = "All";
    this.GetData();
  }
  
  //1. api/ProductsAPI/StockRep StkBelowMinLevel
  GetData() {
    this.loading = true;
    let FromDate = this.hardfunc.hsConvDt(new Date());
    let ToDate = this.hardfunc.hsConvDt(new Date());

    let data: any = {
      Prodgrp_id: null,
      Store_id: 1,
      Fdate: FromDate,
      Tdate: ToDate,     
      StkLevel: "B",
      Category: this.repmodel.Category,
      OnlyTrans: null,
      Product_id: null,
      Status: "I",
    }
    this.ManufacService.StkBelowMinLevel(data)
      .subscribe(
        (data1: any) => {              
          this.datalist = data1;          
          this.loading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
          this.loading = false;
        }
      )
  }
  SaveIndent(data: any){      
    this.loading = true;
    let data1: any = {
      Firm: this.hardfunc.Firmx,
      Srno: 1,
      Tdate: new Date(),
      Product_id: data.Product_id,
      Short_nm: data.Short_nm,
      Qnty1: data.Min_stock,
      Unit1: 0,
      Reqdt: new Date(),
      Remark: "",
      Username: this.hardfunc.UserName,
    }
    this.ManufacService.AddToIndent(data1)
      .subscribe((data: any) => {
        console.log(data);
        this.notificationservice.success("Indent Saved Successfully... ");
        this.loading = false;
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        this.notificationservice.warn(errstr);
        this.loading = false;
      });
  }

}
