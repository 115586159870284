import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'src/app/Services/Common/common.service';
import { RoleManagementComponent } from '../role-management.component';


@Component({
  selector: 'app-role-crud1',
  templateUrl: './role-crud1.component.html',
  styleUrls: ['./role-crud1.component.css']
})


export class RoleCrud1Component implements OnInit {

  title: string;
  DataForm: FormGroup;
  loading: boolean = false;
  msgs: any = [];
  constructor(private service: CommonService,
    private notificationservice : NotificationService,
    private adminservice: AdminService,
    public dialogRef: MatDialogRef<RoleManagementComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public ReceivedData: any) {
      this.DataForm = this.createFormGroup();
      this.GetData();
     }

  ngOnInit() {
  }
  GetData() {
    if (this.ReceivedData.id == -1) {
        this.title="ADDITION";
    }
    else {
      this.adminservice.GetRole(this.ReceivedData.id)
        .subscribe(
          data => {
            this.DataForm.patchValue(data);
            this.title="EDITING";
          },
          err => {
            console.log(err);
            this.openSnackBar("saverity: 'error', summary: 'Error' Message', detail: err.error.error_description", "dismiss");
          }
        )
    }
  }
  SaveData() {
    this.loading = true;
    this.adminservice.SaveRole(this.DataForm.value)
      .subscribe(
        data => {
          this.loading = false;
          this.onClose();
          this.notificationservice.success(' Data Saved Successfully... ');
        },
        err => {
          if (err.error.error_description) {
            this.openSnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          }
        }
      )
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  createFormGroup() {
    return new FormGroup({
      Id: new FormControl(),
      Name: new FormControl('', Validators.required),
    });
  }
  initializeFormGroup() {

  }
  onClose() {
    this.DataForm.reset();
    this.initializeFormGroup();
    this.dialogRef.close();
  }
  onClear(){

  }
}


// @Component({
//   selector: 'app-role-crud',
//   templateUrl: './role-crud.component.html',
//   styleUrls: ['./role-crud.component.css']
// })
// export class RoleCrudComponent implements OnInit {

//   title: string;
//   DataForm: FormGroup;
//   loading: boolean = false;
//   msgs: any = [];
//   constructor(private service: CommonService,
//     private notificationservice : NotificationService,
//     private adminservice: AdminService,
//     public dialogRef: MatDialogRef<RoleManagementComponent>,
//     private _snackBar: MatSnackBar,
//     @Inject(MAT_DIALOG_DATA) public ReceivedData: any
//   ) {
//     this.DataForm = this.createFormGroup();
//     this.GetData();
//   }

//   ngOnInit() {
//   }

//   GetData() {
//     if (this.ReceivedData.id == -1) {
//         this.title="ADDITION";
//     }
//     else {
//       this.adminservice.GetRole(this.ReceivedData.id)
//         .subscribe(
//           data => {
//             this.DataForm.patchValue(data);
//             this.title="EDITING";
//           },
//           err => {
//             console.log(err);
//             this.openSnackBar("saverity: 'error', summary: 'Error' Message', detail: err.error.error_description", "dismiss");
//           }
//         )
//     }
//   }
//   SaveData() {
//     this.loading = true;
//     this.adminservice.SaveRole(this.DataForm.value)
//       .subscribe(
//         data => {
//           this.loading = false;
//           this.onClose();
//           this.notificationservice.success(' Data Saved Successfully... ');
//         },
//         err => {
//           if (err.error.error_description) {
//             this.openSnackBar(err.error.error_description, "dismiss");
//           }
//           else {
//             this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
//           }
//         }
//       )
//   }
//   openSnackBar(message: string, action: string) {
//     this._snackBar.open(message, action, {
//       duration: 2000,
//     });
//   }
//   createFormGroup() {
//     return new FormGroup({
//       Id: new FormControl(),
//       Name: new FormControl('', Validators.required),
//     });
//   }
//   initializeFormGroup() {

//   }
//   onClose() {
//     this.DataForm.reset();
//     this.initializeFormGroup();
//     this.dialogRef.close();
//   }
//   onClear(){

//   }
// }