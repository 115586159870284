import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AdminService } from '../admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { ModuleCrudComponent } from './module-crud/module-crud.component';
import { ModuleUserComponent } from './module-user/module-user.component';
import { ModuleRoleComponent } from './module-role/module-role.component';


@Component({
  selector: 'app-module-management',
  templateUrl: './module-management.component.html',
  styleUrls: ['./module-management.component.css']
})
export class ModuleManagementComponent implements OnInit {

  massage: string;
  datalist: any = [];
  loading: boolean = false;
  repmodel: any = {};
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  filtertext: string;

  constructor(
    public dialog: MatDialog,
    private _http: HttpClient,
    private service: CommonService,
    private hardfunc: HardFuncService,
    private notificationService: NotificationService,
    private adminservice: AdminService,
   ) { }

  ngOnInit() {
  }

  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['Wmodule_id' , 'Wmodule_nm' , 'Url','Parent_id', 'Action'];
  }

  getData() {
    this.loading = true;
    this.datalist = [];

    this.adminservice.getAllModules()
      .subscribe(
        (data: any) => {
          this.datalist = data;
          this.loading = false;
          this.dataSource = new MatTableDataSource(<any>this.datalist);
          this.DisplayCol();
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
        }
      )
  }
  openDialog(Wmodule_id: number): void {
    const dialogRef = this.dialog.open(ModuleCrudComponent, {
      width: '528px',
      data: {id:Wmodule_id}   
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  openDeleteRole(Wmodule_id: number): void {
    this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
    .afterClosed().subscribe(res =>{
      if(res){
        this.loading = true;
        const dialogRef = this.adminservice.DeleteModule(Wmodule_id)
        .subscribe(
          (data:any)=>{
            this.loading = false;
            this.notificationService.success("Data deleted succefully");
            this.getData();
          },
          err => {
            this.loading = false;
            let errstr =  this.hardfunc.getError(err);
            console.log(err);
            this.notificationService.warn(errstr);
          }
        )
        
        // this.notificationService('Deleted Successfully');
      }
    });
  }

  openUserModuleDialog(Wmodule_id: number , Wmodule_nm:string): void {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    let w = '90%';
    let h = '80%';
    if (isSm )
    {
       w = '100%';
       h = '100%';
    }
    const dialogRef = this.dialog.open(ModuleUserComponent, {
      width: w,
      maxWidth:w,
      height: h,
      data: { type:'M', id: Wmodule_id , nm : Wmodule_nm }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  openUserRoleDialog(Wmodule_id: number , Wmodule_nm:string): void {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    let w = '90%';
    let h = '80%';
    if (isSm )
    {
       w = '100%';
       h = '100%';
    }
    const dialogRef = this.dialog.open(ModuleRoleComponent, {
      width: w,
      maxWidth:w,
      height: h,
      data: { type:'M', id: Wmodule_id , nm : Wmodule_nm }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

 
}
