import { Component, OnInit } from '@angular/core';
import { ManufacturingService } from '../../manufacturing.services';
import { CommonService } from 'src/app/Services/Common/common.service';
import { MatSnackBar } from '@angular/material';
import * as XLSX from 'xlsx';

interface Data1 {
  key: string;
  value: string;
}
@Component({
  selector: 'app-pmatrixpivot',
  templateUrl: './pmatrixpivot.component.html',
  styleUrls: ['./pmatrixpivot.component.css']
})
export class PmatrixpivotComponent implements OnInit {

  loading: boolean = false;
  model: any = {};
  repmodel: any = {};
  PrdGroupList: any = [];
  matrixpivot: any = [];
  dataSource: Data1[] = [];
  displayedColumns: string[] = ['key', 'value'];
  fileName= 'PrdGroupExcel.xlsx';
  filtertext: string = "";

  constructor(private mnfservice: ManufacturingService,
    private _cservice: CommonService,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.loading = false;
    this.PrdGroup();
    this.model.Grp_id = 15;
    this.GetData();
  }
  GetData() {
    this.mnfservice.GetMatrixPivot(this.model.Grp_id)
      .subscribe((data: any) => {


        this.matrixpivot = data;

        // const newdata: Data1[] = [];
        // // for (var prop in this.matrixpivot) {
        // //   console.log(prop);

        // // newdata.push({key: prop,
        // //               value: this.matrixpivot[prop]                     
        // // });
        // // } 
        // for (let obj of this.matrixpivot) {
        //     console.log(obj);
        //   // for (let [key,value] in obj) {
        //   //   //console.log(value);
        //   //   newdata.push({key,value: [key]})

        //   // }
        //   for (let key in obj) {
        //     newdata.push({ key: key, value: obj[key] });
        //   }
          
        // }
        // this.dataSource = newdata;
        // console.log(this.dataSource);
      });
  }
  PrdGroup() {
    this.repmodel.CG = 'A';
    this.repmodel.Status = 'P';
    this._cservice.PrdGroupListAll()
      .subscribe(data => {
        this.PrdGroupList = data;
        this.loading = false;
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }
  exportexcel(): void
  {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
    /* save to file */  
    XLSX.writeFile(wb, this.fileName);
 
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
