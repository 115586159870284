import { Component, OnInit, Optional, Inject } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MatSnackBar, MatPaginator, MatDialog } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Sort, MatTableDataSource } from '@angular/material';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-party-os',
  templateUrl: './party-os.component.html',
  styleUrls: ['./party-os.component.css']
})
export class PartyOsComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  repmodel: any = {};
  OrdData: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  DataForm: FormGroup;

  constructor(private hardfunc: HardFuncService,
              private _http: HttpClient,
              private MatSnackbar: MatSnackBar,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private fb: FormBuilder) { }

  ngOnInit() {
    this.getData();
    this.DataForm = this.createFormGroup();
  }
  DisplayCol() {
		let isSm = window.matchMedia(`(max-width:500px)`).matches;
		this.displayedColumns = ['Invno', 'InvDts','Ordno','Invamt','Items','Balamt','DueDates','Paynote','Person','Dname'];
	}
  
  getData() {
		this.loading = true;
    this.datalist = [];
    let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/GetDetail1";
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    this.repmodel.Pmst_cod = this.ReceivedData.PMST_COD;
    this._http.post(url, this.repmodel, { headers: headers })
      .subscribe((data1: any) => {
          
          let g_total = 0;
          let gt_Invamt = 0;
          let gt_BalanceOS = 0;

          data1.map(m=> {
					
            let s_total = 0;
            gt_Invamt += m.Invamt;	
            gt_BalanceOS += m.Balamt;
            // Sub Data 
            m.Subdatas.map(l=>{
              l.Header = 0;
              s_total += l.Paymrcd;			
              g_total += l.Paymrcd;	
              	
            });
            if(s_total != 0){
              m.Subdatas.push({Header: 1,
                Paymrcd:s_total,
              });		
            }
            
          });
         
          data1.push({
            Invamt: gt_Invamt,
            Balamt: gt_BalanceOS,
            Subdatas:[{
              Header:2,
              Paymrcd:g_total
            }],
            Header: 2
          })
				this.datalist = data1;
				this.loading = false;
				this.dataSource = new MatTableDataSource(<any>this.datalist);
				//this.dataSource.paginator = this.paginator;
				this.DisplayCol();
				},
				err => {
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description, "dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					}
				}
			)
	}
  createFormGroup() {
    return this.fb.group({
      Outstanding: new FormControl('O'),
    });
  }
  openSnackBar(message: string, action: string) {
    this.MatSnackbar.open(message, action, {
      duration: 2000,
    });
  }

}
