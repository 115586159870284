import { Routes } from '@angular/router';
import { CodemastComponent } from './codemast/codemast.component';
import { GstmastComponent } from "./gstmast/gstmast.component";
import { Pdfviewer1Component } from './pdfviewer1/pdfviewer1.component';
import { AppInboxComponent } from './email/app-inbox.component';
import { MailComposeComponent } from './email/mail-compose.component';
import { BankEntryComponent } from './bank-entry/bank-entry.component';


 export const GeneralRoutes: Routes = [
      {
        path: 'codemast',
        component: CodemastComponent,
        data: { title: 'Partywise GST' }
      },
      {
        path: 'gstmast',
        component: GstmastComponent,
        data: { title: 'GST Master' }
      },
      
      {
        path: 'pdfviewer1',
        component: Pdfviewer1Component,
        data: { title: 'PDF Viewer... ' }
      },
        {
          path: 'AppInbox',
          component: AppInboxComponent,
          data: { title: 'Inbox... ' }
        },
          {
            path: 'MailCompose',
            component: MailComposeComponent,
            data: { title: 'Mail Compose... ' }
          },
          {
            path: 'bank-entry',
            component: BankEntryComponent,
            data: { title: 'Bank-entry ' }
          }
      
 ];
