import {Routes} from '@angular/router';
import { Component } from '@angular/core';
import { PendordComponent } from './pendord/pendord.component';
import { PdfviewerComponent } from './pdfviewer/pdfviewer.component';
import { InvviewerComponent } from './invviewer/invviewer.component';
import { OrderComponent } from './order/order.component';
import { ReturnComponent } from './return/return.component';
import { EnqListComponent } from './Enq/Enq-list.component';
import { OrdDetComponent } from './ord-det/ord-det.component';
import { OsComponent } from './os/os.component';
import { OsdetailComponent } from './osdetail/osdetail.component';
import { AcgrpComponent } from './acgrp/acgrp.component';
import { EnqreportsComponent } from './enqreports/enqreports.component';
import { TemplateDrivenComponent } from './template-driven/template-driven.component';
import { PdcListComponent } from './pdc/Pdc-list.component';
import { PdcManComponent } from './pdc/pdc-man.component';
import { AuthGuard } from 'src/app/Services/auth/auth.guard';
import { EmdComponent } from './emd/emd.component';
import { BgComponent } from './bg/bg.component';
import { BankGuaranteeComponent } from './bg/bank-guarantee/bank-guarantee.component';
import { EmdManComponent } from './emd/emd-man/emd-man.component';
import { EnquiryComponent } from './Enq/enquiry/enquiry.component';
import { EnqComponent } from './Enq/enq/enq.component';
import { OS1Component } from './os1/os1.component';
import { PendordAttachComponent } from './pendord/pendord-attach/pendord-attach.component';
import { FDRComponent } from './fdr/fdr.component';
import { FdrManComponent } from './fdr/fdr-man/fdr-man.component';
import { FactransManComponent } from './fac-trans-crud/factrans-man/factrans-man.component';
import { StoreManComponent } from './store-prod/store-man/store-man.component';
import { GlTransComponent } from './gl-trans/gl-trans.component';
import { SalesMoniterComponent } from './sales-moniter/sales-moniter.component';
import { CrComponent } from './cr/cr.component';
import { QuoteCrudComponent } from './quote-crud/quote-crud.component';
import { PackingListComponent } from './packing-list/packing-list.component';
import { SalesStatisticsComponent } from './sales-statistics/sales-statistics.component';
import { RejectionMngComponent } from './rejection-mng/rejection-mng.component';
import { CrEntryComponent } from './cr-entry/cr-entry.component';
import { ProductListComponent } from './store-prod/product-list/product-list.component';

export const SalesRoutes : Routes =[

    {
        path: 'pendord',
        component: PendordComponent,
        data: { title: 'Pendord' }
    },
    {
        path: 'pdfviewer/:DocuType/:FileName',
        component: PdfviewerComponent,
        data: { title: 'PDF Viewer... ' }
    },
    {
        path: 'pdfviewer/:Ordno/:Oloc/:DocuType',
        component: PdfviewerComponent,
        data: { title: 'PDF Viewer... ' }
    },
    {
        path: 'pdfviewer/:Firmx/:Yrx/:Ordno/:Oloc/:DocuType',
        component: PdfviewerComponent,
        data: { title: 'PDF Viewer... ' }
    },
    {
        path: 'PendordAttachComponent',
        component: PendordAttachComponent,
        data: { title: 'Attachment... ' }
    },
    {
        path: 'invviewer/:DocuType/:Exr/:Invno',
        component: InvviewerComponent,
        data: { title: 'PDF Viewer... ' }
    },
    {
        path: 'invviewer/:DocuType/:Firmx/:Yrx/:Exr/:Invno',
        component: InvviewerComponent,
        data: { title: 'PDF Viewer... ' }
    },
    {
        path: 'pdfviewer',
        component: PdfviewerComponent,
        data: { title: 'PDF Viewer... ' }
    },
    {
        path: 'enquiry',
        component: EnqComponent,
        data: { title: 'Enquiry... ' }
    },
    {
        path: 'order',
        component: OrderComponent,
        data: { title: 'Order... ' }
    },
    {
        path: 'os',
        component: OsComponent,
        data: { title: 'Order... ' }
    },
    {
        path: 'invviewer',
        component: InvviewerComponent,
        data: { title: 'return... ' }
    },
    {
        path: 'orddet/:Ordno/:Oloc/:Xirmx/:Xrx',
        component: OrdDetComponent,
        data: { title: 'return... ' }
    },
    {
        path: 'orddet/:Ordno/:Oloc/:Xirmx',
        component: OrdDetComponent,
        data: { title: 'return... ' }
    },
    {
        path: 'orddet/:Ordno/:Oloc',
        component: OrdDetComponent,
        data: { title: 'return... ' }
    },
    {
        path: 'orddet/:Ordno',
        component: OrdDetComponent,
        data: { title: 'return... ' }
    },
    {
        path: 'orddet',
        component: OrdDetComponent,
        data: { title: 'return... ' }
    },
    {
        path: 'osdetail/:Pmst_cod',
        component: OsdetailComponent,
        data: { title: 'return... ' }
    },
    {
        path: 'acgrp',
        component: AcgrpComponent,
        data:{title:'acgrp..'}
    },
    {
        path: 'enqreports',
        component: EnqreportsComponent,
        data:{title:'acgrp..'}
    },
    {
        path: 'template-driven',
        component: TemplateDrivenComponent,
        data:{title:'acgrp..'}
    },
    {
        path: 'pdc',
        component: PdcManComponent,
        canActivate:[AuthGuard],
        data:{Title:'PDC..' ,Wmodule_id:1040}
    },
    {
        path: 'emd',
        component: EmdComponent,
        data:{Title:'EMD..'}
    },
    {
        path: 'bg',
        component: BgComponent,
        data:{Title:'BG..'}
    },
    {
        path: 'bank-guarantee',
        component: BankGuaranteeComponent,
        data:{Title:'Bank-Guarantee..'}
    },
    {
        path: 'emd-man',
        component: EmdManComponent,
        data:{Title:'Emd-Man..'}
    },
    {
        path: 'os1',
        component: OS1Component,
        data:{Title:'OS1'}
    },
    {
        path: 'FDR',
        component: FDRComponent,
        data:{Title:'FDR'}
    },
    {
        path: 'fdr-man',
        component: FdrManComponent,
        data:{Title:'FDR-man'}
    },
    // {
    //     path: 'factrans-man',
    //     component: FactransManComponent,
    //     data:{Title:'Factrans-man'}
    // },
    {
        path: 'factrans-man/:Type',
        component: FactransManComponent,
        data:{Title:'Factrans-man'}
    },
    {
        path: 'store-prod',
        component: ProductListComponent,
        data:{Title:'Store-prod'}
    },
    {
        path: 'gl-trans',
        component: GlTransComponent,
        data:{Title:'gl-trans'}
    },
    {
        path: 'sales-moniter',
        component: SalesMoniterComponent,
        data:{Title:'sales-moniter'}
    },  
    {
        path: 'cr',
        component: CrComponent,
        data:{Title:'cr'}
    },
    {
        path: 'Quote-crud',
        component: QuoteCrudComponent,
        data:{Title:'Quote-crud'}
    },
    {
        path: 'Quote-crud/:Enqno',
        component: QuoteCrudComponent,
        data:{Title:'Quote-crud'}
    },   
    {
        path: 'packing-list',
        component: PackingListComponent,
        data:{Title:'packing-list'}
    }, 
    {
        path: 'sales-statistics',
        component: SalesStatisticsComponent,
        data:{Title:'sales-statistics'}
    }, 
    {
        path: 'rejection-mng',
        component: RejectionMngComponent,
        data:{Title:'rejection-mng'}
    },   

];