import { Component, OnInit, Inject, Optional } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
@Component({
  selector: 'app-fusermast-createuser',
  templateUrl: './fusermast-createuser.component.html',
  styleUrls: ['./fusermast-createuser.component.css']
})
export class FusermastCreateuserComponent implements OnInit {

  loading: boolean = false;
  DataForm: FormGroup;

  constructor(private fb: FormBuilder,
              private adminservice: AdminService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private hardfunc: HardFuncService,
              private notificationservice: NotificationService) { }

  ngOnInit() {
    this.loading = true;
    this.DataForm = this.createFormGroup();
    this.GetDatabyId();
  }
  SaveData(){
    this.loading = true;
    console.log(this.DataForm.value);
    this.adminservice.Createfusermast(this.DataForm.value)
      .subscribe((data: any) => {
        console.log(data);
        this.loading = false;
        this.notificationservice.success("Data Saved Successfully...")
      });
      err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.notificationservice.warn(errstr);
        this.loading = false;
      }
  }
  GetDatabyId() {
    if (this.ReceivedData) {
      if (this.ReceivedData.id == -1) {
        //this.title = "ADDITION";
      }
      else {
        this.adminservice.GetDatafusermast(this.ReceivedData.id)
          .subscribe(
            (data: any) => {
              data.Username = data.Username.trim();
              data.Password = data.Password.trim();
              this.DataForm.patchValue(data);
              return false;
            },
            err => {
              let errstr = this.hardfunc.getError(err);
              this.notificationservice.warn(errstr);
            }
          )
      }
    }
    else {
      this.ReceivedData = {};
      this.ReceivedData.id = -1;
      //this.title = "ADDITION";
    } 
  }  
  createFormGroup(){
    return this.fb.group({
      Pk_id: new FormControl(-1),
      Username: new FormControl('',Validators.maxLength(10)),
      Password: new FormControl('',Validators.maxLength(10)),
      Passcng_dt: new FormControl(new Date()),
      Max_login: new FormControl(0)
    });
  }
}
