import { Component, OnInit } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { ManufacturingService } from '../manufacturing.services';
import { MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import * as   XLSX from 'xlsx';
import * as moment from 'moment';
import {ThemePalette} from '@angular/material/core';

export interface Task {
  name: string;
  Checkbox: boolean;
  color: ThemePalette;
}
@Component({
  selector: 'app-report-sample',
  templateUrl: './report-sample.component.html',
  styleUrls: ['./report-sample.component.css']
})
export class ReportSampleComponent implements OnInit {

  loading: boolean = false;
  reportdata: any = [];
  DataForm: FormGroup;
  repmodel: any = {};
  Department: any = [];
  PrdGroupList: any = [];
  ItemName: any = [];
  ItemListdata: any = [];
  Stock_type: any = [];
  filename:string = 'Report.xlsx';
  constructor(private hardfunc: HardFuncService,
    private manufacservice: ManufacturingService,
    private MatSnackbar: MatSnackBar,
    private fb: FormBuilder,
    private _http: HttpClient,
    private _cservice: CommonService,
    private NotificationService: NotificationService) { }

  ngOnInit() {
    this.loading = true;
    this.GetDeptName();
    this.ItemGroup();
    this.GetStock_Type();
    this.repmodel.Type = 'D';
    this.DataForm = this.createFormGroup();
    this.ReportSample();
  }
  //Report Sample -- Detail
  ReportSample() {    
    let data = {
      Firm: this.hardfunc.Firmx,
      Fdate: this.hardfunc.hsConvDt(this.DataForm.controls['Fdate'].value),
      Tdate: this.hardfunc.hsConvDt(this.DataForm.controls['Tdate'].value),
      Dept_id: this.DataForm.controls['Dept_id'].value,
      Grp_nm: this.DataForm.controls['Grp_nm'].value,
      // Product_id: this.DataForm.controls['Product_id'].value,
      TranType: this.DataForm.controls['TranType'].value,
      Division: this.DataForm.get("Division").value      
    }
    this.manufacservice.InventoryReports(data)
      .subscribe((data: any) => {        
        data.map(d => {
          if(d.Edit_dt){
            d.Edit_dt = moment(d.Edit_dt).format("DD/MM/yyyy HH:mm:ss");
          } 
          if(d.Create_dt){
            d.Create_dt = moment(d.Create_dt).format("DD/MM/yyyy HH:mm:ss");
          }          
          d.Tdate = moment(d.Tdate).format('DD/MM/yyyy');
          d.Op_Stock = Math.round(d.Stock + d.Issue - d.Receipt);
          
        });
        this.reportdata = data;
      },
        err => {
          if (err.error.error_description) {
            this.opensnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.opensnackBar("Error in getting data, Please Check Server Connectivity...", "dismiss")
          }
        });
  }

  DownloadPDF(){
    let data = {
      Firm: this.hardfunc.Firmx,
      Fdate: this.hardfunc.hsConvDt(this.DataForm.controls['Fdate'].value),
      Tdate: this.hardfunc.hsConvDt(this.DataForm.controls['Tdate'].value),
      Dept_id: this.DataForm.controls['Dept_id'].value,
      Grp_nm: this.DataForm.controls['Grp_nm'].value,
      // Product_id: this.DataForm.controls['Product_id'].value,
      TranType: this.DataForm.controls['TranType'].value,
      Division: this.DataForm.get("Division").value   
    }
    this.manufacservice.InvReportsPDF(data)
      .subscribe((data: any) => {
        this.loading = false;					 
					const data1 = window.URL.createObjectURL(data);

					var link = document.createElement('a');
					link.href = data1;
					link.target = "_blank";
					link.download = "Inventory-Report.pdf";
					link.click();
					setTimeout(function () {
						// For Firefox it is necessary to delay revoking the ObjectURL
						window.URL.revokeObjectURL(data1);
					}, 100);
      },
        err => {
          if (err.error.error_description) {
            this.opensnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.opensnackBar("Error in getting data, Please Check Server Connectivity...", "dismiss")
          }
        });
  }
  AllDeptPdf(){
    let data = {      
      Fdate: this.hardfunc.hsConvDt(this.DataForm.controls['Fdate'].value),
      Tdate: this.hardfunc.hsConvDt(this.DataForm.controls['Tdate'].value),
      Dept_id: this.DataForm.controls['Dept_id'].value,
      Grp_nm: this.DataForm.controls['Grp_nm'].value,
      // Product_id: this.DataForm.controls['Product_id'].value,
      TranType: this.DataForm.controls['TranType'].value,
      Division: this.DataForm.get("Division").value   
    }
    this.manufacservice.AllDeptPdf(data)
      .subscribe((data: any) => {
        this.loading = false;
					 console.log(data);
					 const data1 = window.URL.createObjectURL(data);

					var link = document.createElement('a');
					link.href = data1;
					link.target = "_blank";
					link.download = "Inventory-Report.pdf";
					link.click();
					setTimeout(function () {
						// For Firefox it is necessary to delay revoking the ObjectURL
						window.URL.revokeObjectURL(data1);
					}, 100);
      },
        err => {
          if (err.error.error_description) {
            this.opensnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.opensnackBar("Error in getting data, Please Check Server Connectivity...", "dismiss")
          }
        });
  }
  GetDeptName() {
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/deptssAPI/GetDatas";
    this._http.post(url, this.repmodel)
      .subscribe((data1: any) => {
        this.Department = data1;
      }, err => {
        console.log(err);
      });
  }
  ItemGroup() {
    this.repmodel.CG = 'A';
    this.repmodel.Status = 'P';
    this._cservice.PrdGroupListAll()
      .subscribe(data => {        
        this.PrdGroupList = data;
        this.loading = false;
      },
        err => {
          console.log(err);
          this.opensnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }
  GetItemlist(grp_id: number) {
    this.repmodel.Grp_id = grp_id;
    console.log(this.repmodel.grp_id);

    this.manufacservice.ProductDetails(this.repmodel.Grp_id)
      .subscribe((data: any) => {        
        this.ItemListdata = data;
      });
  }
  GetStock_Type() {
    this.loading = true;
    this._cservice.CodeList('Stock_type')
      .subscribe(
        (data: any) => {
          this.Stock_type = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn(errstr);
        }
      );
  }
  exportexcel(): void {
    // Option-2 Entire JSON
    const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reportdata);
    
    this.reportdata.map(m => {
      m.Product_nm = m.Product_id + " - " + m.Product_nm;
    });

    // Option-3 Selected fields from JSON
    let newData = this.reportdata.map(({ Tdate, Product_nm, TranType, Qnty, Op_Stock, Receipt, Issue, Stock, Unit, Sender_dept }) =>
      ({ Tdate, Product_nm, TranType, Qnty, Op_Stock, Receipt, Issue, Stock, Unit, Sender_dept }));
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.filename);
  }
  opensnackBar(message: string, action: string) {
    this.MatSnackbar.open(message, action, {
      duration: 2000,
    });
  }
  createFormGroup() {
    return this.fb.group({
      Fdate: new FormControl(new Date()),
      Tdate: new FormControl(new Date()),
      Dept_id: new FormControl("All"),
      Grp_nm: new FormControl('*All*'),
      // Product_id: new FormControl(-1),
      TranType: new FormControl(''),   
      Division: new FormControl("*All*"),
    });
  }
}
