import { Component, OnInit, Inject, Optional } from '@angular/core';
import { ManufacturingService } from '../manufacturing.services';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

interface Tree {
	root: TreeNode;
}
 
interface TreeNode {
	label: string;
	children: TreeNode[];
}

@Component({
  selector: 'app-bom',
  templateUrl: './bom.component.html',
  styleUrls: ['./bom.component.css']
})
export class BOMComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  OrdData: any = [];
  msgFromBOM1 = [];
  
  public data: Tree;
	public selectedTreeNode: TreeNode | null;

  constructor(private Manufacturing: ManufacturingService,
              private hardfunc: HardFuncService,
              private notificationService: NotificationService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,) { }

  ngOnInit() {
    this.GetData();
  }
  
  msgToParent(no_of_msg: number) {
    this.msgFromBOM1.push(no_of_msg);
  }

  GetData() {
    this.loading = true;
		this.datalist = [];
    if (this.ReceivedData) {
      this.Manufacturing.GetBOM(this.ReceivedData.Product_id,this.ReceivedData.Product_nm,this.ReceivedData.Qnty)
        .subscribe(
          (data1: any) => {
            console.log(data1);
            let P_name = '###';
            this.OrdData = [];
            
            data1.map(m => {
              console.log(m);
              if (m.En === 'Y'){
                m.Check= true;
            }
              
            if(m.Fprod_id === this.ReceivedData.Product_id)
            {
              m.ShowYN = true;
              m.Expand = false;		
            }
            else
            {
              m.ShowYN = false;
              m.Expand = false;		
            }
            m.Header = 1;
            this.OrdData.push(m);             
          })
          },
            err => {
              let errstr = this.hardfunc.getError(err);
              this.notificationService.warn(errstr);
            }
          )
    }
  }

  StockYN(Product_id: number, yesno:boolean){
      let str1 : string 
        if (yesno === true){
          str1 = 'Y';
        }else{
          str1 = 'N';
        }
        this.loading = true;
        const dialogRef = this.Manufacturing.Ignore_Items(Product_id,str1)
        .subscribe(
          data => {
            this.notificationService.success(" Data saved Successfully... ");
          },
          err => {
            console.log(err);
            this.notificationService.warn("Error in deleting data ...");
          }
        )
  }
 
  Expand(data){
	console.log(data);
	data.Expand = !data.Expand;
	data.ChildData.map(m => {
    if (m.En === 'Y'){
      m.Check= true;
    }
		m.Expand = false;
		m.ShowYN = data.Expand;
	});

  }
  
}
