import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-po-man',
  templateUrl: './po-man.component.html',
  styleUrls: ['./po-man.component.css']
})
export class PoManComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
