import { Routes } from '@angular/router';
import { DashboardInventoryComponent } from './dashboard-inventory/dashboard-inventory.component';
import { FixStockComponent } from './fix-stock/fix-stock.component';
import { GatepassImportComponent } from './gatepass-import/gatepass-import.component';
import { GrnListComponent } from './grn-list/grn-list.component';
import { Grn1ListComponent } from './grn1-list/grn1-list.component';
import { PparaCrudComponent } from './ppara-crud/ppara-crud.component';
import { PrdctnComponent } from './prdctn/prdctn.component';
import { PrdgroupCrudComponent } from './prdgroup-crud/prdgroup-crud.component';
import { PmatrixpivotComponent } from './stk-repo/pmatrixpivot/pmatrixpivot.component';
import { StkRepoComponent } from './stk-repo/stk-repo.component';
import { StkrepallComponent } from './stk-repo/stkrepall/stkrepall.component';
import { Stkrepall1Component } from './stk-repo/stkrepall1/stkrepall1.component';
import { ReportSampleComponent } from './report-sample/report-sample.component';
import { ProductSalesComponent } from './product-sales/product-sales.component';


export const ManufacturingRoutes: Routes = [
  {
    path: 'grn-list',
    component: GrnListComponent,
    data: { title: 'grn-list' }
  },
  {
    path: 'grn1-list',
    component: Grn1ListComponent,
    data: { title: 'grn1-list' }
  },
  {
    path: 'Stk-Repo',
    component: StkRepoComponent,
    data: { title: 'Stk-Repo' }
  },
  {
    path: 'gatepass-import',
    component: GatepassImportComponent,
    data: { title: 'gatepass-import' }
  },
  {
    path: 'Prdctn',
    component: PrdctnComponent,
    data: { title: 'Prdctn' }
  },
  {
    path: 'Stkrepall',
    component: StkrepallComponent,
    data: { title: 'Stkrepall' }
  },
  {
    path: 'Stkrepall1',
    component: Stkrepall1Component,
    data: { title: 'Stkrepall1' }
  },
  {
    path: 'Dashboard-Inventory',
    component: DashboardInventoryComponent,
    data: { title: 'Dashboard-Inventory' }
  },
  {
    path: 'fix-stock',
    component: FixStockComponent,
    data: { title: 'fix-stock' }
  },
  {
    path: 'Ppara',
    component: PparaCrudComponent,
    data: { title: 'Ppara' }
  },
  {
    path: 'PrdGroup',
    component: PrdgroupCrudComponent,
    data: { title: 'PrdGroup' }
  },
  {
    path: 'Pmatrixpivot',
    component: PmatrixpivotComponent,
    data: { title: 'Pmatrixpivot' }
  },
  {
    path: 'report-sample',
    component: ReportSampleComponent,
    data: { title: 'report-sample' }
  },
  {
    path: 'Product-sales',
    component: ProductSalesComponent,
    data: { title: 'Product-sales' }
  }

];
