import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';

@Injectable({
  providedIn: 'root'
})
export class StoreProductService {

  constructor(private hardfunc: HardFuncService, private http: HttpClient) { }

  getDatas(prdgrp_id: string) {
		let data: any = {};
		data.Prodgrp_id = prdgrp_id;
		var headers = new HttpHeaders({
			'content-Type': 'application/json',
			'process-Data': 'false',
			'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
		  })
		// data.Firmx = this.hardfunc.Firmx;
		// data.Yrx = this.hardfunc.Yrx;
		
		let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/GetProductList";
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}

	// Ignore_Items(Itcode:number,str1:string){
	// 	let data: any = {};
	// 	data.itcode = Itcode;
	// 	data.str1 = str1;
	// 	let url = this.hardfunc.BaseAPIUrl+"api/ProductsAPI/ChangeStatus?itcode="+Itcode+"&En="+str1;
	// 	return this.http.get(url, { headers: this.getheaders});
	//   }
}
