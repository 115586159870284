import { Component, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { HardFuncService } from '../Common/hardfunc.service';


@Injectable()
export class AuthGuard implements CanActivate {
  public authToken;
  private isAuthenticated = true;

  constructor(private router: Router,
    private adminservice : AdminService,
    private hardfunc: HardFuncService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log("* ======= CanActive Called =========== * ")
    var permission = this.hardfunc.GetLocalPermissionbyModule_id(route.data.Wmodule_id ) || "N";
    if (permission.substring(0,1) === 'Y' )
    {
      return true;
    }
    else
    {
      //this.router.navigate([""],{ queryParams: { retUrl: route.url} });
      this.router.navigate([""]);
      return false;
    }
  }

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //   return new Observable<boolean>(obs => {
  //     this.adminservice.GetPermissionbyUserModule_id(this.hardfunc.UserName,route.data.wmodule_id)
  //     .subscribe(
  //        data => {
  //          if (data !== 1) {
  //     //       this.router.navigateByUrl('/teamlanding/' + next.params.id);
  //           obs.next(false);
  //          }
  //          else {
  //            obs.next(true);
  //          }
  //        }
  //     );
  //   });
  // }
}
