import { Component, OnInit,Inject  } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { Observable } from 'rxjs';
import { debug } from 'util';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesService } from '../sales.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { OsComponent } from '../os/os.component';
import { MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-osdetail',
  templateUrl: './osdetail.component.html',
  styleUrls: ['./osdetail.component.css']
})
export class OsdetailComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  dataSource: any = [];
  cols: any[];
  repmodel: any = {};
  Party:any= {};
  msgs: any[] = [];
  t_amt1:number = 0;
  t_amt2:number = 0;
  t_amt3:number = 0;
  displayedColumns: string[] = [];
  msg:string = 'OS Details Not Found!';
  constructor(
    private _http: HttpClient,
    private hardfunc: HardFuncService,
    public dialogRef: MatDialogRef<OsComponent>,
    @Inject(MAT_DIALOG_DATA) public receiveddata: any,
    private _cservice: CommonService,
    private _router:Router,
    private sal_service: SalesService,
    public dialog: MatDialog,
    private _snackBar:MatSnackBar,
    private route : ActivatedRoute
  ) { }

  ngOnInit() {
    this.loading = true;
    this.Party = this.receiveddata;
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    if (isSm)
    {
      this.displayedColumns = [ 'SMALL'];
    }
    else
    {
      this.displayedColumns = ['INVNO','ORDNO','INVDATE','INVAMT','PAYMRCD','BALAMT',"REMARKS"];
    }
    this.getData();
  }

  getData() {
    this.loading = true;
    let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/GetDetail";
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    this.repmodel.Pmst_cod = this.Party.PMST_COD;
    this._http.post(url, this.repmodel, { headers: headers })
      .subscribe((data1: any) => {
       if (data1){
        this.dataSource = data1;
        this.t_amt1 = 0;
        this.t_amt2 = 0;
        this.t_amt3 = 0;
        this.dataSource.map(data => {
          this.t_amt1 += data.INVAMT;
          this.t_amt2 += data.PAYMRCD;
          this.t_amt3 += data.BALAMT;
        })
      }
      else{
        this.msgs;
      }
        //this.datalist.push({EXR:"Total", INVAMT:t_amt1, PAYMRCD: t_amt2,BALAMT: t_amt3 });
        this.loading = false;
      },
        err => {
          if (err.error.error_description)
            {
              this.openSnackBar(err.error.error_description,"dismiss");
            }
            else
            {
              this.openSnackBar("Check Server Connectivity ","dismiss");
            }
          this.loading = false;
        });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}



