import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { GlTransService } from '../gl-trans.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { GlTransComponent } from '../gl-trans.component';

@Component({
  selector: 'app-gl-tran-crud',
  templateUrl: './gl-tran-crud.component.html',
  styleUrls: ['./gl-tran-crud.component.css']
})
export class GlTranCrudComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  DataForm: FormGroup;
  constructor(private fb: FormBuilder,
              private glTranService: GlTransService, 
              private notificationService: NotificationService,
              private hardfunc: HardFuncService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              @Optional() public dialogRef: MatDialogRef<GlTransComponent>,) { }

  ngOnInit() {
    this.DataForm = this.createFormGroup();
    this.GetData();
  }
  ngOnDestroy(): void {
    console.clear();
  }

  // SaveGLTran() {   
  // this.glTranService.SaveDesc(this.DataForm.value)
  //   .subscribe(
  //     data => {
  //       this.loading = false;
  //       this.notificationService.success(' Data Saved Successfully... ');
  //     },
  //     err => {
  //       let errstr = this.hardfunc.getError(err);
  //       console.log(err);
  //       this.notificationService.warn(errstr);
  //       this.loading = false;
  //     }
  //   )
  // }
  GetData() {
    this.DataForm.controls.Sql_id.patchValue(this.ReceivedData.id);
    this.DataForm.controls.Descri1.patchValue(this.ReceivedData.Descri1);
    this.DataForm.controls.Firm.patchValue(this.hardfunc.Firmx);
    this.DataForm.controls.Yr.patchValue(this.hardfunc.Yrx);
  }
  createFormGroup() {
    return this.fb.group({
      Sql_id : new FormControl(-1),
      Descri1: new FormControl('',Validators.required),
      Firm: new FormControl(''),
      Yr: new FormControl('')
    });
  }

}
