import { Component, OnInit, ViewChild } from '@angular/core';
import { PurchaseService } from '../purchase.service';
import { Sort, MatTableDataSource } from '@angular/material';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { PartyHelpComponent } from '../../shared/party-help/party-help.component';
import * as moment from 'moment'

@Component({
  selector: 'app-poreg',
  templateUrl: './poreg.component.html',
  styleUrls: ['./poreg.component.css']
})
export class PoregComponent implements OnInit {

  loading: boolean = false;
  DataForm: FormGroup;
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  filteredOptions: any = [];
  PartyData: any = [];
  id: number;
  Indenters: any = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private purchaseservice: PurchaseService,
              private fb: FormBuilder,
              private commonservice: CommonService,
              private hardfunc: HardFuncService,
              private notificationservice: NotificationService,
              private dialog: MatDialog,
              private MatSnackBar: MatSnackBar) { }

  ngOnInit() {
    this.loading = true;
    this.DataForm = this.createFormGroup();
    this.GetData();
    this.GetIndeter();
    // this.DataForm.get('Party_name').valueChanges.subscribe(response => {
    //   this._filter(response);
    // });
    this.GetParty(this.hardfunc.Firmx, this.hardfunc.Yrx);
  }
  DisplayCol(){
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['Pono', 'Podts', 'Indtno', 'PartyName', 'Items'];
  }
  GetData(){
    this.loading = true;
    this.datalist = [];
    var data: any = {};
    let cnd = "";

    cnd += " and a.podt >= convert(datetime,''"+moment(this.DataForm.get("Podts").value).format("DD/MM/yyyy")+"'',103) ";
    cnd += " and a.podt <= convert(datetime,''"+moment(this.DataForm.get("Tdate").value).format("DD/MM/yyyy")+"'',103) ";
    cnd += " and c.pqnty > 0";
    // cnd += " and a.Option = 'D' ";
    if(this.DataForm.get("Indenter").value != "*All*"){
      cnd += " and Indter = '' "+ this.DataForm.get("Indenter").value +" '' "
    }
    data.Cond = cnd;    
    this.purchaseservice.GetPoreg(data)
      .subscribe(data => {        
        this.datalist = data;
        this.datalist.map(d => {
          d.Party_name = d.Party_name.trim();
          d.Party_city = d.Party_city.trim();
        });
        console.log(this.datalist);
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.dataSource.paginator = this.paginator;
        this.DisplayCol();
        this.loading = false;
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        this.notificationservice.warn(errstr);
        this.loading = false;
      });
  }
  GetIndeter() {
    this.loading = true;
    this.Indenters = [];
    
    this.purchaseservice.getIndenterList()
      .subscribe(
        (data: any) => {          
           this.Indenters = data;           
          this.loading = false;
        },
        err => {
          this.loading = false;
          if (err.error.error_description) {
            this.openSnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          }
        }
      )
  }
  //4. api/FinanceAPI/GetPartyList --Get party List
  GetParty(Firm: string, Yr: string) {
    this.loading = true;
    this.commonservice.GetPartyList(Firm, Yr)
      .subscribe(
        (data: any) => {
          this.PartyData = JSON.parse(data);
          this.PartyData = this.PartyData.map((x) => {
            return {
              nm: x.id.trim()+'-'+x.nm.trim() +'-'+ x.Party_city.trim() + '-'+ x.Party_Stat.trim(),
              id: x.id
            }
          });
          //this.PartyData.map((res:[]) => res.map(item => item["PARTY_NAME"]) )
          //this.filteredOptions = this.PartyData;
          this.loading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
          this.loading = false;
        });
  }
  PartywiseInd(){
    this.loading = true;
    var data: any = {};
    let cnd = "  ";
    this.filteredOptions.map(w => {
      console.log(w);
      this.id = w.id;
    });
    if (this.DataForm.value.Party_name.length > 0) {
      cnd += " and a.Pmst_cod = ''" + this.id + "'' ";
      //sql_cond = sql_cond + " and a.Pmst_cod = ''"+thisform.cmbparty.Value + "'' "
    }
    data.Cond = cnd; 
    this.purchaseservice.getPartyWiseInd(data)
      .subscribe((data: any) => {
        console.log(data);
        this.loading = false;
        this.datalist = data;
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.dataSource.paginator = this.paginator;
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        this.notificationservice.warn(errstr);
        this.loading = false;
      });
  }
  
  createFormGroup(){
    return this.fb.group({
      // Party_name: new FormControl(''),      
      Podts: new FormControl(new Date()),
      Tdate: new FormControl(new Date()),
      Indenter: new FormControl("*All*"),
      // Option: new FormControl('D')
    });
  }
  private _filter(value: string) {
    if (!value)
    {
      this.filteredOptions = [];
      return;
    }
    if (value.length < 3) {
      this.filteredOptions = [];
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredOptions = this.PartyData.filter(option => option.nm.toLowerCase().includes(filterValue));
  }
  openSnackBar(message: string, action: string) {
    this.MatSnackBar.open(message, action, {
      duration: 2000,
    });
  }
}

