import { Injectable } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GlTransService {

  constructor(private hardfunc: HardFuncService,private http: HttpClient) { }

  getDatas(data: any){
    data.Firm = this.hardfunc.Firmx;
    data.Year = this.hardfunc.Yrx;
    
    let url = this.hardfunc.BaseAPIUrl+"api/Finance/gl_transAPI/GetList"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }
  SaveGLTran(data: any){
    data.Firm = this.hardfunc.Firmx;
		data.Year = this.hardfunc.Yrx;
		let url = this.hardfunc.BaseAPIUrl + "api/Finance/gl_transAPI/SaveData";
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }

  SaveDesc(Sql_id: number, Descri1: string){
    let data: any = {
      Sql_id: Sql_id,
      Descri1: Descri1,
      Firm: this.hardfunc.Firmx,
		  Yr: this.hardfunc.Yrx,
    };
    
		let url = this.hardfunc.BaseAPIUrl + "api/Finance/gl_transAPI/SaveDesc";
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }

  GetDatabyId(Sql_id: number,Firmx: string,Yrx: string,Descri1: string) {
		let data: any = {};
		data.Firm = Firmx;
		data.Year = Yrx;
    data.Sql_id = Sql_id;
    data.Descri1 = Descri1;
		let url = this.hardfunc.BaseAPIUrl + "api/Finance/gl_transAPI/GetDataId?id="+Sql_id;
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}
}
