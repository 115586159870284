import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { AdminService } from '../../admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FmoduleComponent } from '../fmodule.component';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { CommonService } from 'src/app/Services/Common/common.service';
import { FmoduleRightsComponent } from '../fmodule-rights/fmodule-rights.component';

@Component({
  selector: 'app-fmodule-list',
  templateUrl: './fmodule-list.component.html',
  styleUrls: ['./fmodule-list.component.css']
})
export class FmoduleListComponent implements OnInit {
  
  loading: boolean = false;
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  constructor(private adminservice: AdminService,
              private dialog: MatDialog,
              private notificationservice: NotificationService,
              private hardfunc: HardFuncService,
              private commonservice: CommonService) { }

  ngOnInit() {
    this.loading = true;
    this.getData();
    this.GetUser();
  }
  DisplayCol(){
    this.displayedColumns = ['Pk_id','Grp','Module_id','V_type','Descr','Action'];
  }
  getData(){
    this.loading = true;
    this.adminservice.GetFmoduleData()
      .subscribe((data:any) => {
        console.log(data);
        this.loading = false;
        this.datalist = data;
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DisplayCol();
      });
      err => {
        this.loading = false;
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.notificationservice.warn(errstr);
      }
  }
  openDialog(data: any): void {
		const dialogRef = this.dialog.open(FmoduleComponent, {
			width: '528px',
			data: { id: data.Pk_id, Module_id: data.Module_id, Do_event: data.Do_event, Grp: data.Grp, Vtype: data.V_type }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}
  Deletemodule(Pk_id: number){
    this.adminservice.openConfirmDialog("Are you sure, You want to delete this?")
      .afterClosed().subscribe(res => {
        if(res)
        {
          this.loading = true;
          const dialogRef = this.adminservice.deletemodule(Pk_id)
            .subscribe((data: any) => {
              this.loading = false;
              this.notificationservice.warn("Data deleted Successfully");
              this.getData();
            },
            err => {
              this.loading = false;
              let errstr = this.hardfunc.getError(err);
              console.log(err);
              this.notificationservice.warn(errstr);
            }
            )
        }
      })
  }
  GetUser() {
    this.loading = true;
    this.commonservice.getAllUsers()
      .subscribe(
        (data: any) => {
          //console.log(data);
          // this.UserData = data;
          // this.UserData = this.UserData.map((x) => {
          //   return {
          //     nm: x.UserName,
          //     id: x.Id
          //   }
          // });
          this.loading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
          this.loading = false;
        });
  }
  openUserModuleDialog(data: any){
        let isSm = window.matchMedia(`(max-width: 960px)`).matches;
        let w = '50%';
        let h = '80%';
        if (isSm )
        {
          w = '100%';
          h = '100%';
        }
        const dialogRef = this.dialog.open(FmoduleRightsComponent, {
          width: w,
          maxWidth:w,
          height: h,
          data: { id: data.Module_id, Descr: data.Descr, chk1: data.chk1, chk2: data.chk2, chk3: data.chk3,
                  chk4: data.chk4, chk5: data.chk5, chk6: data.chk6,}
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        });
  }
  openModule(Wmodule_id: number): void {
    const dialogRef = this.dialog.open(FmoduleComponent, {
      width: '528px',
      data: {id:Wmodule_id}   
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
