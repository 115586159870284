import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AdminService } from '../admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
@Component({
  selector: 'app-umodule',
  templateUrl: './umodule.component.html',
  styleUrls: ['./umodule.component.css']
})
export class UmoduleComponent implements OnInit {

  loading: boolean = false;
  DataForm: FormGroup;
  constructor(private fb: FormBuilder,
              private adminservice: AdminService,
              private notificationservice: NotificationService) { }

  ngOnInit() {
    this.DataForm = this.createFormGroup();
  }
  SaveData(){
    this.loading = true;
    console.log(this.DataForm.value);
    this.adminservice.SaveUmodule(this.DataForm.value)
      .subscribe(data => {
        this.loading = false;
        this.notificationservice.success("Data Saved Successfully");
      })
  }
  
  createFormGroup(){
    return this.fb.group({
      Username: new FormControl(''),
      Password: new FormControl('')
    });
  }  

}
