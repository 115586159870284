import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { RouterLinkActive, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invviewer',
  templateUrl: './invviewer.component.html'
})
export class InvviewerComponent implements OnInit {
  src:string = "";
  r1:any={};
  ErrMsg:string = "";

  constructor(
    private _http: HttpClient,
    private hardfunc: HardFuncService,
    private _cservice: CommonService,
    private route : ActivatedRoute
  ) {   }

  ngOnInit() {
    let url = this.hardfunc.BaseAPIUrl + "api/Common/DocuViewerAPI/GetDocu";
    this.route.paramMap
      .subscribe(params => {
          this.r1.exr = params.get('Exr');
          this.r1.invno = params.get('Invno');
          this.r1.DocuType = params.get('DocuType');
          this.r1.Firmx =  params.get('Firmx');
          this.r1.Yrx = params.get('Yrx');
      })
   
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Accept': 'application/json',      
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    this.ErrMsg = "";
    this._http.post(url, this.r1, { headers: headers,  responseType: 'blob' as 'json'  })
      .subscribe(data1 => {
        this.src = window.URL.createObjectURL(data1);
        //this.loading = false;
      },
        err => {
          console.log(err);
          if (err.status == 404){
              this.ErrMsg = "Requested file not found ... ";
          }
          else{
              this.ErrMsg = "Error in getting pdf file "+err.message;
          }
        });
  }
}
