import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsrregComponent} from './usrreg/usrreg.component';
import { AdminRoutes } from './admin.routing';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsrlstComponent} from './usrlst/usrlst.component';
import { MatTableModule, MatInputModule, MatIconModule, MatCardModule, MatMenuModule, MatProgressBarModule, MatButtonModule, MatChipsModule, MatListModule, MatSidenavModule, MatGridListModule, MatTooltipModule, MatToolbarModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatCheckboxModule, MatAutocompleteModule, MatExpansionModule, MatFormFieldModule, MatPaginatorModule, MatDialog, MatDialogModule } from '@angular/material';
import { from } from 'rxjs';
import { AppLoaderModule } from 'src/app/Services/app-loader/app-loader.module';
import { ResetPwdComponent } from './reset-pwd/reset-pwd.component';
import { ChangePwdComponent } from './change-pwd/change-pwd.component';
import { ChangePwdNewComponent } from './change-pwd-new/change-pwd-new.component';
// import { MustMatchDirective } from './change-pwd-new/_helpers/must-match.directive';
import { BrowserModule } from '@angular/platform-browser';
import { RoleManagementComponent } from './role-management/role-management.component';
import { ModuleManagementComponent } from './module-management/module-management.component';
import { RoleCrudComponent } from './role-management/role-crud/role-crud.component';
import { ModuleCrudComponent } from './module-management/module-crud/module-crud.component';
import { DialogComponent } from './role-management/dialog/dialog.component';
import { ModuleListComponent } from './role-management/module-list/module-list.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { RoleUserComponent } from './role-management/role-user/role-user.component';
import { RoleModuleComponent } from './role-management/role-module/role-module.component';
import { ModuleUserComponent } from './module-management/module-user/module-user.component';
import { ModuleRoleComponent } from './module-management/module-role/module-role.component';
import { ProfileComponent } from './profile/profile.component';
import { UserRoleComponent } from './usrlst/user-role/user-role.component';
import { UserModuleComponent } from './usrlst/user-module/user-module.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { RoleCrud1Component } from './role-management/role-crud1/role-crud1.component';
import { FmoduleComponent } from './fmodule/fmodule.component';
import { FmoduleListComponent } from './fmodule/fmodule-list/fmodule-list.component';
import { FmoduleRightsComponent } from './fmodule/fmodule-rights/fmodule-rights.component';
import { UmoduleComponent } from './umodule/umodule.component';
import { UmoduleListComponent } from './umodule/umodule-list/umodule-list.component';
import { FusermastComponent } from './fusermast/fusermast.component';
import { FusermastListComponent } from './fusermast/fusermast-list/fusermast-list.component';
import { FusermastCreateuserComponent } from './fusermast/fusermast-createuser/fusermast-createuser.component';
import { SessionDataComponent } from './session-data/session-data.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DocuRightsComponent } from './module-management/docu-rights/docu-rights.component';
import { CopyrightsComponent } from './fmodule/copyrights/copyrights.component';
//import { MustMatchDirective } from './change-pwd-new/_helpers/must-match.directive';

const material = [
  MatInputModule,
  MatIconModule,
  MatCardModule,
  MatMenuModule,
  MatProgressBarModule,
  MatButtonModule,
  MatChipsModule,
  MatListModule,
  MatSidenavModule,
  MatGridListModule,
  MatTooltipModule,
  MatToolbarModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  MatCheckboxModule

]


@NgModule({
  declarations: [UsrregComponent, UsrlstComponent, ResetPwdComponent,  ChangePwdComponent,
     ChangePwdNewComponent, 
    //  MustMatchDirective, 
     RoleManagementComponent,
      ModuleManagementComponent, 
       ModuleCrudComponent,
        DialogComponent,
         ModuleListComponent, 
         RoleUserComponent,
          RoleModuleComponent,
          ModuleUserComponent,
          ModuleRoleComponent,
          ProfileComponent,
          UserRoleComponent,
          UserModuleComponent,
          AlertDialogComponent,
          RoleCrud1Component,
          RoleCrudComponent,
          FmoduleComponent,
          FmoduleListComponent,
          FmoduleRightsComponent,
          UmoduleComponent,
          UmoduleListComponent,
          FusermastComponent,
          FusermastListComponent,
          FusermastCreateuserComponent,
          SessionDataComponent,
          DocuRightsComponent,
          CopyrightsComponent
        ],
  entryComponents: [UsrregComponent ,
     ResetPwdComponent,
     RoleManagementComponent,
     RoleCrud1Component,
     ModuleCrudComponent,
     DialogComponent,
     ModuleListComponent,
     RoleUserComponent,
     RoleModuleComponent,
     ModuleUserComponent,
     ModuleRoleComponent,
     UserRoleComponent,
     AlertDialogComponent,
     FmoduleComponent,
     FmoduleRightsComponent,
     FusermastComponent,
     FusermastCreateuserComponent,
     CopyrightsComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    MatInputModule,
    material,
    AppLoaderModule,
    CKEditorModule,
    RouterModule.forChild(AdminRoutes)

  ]

})
export class AdminModule { }