import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSnackBar, MatPaginator, MatDialog } from '@angular/material';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray, NgForm } from '@angular/forms';
import { CommonService } from 'src/app/Services/Common/common.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { PartyOsComponent } from './party-os/party-os.component';
import { EditNoteComponent } from './edit-note/edit-note.component';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { Os1Service } from './os1.service';
import { Sorder1EditComponent } from './sorder1-edit/sorder1-edit.component';
import { PagerserviceService } from './pagerservice.service';
import * as   XLSX from 'xlsx';
import * as moment from 'moment'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-os1',
  templateUrl: './os1.component.html',
  styleUrls: ['./os1.component.css']
})
export class OS1Component implements OnInit, OnDestroy {

  loading: boolean = false;
  datalist: any = [];
  repmodel: any = {};
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  OrdData: any = [];
  DataForm: FormGroup;
  StateList: any = [];
  Dir_id: number = 0;
  PARTY_STAT: any = [];
  PtpenordAll: any = [];
  State: string;
  @ViewChild('scroll', { static: true }) scroll: ElementRef;
  FinyrList: any = [];
  FinanYear: any = [];
  Totsal1: number;
  Noti_Array: any = [];
  no_of_msg: number = 0;
  party_name: string;
  MarketingList: any = [];
  fileName: string = 'OutStanding.xlsx'
  // array of all items to be paged
  private allItems: any[];
  OsData: any = [];
  CateClicked: boolean = false;
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  // @ViewChild('f') form: NgForm;

  constructor(private hardfunc: HardFuncService,
              private _http: HttpClient,
              private _snackBar: MatSnackBar,
              private fb: FormBuilder,
              private elementRef: ElementRef,
              private commonservice: CommonService,
              private notificationservice: NotificationService,
              private dialog: MatDialog,
              private adminservice: AdminService,
              private Os1Service: Os1Service,
              private pagerService: PagerserviceService,
              private datepipe: DatePipe) { }
    
  ngOnInit() {
    this.loading = true;
    //this.getData();    
    
    this.getState();
    //this.DataForm = this.createFormGroup();
    this.getFinyr();
    this.getNotification();
    //this.refresh();
    this.getMarketing();
    this.repmodel.Mname = "*All*";
    this.repmodel.State_id = "*All*";
  }
  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);

    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
}
// refresh(){
//             this.datalist = [];
//             let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/GetDetail";
//             var headers = new HttpHeaders({
//               'content-Type': 'application/json',
//               'process-Data': 'false',
//               'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
//             })
//             this.repmodel.Firmx = this.hardfunc.Firmx;
//             this.repmodel.Yrx = this.hardfunc.Yrx;
//             var data1: any = {};
//             //this.repmodel = this.DataForm.value;
//             this._http.post(url, this.repmodel, { headers: headers })
//               .subscribe((data1: any) => { 
                
//                 this.allItems = [];
//                 let P_name = '###';
//                 let TotalInv: number = 0;
//                 let TotalRec: number = 0;
//                 let TotalBal: number = 0;
//                 data1.map(m => {
//                   if (P_name !== m.PMST_COD) {
//                     if (P_name !== '###') {
//                       this.allItems.push({ Header: 2, InvTotal: TotalInv, Receipt: TotalRec, Balance: TotalBal, Lyr_clbal: m.Lyr_clbal });
//                       // Blank Row
//                     }
//                     TotalInv = 0;
//                     TotalRec = 0;
//                     TotalBal = 0;
//                     this.allItems.push({
//                       Header: 1, PMST_COD: m.PMST_COD, party_name: m.party_name, Lyr_clbal: m.Lyr_clbal, ORDNO: m.ORDNO, party_city: m.party_city, OLOC: m.OLOC,
//                       Exr: m.Exr, Invno: m.Invno, PARTY_STAT: m.PARTY_STAT, PERSON: m.PERSON,PERSON1: m.PERSON1, Dname: m.Dname, Mname: m.Mname, Email: m.EMAIL,
//                       Email1: m.EMAIL1, Phone: m.PHONE,Phone1: m.PHONE1, Terms: m.Terms, Narr: m.Narr, Inv_notes: m.Inv_notes, Ord_notes: m.Ord_notes
//                      ,No_of_notes: m.No_of_notes, DueRem: m.DueRem});
//                     P_name = m.PMST_COD
//                   }
//                   m.Header = 0;
//                   this.loading = false;
//                   { this.allItems.push(m); }
                  
//                   TotalInv += m.INVAMT;
//                   TotalRec += m.PAYMRCD;
//                   TotalBal += m.BALAMT;
                  
//                 });
//                 this.setPage(1);
//                 // this.DataForm.controls["Exr"].patchValue(0);
//                 // this.DataForm.controls["Invno"].patchValue(0); 
//               },
//                 err => {
//                   console.log(err);
//                   this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
//                   this.loading = false;
//                 });
// }
  ScrolltoTop() {
    this.scroll.nativeElement.scrollTop = 0;
  }
ngOnDestroy(): void {
  console.clear();
}
  getData() {
    this.loading = true;
    this.datalist = [];
    let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/GetDetail";
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    
    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    this.repmodel.State_id = this.repmodel.State_id;
    this.repmodel.Mname = this.repmodel.Mname;
    var data1: any = {};
    //this.repmodel = this.DataForm.value;
    this._http.post(url, this.repmodel, { headers: headers })
      .subscribe((data1: any) => { 
        this.OsData = data1;
        console.log(this.OsData);
        this.OrdData = [];
        let P_name = '###';
        let TotalInv: number = 0;
        let TotalRec: number = 0;
        let TotalBal: number = 0;
        this.loading = false;
        this.OsData.map(m => {
          //m.InvDts = moment(new Date(m.InvDts)).format('DD/MM/yyyy');
          
          
          if (P_name !== m.PMST_COD) {
            if (P_name !== '###') {
              this.OrdData.push({ Header: 2, InvTotal: TotalInv, Receipt: TotalRec, Balance: TotalBal, Lyr_clbal: m.Lyr_clbal });
              // Blank Row
              this.OrdData.push({Header: 3}); // If multiple row blank at time push
              //this.OrdData.push({Header: 3});
            }
            TotalInv = 0;
            TotalRec = 0;
            TotalBal = 0;
            this.OrdData.push({
              Header: 1, PMST_COD: m.PMST_COD, party_name: m.party_name, Lyr_clbal: m.Lyr_clbal, ORDNO: m.ORDNO, party_city: m.party_city, OLOC: m.OLOC,
              Exr: m.Exr, Invno: m.Invno, PARTY_STAT: m.PARTY_STAT, PERSON: m.PERSON,PERSON1: m.PERSON1, Dname: m.Dname, Mname: m.Mname, Email: m.EMAIL,
              Email1: m.EMAIL1, Phone: m.PHONE,Phone1: m.PHONE1, Terms: m.Terms, Narr: m.Narr, Inv_notes: m.Inv_notes, Ord_notes: m.Ord_notes
             ,No_of_notes: m.No_of_notes, DueRem: m.DueRem});
            P_name = m.PMST_COD
          }

          TotalInv += m.INVAMT;
          TotalRec += m.PAYMRCD;
          TotalBal += m.BALAMT;

          m.Header = 0;
          this.loading = false;
          { this.OrdData.push(m); }        
          console.log(this.OrdData);
        });
        // this.DataForm.controls["Exr"].patchValue(0);
        // this.DataForm.controls["Invno"].patchValue(0); 
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          this.loading = false;
        });
  }

  ExportExcel() {
    this.loading = true;
    this.datalist = [];
    let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/ExportExcel";
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    
    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    this.repmodel.State_id = this.repmodel.State_id;
    this.repmodel.Mname = this.repmodel.Mname;
    //this.repmodel.Vtype = Vtype;
    var data1: any = {};
    //this.repmodel = this.DataForm.value;
    this._http.post(url, this.repmodel, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((data1: any) => { 
        console.log(data1);
            const data = window.URL.createObjectURL(data1);
            var link = document.createElement('a');
            link.href = data;
            link.target = "_blank";
            link.download = "OS data.xls";
            this.loading = false;
            link.click();
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          this.loading = false;
        });
  }
  
  GetNullData() {
    this.loading = true;
    this.datalist = [];
    let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/GetDetail";
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    var data1: any = {};
    //this.repmodel = this.DataForm.value;
    this._http.post(url, this.repmodel, { headers: headers })
      .subscribe((data1: any) => { 
        this.OsData = JSON.parse(data1);
        this.OrdData = [];
        let P_name = '###';
        let TotalInv: number = 0;
        let TotalRec: number = 0;
        let TotalBal: number = 0;
        this.OsData.map(m => {
          if(m.PERSON == null || m.Dname == null || m.Mname == null || m.PERSON == "-"){
           
          if (P_name !== m.PMST_COD) {
            if (P_name !== '###') {
              this.OrdData.push({ Header: 2, InvTotal: TotalInv, Receipt: TotalRec, Balance: TotalBal, Lyr_clbal: m.Lyr_clbal });
              // Blank Row
            }
            TotalInv = 0;
            TotalRec = 0;
            TotalBal = 0;
            this.OrdData.push({
              Header: 1, PMST_COD: m.PMST_COD, party_name: m.party_name, Lyr_clbal: m.Lyr_clbal, ORDNO: m.ORDNO, party_city: m.party_city, OLOC: m.OLOC,
              Exr: m.Exr, Invno: m.Invno, PARTY_STAT: m.PARTY_STAT, PERSON: m.PERSON,PERSON1: m.PERSON1, Dname: m.Dname, Mname: m.Mname, Email: m.EMAIL,
              Email1: m.EMAIL1, Phone: m.PHONE,Phone1: m.PHONE1, Terms: m.Terms, Narr: m.Narr, Inv_notes: m.Inv_notes, Ord_notes: m.Ord_notes
             ,No_of_notes: m.No_of_notes, DueRem: m.DueRem});
            P_name = m.PMST_COD
          }
          m.Header = 0;
          this.loading = false;
          { this.OrdData.push(m); }
          
          TotalInv += m.INVAMT;
          TotalRec += m.PAYMRCD;
          TotalBal += m.BALAMT;
          }
        });
      
        // this.DataForm.controls["Exr"].patchValue(0);
        // this.DataForm.controls["Invno"].patchValue(0); 
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          this.loading = false;
        });
  }
  CreatePDF(PMST_COD: number, party_name: string, party_city: string, PARTY_STAT: string, Lyr_clbal: number){
    this.loading = true;
    this.datalist = [];
    let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/GetDetailPDF";
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    this.repmodel.Pmst_cod = PMST_COD;
    this._http.post(url, this.repmodel, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((data1: any) => {
          console.log(data1);
          console.log(data1);
            const data = window.URL.createObjectURL(data1);
            var link = document.createElement('a');
            link.href = data;
            link.target = "_blank";
            link.download = PMST_COD+'-'+party_name +'-'+party_city+"_OS.pdf";
            this.loading = false;
            link.click();
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);
				},
				err => {
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description, "dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					}
				}
			)
  }
  UpdateMkt(ORDNO: number, OLOC: number, Handle_id: number){
    console.log(Handle_id,ORDNO,OLOC);
    this.Os1Service.UpdateMtkPartial(ORDNO, OLOC, Handle_id)
      .subscribe((data: any) => {
        console.log(data);
      });
  }
  // exportexcel()
  // {
  //     /* table id is passed over here */   
  //     let element = document.getElementById('excel-table'); 
  //     console.log(element);
  //     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
  //     console.log(ws);
  //     /* generate workbook and add the worksheet */
  //     const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //     console.log(wb);
  //     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //     /* save to file */
  //     XLSX.writeFile(wb, this.fileName);
    
  // }
  getMarketing() {
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=M";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.MarketingList = data1;
      }, err => {
        console.log(err);
      });
  }
  RemoveInvoice(Osd_id: number) {
    this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
      .afterClosed().subscribe(res => {
        if (res) {
          let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/IgnoreOsData?Osd_id=" + Osd_id;
          var headers = new HttpHeaders({
            'content-Type': 'application/json',
            'process-Data': 'false',
            'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
          })
          this.repmodel.Firmx = this.hardfunc.Firmx;
          this.repmodel.Yrx = this.hardfunc.Yrx;
          var data1: any = {};
          this._http.post(url, this.repmodel, { headers: headers })
            .subscribe(
              (data: any) => {
                this.loading = false;
                this.notificationservice.warn("Data deleted succefully");
                this.getData();
              },
              err => {
                this.loading = false;
                let errstr = this.hardfunc.getError(err);
                console.log(err);
                this.notificationservice.warn(errstr);
              }
            )
        }
      });
  }
  OS_DuedtExp(){
    this.notificationservice.success("Emailing Advance Intimation...");
    this.Os1Service.OS_DueDtExpired()
      .subscribe((data: any) => {
        this.notificationservice.success("Email Sent Successfully...");
      });
  }
  ClickRightCate(data){
    console.log(data);
    data.CateClicked = true;
    return false;
  }
  CloseDropDown(data){
    data.CateClicked = false;
    return false;
  }
  EmailReminder(Pmst_cod: string, DueRem: string){      
      this.loading = true;
      this.Os1Service.UpdatePartymst(Pmst_cod, DueRem)
        .subscribe((data: any) => {
          data.DueRem;
          this.loading = false;
          this.notificationservice.success("Data Saved Successfully...");
        },
        err => {
          console.log(err);
        });      
  }
  OS_AdvEmail(){
    this.Os1Service.OS_AdvEmail()
      .subscribe((data: any) => {
        this.notificationservice.success("Email Sent Successfully...");
      });
  }
  EditNote(Osd_id: number, PMST_COD: string, party_name: string,party_city: string, PARTY_STAT: string,Exr: string,Invno: number,FIRM: string,FYEAR: string) {
    const dialogRef = this.dialog.open(EditNoteComponent, {
      width: '840px',
      height: '510px',
      data: { id: Osd_id, PMST_COD: PMST_COD, party_name: party_name, party_city: party_city, PARTY_STAT: PARTY_STAT, Exr: Exr, Invno: Invno, Firm: FIRM, Fyear: FYEAR }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  InvRecord(x: any){
    const dialogRef = this.dialog.open(Sorder1EditComponent, {
      width: '640px',
      height: '510px',
      data: { Ordno: x.ORDNO,Oloc: x.OLOC,Exr: x.Exr,Invno: x.Invno,Pmst_cod: x.PMST_COD,
              party_name: x.party_name,party_city: x.party_city,party_stat: x.PARTY_STAT }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  Delete_Noti(Notification_id: number): void{
    this.loading = true;
    this.adminservice.Delete_notification(Notification_id)
    .subscribe(
      (data:any)=>{
      this.loading = false;
      this.getNotification();
      },
      err => {
      this.loading = false;
      let errstr =  this.hardfunc.getError(err);
      console.log(err);
      }
    )
}
  getNotification() {
    this.Noti_Array = [];
    this.adminservice.getNotificationbyType("I1")
      .subscribe(
        (data: any) => {
          this.Noti_Array = data;
          this.no_of_msg = 0;
          this.Noti_Array.map(m => {
              if (m.Msg != null) {
                this.no_of_msg += 1;
                let x = m.Msg.split('#&#');
                m.MsgHeader = x[0];
                m.MsgBody = x[1];
                m.MsgFooter = x[2];
              }
          });
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationservice.warn(errstr);
        }
      )
  }

  OpenInvoice(Pmst_cod: string){
    this.party_name = Pmst_cod;
  }

  // pending_orderFilter() {
  //   this.OrdData = [];
  //   let l_Grp_nm = '###';
  //   //this.Totsal1 = 0;
  //   this.PtpenordAll.map(m => {
  //     if (m.PARTY_STAT == this.PARTY_STAT) {
  //       if (l_Grp_nm !== m.PARTY_STAT) {
  //         this.OrdData.push({
  //           Header: 1, party_name: m.party_name, ORDNO: m.ORDNO, party_city: m.party_city,
  //           PARTY_STAT: m.PARTY_STAT
  //         });
  //         l_Grp_nm = m.party_name, m.ORDNO, m.party_city, m.PARTY_STAT
  //       }
  //       //this.Totsal1 += m.Amt;
  //       m.Header = 0;
  //       { this.OrdData.push(m); }
  //     }
  //   });
  // }
 
  getFinyr() {
    this.loading = true;
    this.commonservice.CodeList('FINYEAR')
      .subscribe(
        (data: any) => {
          this.FinyrList = JSON.parse(data);
          this.loading = false;
          this.FinanYear = data;
          this.FinanYear = [];
          let l_Finyr = '###';
          this.Totsal1 = 0;
          this.FinanYear.map(m => {
            if (l_Finyr !== m.Finyr) {
              this.FinanYear.push({ Header: 1, Finyr: m.Finyr, Pmst_cod: m.Pmst_cod });

              l_Finyr = m.Finyr
            }
            this.Totsal1 += m.Amt;
            m.Header = 0;
            { this.FinanYear.push(m); }
          });
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
        }

      );
  }
  PartyOsDetail(PMST_COD: number, party_name: string, party_city: string, PARTY_STAT: string, Lyr_clbal: number) {
    const dialogRef = this.dialog.open(PartyOsComponent, {
      width: '1350px',
      data: { PMST_COD: PMST_COD, party_name: party_name, party_city: party_city, PARTY_STAT: PARTY_STAT, Lyr_clbal: Lyr_clbal }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  createFormGroup() {
    return this.fb.group({
      party_name: new FormControl(''),
    });
  }
  getState() {
    let url = this.hardfunc.BaseAPIUrl + "api/Common/StateMastsAPI/GetDatas";
    this._http.post(url, this.repmodel)
      .subscribe((data1: any) => {
        console.log(data1);
        this.StateList = data1;
      }, err => {
        console.log(err);
      });
  }
  GetDocu(ORDNO: number, OLOC: number, DocuType: string) {
    let url = this.hardfunc.BaseAPIUrl + "api/Common/DocuViewerAPI/GetDocu";
    var r1: any = {};
    r1.Ordno = ORDNO;
    r1.Oloc = OLOC;
    r1.Firmx = this.hardfunc.Firmx;
    r1.Yrx = this.hardfunc.Yrx;
    r1.DocuType = DocuType;
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })

    this._http.post(url, r1, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((data1: any) => {
        const data = window.URL.createObjectURL(data1);

        var link = document.createElement('a');
        link.href = data;
        link.target = "_blank";
        link.download = "file.pdf";
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
        this.loading = false;

      },
        err => {
          console.log(err);
          // this.msgs.push({ severity: 'error', summary: 'Error', detail: `Error in getting data , please check server connectivity...  ` });
          this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          this.loading = false;
        });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}


