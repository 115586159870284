import { Component, OnInit, ViewChild } from '@angular/core';
import { EnqService } from '../Enq.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms'
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import * as moment from 'moment'
import { PendordAttachComponent } from '../../pendord/pendord-attach/pendord-attach.component';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { PendordService } from '../../pendord/pendord.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnqpartydetailComponent } from '../enqpartydetail/enqpartydetail.component';
import { DmsService } from 'src/app/Services/Common/dms.service';
import { Router } from '@angular/router';
import { QuoteMailComponent } from '../../quote/quote-mail/quote-mail.component';
import { SalesService } from '../../sales.service';
import { EmailComponent } from 'src/app/Views/shared/email/email.component';

@Component({
	selector: 'app-user-dashboard',
	templateUrl: './user-dashboard.component.html',
	styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {

	loading: boolean = false;
	datalist: any = [];
	datasource: any[];
	dataSource: MatTableDataSource<unknown>;
	displayedColumns: string[] = [];
	DataForm: FormGroup;
	Dom_For: string = 'D';
	Username: string = '';
	Noti_Array: any = [];
	no_of_msg: number = 0;
	Tdata: any = [];
	Ndata: any = [];
	repmodel: any = {};
	MarketingList: any = [];
	DirectorList: any = [];
	Type: string = "";
	//inputvar = "Hi i am parent";
	
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	constructor(private Enqservice: EnqService,
		private _snackBar: MatSnackBar,
		private fb: FormBuilder,
		private HardFunc: HardFuncService,
		private dialog: MatDialog,
		private adminservice: AdminService,
		private Notification: NotificationService,
		private PendordService: PendordService,
		private _http: HttpClient,
		private dmsservice: DmsService,
		public router: Router,
		private SalesService: SalesService) { }

	ngOnInit() {
		this.loading = false;
		this.Username = this.HardFunc.UserName;
		this.DataForm = this.createFormGroup();
		//this.getAttachment();
		this.getMarketing();
		this.getHandles();
		//this.GetEnqEmail();
		if (this.HardFunc.Mkt === "Y") {
			this.Type = "M";
		}
		if (this.HardFunc.Dir === "Y") {
			this.Type = "D";
		}
	}
	DisplayCol() {
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		if (this.Dom_For == 'D') {
			if (isSm) {
				this.displayedColumns = ['PARTY_NAME', 'Action'];
			}
			else {
				this.displayedColumns = ['PARTY_NAME', 'Efw', 'SRNO', 'DORG', 'Items', 'Contact_details', 'Action', 'Attachment'];
			}
		}
		else {
			this.displayedColumns = ['PARTY_NAME', 'SRNO', 'DORG', 'Items', 'Contact_details', 'Action', 'Attachment'];
		}
	}
	//Redirect On Quotation
	Redi_quote(Srno: number){
		this.router.navigate(['/sales/Quote-crud/'+Srno]);
	}
	getData() {
		this.loading = true;
		this.datalist = [];
		var data: any = {};
		let cnd = " ";
		let Option1 = this.DataForm.get('Option').value;
		if (Option1 === 'O') {
			if (this.DataForm.value.Status != "*All*") {
				cnd += " and STATUS = '" + this.DataForm.value.Status + "'";
			}
			if (this.DataForm.value.Efw != "*All*") {
				cnd += " and Efw = ''" + this.DataForm.value.Efw + "'' ";
			}
			if (this.DataForm.value.Prodname != "*All*") {
				cnd += " and Prodname = ''" + this.DataForm.value.Prodname + "'' ";
			}
		}
		else if (Option1 === 'P') {
			if (this.DataForm.value.Efw != "*All*") {
				cnd += " and Efw = ''" + this.DataForm.value.Efw + "'' ";
			}
			if (this.DataForm.value.Prodname != "*All*") {
				cnd += " and Prodname = ''" + this.DataForm.value.Prodname + "'' ";
			}
			cnd += " and STATUS = " + this.DataForm.controls["Status"].patchValue("*All*") + "'";
		}
		data.cond = cnd;
		this.DataForm.controls.Fdate.patchValue(this.HardFunc.hsConvDt(this.DataForm.controls["Fdate"].value));
		this.DataForm.controls.Tdate.patchValue(this.HardFunc.hsConvDt(this.DataForm.controls["Tdate"].value));
		this.Enqservice.UserGetData(this.DataForm.value)
			.subscribe(
				(data: any) => {
					console.log(data);
					this.datalist = data;
					this.dataSource = new MatTableDataSource(<any>this.datalist);
					this.DisplayCol();
					this.getNotification();
					this.getAttachment();
					this.loading = false;
					this.dataSource.paginator = this.paginator;
					this.LoadImages();
				},
				err => {
					this.loading = false;
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description, "dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					}
				}
			)
	}


	LoadImages() {

		this.datalist.map(m => {
			m.DocuList.map(x => {
				x.FileType = "OTH";

			})

		}
		)
	}
	LoadImagebyId(objFile: any) {
		this.loading = true;
		this.dmsservice.LoadImagebyId(objFile.Docu_id)
			.subscribe(
				(data1: any) => {
					objFile.ImageStr = 'data:image/jpeg;base64,' + data1;
					this.loading = false;
				},
				err => {
					this.loading = false;
					let errstr = this.HardFunc.getError(err);
					this.Notification.warn("Error in image loading " + errstr);
				}
			)
	}
	Regret_enq(Srno: number, Type: string) {
		this.Enqservice.RegretEnq(Srno, Type)
			.subscribe((data: any) => {
				this.Notification.success("Email Sent Successfully...")
			})
	}
	SendRegretMail(Srno: number){		
		this.adminservice.openConfirmDialog("Are you sure you want to Regret this Enquiry ?")
		.afterClosed().subscribe(res =>{
		  if(res){
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		let w = '90%';
		let h = '80%';
		if (isSm )
		{
		   w = '100%';
		   h = '100%';
		}
		this.Enqservice.SendRegretEmail(Srno)
		  .subscribe((data1: any) => {
			console.log(data1);
			const dialogRef = this.dialog.open(QuoteMailComponent, {
			  width: w,
			  maxWidth:w,
			  height: h,
			  data: { Atts: data1, Type: 'Enq', Srno: Srno }
			});
			dialogRef.afterClosed().subscribe(result => {
			  console.log('The dialog was closed');
			});
		  },
		  err => {
			let errstr = this.HardFunc.getError(err);
			this.Notification.warn(errstr);
		  });
		}
	});
		
	  }
	//Download PDF
	DownloadPDF() {

		this.loading = true;
		this.datalist = [];
		var data: any = {};
		let cnd = " ";

		let Option1 = this.DataForm.get('Option').value;
		if (Option1 === 'O') {
			if (this.DataForm.value.Status != "*All*") {
				cnd += " and STATUS = '" + this.DataForm.value.Status + "'";
			}
		}
		else if (Option1 === 'P') {
			cnd += " and STATUS = " + this.DataForm.controls["Status"].patchValue("*All*") + "'";
		}
		data.cond = cnd;
		this.DataForm.controls.Fdate.patchValue(this.HardFunc.hsConvDt(this.DataForm.controls["Fdate"].value));
		this.DataForm.controls.Tdate.patchValue(this.HardFunc.hsConvDt(this.DataForm.controls["Tdate"].value));

		console.log(this.DataForm.value);
		this.Enqservice.GetUserPDFData(this.DataForm.value)
			.subscribe(
				(data1: any) => {
					this.loading = false;
					console.log(data1);
					const data = window.URL.createObjectURL(data1);

					var link = document.createElement('a');
					link.href = data;
					link.target = "_blank";
					link.download = "Enquiry.pdf";
					link.click();
					setTimeout(function () {
						// For Firefox it is necessary to delay revoking the ObjectURL
						window.URL.revokeObjectURL(data);
					}, 100);

				},
				err => {
					console.log(err);
					this.loading = false;
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description, "dismiss");
					}
					else {
						this.loading = false;
						this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					}
				}
			)

	}

	getNotification() {
		this.Noti_Array = [];
		this.adminservice.getNotificationbyType('EN')
			.subscribe(
				(data: any) => {
					this.Noti_Array = data;
					data.map(w => {
						if (w) {
							var obj = this.datalist.filter(x => x.Srno === w.Ordno);
							if (obj.length > 0) {
								let o = obj[0];
								o.Tag = "New";
							}
						}
					});
				},
				err => {
					let errstr = this.HardFunc.getError(err);
					console.log(err);
					this.Notification.warn(errstr);
				}
			)
	}

	PartyDetail(Party_name: string) {
		const dialogRef = this.dialog.open(EnqpartydetailComponent, {
			width: '1000px',
			height: '700px',
			data: { Party_name: Party_name }

		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
	// 7. api/DMSAPI/DocuSumbyOrdno -- API For -- Document Sum by Order No.
	getAttachment() {
		this.PendordService.GetDatabyAttach(this.HardFunc.Firmx, 'EN')
			.subscribe(
				(data: any) => {
					data.map(w => {
						if (w) {
							var obj: any = this.datalist.filter((x: any) => x.Srno === w.Ordno && x.Oloc === x.Oloc)[0];
							if (obj) {
								obj.No_of_docu = w.No_of_docu;
							}
						}
					});
				}
			);
	}
	Delete_Noti(): void {
		this.loading = true;
		this.adminservice.DeleteNotibyType('EN')
			.subscribe(
				(data: any) => {
					this.loading = false;
					//   this.getNotification();
				},
				err => {
					this.loading = false;
					let errstr = this.HardFunc.getError(err);
					console.log(err);
				}
			)
	}
	getMarketing() {
		let url = this.HardFunc.BaseAPIUrl + "api/UsersAPI/getAllUsersSelectedMkt?Mkt=Y";
		this._http.get(url, this.repmodel)
			.subscribe((data1: any) => {
				this.MarketingList = data1;
			}, err => {
				console.log(err);
			});
	}
	getHandles() {
		let url = this.HardFunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=D";
		this._http.get(url, this.repmodel)
			.subscribe((data1: any) => {
				this.DirectorList = data1;
			}, err => {
				console.log(err);
			});
	}
	openAttachment(SRNO: number, Enq_id: number, Vtype: string): void {
		const dialogRef = this.dialog.open(PendordAttachComponent, {
			width: '925px',
			height: '600px',
			// Ordno: enq_no, Oloc: 1, id: this.DataForm.get("Enq_id").value, Vtype: 'EN', Wmodule_id: 1133
			data: { Ordno: SRNO, id: Enq_id, Oloc: 1, Vtype: Vtype, Wmodule_id: 1133 }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}
	SendMail(element: any){
		console.log(element);
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		let w = '90%';
		let h = '80%';
		if (isSm )
		{
		w = '100%';
		h = '100%';
		}
		this.SalesService.EnqEmail(element.Srno)
		.subscribe((data1: any) => {   
			console.log(data1);
			if(data1){
				const dialogRef = this.dialog.open(QuoteMailComponent, {
				width: w,
				maxWidth:w,
				height: h,
				data: { data: data1, Atts: data1, Type: 'Quote' }
				});
				dialogRef.afterClosed().subscribe(result => {
				console.log('The dialog was closed');
				});
			}else{
				const dialogRef = this.dialog.open(EmailComponent, {
				width: w,
				maxWidth:w,
				height: h,
				data: { Type: 'Quote' }
				});
				dialogRef.afterClosed().subscribe(result => {
				console.log('The dialog was closed');
				});
			}
			//Open simple Email mail box
		},
		err => {
			let errstr = this.HardFunc.getError(err);
			this.Notification.warn(errstr);
		});
	}
	createFormGroup() {
		return this.fb.group({
			Option: new FormControl("P"),
			Fdate: new FormControl(this.HardFunc.Fdate),
			Tdate: new FormControl(new Date()),
			Efw: new FormControl('*All*'),
			Prodname: new FormControl('*All*'),
			Status: new FormControl('*All*')
		});
	}
	openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}
}
