import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { EnqCrudComponent } from '../Enq/Enq-crud.component';
import { EnqService } from './Enq.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { SalesService } from '../sales.service';
import { MatTableDataSource} from '@angular/material/table';
import { Sort } from '@angular/material';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import * as moment from 'moment';
import * as   XLSX from 'xlsx';
import { PendordAttachComponent } from '../pendord/pendord-attach/pendord-attach.component';
import { PendordService } from '../pendord/pendord.service';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { PendordDialogComponent } from '../pendord/pendord-dialog/pendord-dialog.component';
import { DmsService } from 'src/app/Services/Common/dms.service';

@Component({
	selector: 'app-EnqList',
	templateUrl: './Enq-list.component.html',
	styleUrls: ['./Enq-list.component.css']
})
export class EnqListComponent implements OnInit {
	msgs: any[] = [];
	datalist: any = [];
	loading: boolean = false;
	repmodel: any = {};
	CallFrom:string = "";
	Dom_For:string = 'D';
	DataForm: FormGroup;
	datasource:any = [];
	dataSource: MatTableDataSource<unknown>;
	displayedColumns: string[] = [];
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	StateList: any = [];
	MarketingList: any = [];
	filename:string= 'Enquiry-List.xlsx';
	Noti_Array: any = [];
	no_of_msg: number = 0;
	DirectorList: any = [];

	constructor(
		private _http: HttpClient,
		public dialog: MatDialog,
		private commonservice: CommonService,
		private notificationservice: NotificationService,
		private enqservice: EnqService,
		private salesservice: SalesService,
		private hardfunc: HardFuncService,
		private _snackBar: MatSnackBar,
		private fb: FormBuilder,
		private pendordservice: PendordService,
		private adminservice: AdminService,
		private dmsService: DmsService,
		@Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    	@Optional() public dialogRef: MatDialogRef<EnqListComponent>) { }

	ngOnInit() {

		if(this.ReceivedData){
			this.CallFrom = "DASH";
			this.getData_dash();
			//this.LoadImages();
			this.loading = false;
		}else{
			this.CallFrom = "DIR";
			this.DataForm = this.createFormGroup();
			this.loading = false;
			this.getState();
			this.getMarketing();
			this.getAttachment();
			this.getState();
			this.getHandles();	
			//this.LoadImages();
		}

	}

	openDialog(pk_id: number): void {
		const dialogRef = this.dialog.open(EnqCrudComponent, {
			maxWidth: '100vw',
			maxHeight: '100vh',
			height: '100%',
			width: '100%',
			data: { id: pk_id }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}
	
	DisplayCol() {
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		if (this.Dom_For == 'D')
		{
		  if (isSm)
		  {
			this.displayedColumns = ['PARTY_NAME','Action'];
		  }
		  else
		  {
			this.displayedColumns = ['Srno', 'Oref', 'Dorg','Party_name','Items','Rcby','D_o_o','Action'];
		  }
		}
		else
		{
		  this.displayedColumns = ['Srno', 'Oref', 'Dorg','Party_name','Items','Rcby','D_o_o','Action'];
		}
		if(this.ReceivedData)
		{
			this.displayedColumns = ['Srno', 'Oref', 'Dorg','Party_name','Items','D_o_o','Action']
		}
	  }

	  getData() {
		this.loading = true;
		this.datalist = [];
		var data: any = {};
		let cnd = " ";
	
		if (this.DataForm.value.PARTY_NAME.length > 0)
		{
			cnd += " and PARTY_NAME like ''%"+this.DataForm.value.PARTY_NAME+"%'' ";	
		}
		if (this.DataForm.value.STATUS != "*All*")
		{
			cnd += " and STATUS = ''"+this.DataForm.value.STATUS+"''";	
		}
		if (this.DataForm.value.State != "*All*")
		{
			cnd += " and STATE = ''"+this.DataForm.value.State+"''";	
		}
		if (this.DataForm.value.Asnto != "*All*")
		{
			cnd += " and Asnto like ''%"+this.DataForm.value.Asnto+"%'' ";	
		}
		if (this.DataForm.value.Dirc != "*All*")
		{
			cnd += " and Dirc like ''%"+this.DataForm.value.Dirc.trim()+"%'' ";	
		}
		data.Fdates = moment(this.DataForm.controls["Fdate"].value).format("DD/MM/yyyy");
		data.Tdates = moment(this.DataForm.controls["Tdate"].value).format("DD/MM/yyyy");
		data.cond = cnd;		
		this.enqservice.getDatas(data)
			.subscribe(
				(data: any) => {
					console.log(data);
					this.datalist = data;					
					this.dataSource = new MatTableDataSource(<any>this.datalist);
					this.DisplayCol();
					this.getAttachment();
					this.dataSource.paginator = this.paginator;
					//this.LoadImages();
					this.loading = false;
				},
				err => {
					this.loading = false;
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description,"dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
					}
				}
			)
			
	}
	// LoadImages() {
		
	// 	this.datalist.map(m=> 
	// 		{
	// 			if (m.Filename.indexOf('.pdf') > 0)
	// 			{
	// 			  m.FileType = "PDF";
	// 			}
	// 			else if (m.Filename.toLowerCase().indexOf('.jpg') > 0 || m.Filename.toLowerCase().indexOf('.jpeg') > 0) 
	// 			{
	// 			  m.FileType = "IMG";
	// 			  this.LoadImagebyId(m) 
	// 			}
	// 			else 
	// 			{
	// 			  m.FileType = "OTH";
	// 			}  
	// 		}  
	// 	  )
	//   }
	// LoadImagebyId(objFile: any){
	// this.loading = true;
	// this.dmsService.LoadImagebyId(objFile.Docu_id)
	// .subscribe(
	// 	(data1: any) => {
	// 	objFile.ImageStr = 'data:image/jpeg;base64,'+data1;
	// 	this.loading = false;
	// 	},
	// 	err => {
	// 		this.loading = false;
	// 		let errstr = this.hardfunc.getError(err);
	// 		this.notificationservice.warn("Error in image loading "+errstr);
	// 	}
	// 	)
	// }
	getData_dash() {
		this.loading = true;
		this.datalist = [];
		var data: any = {};
		data.Asnto = this.ReceivedData.Asnto;
		data.Dorg = moment(this.ReceivedData.Dorg).format("DD/MM/yyyy");		
		data.Fdates = data.Dorg;
		data.Tdates = data.Dorg;
		data.TranType = "DASH";
		if(this.ReceivedData.Offered === 'Offered')
		{
			 data.Status = this.ReceivedData.Offered;
		}		
		this.enqservice.getDatas(data)
			.subscribe(
				(data: any) => {
					this.loading = false;
					this.datalist = data;					
					this.dataSource = new MatTableDataSource(<any>this.datalist);
					this.DisplayCol();
					this.getAttachment();
					this.dataSource.paginator = this.paginator;
				},
				err => {
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description,"dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
					}
				}
			)
			
	}

	//Download PDF
	DownloadPDF() {
		this.loading = true;
		this.datalist = [];
		var data: any = {};
		let cnd = " ";
	
		if (this.DataForm.value.PARTY_NAME.length > 0)
		{
			cnd += " and PARTY_NAME like ''%"+this.DataForm.value.PARTY_NAME+"%'' ";	
		}
		if (this.DataForm.value.STATUS != "*All*")
		{
			cnd += " and STATUS = ''"+this.DataForm.value.STATUS+"''";	
		}
		if (this.DataForm.value.State != "*All*")
		{
			cnd += " and STATE = ''"+this.DataForm.value.State+"''";	
		}
		if (this.DataForm.value.Asnto != "*All*")
		{
			cnd += " and Asnto like ''%"+this.DataForm.value.Asnto+"%'' ";	
		}
		if (this.DataForm.value.Dirc != "*All*")
		{
			cnd += " and Dirc like ''%"+this.DataForm.value.Dirc.trim()+"%'' ";	
		}
		data.Fdates = moment(this.DataForm.controls["Fdate"].value).format("DD/MM/yyyy");
		data.Tdates = moment(this.DataForm.controls["Tdate"].value).format("DD/MM/yyyy");
		data.cond = cnd;
		console.log(this.DataForm.value);
		this.enqservice.GetPDFdata(data)
			.subscribe(
				(data1: any) => {
					this.loading = false;
					 const data = window.URL.createObjectURL(data1);

					var link = document.createElement('a');
					link.href = data;
					link.target = "_blank";
					link.download = "Enquiry.pdf";
					link.click();
					setTimeout(function () {
						// For Firefox it is necessary to delay revoking the ObjectURL
						window.URL.revokeObjectURL(data);
					}, 100);

				},
				err => {
					console.log(err);
					this.loading = false;
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description,"dismiss");
					}
					else {
						this.loading = false;
						this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
					}
				}
			)
			
	}

	openEnqDialog(Enq_id: number){
		const dialogRef = this.dialog.open(EnqCrudComponent, {
			width: '1150px',
			height: '600px',
			data: { id: Enq_id }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
		}
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
	
	exportexcel():void{
		// Option-2 Entire JSON
		const ws1:XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.datalist);

		// Option-3 Selected fields from JSON
		let newData = this.datalist.map(({Srno, Oref, Dorg, Party_name, Rcby,D_o_o}) =>
										({Srno, Oref, Dorg, Party_name, Rcby,D_o_o}));
		const ws:XLSX.WorkSheet=XLSX.utils.json_to_sheet(newData);
		
		const wb:XLSX.WorkBook=XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws,'Sheet1');
	
		XLSX.writeFile(wb,this.filename);
	  }
	getState() {
		let url = this.hardfunc.BaseAPIUrl + "api/Common/StateMastsAPI/GetDatas";
		this._http.post(url, this.repmodel)
		  .subscribe((data1: any) => {
			this.StateList = data1;
		  }, err => {
			console.log(err);
		  });
	  }
	getHandles() {
	let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=D";
	this._http.get(url, this.repmodel)
		.subscribe((data1: any) => {
		this.DirectorList = data1;
		}, err => {
		console.log(err);
		});
	}
	getMarketing() {
	let url = this.hardfunc.BaseAPIUrl + "api/UsersAPI/getAllUsersSelectedMkt?Mkt=Y";
	this._http.get(url, this.repmodel)
		.subscribe((data1: any) => {
		this.MarketingList = data1;
		}, err => {
		console.log(err);
		});
	}
	createFormGroup(){
		return this.fb.group({
			PARTY_NAME: new FormControl(''),
			STATUS: new FormControl('Pending'),
			State: new FormControl('*All*'),
			Asnto: new FormControl('*All*'),
			Dirc: new FormControl('*All*'),
			Fdate: new FormControl(this.hardfunc.Fdate),
			Tdate: new FormControl(new Date())
		});
	}
	getAttachment() {
		this.pendordservice.GetDatabyAttach(this.hardfunc.Firmx, 'EN')
			.subscribe(
				(data: any) => {
					data.map(w => {
						if (w) {
							var obj: any = this.datalist.filter((x: any) => x.Srno === w.Ordno && x.Oloc === x.Oloc)[0];
							if (obj) {
								obj.No_of_docu = w.No_of_docu;
							}
						}
					});
				}
			);
	}
	GetEnqEmail(Srno: number) {
		this.loading = true;
		this.datalist = [];	
		this.enqservice.SendEnqEmail(Srno)
			.subscribe(
				(data: any) => {
					this.loading = false;
					this.getData();
					this.notificationservice.success('Email Sent Successfully...');
				},
				err => {
					let errstr = this.hardfunc.getError(err);
					console.log(err);
					this.notificationservice.warn(errstr);
					this.loading = false;
				  }
			)	
	}
	
	openAttachment(Srno: number, Enq_id: number, Vtype: string): void {
		const dialogRef = this.dialog.open(PendordAttachComponent, {
		  width: '925px',
		  height: '600px',		  			
		  data: { Ordno: Srno, Oloc: 1, id:Enq_id, Vtype: Vtype, Wmodule_id: 1136 }
		});
		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		});
	  }
	
	sortData(sort: Sort) {
		const data = this.datalist.slice();
		if (!sort.active || sort.direction === '') {
		  this.dataSource = data;
		  return;
		}
		this.dataSource = data.sort((a, b) => {
		  const isAsc = sort.direction === 'asc';
		  switch (sort.active) {
			case 'Party_name': return compare(a.Party_name, b.Party_name, isAsc);
			// case 'Srno': return compare(a.Srno, b.Srno, isAsc);
			// case 'Offer_dt': return compare(a.Offer_dt, b.Offer_dt, isAsc);
			default: return 0;
		  }
		});
	  }
	openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
		  duration: 2000,
		});
	  }

}
function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

