import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/Services/Common/common.service';

@Component({
  selector: 'app-store-prod',
  templateUrl: './store-prod.component.html',
  styleUrls: ['./store-prod.component.css']
})
export class StoreProdComponent implements OnInit {

  loading: boolean = false
  DataForm: FormGroup
  constructor(private fb: FormBuilder,private commonservice: CommonService) { }

  ngOnInit() {
    this.loading = true;
    this.DataForm = this.createFormGroup();
  }
  GetPartyDetail(){
   
    this.commonservice.GetPartyDetail(this.DataForm.get("Pmst_cod").value)
    .subscribe(
        (data:any) => {
            var data1 = data[0];
            this.DataForm.get("Product_nm").patchValue(data1.PARTY_NAME.trim());
        },
        err => {
          console.log('error Connectivity...');
        }
    )
  }
  createFormGroup() {
    return this.fb.group({
      Prdgroup: new FormControl(''),
      Prod_code: new FormControl(),
      testdwn: new FormControl(''),
      Pmst_cod: new FormControl(''),
      Product_nm: new FormControl(''),
      Short_nm: new FormControl(''),
      Desc: new FormControl(''),
      Unit: new FormControl('L'),
      Drg_no: new FormControl(),
      Status: new FormControl('A'),
      Opstock: new FormControl('')
    });
  }

}
