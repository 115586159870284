import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar, PageEvent, MatPaginator } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { Observable } from 'rxjs';
import { MatDatepickerModule, MatInputModule, MatNativeDateModule } from '@angular/material';
import * as moment from 'moment';
//import { MatTableExporterModule } from 'mat-table-exporter';
import * as   XLSX from 'xlsx';

export interface EnqreportsComponent {
  srno: number;
  party_name: string;
  state: string;
  duedt: string;
  asnto: string;
  status: string;
}

@Component({
  selector: 'app-enqreports',
  templateUrl: './enqreports.component.html',
  styleUrls: ['./enqreports.component.css']
})

export class EnqreportsComponent implements OnInit {

  loading: boolean = false;
  bSearch: any = [];
  FieldList: any = [];
  repmodel: any = {};
  datalist: any = [];
  datasource: any = [];
  msgs: any[] = [];
  Firmx: any[];
  sort: any;
  chkDate: boolean = false;
  dataSource: MatTableDataSource<unknown>;
  err: any[];
  dialog: any;
  cols: any[];
  displayedColumns: string[] = [];
  checked: boolean = false;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  Fdate: Date;
  Tdate: Date;
  duplicateArray = []
  Status: string;
  filename:string= 'Enquiry-Reports.xlsx';
  StateList:any = [];

  constructor(
    private _http: HttpClient,
    private hardfunc: HardFuncService,
    private cservice : CommonService,
    private _snackBar: MatSnackBar,
    private _router: Router
  ) { }


  ngOnInit() {
    this.loading = false;
    this.repmodel.CG = 'A';
    this.repmodel.Status = 'P';
    this.getData();
    this.Fdate = new Date("01-04-2021");
    this.Tdate = new Date("31-03-2021");
    this.Status = "";
    this.duplicateArray = this.datalist

    this.loading = true;  
    this.cservice.GetStates()
    .subscribe(data => {
        this.StateList = data;
        this.loading = false;
    },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting state list , please check connection.","dismiss");
          this.loading = false;
    });
 
    var statuslist = [
      {nm:'PENDING',id : 'PENDING'},
      {nm:'OFFERED',id : 'OFFERED'},
      {nm:'REGRETED',id : 'REGRETED'}
    ]

    this.FieldList = [
      { field: 'party_name', type: 'String', header: 'Party Name' },
      { field: 'state', type: 'Dropdown', header: 'Party State', items: this.StateList },
      { field: 'dorc', type: 'Date', header: 'Registration Date ' },
      { field: 'd_o_o', type: 'Date', header: 'Due Date ' },
      { field: 'status', type: 'Dropdown', header: 'Status' , items: statuslist },
      { field: 'asnto', type: 'String', header: 'Assign to' }
    ]

    this.bSearch = []
  
    this.loading = false;
  }

  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['Srno', 'Party_name', 'State', 'D_o_o', 'Asnto', 'Status'];
  }

  getData() {
    
    this.loading = true;
    this.datalist = [];
    let url = this.hardfunc.BaseAPIUrl + "api/EnqAPI/AllEnqs";
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer' + localStorage.getItem("UserToken")
    })
    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    let cnd = "";
    this.bSearch.map(m=>
      {
        if (m.Operator && m.FieldType && m.Value)
        {
          if (m.Logical)
          {
            cnd = cnd + m.Logical+' ';
          }
          cnd = cnd + m.Field;
          switch (m.Operator) {
            case "eq":
              cnd = cnd + " = ";
              break;
            case "lt":
                cnd = cnd + " = ";
                break;
            case "lteq":
               cnd = cnd + " <= ";
                break;
            case "gt":
                  cnd = cnd + " < ";
                  break;
            case "gteq":
                 cnd = cnd + " >= ";
                  break;
            case "cont" :
            case "start":
            case "end":
                cnd = cnd + " like ";                  
                break;
            default:
              cnd = cnd + " = ";
              break;
          }
          if (m.FieldType === "String")
          {
            switch (m.Operator) {
            case "cont" :
              cnd = cnd + "'%"+m.Value+"%'    ";
              break;
            case "start" :
              cnd = cnd + "'"+m.Value+"%'    ";
              break;
            case "end" :
              cnd = cnd + "'%"+m.Value+"'    ";
              break;
            default :
              cnd = cnd + "'"+m.Value+"'    ";
              break;
            }
            
          }
          if (m.FieldType === "Dropdown")
          {
            cnd = cnd + "'"+m.Value+"'    ";
          }
          if (m.FieldType === "Date")
          {
            cnd = cnd + " convert(datetime,'"+moment(m.Value).format('DD-MM-YYYY')+"',103)    ";
          }
        }
      });
    this.repmodel.cond = cnd;

    this._http.post(url, this.repmodel, { headers: headers })
      .subscribe((data1: any) => {
        this.datalist = JSON.parse(data1);
        this.loading = false;
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        //this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.DisplayCol();
      },
        (err: any) => {
          console.log(err);
          this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          this.loading = false;
        });
  }

  addSearch() {
    let validrecords : boolean = true;
    let i = 0;
    this.bSearch.map(m=> {
        if (!m.Logical && i > 0)
        {
          validrecords = false; 
        }
        if (!m.Field)
        {
          validrecords = false;
        }
        if (!m.Operator)
        {
          validrecords = false;
        }
        if (!m.Value)
        {
          validrecords = false;
        }
        i++;
    });
    if (validrecords)
    {
      if (i ===0)
      {
        this.bSearch.push({});
      }
      else
      {
        this.bSearch.push({Logical:'and'});
      }
    }
    else{
      alert("Incomplete conditions");

    }
    

  }

  deleteRow(validrecords) {   
    let index: string = this.datasource.findIndex(i => i === validrecords);
    console.log(this.datasource.findIndex(i => i === validrecords));
    this.bSearch.splice(index,1)
    this.dataSource = new MatTableDataSource(<any>index);
}

  getDetails(Party: any) {

    let mydata: any = {};
    this.repmodel = {};
    this.repmodel = Party;

    const dialogRef = this.dialog.open(EnqreportsComponent, {
      width: '1000px',
      data: this.repmodel
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }

  FldChange(obj:any){
    
    var filter = this.FieldList.filter(m => m.field === obj.Field);
    if (filter)
    {
      obj.FieldType = filter[0].type;
      obj.Items  = filter[0].items;
    }
    else
    {
      obj.FieldType = "";
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onDateChange(newDate: Date) {
  }
  reverseAndTimeStamp(dateString) {
    const reverse = new Date(dateString.split("-").reverse().join("-"));
    return reverse.getTime();
  }

  exportexcel():void{
    let element = document.getElementById('excel-table');
    const ws:XLSX.WorkSheet=XLSX.utils.table_to_sheet(element);

    const wb:XLSX.WorkBook=XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws,'Sheet1');

    XLSX.writeFile(wb,this.filename);
  }
}