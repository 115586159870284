import {Validator, NG_VALIDATORS, AbstractControl} from '@angular/forms';
import { Directive, Input } from '@angular/core';


@Directive({
    selector: '[matchVarieble]',
    providers: [{
        provide : NG_VALIDATORS,
        useExisting : MismatchDirective,
        multi : true
    }]

})
export class MismatchDirective implements Validator {
    
    
    @Input() matchVarieble :  string;
    
    validate(control: AbstractControl): {[key:string]: any} | null {
        

        const abc = control.parent.get(this.matchVarieble);
        if(abc && abc.value !== control.value){
            return {'notEqual' :  true};
        }
        return null;

    }

    
}