import { Component, OnInit } from '@angular/core';
import { PurchaseService } from '../purchase.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { Sort, MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { IndenterCrudComponent } from '../indenter-crud/indenter-crud.component';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';

@Component({
  selector: 'app-indenter-list',
  templateUrl: './indenter-list.component.html',
  styleUrls: ['./indenter-list.component.css']
})
export class IndenterListComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];

  constructor(private HardFunc: HardFuncService,
              private PurService: PurchaseService,
              private MatSnackBar: MatSnackBar,
              public dialog: MatDialog,
              private adminservice: AdminService,
              private NotificationSer: NotificationService) { }

  ngOnInit() {
    this.loading = true;
    this.getData();
  }
  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['INDT','INDT_NM','Username','Action'];
  }
  getData() {
    this.loading = true;
    this.datalist = [];
   
    this.PurService.getIndenterList()
      .subscribe(
        (data: any) => {          
          this.datalist = data;
          this.loading = false;
          this.dataSource = new MatTableDataSource(<any>this.datalist);
          this.DisplayCol();
          // this.dataSource.paginator = this.paginator;
        },
        err => {
          if (err.error.error_description) {
            this.openSnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          }
        }
      )
  }
  IndenterEdit(INDT_ID: number,INDT: string, INDT_NM: string, Username: string){
    console.log(INDT_ID);
    const dialogRef = this.dialog.open(IndenterCrudComponent, {
      width: '740px',
      data: { id: INDT_ID, INDT: INDT, INDT_NM: INDT_NM, Username: Username }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  AddIndenter(){
    const dialogRef = this.dialog.open(IndenterCrudComponent, {
      width: '740px',
       data: { id: -1 }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  //6. api/Sales/EMDsAPI/DeleteData
	DeleteRcd(INDT_ID: number): void {
    this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
      .afterClosed().subscribe(res => {
        if (res) {
          this.loading = true;
          const dialogRef = this.PurService.DeleteIndenter(INDT_ID)
            .subscribe(
              (data: any) => {
                this.loading = false;
                this.NotificationSer.warn("Data deleted succefully");
                this.getData();
              },
              err => {
                this.loading = false;
                let errstr = this.HardFunc.getError(err);
                console.log(err);
                this.NotificationSer.warn(errstr);
              }
            )
  
          // this.notificationService('Deleted Successfully');
        }
      });
    }
  openSnackBar(message: string, action: string) {
    this.MatSnackBar.open(message, action, {
      duration: 2000,
    });
  }
}
