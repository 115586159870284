import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pur-ret',
  templateUrl: './pur-ret.component.html',
  styleUrls: ['./pur-ret.component.css']
})
export class PurRetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
