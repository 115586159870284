import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData { }

@Component({
    selector: 'party-lookup-dialog',
    templateUrl: './dialog.html',
  })
  export class PartyLookupDialog {
  
    constructor(
      public dialogRef: MatDialogRef<PartyLookupDialog>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
  
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  }