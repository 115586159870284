import { Component, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray, MaxLengthValidator } from '@angular/forms';
import { EnqListComponent } from '../Enq/Enq-list.component';
import { MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { EnqService } from './Enq.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PartyHelpComponent } from '../../shared/party-help/party-help.component';
import { AdminService } from '../../admin/admin.service';
import { PendordAttachComponent } from '../pendord/pendord-attach/pendord-attach.component';

@Component({
  selector: 'app-Enq-crud',
  templateUrl: './Enq-crud.component.html',
  styleUrls: ['./Enq-crud.component.css']
})
export class EnqCrudComponent implements OnInit, OnDestroy {

  DataForm: FormGroup;
  loading: boolean = false;
  msgs: any = [];
  title: string;
  repmodel: any = {};
  MarketingList: any = [];
  DirectorList: any = [];
  StateList: any = [];
  ModeOfEnquiry: any = [];
  initialValues : any [];
  file: any;

  constructor(
    private commonservice: CommonService,
    private hardfunc: HardFuncService,
    private notificationService: NotificationService,
    private enqservice: EnqService,
    private _http: HttpClient,
    @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    @Optional() public dialogRef: MatDialogRef<EnqListComponent>,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private adminservice: AdminService) {
  }
  
  ngOnInit(){
    this.DataForm = this.createFormGroup();
    this.initialValues = this.DataForm.value;
    this.loading = true;
    //this.getEnqSrno();
    this.GetData();
    this.getMarketing();
    this.getHandles();
    this.getState();
    this.getModeOfEnq();
  }
ngOnDestroy(): void {
  console.clear();
}
  GetData() {
  if (this.ReceivedData) {
    
    if (this.ReceivedData.id == -1) {
      this.title = "ADDITION";
    }
    else {
      this.enqservice.GetDatabyId(this.ReceivedData.id)
        .subscribe(
          (data1: any) => {
            data1.Moi = data1.Moi.trim();
            this.DataForm.patchValue(data1);        
            this.title = "EDITING";
            this.GetSubItems(data1.Srno);
            return false;
          },
          err => {
            let errstr = this.hardfunc.getError(err);
            console.log(err);
            this.notificationService.warn(errstr);
          }
        )
    }
    }
    else {
      this.ReceivedData = {};
      this.ReceivedData.id = -1;
      this.title = "ADDITION";
    }
  }
  
  GetSubItems(Srno:number){
    (this.DataForm.controls["SubDatas"] as FormArray).clear();
    this.enqservice.GetEnq1DatabyId(Srno)
    .subscribe(
      (data: any) => {
        data.map(m => {
            let d = this.fb.group({
              Enq_id: m.Enq_id,
              Pk_id:m.Pk_id,
              Enq1_id: 0,
              Srno: m.Srno,
              Sr: m.Sr,
              Prodname: m.Prodname,
              Qnt: m.Qnt
            });
            (this.DataForm.controls['SubDatas'] as FormArray).push(d);
        });
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.notificationService.warn(errstr);
      }
    )
  }

  SaveData() {
    this.loading = true;
    let EN = this.DataForm.get("Type").value;
    if (EN === 'E') {
      this.DataForm.controls.Oref.patchValue("Enquiry");
      var today = new Date();
      var AdvDate = new Date(new Date().setDate(today.getDate() + 30));
      this.DataForm.controls.Duedt.patchValue(AdvDate);
    }
    if (EN === 'T') {
      this.DataForm.controls.Oref.patchValue("Tender");
    }
    if (EN === 'G') {
      this.DataForm.controls.Oref.patchValue("Gem Tender");
    }
    console.log(this.DataForm.value);
    this.enqservice.SaveData(this.DataForm.value)
      .subscribe(
        data1 => {
          this.ResetData();
          this.GetData();
          //For Attachament
          if (this.ReceivedData.id == -1) {
            let enq_no = data1;
            const dialogRef = this.dialog.open(PendordAttachComponent, {
              width: '925px',
              height: '600px',
              data: { Ordno: enq_no, Oloc: 1, id: this.DataForm.get("Enq_id").value, Vtype: 'EN', Wmodule_id: 1133 }
            });
            dialogRef.afterClosed().subscribe(result => {
              console.log('The dialog was closed');
            });
          }
          this.notificationService.success(' Data Saved Successfully' + '-' + data1);
          this.loading = false;
          
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  
  GetFile(event){
    this.file = event.target.files[0];
    console.log("file", this.file);
  }
  HelpDialog(){
    let data = {
      Firmx:this.hardfunc.Firmx,
      Cond:" a.Grp in  (''B'',''H'' ) "
    }
    const dialogRef = this.dialog.open(PartyHelpComponent, {
			width: '970px',
			height: '848px',
      data
		});
		dialogRef.afterClosed().subscribe(result => {
      this.DataForm.get('Party_name').patchValue(result.nm.trim());
      this.DataForm.get('City').patchValue(result.Party_city.trim());
      this.DataForm.get('State').patchValue(result.Party_Stat.trim());
      this.DataForm.get('Addr1').patchValue(result.Party_adr1.trim() + '-' + result.Party_adr2.trim() + '-' + result.Party_adr3.trim()
                                                 + '-' + result.Party_city.trim() +'-'+result.Party_Stat.trim());
			 console.log('The dialog was closed');
		});
  }
  getModeOfEnq() {
    this.loading = true;
    this.commonservice.CodeList('MOI')
      .subscribe(
        (data: any) => {
          this.ModeOfEnquiry = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
        }
      );
  }
  getState() {
    let url = this.hardfunc.BaseAPIUrl + "api/Common/StateMastsAPI/GetDatas";
    this._http.post(url, this.repmodel)
      .subscribe((data1: any) => {
        this.StateList = data1;
      }, err => {
        console.log(err);
      });
  }
  addNext() {
    (this.DataForm.controls['SubDatas'] as FormArray).push(this.createItem())
  }
  deleteRow(i: number) {
    (this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);
  }
  getMarketing() {
    let url = this.hardfunc.BaseAPIUrl + "api/UsersAPI/getAllUsersSelectedMkt?Mkt=Y";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.MarketingList = data1;
      }, err => {
        console.log(err);
      });
  }
  getHandles() {
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=D";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.DirectorList = data1;
      }, err => {
        console.log(err);
      });
  }
  
  // getHandles() {
  //   let url = this.hardfunc.BaseAPIUrl + "api/UsersAPI/getAllUsersSelectedMkt?Dir=Y";
  //   this._http.get(url, this.repmodel)
  //     .subscribe((data1: any) => {
  //       this.DirectorList = data1;
  //     }, err => {
  //       console.log(err);
  //     });
  // }
  ResetData() {
    if (this.ReceivedData.id > 0) {
      this.dialogRef.close();
    }
    else {
      this.DataForm.reset();
      (this.DataForm.controls["SubDatas"] as FormArray).clear();
      this.DataForm.patchValue(this.initialValues);
      Object.keys(this.DataForm.controls).forEach(key => {
        this.DataForm.get(key).setErrors(null);
      });
    }
  }
  onClear(){
    this.DataForm.reset();
    console.clear();
  }
  
  createFormGroup() {
    return this.fb.group({
      Type: new FormControl('E', Validators.required),
      Srno: new FormControl(0, Validators.required,),
      Oref: new FormControl('Enquiry',Validators.maxLength(25)),
      Dorc: new FormControl(new Date()),
      Dorg: new FormControl(new Date()),
      Pmst_cod: new FormControl(''),
      Party_name: new FormControl('',[Validators.maxLength(50),Validators.required]),
      Addr1: new FormControl('',[Validators.maxLength(400),Validators.required]),
      City: new FormControl('',[Validators.maxLength(30),Validators.required]),
      State: new FormControl('',[Validators.maxLength(40),Validators.required]),
      Duedt: new FormControl(new Date()),
      Moi: new FormControl('',Validators.maxLength(10)),
      Efw: new FormControl(''),
      Status: new FormControl('PENDING',Validators.maxLength(10)),
      D_o_o: new FormControl(''),
      Rplby: new FormControl('',Validators.maxLength(20)),
      Rcby: new FormControl('',Validators.maxLength(20)),
      Asnto: new FormControl('',Validators.required),
      Prod: new FormControl('',Validators.maxLength(50)), 
      Poc: new FormControl('',Validators.maxLength(25)),
      Cell: new FormControl('',Validators.maxLength(25)),
      Email_id: new FormControl('',[Validators.email,Validators.maxLength(100)]),
      Rem: new FormControl('',Validators.maxLength(25)),
      Dirc: new FormControl('',Validators.required),
      Teno: new FormControl('',Validators.maxLength(25)),
      Project: new FormControl('',Validators.maxLength(100)),
      No_of_quot: new FormControl(0),
      Prio: new FormControl('L'),
      Enq_id: new FormControl(-1),
      SelectParty: new FormControl('E'),
      ESubject: new FormControl('',Validators.maxLength(300)),
      SubDatas: this.fb.array([this.createItem()]),
    });
  }
  createItem(): FormGroup {
    return this.fb.group({  
      Pk_id:[0],
      Enq1_id: [0],
      SRNO: [0],
      SR: [0],
      Prodname: [''],
      Qnt: []
    });
  }

  initializeFormGroup() {

  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  // onClose() {
  //   this.DataForm.reset();
  //   this.initializeFormGroup();
  //   this.dialogRef.close();
  // }

}