import { Component, OnInit, Optional, Inject, Input, ViewChild, AfterViewInit, OnDestroy, OnChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FactransService } from './factrans.service';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FactransListComponent } from './factrans-list/factrans-list.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductHelpComponent } from '../../shared/product-help/product-help.component';
import { Sort, MatDialog, MatTableDataSource, MatSnackBar } from '@angular/material';
import { AdminService } from 'src/app/Views/admin/admin.service';
import * as moment from 'moment'

@Component({
  selector: 'app-fac-trans-crud',
  templateUrl: './fac-trans-crud.component.html',
  styleUrls: ['./fac-trans-crud.component.css']
})
export class FacTransCrudComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  loading: boolean = false;
  DataForm: FormGroup;
  HelpForm: FormGroup;
  title: string;
  initialValues: any[];
  repmodel: any = {};
  Dept_nm: any = [];
  productlist: any = [];
  @Input() Unit: string;
  filteredOptions: any = [];
  HelpList: any = [];
  @ViewChild(ProductHelpComponent, { static: false }) child1: ProductHelpComponent;
  msgFromChild1: any;
  Product_id = '';
  Product_nm = '';
  isDisabled = true;
  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDay();
  minDate = moment(new Date(this.hardfunc.Fdate)).format("YYYY-MM-DD");
  maxDate = moment(new Date(this.hardfunc.Tdate)).format("YYYY-MM-DD");
  @Input('Type') Type;
  @Input('fUnit') fUnit;
  disableBtn = false;
  TranTypeforType: string;
  Username: string;

  constructor(private factransservice: FactransService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private hardfunc: HardFuncService,
    @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    @Optional() public dialogRef: MatDialogRef<FactransListComponent>,
    private _http: HttpClient,
    private dialog: MatDialog,
    private adminservice: AdminService,) { }

  ngOnInit() {
    this.loading = true;
    this.DataForm = this.createFormGroup();
    this.initialValues = this.DataForm.value;
    this.GetData();
    this.HelpForm = this.HelpFormGroup();
    this.getDeptName();

    this.DataForm.get('TranType').patchValue(this.Type);
  }

  ngOnChanges() {
    //console.log("On Changes ");    
    this.loading = true;    
    console.log(this.Username);
    this.DataForm = this.createFormGroup();
    this.initialValues = this.DataForm.value;
    this.GetData();
    this.HelpForm = this.HelpFormGroup();
    this.getDeptName();

    this.DataForm.get('TranType').patchValue(this.Type);
  }

  ngAfterViewInit() {
    console.log("After view Init ");
    // this.currentMsgToParent = this.child1.currentMsgToParent;
    // this.msgFromChild1 = this.child1.msgFromChild1;
  }
  ngOnDestroy(): void {
    console.clear();
  }
  //1. api/Sales/factransAPI/GetDatabyId
  GetData() {
    this.loading = true;
    if (this.ReceivedData) {

      if (this.ReceivedData.id == -1) {
        this.title = "ADDITION";
      }
      else {
        this.factransservice.GetDatabyId(this.ReceivedData.id, this.ReceivedData.TranType)
          .subscribe(
            (data: any) => {
              this.TranTypeforType = data.TranType;
              console.log(this.TranTypeforType);
              //this.DataForm.controls.TranType.patchValue(this.TranTypeforType);
              this.DataForm.patchValue(data);
              console.log(this.DataForm.value);
              const formarr = new FormArray([]);
              let idx: number = 0;
              data.Items.map(m => {
                formarr.push(
                  this.fb.group({
                    Ow_mast_id: m.Ow_mast_id,
                    Product_id: m.Product_id,
                    Product_nm: m.Product_nm,
                    Qnty: m.Qnty,
                    Unit: m.Unit,
                    Stock: 0,
                  })
                );
              });
              this.PrdWithStk(idx);
              idx++;
              this.loading = false;
              this.DataForm.setControl("Items", formarr);
              this.title = "EDITING";
              return false;
            },
            err => {
              let errstr = this.hardfunc.getError(err);
              console.log(err);
              this.notificationService.warn(errstr);
            }
          )
      }
    }
    else {
      this.ReceivedData = {};
      this.ReceivedData.id = -1;
      this.title = "ADDITION";
    }
  }
  //   disableField(checked) {
  //     if (!checked) {
  //       //this.DataForm.value.Prodgrp_id = 0;
  //       this.DataForm.controls["Prodgrp_id"].patchValue("All");
  //     } else {
  //       this.DataForm.value.Prodgrp_id;
  //     }
  // }
  //2. api/Sales/factransAPI/SaveData
  SaveTrans() {
    
    if (this.Type === 'INTR' && this.DataForm.get('TranType').value != null) {
      let ValidEnt: boolean = true;
      let Tdate = this.hardfunc.hsConvDt(this.DataForm.controls.Tdate.value);
      console.log(Tdate);
      this.DataForm.controls.Tdate.patchValue(Tdate);
      console.log(this.hardfunc.Fdate);
      if (Tdate <= this.hardfunc.Fdate || Tdate >= this.hardfunc.Tdate) {
        this.notificationService.warn("Invalid date... !!! ");
        return;
      }
      if (this.DataForm.controls.Tran_from.value === this.DataForm.controls.Issue_to.value) {
        this.notificationService.warn("From department and to department could not be similar !!! ");
        return;
      }
      this.loading = true;
      
      this.factransservice.SaveData(this.DataForm.value)
        .subscribe(
          data => {
            this.adminservice.openAlertDialog("Your Entry Number is", data)
              .afterClosed().subscribe(res => {

                // if (res) {
                //   alert(data);
                // }
              });
            this.loading = false;
            this.ResetData();
            this.notificationService.success(' Data Saved Successfully... ');
          },
          err => {
            let errstr = this.hardfunc.getError(err);
            console.log(err);
            this.notificationService.warn(errstr);
            this.loading = false;
          })
    }
    else {
      let Tdate = this.hardfunc.hsConvDt(this.DataForm.controls.Tdate.value);
      this.DataForm.controls.Tdate.patchValue(Tdate);
      this.factransservice.SaveData(this.DataForm.value)
        .subscribe(
          data => {
            this.adminservice.openAlertDialog("Your Entry Number is", data)
              .afterClosed().subscribe(res => {
                console.log(res);
              });
            this.loading = false;
            this.ResetData();
            this.notificationService.success(' Data Saved Successfully... ');
          },
          err => {
            let errstr = this.hardfunc.getError(err);
            console.log(err);
            this.notificationService.warn(errstr);
            this.loading = false;
          });
    }

  }
  // Consumption(){
  //   let Tdate = this.hardfunc.hsConvDt(this.DataForm.controls.Tdate.value);
  //   this.DataForm.controls.Tdate.patchValue(Tdate);
  //   this.factransservice.SaveData(this.DataForm.value)
  //     .subscribe(
  //       data => {
  //         this.adminservice.openAlertDialog("Your Entry Number is",data)
  //           .afterClosed().subscribe(res => {

  //             // if (res) {
  //             //   alert(data);
  //             // }
  //           });
  //         this.loading = false;
  //         this.ResetData();
  //         this.notificationService.success(' Data Saved Successfully... ');
  //       },
  //       err => {
  //         let errstr = this.hardfunc.getError(err);
  //         console.log(err);
  //         this.notificationService.warn(errstr);
  //         this.loading = false;
  //       })
  // }
  selectedDrpdown() {
    if (this.DataForm.controls.Tran_from.value === this.DataForm.controls.Issue_to.value) {
      this.DataForm.invalid;
      this.notificationService.warn("From Department and To Department could not be similar !!! ");
      return;
    }
  }
  //For Help Dialog
  HelpDialog(index: number) {
    const dialogRef = this.dialog.open(ProductHelpComponent, {
      width: '970px',
      height: '848px',
      data: { i: index }
    });
    dialogRef.afterClosed().subscribe(result => {
      let formarray = (this.DataForm.controls['Items'] as FormArray);
      let formgroup = (formarray.at(index) as FormGroup);
      formgroup.get('Product_id').patchValue(result.Product_id);
      formgroup.get('Product_nm').patchValue(result.Short_nm.trim());
      formgroup.get('Unit').patchValue(result.Unit);
      console.log('The dialog was closed');
    });
  }

  ResetData() {
    if (this.ReceivedData.id > 0) {
      this.dialogRef.close();
    }
    else {
      this.DataForm.reset();
      this.DataForm.patchValue(this.initialValues);
      Object.keys(this.DataForm.controls).forEach(key => {
        this.DataForm.get(key).setErrors(null);
      });

    }
  }

  addNext() {
    (this.DataForm.controls['Items'] as FormArray).push(this.createItem())
  }
  deleteRow(i: number) {
    (this.DataForm.controls['Items'] as FormArray).removeAt(i);
  }

  // getProductlist(index: number) {
  //   let formarray = (this.DataForm.controls['Items'] as FormArray);
  //   let formgroup = (formarray.at(index) as FormGroup);
  //   let product_id = formgroup.get('Product_id').value;
  //   let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/GetProductDetail?product_id=" + product_id;
  //   this._http.get(url, this.repmodel)
  //     .subscribe((data1: any) => {
  //       console.log(data1);
  //       this.productlist = data1;
  //       this.productlist = this.productlist.map((x) => {
  //         let formarray = (this.DataForm.controls['Items'] as FormArray);
  //         let formgroup = (formarray.at(index) as FormGroup);
  //         formgroup.get('Product_nm').patchValue(x.Short_nm.trim());
  //         formgroup.get('Unit').patchValue(x.Unit);
  //       });
  //     }, err => {
  //       console.log(err);
  //     });
  // }
  PrdWithStk(index: number) {
    let tranfromId = this.DataForm.get("Tran_from").value;
    let formarray = (this.DataForm.controls['Items'] as FormArray);
    let formgroup = (formarray.at(index) as FormGroup);
    let product_id = formgroup.get('Product_id').value;
    let firmx = this.hardfunc.Firmx;
    let yrx = this.hardfunc.Yrx;
    let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/PrdWithStock?product_id=" +product_id+ 
                                      "&tranfromId=" +tranfromId+ "&Firmx=" +firmx+ "&Yrx=" +yrx;
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        console.log(data1);
        this.productlist = data1;
        this.productlist = this.productlist.map((x) => {
          let formarray = (this.DataForm.controls['Items'] as FormArray);          
          let formgroup = (formarray.at(index) as FormGroup);

          formgroup.get('Product_nm').patchValue(x.Short_nm.trim());
          formgroup.get('Unit').patchValue(x.Sunit);
          formgroup.get("Stock").patchValue(x.Stock);
        });
      }, err => {
        console.log(err);
      });
  }
  getDeptName() {
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/deptssAPI/GetDatas";
    this._http.post(url, this.repmodel)
      .subscribe((data1: any) => {
        this.Dept_nm = data1;
        //this.selectedDrpdown();
      }, err => {
        console.log(err);
      });
  }
  onClear() {

  }
  createFormGroup() {
    return this.fb.group({
      Facmast_id: new FormControl(-1),
      TranType: this.Type,
      fUnit: new FormControl(this.Unit),
      Issue_to: new FormControl(1),
      Tdate: new FormControl(new Date(), Validators.min(moment(new Date()).millisecond())),
      Slip_no: new FormControl(''),
      Sender_name: new FormControl(''),
      Rcvr_name: new FormControl(''),
      Remarks: new FormControl(''),
      Tran_from: new FormControl(1),
      Type: this.Type,
      Items: this.fb.array([this.createItem()]),
    });
  }
  createItem(): FormGroup {
    return this.fb.group({
      Ow_mast_id: [0], //new FormControl(-1)
      Product_id: [],
      Product_nm: [''],
      Qnty: [0],
      Unit: new FormControl('NOS.'),
      Qnty2: [0],
      Unit2: new FormControl('NOS.'),
      Stock: new FormControl(),
    });
  }
  HelpFormGroup() {
    return this.fb.group({
      Product_id: new FormControl(),
      Product_nm: new FormControl(''),
    });
  }
}
