import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
declare var jsPDF: any;
import html2canvas from 'html2canvas';
import * as html2pdf from 'html2pdf.js';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { OsdetailComponent } from '../sales/osdetail/osdetail.component';

@Component({
  selector: 'app-dbdetail',
  templateUrl: './dbdetail.component.html',
  styleUrls: ['./dbdetail.component.css']
})
export class DbdetailComponent implements OnInit {
  repmodel: any = {};
  loading: boolean;
  datalist: any[];
  g_Invtot: number = 0;
  g_bsc: number = 0;
  c_bsc: number = 0;
  tc_bsc: number = 0;
  g_gst: number = 0;
  g_igst = 0;
  xAmt: number = 0;
  xtot: number = 0;
  Counter: number = 0;
  stot: number;
  btot: number = 0;
  gtot: number = 0;
  constructor(
    private _http: HttpClient,
    private hardfunc: HardFuncService,
    private _cservice: CommonService,
    private _snackBar: MatSnackBar,
    private notificationservice: NotificationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public receiveddata: any
  ) { }

  ngOnInit() {
    this.RefreshData();
  }

  // Refresh Data
  RefreshData() {
    this.Counter++;
    this.loading = true;
    this.datalist = [];

    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })

    this.repmodel = this.receiveddata;
    if (this.repmodel.DF === 'E') {
      this.repmodel.DF = 'F';
    }

    this.repmodel.Yrx = this.hardfunc.Yrx;
    if (this.receiveddata.Type === "Osdata" || this.receiveddata.Type === "Paymrecd") {
      this.repmodel.Firmx = this.receiveddata.Firmx;
    }
    else {
      this.repmodel.Firmx = this.hardfunc.Firmx;
    }

    this.repmodel.Ac_typ = 'T';
    // Sales Order
    if (this.receiveddata.Type === "Order" || this.receiveddata.Type === "Spkdata") {

      if (this.receiveddata.Type === "Order") {
        this.repmodel.Grp_yn = 'N';
      }
      else {
        this.repmodel.Grp_yn = 'Y';
        //this.repmodel.DF = 'A';
      }
      console.log(this.repmodel);
      let url = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/OrderDetails_bydate";

      this._http.post(url, this.repmodel, { headers: headers })
        .subscribe((data1: any) => {
          this.loading = false;
          this.datalist = [];
          let l_Ordno = -1;
          let l_Oloc = -1;
          this.xtot = 0;
          this.stot = 0;

          data1.map(m => {
            if (l_Ordno !== m.Ordno || l_Oloc !== m.Oloc) {
              if (l_Ordno !== -1) {
                this.datalist.push({ Header: 2, OrdTotal: this.stot });
              }
              this.datalist.push({
                Header: 1, Ordno: m.Ordno,
                Oloc: m.Oloc,
                Orddt: m.Orddt,
                Pmst_cod: m.Pmst_cod,
                Party_name: m.Party_name,
                Party_city: m.Party_city,
                Party_stat: m.Party_stat,
                Lyr_clbal: m.Lyr_clbal,
                Ordnos: m.Ordnos
              });
              l_Ordno = m.Ordno
              l_Oloc = m.Oloc
              this.stot = 0;

            }
            m.Header = 0;
            m.Pro_detail = m.Product_id + "-" + m.Pro_detail;
            { this.datalist.push(m); }
            this.xtot += m.Qnty * m.Rate
            this.stot += m.Qnty * m.Rate
          });
          this.datalist.push({ Header: 2, OrdTotal: this.stot });
          this.datalist.push({ Header: 9, OrdTotal: this.xtot });
        }, err => {
          console.log(err);

        });
    }
    // Statewise Sales Order detail

    if (this.receiveddata.Type === "StatorData") {

      let url = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/OrderDetails_bystate";

      this._http.post(url, this.repmodel, { headers: headers })
        .subscribe((data1: any) => {
          this.loading = false;
          this.datalist = [];
          let l_Ordno = -1;
          let l_Oloc = -1;
          data1.map(m => {
            if (l_Ordno !== m.Ordno || l_Oloc !== m.Oloc) {
              if (l_Ordno !== -1) {
                this.datalist.push({ Header: 2, OrdTotal: this.stot });
              }
              this.datalist.push({
                Header: 1, Ordno: m.Ordno,
                Oloc: m.Oloc,
                Orddt: m.Orddt,
                Party_name: m.Party_name,
                Party_city: m.Party_city,
                Party_stat: m.Party_stat,
                Ordnos: m.Ordnos
              });
              l_Ordno = m.Ordno
              l_Oloc = m.Oloc
              this.stot = 0;
            }
            m.Header = 0;
            { this.datalist.push(m); }
            this.xtot += m.Qnty * m.Rate
            this.stot += m.Qnty * m.Rate
          });
          this.datalist.push({ Header: 2, OrdTotal: this.stot });
          this.datalist.push({ Header: 9, OrdTotal: this.xtot });

        }, err => {
          console.log(err);

        });
    }

    // Partywise Pending Sales Order detail

    if (this.receiveddata.Type === "PenOrderBlock") {

      let url = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/PendOrderdetails_byparty";

      this._http.post(url, this.repmodel, { headers: headers })
        .subscribe((data1: any) => {
          console.log(data1);
          this.loading = false;
          this.xtot = 0;
          this.stot = 0;
          this.datalist = [];
          let l_Ordno = -1;
          let l_Oloc = -1;
          data1.map(m => {
            if (l_Ordno !== m.Ordno || l_Oloc !== m.Oloc) {
              // Footer Row
              if (l_Ordno !== -1) {
                this.datalist.push({ Header: 2, OrdTotal: this.stot });
              }

              // Header Row
              this.datalist.push({
                Header: 1,
                Ordno: m.Ordno,
                Oloc: m.Oloc,
                Orddt: m.Orddt,
                Ordnos: m.Ordnos
              });

              l_Ordno = m.Ordno;
              l_Oloc = m.Oloc;
              this.stot = 0;
            }
            this.xtot += m.Qnty * m.Rate;
            this.stot += m.Qnty * m.Rate;
            m.Header = 0;
            { this.datalist.push(m); }            
          });
          this.datalist.push({ Header: 2, OrdTotal: this.stot });
          this.datalist.push({ Header: 9, OrdTotal: this.xtot });
        }, err => {
          console.log(err);

        });
    }

    // SALES - START   

    if (this.receiveddata.Type === "Sales") {
      let url = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/SalesdetailsByDate";
      this._http.post(url, this.repmodel, { headers: headers })
        .subscribe((data1: any) => {
          this.loading = false;
          this.datalist = [];
          let l_Invno = -1;
          let l_Exr = '';
          let l_Pono = -1;
          let l_Year = ''
          this.g_Invtot = 0;
          this.g_bsc = 0;
          this.g_gst = 0;
          this.c_bsc = 0;
          this.tc_bsc = 0;
          this.xtot = 0;
          this.stot = 0;
          this.btot = 0;
          this.gtot = 0;
          data1.map(m => {
            if (l_Invno !== m.Invno || l_Exr !== m.Exr) {
              if (l_Invno != -1) {
                this.datalist.push({
                  Header: 2,
                  Total: this.stot,
                  Bsc: this.g_bsc,
                  Gst: this.g_gst,
                  Cbsc: this.c_bsc
                });
              }
              this.stot = 0;
              this.g_bsc = 0;
              this.g_gst = 0;
              this.c_bsc = 0;
              this.datalist.push({
                Header: 1, Exr: m.Exr,
                Invno: m.Invno,
                Pmst_cod: m.Pmst_cod,
                Party_name: m.Party_name,
                Party_city: m.Party_city,
                Party_stat: m.Party_stat,
                Invtot: m.Invtot,
                Orno: m.Orno,
                Oloc: m.Oloc,
                Invdt: m.Invdt,
                Dir_nm: m.Dir_nm,
                Fld_nm: m.Fld_nm,
                Lyr_clbal: m.Lyr_clbal
              });
              l_Invno = m.Invno
              l_Exr = m.Exr
            }


            m.Header = 0;
            {
              this.datalist.push(m);
              this.stot += m.Bsc + m.Excamt + m.Tax;
              this.g_bsc += m.Bsc;
              this.c_bsc += m.Fbsc;
              this.g_gst += m.Excamt + m.Tax;

              this.btot += m.Bsc;
              this.gtot += m.Excamt + m.Tax;
              this.xtot += m.Bsc + m.Excamt + m.Tax;
              this.tc_bsc += m.Fbsc
            }
          });
          this.datalist.push({
            Header: 2,
            Total: this.stot,
            Bsc: this.g_bsc,
            Gst: this.g_gst,
            Cbsc: this.c_bsc

          });

          this.datalist.push({
            Header: 9, Total: this.xtot,
            Bsc: this.btot,
            Gst: this.gtot,
            Fbsc: this.tc_bsc
          });

        }, err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationservice.warn(errstr);

        });
    }
    // SALES - END

    // SPK SALES  - START   
    if (this.receiveddata.Type === "Totspkdt") {
      let url = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/SpkSalesdetailsByDate";
      this._http.post(url, this.repmodel, { headers: headers })
        .subscribe((data1: any) => {
          this.loading = false;

          this.datalist = [];
          let l_Invno = -1;
          let l_Exr = ''
          this.g_Invtot = 0;
          this.g_bsc = 0;
          this.g_gst = 0;
          this.g_igst = 0;

          this.stot = 0;
          this.xtot = 0;
          let sbsc = 0;
          let xbsc = 0;
          data1.map(m => {
            if (l_Invno !== m.Invno || l_Exr !== m.Exr) {
              if (l_Invno != -1) {
                this.datalist.push({ Header: 2, Total: this.stot });
              }
              this.stot = 0;

              this.datalist.push({
                Header: 1, Exr: m.Exr,
                Invno: m.Invno,
                Party_name: m.Party_name,
                Party_city: m.Party_city,
                Party_stat: m.Party_stat,
                Invtot: m.Invtot,
                Orno: m.Orno,
                Oloc: m.Oloc,
                Invdt: m.Invdt
              });
              this.g_Invtot += m.Bsc + m.Excamt + m.Tax;
              this.g_bsc += m.Bsc;
              l_Invno = m.Invno
              l_Exr = m.Exr
            }
            this.stot += m.Bsc + m.Excamt + m.Tax;
            this.xtot += m.Bsc + m.Excamt + m.Tax;
            sbsc += m.Bsc;
            xbsc += m.Bsc;
            m.Header = 0;
            { this.datalist.push(m); }
          });
          this.datalist.push({ Header: 2, Total: this.stot, Bsc: sbsc });
          this.datalist.push({ Header: 9, Total: this.xtot, Bsc: xbsc });
        }, err => {
          console.log(err);

        });
    }
    // SPK SALES - END

    // SALES State - START   
    if (this.receiveddata.Type === "Totstat") {
      let url = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/dash_dispinfm_statewise";
      this._http.post(url, this.repmodel, { headers: headers })
        .subscribe((data1: any) => {
          this.loading = false;
          this.datalist = [];
          let l_Invno = -1;
          let l_Exr = ''

          let g_Invtot = 0;
          let s_bsc = 0;
          let s_gst = 0;
          let s_igst = 0;

          let s_Invtot = 0;

          data1.map(m => {
            if (l_Invno !== m.Invno || l_Exr !== m.Exr) {
              if (l_Invno != -1) {
                this.datalist.push({
                  Header: 2, Bsc: s_bsc,
                  Gst: s_gst,
                  Igst: s_igst
                });
              }


              this.datalist.push({
                Header: 1, Exr: m.Exr,
                Invno: m.Invno,
                Party_name: m.Party_name,
                Party_city: m.Party_city,
                Party_stat: m.Party_stat,
                Invtot: m.Invtot,
                Orno: m.Orno,
                Oloc: m.Oloc,
                Invdt: m.Invdt,
                Ordnos: m.Ordnos
              });

              s_bsc = 0;
              s_igst = 0;
              s_gst = 0;

              l_Invno = m.Invno;
              l_Exr = m.Exr
            }
            m.Header = 0;
            g_Invtot += m.Bsc + m.Excamt + m.Tax;
            s_bsc += m.Bsc;
            s_igst += m.Excamt;
            s_gst += m.Tax;
            { this.datalist.push(m); }
          });

          this.datalist.push({
            Header: 2, Bsc: s_bsc,
            Gst: s_gst,
            Igst: s_igst
          })

          this.datalist.push({
            Header: 9, InvTot: g_Invtot
          })

        }, err => {
          console.log(err);

        });
    }
    // SALES State - END


    // Osdata - START   
    if (this.receiveddata.Type === "Osdata") {
      let url = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/dash_OS_SUM_det";
      this._http.post(url, this.repmodel, { headers: headers })
        .subscribe((data1: any) => {
          this.loading = false;
          this.datalist = [];
          let l_Party = -1;
          let t_Balamt = 0;
          let t_Invamt = 0;
          let t_Paymrcd = 0;
          let t_btot = 0;
          data1.map(m => {
            if (l_Party !== m.Party) {
              if (l_Party != -1) {
                this.datalist.push({
                  Header: 2, Party: 'Sub-Total',
                  Balamt: t_Balamt,
                  Invamt: t_Invamt,
                  Paymrcd: t_Paymrcd
                })
              }
              t_Balamt = 0;
              t_Invamt = 0;
              t_Paymrcd = 0;
              this.datalist.push({ Header: 1, Party: m.Party,Firm : m.Firm });
              l_Party = m.Party
            }
            m.Header = 0;
            t_Balamt += m.Balamt;
            t_btot += m.Balamt
            t_Invamt += m.Invamt;
            t_Paymrcd += m.Paymrcd;
            { this.datalist.push(m); }
          });  // loop-end
          this.datalist.push({
            Header: 2, Party: 'Sub-Total',
            Balamt: t_Balamt,
            Invamt: t_Invamt,
            Paymrcd: t_Paymrcd
          })

          this.datalist.push({
            Header: 9, Balamt: t_btot
          });


        }, err => {
          console.log(err);

        });
    }
    // Osdata - END

    // Paymrecd - START   
    if (this.receiveddata.Type === "Paymrecd") {
      let url = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/dash_paymrcd_datewise";
      this._http.post(url, this.repmodel, { headers: headers })
        .subscribe((data1: any) => {
          this.loading = false;
          this.datalist = data1;
          let xAmt = 0
          data1.map(xyz => {
            xAmt += xyz.Amt;

          });
          data1.push({
            Amt: xAmt
          });

        }, err => {
          console.log(err);

        });

    }

    // Paympaid - START   
    if (this.receiveddata.Type === "Paympaid") {

      let url = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/paympaid_datewise";
      this._http.post(url, this.repmodel, { headers: headers })
        .subscribe((data: any) => {
          this.loading = false;
          let data1 = data;
          this.datalist = [];
          this.xAmt = 0
          data1.map(xyz => {   // Do While .not. eof()
            this.xAmt += xyz.Amt;
            xyz.Header = 0;
            { this.datalist.push(xyz); }
          });  // Enddo
          this.datalist.push({
            Header: 9, Total: this.xAmt
          })

        }, err => {
          console.log(err);

        });

    }

    


    if (this.receiveddata.Type === "Ppurordata") {

      let url = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/Penpurpolist";
      this._http.post(url, this.repmodel, { headers: headers })
        .subscribe((data1: any) => {
          data1 = data1;
          console.log(data1);
          this.loading = false;
          console.log(data1);
          let l_Year = '###';
          let l_Pono = -1;
          this.datalist = [];
          this.xAmt = 0
          data1.map(m => {
            if (l_Year !== m.Year || l_Pono !== m.Pono)
              this.datalist.push({
                Header: 1, Year: m.Year,
                Pono: m.Pono,
                Orddt: m.Orddt
              });
            l_Pono = m.Pono;
            l_Year = m.Year;

            m.Header = 0;
            {
              this.datalist.push(m);

            }

          });

        }, err => {
          console.log(err);

        });

    }

    // Purchase  - START   
    if (this.receiveddata.Type === "Purchase") {
      let url = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/dash_purchase_det";
      this._http.post(url, this.repmodel, { headers: headers })
        .subscribe((data1: any) => {
          this.loading = false;
          let data = data1
          let l_Srno = -1;
          this.datalist = [];
          let s_Bsctot = 0;
          let s_Taxamt = 0;
          let g_Bsctot = 0;
          let g_Taxamt = 0;
          data.map(m => {
            let t_invtot = 0;
           
            if (l_Srno !== m.Srno) {
              if (l_Srno > 0) {
                this.datalist.push({
                  Header: 2,
                  Bsctot: s_Bsctot,
                  Taxamt: s_Taxamt
                });
                s_Bsctot = 0;
                s_Taxamt = 0;
                
              }

              this.datalist.push({
                Header: 1,
                Srno: m.Srno,
                Party_name: m.Party_name,
                Invno: m.Orddt,
                Invtot: m.Invtot,
              });
              l_Srno = m.Srno;

            }

            m.Header = 0;
            this.datalist.push(m, t_invtot = + m.Bsctot + m.Taxamt);
            s_Bsctot = s_Bsctot+ m.Bsctot;
            s_Taxamt = s_Taxamt+ m.Taxamt;
            g_Bsctot = g_Bsctot+ m.Bsctot;
            g_Taxamt = g_Taxamt+ m.Taxamt;
          });
          if (l_Srno > 0) {
            this.datalist.push({
              Header: 2,
              Bsctot: s_Bsctot,
              Taxamt: s_Taxamt
            });
          }
          this.datalist.push({
            Header: 3,
            Bsctot: g_Bsctot,
            Taxamt: g_Taxamt
          });
        }, err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationservice.warn(errstr);

        });
    }
    // Purchase - END



  }

  ExportPDF() {

    let element = document.getElementById('htmltable');
    // Trial -1  -- Partial Image shows
    //
    // let options = {
    //   background: "white",
    //   allowTaint : false,
    //   useCORS: false
    // };  

    // html2canvas(element,options).then(canvas => {
    //     let docWidth = 208;
    //     let docHeight = canvas.height * docWidth / canvas.width;
    //     //let docHeight = canvas.height;
    //     const contentDataURL = canvas.toDataURL('image/png')
    //     let doc = new jsPDF('p', 'mm', 'a4');
    //     let position = 0;
    //     doc.addImage(contentDataURL, 'PNG', 0, position, docWidth, 2500);

    //     doc.save('exportedPdf.pdf');
    // });

    // Trial -2 --  Grouping Issue
    // 
    // var elementHandler = {
    //   '#ignorePDF': function (element, renderer) {
    //     return true;
    //   }
    // };
    // let doc = new jsPDF('l', 'pt');
    // doc.fromHTML(
    //   element,
    // 15,
    // 15,
    // {
    //   'width': 180,'elementHandlers': elementHandler
    // });
    // doc.save('dbdetail.pdf');

    // Trial -3  -- Grouping issue
    // var columns = [
    //   {title: "Srno", dataKey: "Srno"},
    //   {title: "Product_id", dataKey: "Product_id"}, 
    //   {title: "Pro_detail", dataKey: "Pro_detail"}, 
    //   {title: "Qnty", dataKey: "Qnty"}
    // ];
    // var style={};
    // var columnstyle={  
    // 0: { columnWidth: 80, halign: 'right' },
    // 1: { columnWidth: 80, halign: 'right' },
    // 2: { columnWidth: 225 },
    // 3: { columnWidth: 80, halign: 'right' }
    // };

    // let doc = new jsPDF('l', 'pt');
    // doc.autoTable(columns,this.datalist,{styles:style,columnStyles:columnstyle });
    // doc.save('dbdetail.pdf');


    // Trial -4

    // var HtmlStr = "<table>";
    // HtmlStr += " <thead> ";
    // HtmlStr += "<tr>";
    // HtmlStr += "  <td class='string'>Sr.</td>";
    // HtmlStr += "  <td class='string'>Item Name</td>";
    // HtmlStr += "  <td class='number'>Rate</td>";
    // HtmlStr += "  <td class='number'>Qnty</td>";
    // HtmlStr += "  <td class='number'>Pen.Qnty</td>";
    // HtmlStr += "  <td class='number'>Item Value</td>";
    // HtmlStr += "</tr>";
    // HtmlStr += "</thead>";
    // HtmlStr += "<tbody>";
    // let l_Ordno = -1;
    // let l_Oloc = -1;
    // var elementHandler = {
    //     '#ignorePDF': function (element, renderer) {
    //       return true;
    //    }
    //  };
    // this.datalist.map(m => {
    //   if (l_Ordno !== m.Ordno || l_Oloc !== m.Oloc) {
    //     if (l_Ordno !== -1) {
    //      // this.datalist.push({ Header: 2, OrdTotal: this.stot });
    //     }
    //     // Group Header
    //     l_Ordno = m.Ordno
    //     l_Oloc = m.Oloc
    //     this.stot = 0;
    //   }
    //   if (m.Header === 1)  // Group Header
    //   {
    //     HtmlStr += "<tr>"
    //     HtmlStr += "<td colSpan=6>"+ m.Party_name+ " </td>"
    //     HtmlStr += "<td> </td>"
    //     HtmlStr += "<td> </td>"
    //     HtmlStr += "<td> </td>"
    //     HtmlStr += "<td> </td>"
    //     HtmlStr += "<td> </td>"
    //     //HtmlStr += "<td colspan=6>"+m.Ordno + m.Oloc+ m.Orddt + m.Party_name + m.Party_city+ m.Party_stat+ " PONo: "+ m.Ordnos+"</td>"
    //     HtmlStr += "</tr>"
    //   }

    //   if (m.Header === 0)  // Detail Section
    //   {
    //       HtmlStr += "<tr>"
    //       HtmlStr += "<td class='number' style='width:10%'>"+m.Srno+"</td>"
    //       HtmlStr += "<td class='string' style='width:40%'>"+m.Product_id + m.Pro_detail +"</td>";
    //       HtmlStr += "<td class='number' style='width:10%'>"+m.Rate +"</td>";
    //       HtmlStr += "<td class='number' style='width:10%'>"+m.Qnty +" </td>";
    //       HtmlStr += "<td class='number' style='width:10%'>"+m.Pqnty +"</td>";
    //       HtmlStr += "<td class='number' style='width:10%'>"+m.Qnty * m.Rate +"</td>";
    //       HtmlStr += "</tr>"
    //   }
    // });
    // HtmlStr += "</tbody>";
    // HtmlStr += "</table>";
    // let doc = new jsPDF('l', 'pt');
    // doc.fromHTML(
    //   HtmlStr, // HTML string or DOM elem ref.
    //   0, // x coord
    //   0,
    //   { 'width': 180 ,'elementHandlers': elementHandler}
    // );
    // doc.save('dbdetail.pdf');

    // Trial -5
    var opt = {
      margin: 1,
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    console.log(opt);
    // New Promise-based usage:
    html2pdf().from(element).set(opt).save();
  }

  Osdetail(Yrx: Number, Pmst_cod: string, Party_name: string, Party_city: string, Party_stat: string, Lyr_clbal: number) {

    let Firm = this.hardfunc.Firmx;
    const dialogRef = this.dialog.open(OsdetailComponent, {

      width: '1100px',
      data: { Firm, Yrx, PMST_COD: Pmst_cod, Party_name: Party_name, Party_city: Party_city, Party_stat: Party_stat, Party_bal: Lyr_clbal }

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }
  CreatePDF(){
    if (this.receiveddata.Type === "Osdata") {
      this.repmodel.Firmx = this.hardfunc.Firmx;
      this.repmodel.Fyear = this.hardfunc.Yrx;
      let url = this.hardfunc.BaseAPIUrl + "api/DashboardAPI/dash_OS_SUM_detPDF";
      this._http.post(url, this.repmodel, { headers: this.hardfunc.PostHeaders(), responseType: 'blob' as 'json' })
        .subscribe((data1: any) => {
          this.loading = false;
            console.log(data1);
            const data = window.URL.createObjectURL(data1);
            var link = document.createElement('a');
            link.href = data;
            link.target = "_blank";
            link.download = "Os Data.pdf";
            link.click();
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);


        }, err => {
          console.log(err);

        });
    }
  }
  
  PartywisePDF(Party: string,Firm: string,DueYear:string){
    this.loading = true;
    this.datalist = [];
    let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/GetDetailPDF";
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    this.repmodel.Firmx = Firm;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    let Pmst_cod = Party.substring(0,5) ;
    this.repmodel.Pmst_cod = Pmst_cod;
    this.repmodel.DueYear = DueYear;
    this._http.post(url, this.repmodel, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((data1: any) => {
            console.log(data1);
            const data = window.URL.createObjectURL(data1);
            var link = document.createElement('a');
            link.href = data;
            link.target = "_blank";
            link.download = Firm + "_" + Party.trim()+".pdf";
            this.loading = false;
            link.click();
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);
            this.RefreshData();
				},
				err => {
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description, "dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					}
				}
			)
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}


// Paymrecd - EN



