import { Component, OnDestroy, OnInit } from '@angular/core';
import { FdrService } from '../fdr.service';
import { Sort, MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { FDRComponent } from '../fdr.component';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/Services/Common/common.service';
import * as   XLSX from 'xlsx';
import * as moment from 'moment'

@Component({
	selector: 'app-fdr-list',
	templateUrl: './fdr-list.component.html',
	styleUrls: ['./fdr-list.component.css']
})
export class FdrListComponent implements OnInit, OnDestroy {

	loading: boolean = false;
	datalist: any = [];
	datasource: any = [];
	dataSource: MatTableDataSource<unknown>;
	displayedColumns: string[] = [];
	amount: number;
	matvalue: number;
	DataForm: FormGroup;
	BankList: any = [];
	filename:string= 'BG-List.xlsx';
	Excel: any = [];

	constructor(private fdrservice: FdrService,
				private _snackBar: MatSnackBar,
				private dialog: MatDialog,
				private adminservice: AdminService,
				private notificationService: NotificationService,
				private hardfunc: HardFuncService,
				private fb: FormBuilder,
				private commonservice: CommonService) { }

	ngOnInit() {
		this.getfdrList();
		this.DataForm = this.createFormGroup();
		this.loading = false;
	}
	ngOnDestroy(): void {
		console.clear();
	}
	DisplayCol() {
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['Nos','Firm', 'Bank_id', 'Fdrno', 'Liendept','Amt', 'Fdrdt', 'Period_d', 'Roi', 'Matval', 'Matdt', 'Dstatus', 'Remarks','Action'];
	}
	getData() {
	this.loading = true;
	this.datalist = [];
	var data: any = {};
	let cnd = " 1=1 ";
	if (this.DataForm.value.Firm != "*ALL*") {
		cnd += " and Firm = ''" + this.DataForm.value.Firm + "''";
	}
	if (this.DataForm.value.Dstatus != "*All*") {
		cnd += " and Dstatus = ''" + this.DataForm.value.Dstatus + "''";
	}
	if (this.DataForm.value.Bank_nm != "*All*") {
		cnd += " and a.Bank_id = " + this.DataForm.value.Bank_nm ;
	}
	if (this.DataForm.value.Liendept != "*All*") {
		cnd += " and a.Liendept = ''" + this.DataForm.value.Liendept +"''" ;
	}
	if (this.DataForm.value.Fdr_till != "*All*") {
		if(this.DataForm.value.Fdr_till == "Today"){
			cnd += " and Fdrdt >= convert(datetime,getdate(),103) "; 
		}
		if(this.DataForm.value.Fdr_till == "Matdt"){
			cnd += " and Fdrdt <= convert(datetime,getdate(),103) "; 
		}			
	}
	data.cond = cnd;
	this.fdrservice.getDatas(data)
		.subscribe(
			data => {
			console.log(data);
			this.datalist = data;
			this.amount = 0;
			this.matvalue = 0;
			this.datalist.map(m => {
				this.amount += m.Amt;
				this.matvalue += m.Matval;
			})
			this.loading = false;
			this.dataSource = new MatTableDataSource(<any>this.datalist);
			this.DisplayCol();
			},
			err => {
				if (err.error.error_description) {
					this.openSnackBar(err.error.error_description, "dismiss");
				}
				else {
					this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
				}
			}
		)
	}
	exportexcel():void{
		// Option-2 Entire JSON
		const ws1:XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.datalist);
		this.datalist.map(m =>{
			m.Fdrdt = moment(m.Fdrdt).format('DD/MM/yyyy')
			m.Matdt = moment(m.Matdt).format('DD/MM/yyyy')
		})
		// Option-3 Selected fields from JSON
		let newData = this.datalist.map(({Firm, Bank_id, Fdrno, Liendept, Amt, Fdrdt, Matdt, Matval }) => 
										({Firm, Bank_id, Fdrno, Liendept, Amt, Fdrdt, Matdt, Matval }));
		const ws:XLSX.WorkSheet=XLSX.utils.json_to_sheet(newData);

		const wb:XLSX.WorkBook=XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws,'Sheet1');
	
		XLSX.writeFile(wb,this.filename);
	  }
	getfdrList() {
		this.loading = true;
		this.commonservice.CodeList('FDR_BANK')
		  .subscribe(
			(data: any) => {
			  this.BankList = JSON.parse(data);
			  this.loading = false;
			},
			err => {
			  this.loading = false;
			  let errstr = this.hardfunc.getError(err);
			  this.notificationService.warn(errstr);
			}
		  );
	  }
	openDialog(Fd_id: number){
		const dialogRef = this.dialog.open(FDRComponent, {
			width: '850px',
			height: '400px',
			data: { id: Fd_id }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}
	DeleteRcd(Fd_id: number): void {
		this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
			.afterClosed().subscribe(res => {
			if (res) {
				this.loading = true;
				const dialogRef = this.fdrservice.DeleteFdr(Fd_id)
					.subscribe(
						(data: any) => {
							this.loading = false;
							this.notificationService.warn("Data deleted succefully");
							this.getData();
						},
						err => {
							this.loading = false;
							let errstr = this.hardfunc.getError(err);
							console.log(err);
							this.notificationService.warn(errstr);
						}
					)
			}
			});
	}
	createFormGroup() {
		return this.fb.group({
		  Firm: new FormControl('*ALL*', Validators.required),
		  Bank_nm: new FormControl('*All*',Validators.required),
		//   Fdr_till: new FormControl(''),
		  Dstatus: new FormControl('*All*'),
		  Liendept: new FormControl('*All*'),
		});
	  }
	openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}

	// sortData(sort: Sort) {
	// 	const data = this.datalist.slice();
	// 	if (!sort.active || sort.direction === '') {
	// 	  this.dataSource = data;
	// 	  return;
	// 	}
	
    // function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    //   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    //   }

	// 	this.dataSource = data.sort((a, b) => {
	// 	  const isAsc = sort.direction === 'asc';
	// 	  switch (sort.active) {
	// 		case 'Fdrno': return compare(a.Matdt, b.Matdt, isAsc);
	// 		case 'Matdt': return compare(a.Matdt, b.Matdt, isAsc);
	// 		case 'Fd_id': return compare(a.Fd_id, b.Fd_id, isAsc);
	// 		case 'Fdrdt': return compare(a.Fdrdt, b.Fdrdt, isAsc);
	// 		default: return 0;
	// 	  }
	// 	});
	// }
	sortData(sort: Sort) {
		const data = this.datalist.slice();
		if (!sort.active || sort.direction === '') {
			this.dataSource = data;
			return;
		}

		this.dataSource = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			//const isDes = sort.direction === 'desc';
			switch (sort.active) {
				case 'Nos': return compare(a.Fd_id, b.Fd_id, isAsc);
				case 'Fdrdt': return compare(a.Fdrdt, b.Fdrdt, isAsc);
				case 'Matdt': return compare(a.Matdt, b.Matdt, isAsc);
				case 'Dstatus': return compare(a.Dstatus, b.Dstatus, isAsc);
				
				default: return 0;
			}
		});
	}
}
function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}