import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { CommonService } from 'src/app/Services/Common/common.service';
import { ManufacturingService } from '../manufacturing.services';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { PipeTransform, Pipe } from '@angular/core';

@Component({
  selector: 'app-product-sales',
  templateUrl: './product-sales.component.html',
  styleUrls: ['./product-sales.component.css']
})
export class ProductSalesComponent implements OnInit {

  loading: boolean = false;
  repmodel: any = {};
  PrdGroupList: any = [];
  GrpList: any = [];
  ProdSales: any = [];
  Pparalist: any = [];
  paletteColour = 'primary';
  startButton = false;
  ChangeColor: any = [];
  Year21_Tot: number;
  Year22_Tot: number;
  Year23_Tot: number;
  Qnty_Tot: number;
  PQnty_Tot: number;

  constructor(private _cservice: CommonService,
    private _snackBar: MatSnackBar,
    private manufacturingService: ManufacturingService,
    private hardfunc: HardFuncService,
    private NotificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.repmodel.PrioLength = 4;
    this.repmodel.CG = 'A';
    this.repmodel.Status = 'P';
    this._cservice.PrdGroupList() // 2. api/PrdGroupsAPI/GetPrdGroup -- API For --Get Product Group
      .subscribe(data => {
        this.PrdGroupList = data;
        this.loading = false;
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }
  // change(Ppara_id: number,Priority: number) {
  //   this.Pparalist.map(w => {
  //     if(w.Priority <= Priority && w.Sales === 'Y')
  //     {
  //       this.ChangeColor = w;
  //       console.log(this.ChangeColor);
  //       this.ChangeColor.forEach(element => {
  //         if(element){
  //           this.paletteColour = 'warn';
  //         }
  //       });

  //     }
  //   });
  //   // this.Pparalist.map(m => {
  //   //   if(m.Priority <= Priority && m.Sales === 'Y')
  //   //   {
  //   //     console.log(m);
  //   //     this.paletteColour = 'warn';
        
  //   //   }else{
  //   //     this.paletteColour = 'primary';
  //   //   }
  //   // });
    
  //  }

  ProductSales(Ppara_id: number, Priority : number) {
    this.loading = true;
    let PrioLength = 2;
    this.Pparalist.map(m => {
      if(m.Priority <= Priority && m.Sales === 'Y')
      {
        PrioLength += m.Codelen;  
      }
    });
    this.manufacturingService.ProductSales(this.repmodel.Grp_id, PrioLength)
      .subscribe((data: any) => {
        this.Year21_Tot = 0;
        this.Year22_Tot = 0;
        this.Year23_Tot = 0;
        this.Qnty_Tot = 0;
        this.PQnty_Tot = 0;
        this.ProdSales = data;
        this.loading = false;
        this.ProdSales.map(x => {
          this.Year21_Tot += x.Yr2021;
          this.Year22_Tot += x.Yr2022;
          this.Year23_Tot += x.Yr2023;
          this.Qnty_Tot += x.CumuQnty;
          this.PQnty_Tot += x.CumuPQnty;
        });
      }, err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.NotificationService.warn(errstr);
        this.loading = false;
      });
  }
  GetPparadata() {
    this.ProdSales = [];
    this.manufacturingService.GetPparabyId(this.repmodel.Grp_id)
      .subscribe(
        (data: any) => {
          this.Pparalist = data;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.NotificationService.warn(errstr);
        }
      );
  }
  Grpdata() {
    this.loading = true;
    this.manufacturingService.ProductItems(this.repmodel.Grp_id)
      .subscribe((data: any) => {
        console.log(data);
        this.GrpList = data;
        this.loading = false;

      }, err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.NotificationService.warn(errstr);
        this.loading = false;
      });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
