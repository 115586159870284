import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import * as moment from 'moment';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { Router } from '@angular/router';
import { PdcService } from '../Pdc.service';

@Component({
	selector: 'app-pdc-dash',
	templateUrl: './pdc-dash.component.html',
	styleUrls: ['./pdc-dash.component.css']
})
export class PdcDashComponent implements OnInit, OnDestroy {
	Permission: string = 'NNNNNNNNNN';
	msgs: any[] = [];
	Firmx: any[];
	Numbers: any = [1, 2, 3, 4, 5, 6,7,8];
	datalist: any = [];
	title: any = [];
	loading: boolean = false;
	datasource: any = [];
	displayedColumns: string[] = [];
	repmodel: any = {};
	status_list: any = [];
	bSearch: any = [];
	Fdate: Date;
	Tdate: Date;
	duplicateArray = []
	Status: string;
	StatusList: any = [];
	FieldList: any = [];
	sort: any;
	chkDate: boolean = false;
	err: any[];
	cols: any[];
	checked: boolean = false;
	Paid_Total: number;

	constructor(
		private _http: HttpClient,
		public dialog: MatDialog,
		private commonservice: CommonService,
		private notificationservice: NotificationService,
		private _snackBar: MatSnackBar,
		private hardfunc: HardFuncService,
		private adminservice: AdminService,
		private router: Router,
		private pdcservice: PdcService,


	) { }

	ngOnInit() {
		this.getCodeList();
		this.title[1] = "(1) DEPOSIT IN NEXT 5 DAYS"
		this.title[2] = "(2) EXPIRING IN NEXT 30 DAYS "
		this.title[3] = "(3) BOUNCED IN LAST 15 DAYS"
		this.title[4] = "(4) EXPIRED WITHOUT RE-ISSUE IN LAST 15 DAYS"
		this.title[5] = "(5) PDC ON HOLD"
		this.title[6] = "(6) PDC UNDER CLEARING"
		this.title[7] = "(7) LAST 7 DAYS CHQS"
		this.title[8] = "(8) PDC - PENDING FOR COLLECTION"
		this.getData(1);
		this.getData(2);
		this.getData(3);
		this.getData(4);
		this.getData(5);
		this.getData(6);
		this.getData(7);
		this.getData(8);
		this.loading = false;
		this.StatusList = [];

		this.FieldList = [
			{ field: 'Firm', type: 'String', header: 'Firm' },
			{ field: 'Party_name', type: 'String', header: 'Party Name' },
			{ field: 'Pdctp', type: 'String', header: 'Chq. Type' },
			{ field: 'Chq_depo_dt', type: 'Number', header: 'Chq. Deposit Date ' },
			{ field: 'Chqdt', type: 'Date', header: 'Cheque Date' },
			{ field: 'Amt', type: 'Number', header: 'Amount ' },
			{ field: 'Invno', type: 'String', header: 'Inv No ' },
			{ field: 'Status', type: 'Dropdown', header: 'Status', items: this.StatusList },
			{ field: 'Custody', type: 'String', header: 'Custody' }
		]
		this.loading = false;
		this.Permission = this.hardfunc.GetLocalPermissionbyModule_id(1040);
	}
	status: boolean = false;
	
	DisplayCol() {
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = [];
		this.FieldList.map(m => this.displayedColumns.push(m.field));
		this.displayedColumns.push('Action');
	}
	ngOnDestroy(): void {
		console.clear();
	}
	getData(idx: number) {
		this.loading = true;
		this.datalist = [];
		var data: any = {};
		let url = this.hardfunc.BaseAPIUrl + "api/pdcAPI/Dash_PDC";
		var headers = new HttpHeaders({
			'content-Type': 'application/json',
			'process-Data': 'false',
			'Authorization': 'Bearer' + localStorage.getItem("UserToken")
		})
		this.repmodel.Firmx = this.hardfunc.Firmx;
		this.repmodel.Yrx = this.hardfunc.Yrx;
		this.repmodel.Grp_id = idx;
		this._http.post(url, this.repmodel, { headers: headers })
			.subscribe((data1: any) => {				
				var data = data1;				
				this.datalist[idx] = [];
				var l_grp = '###';
				this.Paid_Total = 0;
				data.map(m => {
					if (l_grp != m.Custody) {
						this.datalist[idx].push({ Grp_nm: m.Custody, Header: 0 });
						l_grp = m.Custody;
					}
					m.Header = 1;
					this.datalist[idx].push(m);
					this.Paid_Total += m.Amt;
				});
				this.datalist[idx].push({Header:2, Amt:this.Paid_Total });
			},
				err => {
					if (err.error.error_description) {
						this.openSnackBar("saverity: 'error', summary: 'Error' Message', detail: err.error.error_description", "dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					}
				}
			)
	}

	cngStatus(Pk_id: number, Status: string) {
		var data: any = {
			Pk_id: Pk_id,
			Status: Status
		};
		this.pdcservice.SavePartialData(data)
			.subscribe(
				data => {
					this.loading = false;
					this.notificationservice.success(' Status updated ');
				},
				err => {
					let errstr = this.hardfunc.getError(err);
					console.log(err);
					this.notificationservice.warn(errstr);
				}
			)
	}

	getCodeList() {
		this.loading = true;
		this.commonservice.CodeList('PDC_STAT')
			.subscribe(
				(data: any) => {
					this.status_list = JSON.parse(data);
					this.loading = false;
				},
				err => {
					this.loading = false;
					let errstr = this.hardfunc.getError(err);
					console.log(err);
					this.notificationservice.warn(errstr);
				}
			);
	}

	openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}

}
