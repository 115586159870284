import { Component, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { BgService } from './bg.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { BgListComponent } from './bg-list/bg-list.component';
import { PendordAttachComponent } from '../pendord/pendord-attach/pendord-attach.component';
import { PartyHelpComponent } from '../../shared/party-help/party-help.component';

@Component({
  selector: 'app-bg',
  templateUrl: './bg.component.html',
  styleUrls: ['./bg.component.css']
})
export class BgComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  DataForm: FormGroup;
  initialValues: any[];
  bg_banklist: any = [];
  bg_Type: any = [];
  title: string;
  BgReqBylist: any = [];

  constructor(
    private bgService: BgService,
    private notificationService: NotificationService,
    private hardfunc: HardFuncService,
    private fb: FormBuilder,
    private commonservice: CommonService,
    @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    @Optional() public dialogRef: MatDialogRef<BgListComponent>,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.DataForm = this.createFormGroup();
    this.initialValues = this.DataForm.value;
    this.loading = true;
    this.getBg_bank();
    this.getType();
    this.GetData();
    this.GetBgGrpby();
  }
  ngOnDestroy(): void {
    console.clear();
  }
  //1. api/Sales/BGsAPI/GetDatabyId --Get Databy ID.
  GetData() {
    if (this.ReceivedData) {

      if (this.ReceivedData.id == -1) {
        this.title = "ADDITION";
      }
      else {
        this.bgService.GetDatabyId(this.ReceivedData.id)
          .subscribe(
            (data: any) => {
              data.Type =  parseInt(data.Type);
              this.DataForm.patchValue(data);
              this.title = "EDITING";
              return false;
            },
            err => {
              let errstr = this.hardfunc.getError(err);
              console.log(err);
              this.notificationService.warn(errstr);
            }
          )
      }
    }
    else {
      this.ReceivedData = {};
      this.ReceivedData.id = -1;
      this.title = "ADDITION";
    }
  }
  GetBgGrpby(){
		this.bgService.GetbgByGrp()
			.subscribe((data: any) => {
				this.BgReqBylist = data;
			},
			err => {
				let errstr = this.hardfunc.getError(err);
				console.log(err);
				this.notificationService.warn(errstr);
			});
	}
  //2. api/Sales/BGsAPI/SaveData --Save BG.
  SaveData() {
    this.loading = true;
    this.bgService.SaveData(this.DataForm.value)
      .subscribe(
        data => {
          this.loading = false;
          this.ResetData();
          this.notificationService.success(' Data Saved Successfully... ');
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  onClear() {

  }
  //3. api/FinanceAPI/GetPartyDetail
  GetPartyDetail(){
    this.commonservice.GetPartyDetail(this.DataForm.get("Pmst_cod").value, this.DataForm.get("Firm").value )
    .subscribe(
        (data:any) => {
            var data1 = data[0];
            console.log(data1);
            this.DataForm.get("Party_name").patchValue(data1.PARTY_NAME.trim());
            this.DataForm.get("Party_city").patchValue(data1.PARTY_CITY.trim());
            this.DataForm.get("Party_Stat").patchValue(data1.PARTY_STAT.trim());
        },
        err => {
            
        }
    )

  }
  ResetData() {
    if (this.ReceivedData.id > 0) {
      this.dialogRef.close();
    }
    else {
      this.DataForm.reset();
      this.DataForm.patchValue(this.initialValues);
      Object.keys(this.DataForm.controls).forEach(key => {
        this.DataForm.get(key).setErrors(null);
      });
    }
  }
  HelpDialog(){
    let data = {
      Firmx:this.DataForm.get("Firm").value,
      Cond:" a.Grp in  (''B'',''H'' ) "
    }

    const dialogRef = this.dialog.open(PartyHelpComponent, {
			width: '970px',
			height: '848px',
      data
		});
		dialogRef.afterClosed().subscribe(result => {
      this.DataForm.get('Pmst_cod').patchValue(result.id);
      this.DataForm.get('Party_name').patchValue(result.nm.trim());
      this.DataForm.get('Party_city').patchValue(result.Party_city.trim());
      this.DataForm.get('Party_Stat').patchValue(result.Party_Stat.trim());
			 console.log('The dialog was closed');
		});
  }

  //3. api/Common/CodemastsAPI/GetCodeListbyCodenm
  getBg_bank() {
    this.loading = true;
    this.commonservice.CodeList('BG_BANK')
      .subscribe(
        (data: any) => {
          this.bg_banklist = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
        }

      );
  }

  //4. api/Common/CodemastsAPI/GetCodeListbyCodenm
  getType() {
    this.loading = true;
    this.commonservice.CodeList('BG_TYPE')
      .subscribe(
        (data: any) => {
          this.bg_Type = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
        }
      );
  }

  createFormGroup() {
    return this.fb.group({
      Pk_id: new FormControl(0),
      Firm: new FormControl('NFF', Validators.required),
      App_no: new FormControl('', Validators.required),
      Tendor_no: new FormControl(''),
      Ordno: new FormControl('', Validators.maxLength(50)),
      Oloc: new FormControl(''),
      Reqby: new FormControl('', Validators.required),
      Type: new FormControl(0, Validators.required),
      Party_name: new FormControl('', Validators.required),
      Party_pono: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      Party_podt: new FormControl(new Date(), Validators.required),
      Amt: new FormControl('', Validators.required),
      Bg_no: new FormControl('', Validators.required),
      Bg_date: new FormControl(new Date(), Validators.required),
      Bg_bank: new FormControl('', Validators.required),
      Bg_expdt: new FormControl(new Date(), Validators.required),
      Bg_claimdt: new FormControl(new Date(), Validators.required),
      OrdTo: new FormControl(''),
      BankChrg: new FormControl(0),
      Remarks : new FormControl(''),
      Party_city: new FormControl(''),
      Party_Stat: new FormControl(''),
      Pmst_cod: new FormControl(''),
      Bg_Rtn:new FormControl('N')
    });
  }

}
