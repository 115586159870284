import { Injectable } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment'

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(
    private hardfunc:HardFuncService ,
      private http:HttpClient
    ) { }

    GetInv_by_Ordno(data:any) {
        var headers = new HttpHeaders({
          'content-Type': 'application/json',
          'process-Data': 'false',
          'Accept': 'application/json',      
          'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
        })
        data.Firmx = this.hardfunc.Firmx;
        data.Yrx = this.hardfunc.Yrx;
        let url = this.hardfunc.BaseAPIUrl+"api/Common/DocuViewerAPI/GetInv_by_Ordno"
        return this.http.post(url, data, { headers: headers });
    }


    SendEmailForOrder(data:any,xmodel:any) {
      var headers = new HttpHeaders({
        'content-Type': 'application/json',
        'process-Data': 'false',
        'Accept': 'application/json',      
        'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
      })
      data = 
      {  Ordno:xmodel.Ordno,
         Oloc:xmodel.Oloc,
         Firmx:xmodel.Firmx,
         Yrx:xmodel.Yrx
      }
      let url = this.hardfunc.BaseAPIUrl+"api/Common/DocuViewerAPI/GetInv_by_Ordno"
      return this.http.post(url, data, { headers: headers });
  }

  getacgrp(){
    let url = this.hardfunc.BaseAPIUrl + "api/AcgrpsAPI/GetAcgrps";
    return this.http.get(url,{headers:this.hardfunc.GetHeaders()});
  }
  
  getDatas(data: any) {
    //var data: any = {};
    data.Firm = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    data.Option = "D";
    // data.Fdate = Fdate;
    // data.Tdate = Tdate;
    let url = this.hardfunc.BaseAPIUrl+"api/PurCheckListAPI/InvChkList"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }
  SaveQuote(data: any){
    console.log(data);
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/quotesAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  QuoteList(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/quotesAPI/GetDatas"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  Quote1List(){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/quote1sAPI/GetDatas"
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetQuotebyId(id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/quotesAPI/GetDatabyId?id=" + id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetQuotation(id: number,Qot_Ver){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/quotesAPI/GetQuotaiton?Qotno=" + id + "&Qot_Ver=" + Qot_Ver
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetTgroup(){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/tgroupsAPI/GetDatas"
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetTgroupbyId(Grp_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/tgroupsAPI/GetDatabyId?id="+Grp_id;
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetEnqDatabySrno(Srno: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/quotesAPI/GetEnqDatabySrno?Srno="+Srno;
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  SendEmail(Quote_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/quotesAPI/QuotationEmail?Quote_id="+Quote_id;
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  EnqEmail(Quote_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/quotesAPI/Enquirymail?Quote_id="+Quote_id;
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  SaveQuotation(data: any){
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
		
    data.Firm = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    let url = this.hardfunc.BaseAPIUrl + "api/Common/EmailAPI/QuotationEmail"
    return this.http.post(url, data, { headers:headers });
  }
  quoteEmail(Quote_id: number,SenderName: string){
    // console.log(data);
    // data.Create_dt = moment(data.Create_dt).format(ddMMyyyy);
    // data.Edit_dt = moment(data.Edit_dt).format(ddMMyyyy);
    // data.Quote_dt = moment(data.Quote_dt).format(ddMMyyyy);
    // data.Quote_dt = moment(data.Edit_dt).format(ddMMyyyy);
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/quotesAPI/QuoteEmailForw?id="+Quote_id + "&SenderName=" + SenderName
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  getUserbyUsername(Username: string){
    let url = this.hardfunc.BaseAPIUrl+"api/Sales/quotesAPI/GetUserDetail?Username="+Username
    return this.http.get(url, { headers:this.hardfunc.GetHeaders() });
  }
  GetDocu(Ordno: number){
    var data: any = {};
    data.Firmx = this.hardfunc.Firmx;
    data.Ordno = Ordno
    data.Oloc = 1;
    data.Vtype = 'EN';
    let url = this.hardfunc.BaseAPIUrl + "api/DMSAPI/GetDatabyOrdno?Ordno="+Ordno+ "&Oloc="+ 1+"&Vtype="+'EN';
		return this.http.get(url,{headers:this.hardfunc.GetHeaders()});
  }
  EnqPartyMail(r1: string){    
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    let url = this.hardfunc.BaseAPIUrl + "api/Common/DocuViewerAPI/GetDocu"
    return this.http.post(url, r1, { headers: headers });    
  }
  EnqMailAtt(Enqno: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/quotesAPI/GetEnqMailAtt?Srno="+Enqno
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetSubCode(){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/quotesAPI/GetSubCode"
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetStatistics(){    
    let Firm = this.hardfunc.Firmx;
    let Yrx = this.hardfunc.Yrx;
    let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/GetStatistics?Firm="+ Firm + "&Yrx=" + Yrx
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetsalesDatabyItem(Prodgrp_id: number){    
    let data: any = {};
    data.Firm = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    data.Grp_id = Prodgrp_id;
    let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/SalesDatabyItem" 
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  SaveTempmast(data: any){      
    var headers = new HttpHeaders({ 'content-Type': 'application/json' });

    let url = this.hardfunc.BaseAPIUrl + "api/Sales/tproductsAPI/SaveData"      
		return this.http.post(url, JSON.stringify(data), { headers:headers });
  }
  GetDataMsg(){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/msgsAPI/GetDatas";
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  SaveEmailId(data: any){    
    let url = this.hardfunc.BaseAPIUrl + "api/Common/CodemastsAPI/SaveEmailId"
    return this.http.post(url, data, {headers: this.hardfunc.PostHeaders() });
  }
  GetRejection(data: any){    
    data.Firm = data.Firm;
    data.Yrx = data.Year;
    console.log(data);
    let url = this.hardfunc.BaseAPIUrl + "api/PurCheckListAPI/GetRejection"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  RejSave(data: any){             
    let url = this.hardfunc.BaseAPIUrl + "api/PurCheckListAPI/RejSaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  RejList(){
    let data: any = {};
    data.Firm = "NFF";
    // data.Yrx = Yrx;
    // data.Asnto = "null";
    let url = this.hardfunc.BaseAPIUrl + "api/PurCheckListAPI/GetRejList"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  RejListEmail(data: any){
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    let url = this.hardfunc.BaseAPIUrl + "api/Common/EmailAPI/RejectionEmail"
    return this.http.post(url, data, { headers: headers });
  }
  SaveConRw(data: any){    
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/conrwsAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  // SaveEnclosures(){
  //   let url = this.hardfunc.BaseAPIUrl + "api/Sales/conrwsAPI/SaveData"
  //   return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  // }
  GetCrData(){
    let data: any = {};
    data.Firmx = this.hardfunc.Firmx;
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/conrwsAPI/GetDatas"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  GetCRbyId(id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/conrwsAPI/GetDatabyId?id=" + id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
   CrEmail(data: any){    
    console.log(data);
    let url = this.hardfunc.BaseAPIUrl + "api/Common/EmailAPI/CrEmail"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  RejDelete(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/PurCheckListAPI/DeleteData?id=" + data.Rejection_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetCrDatabyId(Conrw_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/conrwsAPI/GetDatabyId?id=" + Conrw_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetHandlebyId(Fld_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetdatabyId?Handle_id=" + Fld_id
    return this.http.get(url, { headers: this.hardfunc.GetHeaders() });
  }
  SaveRc(data: any){
    console.log(data);
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/rc_mastsAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  GetRcList(){
    let data: any = {
      Fdate: this.hardfunc.Fdate,
      Tdate: this.hardfunc.Tdate,
      // Pmst_cod: Pmst_code
    }
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/rc_mastsAPI/GetDatas"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  // GetRcData(Pmst_code: string){
  //   let data: any = {
  //     Fdate: this.hardfunc.Fdate,
  //     Tdate: this.hardfunc.Tdate,
  //     Pmst_cod: Pmst_code
  //   }
  //   let url = this.hardfunc.BaseAPIUrl + "api/Sales/rc_mastsAPI/GetDatas"
  //   return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  // }
  GetRcById(Rc_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/rc_mastsAPI/GetDatabyId?id=" + Rc_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  HsnUpdate(Grp_id: number,Product_id: number, HsnCode: string){
    let data: any = {};
      data.Grp_id = Grp_id;
      data.Product_id = Product_id;
      data.Hsn_code = HsnCode;      
      let url = this.hardfunc.BaseAPIUrl + "api/PrdGroupsAPI/PrdgroupUpd";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  GstperUpdate(Grp_id: number,Product_id: number, GSTPer: number){
    let data: any = {};
      data.Grp_id = Grp_id;
      data.Product_id = Product_id;
      data.GSTPer = GSTPer;
      let url = this.hardfunc.BaseAPIUrl + "api/PrdGroupsAPI/PrdgroupUpd";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
}
