import { Component, OnInit, Inject, Optional } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MAT_DIALOG_DATA, MatSnackBar, MatTableDataSource } from '@angular/material';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-jw-summary-item',
  templateUrl: './jw-summary-item.component.html',
  styleUrls: ['./jw-summary-item.component.css']
})
export class JwSummaryItemComponent implements OnInit {

  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  constructor(private hardfunc: HardFuncService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private _http: HttpClient,
              private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.GetJwSummaryItem();
  }
  DispalyCol() {
    this.displayedColumns = ['Pmst_cod', 'Party_name', 'Issue', 'Receipt', 'Balance'];
  }
  GetJwSummaryItem() {
    let url = this.hardfunc.BaseAPIUrl + "api/InventoryDashboard/JwSummaryItem?id=" + this.ReceivedData.id;
    this._http.post(url, null)
      .subscribe((data: any) => {
        this.datalist = data;
        console.log(this.datalist);
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DispalyCol();
      },
        err => {
          if (err.error.error_description) {
            this.openSnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.openSnackBar("Error in getting data, Please Check Server Connectivity...", "dismiss")
          }
        });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
