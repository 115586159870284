import { Component, OnInit } from '@angular/core';
import { ManufacturingService } from '../manufacturing.services';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { PparaCreateComponent } from '../ppara-create/ppara-create.component';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
@Component({
  selector: 'app-ppara-crud',
  templateUrl: './ppara-crud.component.html',
  styleUrls: ['./ppara-crud.component.css']
})
export class PparaCrudComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];

  constructor(private mnfservice: ManufacturingService,
              private dialog: MatDialog,
              private hardfunc: HardFuncService,
              private notificationaservice: NotificationService) { }

  ngOnInit() {
    this.GetDatas();
  }
  DisplayCol(){
    this.displayedColumns = ['Ppara_id','Pgroup_id','grp_nm','Para_nm','Action'];
  }
  GetDatas(){
    this.mnfservice.GetPparaData()
      .subscribe((data: any) => {
        this.datalist = data;
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DisplayCol();
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        this.notificationaservice.warn(errstr);
      });
  }
  OpenDialog(Ppara_id: number, Para_nm: string,grp_nm: string,Pgroup_id: number){
    const dialogRef = this.dialog.open(PparaCreateComponent, {
      width: '500px',
      data: { Ppara_id: Ppara_id, Para_nm: Para_nm, Grp_nm: grp_nm, Pgroup_id: Pgroup_id }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  AddPpara(Ppara_id: number){
    const dialogRef = this.dialog.open(PparaCreateComponent, {
      width: '500px',
      data: { Ppara_id: Ppara_id }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
} 
