import { DecimalPipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, Validators, FormBuilder, FormControl, AbstractControl } from '@angular/forms'
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { BankEntryService } from './bank-entry.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { removeAllListeners } from 'process';

@Component({
  selector: 'app-bank-entry',
  templateUrl: './bank-entry.component.html',
  styleUrls: ['./bank-entry.component.css']
})

export class BankEntryComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  DataForm: FormGroup;
  hospitalForm: any = [];
  FormData: any = [];
  ToDo_CRDB: any = [];
  T_Camt: number;
  T_Damt: number;
  text: number;
  repmodel: any = {};
  datalist: any = [];
  ploading: boolean = false;
  PartyData: any = [];
  filteredOptions: any = [];
  Srl21: number = 1;
  initialValues : any [];
  
  constructor(private fb: FormBuilder,
    private hardfunc: HardFuncService,
    private _http: HttpClient,
    private _snackBar: MatSnackBar,
    @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    private bankService: BankEntryService,
    private notificationService: NotificationService,
    private commonservice: CommonService
  ) { }

  ngOnInit() {
    this.DataForm = this.createFormGroup();
    this.initialValues = this.DataForm.value;
    this.GetParty(this.hardfunc.Firmx, this.hardfunc.Yrx);
    this.party_auto();
    this.increment();
  }
  ngOnDestroy(): void {
    console.clear();
  }
  increment() { //test_Srl21 = 50; credit_amt = 0;
    let party_arr: FormArray = this.DataForm.controls['items'] as FormArray;
    this.Srl21 = 1;    
    //let test_Srl21 = 50;
    for (let Party_control of party_arr.controls) {
      let Party_group: FormGroup = Party_control as FormGroup;

      let test = Party_group.controls["Srl21"].value;

      this.Srl21 = test += 1
    }
  }
  SaveData() {
    this.loading = true;
    this.bankService.SaveData(this.DataForm.value)
      .subscribe(
        data => {
          this.loading = false;
          this.notificationService.success(" Data Saved Successfully... ");
          this.ResetData();
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  ResetData() {
      this.DataForm.reset();
      this.DataForm.patchValue(this.initialValues);
      Object.keys(this.DataForm.controls).forEach(key => {
        this.DataForm.get(key).setErrors(null);
      });
      Object.keys(this.DataForm.controls['items'] as FormArray).forEach(key => {
        this.DataForm.controls['items'].get(key).setErrors(null);
      });
  }
  
  createFormGroup() {
    return this.fb.group({
      trdate1: new FormControl(new Date()),  //trdate1
      Curr_Amt: new FormControl(''),
      Bank_Code: new FormControl(''),
      Bank_Name: new FormControl(''),
      Conv_Rate: new FormControl(0),
      Currency: new FormControl(''), //curr
      TrType1:2,
      Page1:0, // add
      Srl11:0, // add
      descri1: new FormControl(''),  //descri1
      T_Damt: [0, Validators.required],
      T_Camt: [0, Validators.required],
      items: this.fb.array([this.createItem()]),
    }, { validators: matchtotal });
  }
  
  createItem(): FormGroup {
    return this.fb.group({
      Srl21:[this.Srl21],  // increment
      Crdb: ['C', Validators.required],
      credit: [0],  //credit
      debit: [0],  //debit
      Currency: [''], //curr
      Conv_Rate: [0],
      Party_name: ['', Validators.required],
      Party_Amt: [{ value: '', disabled: true }],
    });
  }
  validationMessages = {
    // 'Party_name': {
    //   'required': 'Party Name is required' //,for second Validation
    // },
    'Currency': {
      'required': 'Currency is required'
    },
    'Conv_Rate': {
      'required': 'Convert Rate is required'
    },
    'GroupItem': {
      'mismatch': 'Credit and Debit Total do not Match'
    }
  }
  formErrors = {
    'Currency': '',
    'Conv_Rate': '',
    'GroupItem': ''
  }


  logValidationErrors(group: FormGroup = this.DataForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const AbstractControl = group.get(key);

      this.formErrors[key] = '';
      if (AbstractControl && !AbstractControl.valid &&
        (AbstractControl.touched || AbstractControl.dirty)) {
        const messages = this.validationMessages[key];

        for (const errorkey in AbstractControl.errors) {
          if (errorkey) {
            this.formErrors[key] += messages[errorkey] + ' ';
          }
        }
      }

      if (AbstractControl instanceof FormGroup) {
        this.logValidationErrors(AbstractControl);
      }
    })
  }

  addNext() {
    (this.DataForm.controls['items'] as FormArray).push(this.createItem())
    this.party_auto();
    this.increment();
  }

  deleteRow(i: number) {
    (this.DataForm.controls['items'] as FormArray).removeAt(i);
    this.DoTotal();
  }
    
  party_auto() {
    let party_arr: FormArray = this.DataForm.controls['items'] as FormArray;
    for (let Party_control of party_arr.controls) {
      let Party_group: FormGroup = Party_control as FormGroup;
      Party_group.controls['Party_name'].valueChanges.subscribe(response => {
        this._filter(response);
      });
    }
  }

  DoTotal() {
    let aFormArray: FormArray = this.DataForm.controls['items'] as FormArray;
    this.T_Damt = 0
    this.T_Camt = 0
    for (let c of aFormArray.controls) {
      let d: FormGroup = c as FormGroup;
      let credit_amt = Number(d.controls["credit"].value) as number;
      let debit_amt = Number(d.controls["debit"].value) as number;

      this.T_Damt += debit_amt;
      this.T_Camt += credit_amt;
      //medObj.name.push(c.controls['MedName'].value);
    }
    this.DataForm.controls['T_Damt'].patchValue(this.T_Damt);
    this.DataForm.controls['T_Camt'].patchValue(this.T_Camt);
  }
  GetParty(Firm: string, Yr: string) {
    this.ploading = true;
    this.commonservice.GetPartyList(Firm, Yr)
      .subscribe(
        (data: any) => {
          this.PartyData = JSON.parse(data);
          this.PartyData = this.PartyData.map((x) => {
            return {
              nm: x.nm,
              id: x.id
            }
          });
          //this.PartyData.map((res:[]) => res.map(item => item["PARTY_NAME"]) )
          //this.filteredOptions = this.PartyData;
          this.ploading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
          this.ploading = false;
        });

  }
  onClear(){
    
  }
  private _filter(value: string) {
    if (!value) {
      this.filteredOptions = [];
      return;
    }
    if (value.length < 3) {
      this.filteredOptions = [];
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredOptions = this.PartyData.filter(option => option.nm.toLowerCase().includes(filterValue));
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}

function matchtotal(group: FormGroup): { [Key: string]: any } | null {
  //const tot_db = group.get('T_Damt');
  //const tot_cr = group.get('T_Damt');

  if (group.controls['T_Damt'].value === group.controls['T_Camt'].value && group.controls['T_Damt'].value > 0) {
    return null;
  } else {
    return { 'mismatch': true }
  }
}
