import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ManufacturingService } from '../manufacturing.services';
import { Sort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { MatPaginator } from '@angular/material';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { AdminService } from '../../admin/admin.service';
import { MatDialog } from '@angular/material';
import {ThemePalette} from '@angular/material/core';

export interface Task {
  name: string;
  Checkbox: boolean;
  color: ThemePalette;
}
@Component({
  selector: 'app-grn-list',
  templateUrl: './grn-list.component.html',
  styleUrls: ['./grn-list.component.css']
})
export class GrnListComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  GrnFormArray: Array<any> = [];
  isChecked: boolean = false;
  
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  DataForm: FormGroup;
  
  constructor(private Manufacturing: ManufacturingService, 
              private _snackBar: MatSnackBar,
              private notificationService: NotificationService,
              private hardfunc: HardFuncService,
              private fb: FormBuilder,
              private adminservice: AdminService,
              private dialog:MatDialog) { }

  ngOnInit() {
    //this.getData();
    this.loading = false;
    this.DataForm = this.createFormGroup();
  }
  ngOnDestroy(): void {
    console.clear();
  }
  DisplayCol() {	
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['Grnno','Srno','Invdts','itcode','short_nm','dqnty','Edqnty','Action'];
	}
  //========= Start Select All ============
  task: Task = {
    name: 'Select All',
    Checkbox: false,
    color: 'primary',
  };
  allComplete: boolean = false;
  updateAllComplete() {
    this.allComplete = this.datalist != null && this.datalist.every(t => t.Checkbox);
  }

  someComplete(): boolean {
    if (this.datalist == null) {
      return false;
    }
    return this.datalist.filter(t => t.Checkbox).length > 0 && !this.allComplete;
  }

  setAll(Checkbox: boolean) {
    this.allComplete = Checkbox;
    if (this.datalist == null) {
      return;
    }
    this.datalist.forEach(t => (t.Checkbox = Checkbox));
  }
  //========= End Select All ============

  //1. api/Store/grnsAPI/GetDatas"
    ReceivedGRN(){
      this.loading = true;
      console.log(this.DataForm.value);
      this.Manufacturing.ReceivedGRN(this.DataForm.value)
      .subscribe(data => {
        console.log(data);
        this.datalist = data;
        this.loading = false;
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.datalist.map(m=> {
            if (m.En === 'Y'){
              m.Checkbox = true;
            }
        })
        this.DisplayCol();
        // this.dataSource.paginator = this.paginator;
      },
      err=> {
        this.loading = false;
        let errstr =  this.hardfunc.getError(err);
        console.log(err);
        this.notificationService.warn(errstr);
        
      }); 
    }
    //2. api/Store/grnsAPI/ImportGRN
    SaveGRN(){
    this.loading = true;
     let data = this.datalist.filter(f=> f.Checkbox === true);
     data.map(m=> {
          m.Firmx = this.hardfunc.Firmx;
     });
     this.Manufacturing.SelectedGRN(data)
      .subscribe(data => {
        this.ReceivedGRN();
        this.loading=false;
        this.notificationService.success(" Data saved Successfully... ");
      },
      err=> {
        this.loading = false;
        let errstr =  this.hardfunc.getError(err);
        console.log(err);
        this.notificationService.warn("Error while importing ... "+errstr);
        
      }); 
    }

    //3. api/ProductsAPI/ChangeStatus
    IgnoreItemPerman(Itcode:number,str1:string){
      this.adminservice.openConfirmDialog("Are you sure you want to Ignore this ?")
      .afterClosed().subscribe(res =>{
        if(res){
          this.loading = true;
          const dialogRef = this.Manufacturing.Ignore_Items(Itcode,str1)
          .subscribe(
            data => {
              this.notificationService.success(" Data saved Successfully... ");
              this.ReceivedGRN();
              this.loading=false;
            },
            err => {
              console.log(err);
              this.notificationService.warn("Error in deleting data ...");
              this.loading=false;
            }
          )
        }
      });
    }
    createFormGroup() {
      return this.fb.group({
        Status: new FormControl('P'),
      });
    }
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    openSnackBar(message: string, action: string) {
      this._snackBar.open(message, action, {
      duration: 2000,
      });
    }
    
}
