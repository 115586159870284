import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grn',
  templateUrl: './grn.component.html',
  styleUrls: ['./grn.component.css']
})
export class GrnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
