import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'inr'})
export class IndianCurrencyPipe implements PipeTransform {
  transform(value: number, args: string[]): any {
        if (value == null){
           return '';     
        }

        if (! isNaN(value)) {

            if (value == 0)
            {
                return '0.00';
            }
            else
            {
            var currencySymbol = '₹';
            //var output = Number(input).toLocaleString('en-IN');   <-- This method is not working fine in all browsers!           
            var result = value.toString().split('.');

            var lastThree = result[0].substring(result[0].length - 3);
            var otherNumbers = result[0].substring(0, result[0].length - 3);
            if (otherNumbers != '')
                lastThree = ',' + lastThree;
            var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

            if (result.length > 1) {
                output += "." + result[1].substring(0,2);
            }  
            else{
                output += ".00" ;
            }             

            if (value < 0)
            {
                return "("+output+")"
            }
            return   output;
            // + currencySymbol;
            }
        }

  }
}