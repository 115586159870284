import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-emd-man',
  templateUrl: './emd-man.component.html',
  styleUrls: ['./emd-man.component.css']
})
export class EmdManComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit() {
  }
  ngOnDestroy(): void {
    console.clear();
  }

}
