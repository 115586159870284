import { Component, OnInit, Optional, Inject, ViewChild } from '@angular/core';
import { PurchaseService } from '../../purchase.service';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatPaginator } from '@angular/material';
import { Sort, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-purdialog',
  templateUrl: './purdialog.component.html',
  styleUrls: ['./purdialog.component.css']
})
export class PurdialogComponent implements OnInit {
  
  loading: boolean = false;
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private purchaseService: PurchaseService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getdata();
  }
  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['Srno1','Prod_name', 'Desc1','Bsctot_c','Examt_c', 'Atamt_c', 'Taxamt_c'];
    }
  getdata() {
		this.loading = true;
		this.datalist = [];
		this.purchaseService.PurBySrno(this.ReceivedData.id)
			.subscribe(
				data => {
					this.loading = false;
          this.datalist = data;
          this.dataSource = new MatTableDataSource(<any>this.datalist);
					this.DisplayCol();
				},
				err => {
					if (err.error.error_description) {
						this.openSnackBar(err.error.error_description, "dismiss");
					}
					else {
						this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					}
				}
			)
	}
  openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}
}
