import { Component, OnInit, ViewChild } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatDialog } from '@angular/material';
import { MatPaginator } from '@angular/material';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { StoreProdComponent } from '../store-prod.component';
import { CommonService } from 'src/app/Services/Common/common.service';
import { StoreProductService } from '../store-product.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { ManufacturingService } from 'src/app/Views/manufacturing/manufacturing.services';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  repmodel: any = {};
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  HelpList: any = [];
  DataForm: FormGroup;
  PrdGroupList: any = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private hardfunc: HardFuncService,
              private _http: HttpClient,
              private _snackBar: MatSnackBar,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private _cservice: CommonService,
              private StoreprodService: StoreProductService,
              private notificationService: NotificationService,
              private ManufacturingService: ManufacturingService) { }

  ngOnInit() {
    // this.GetProductById();    
    this.DataForm = this.createFormGroup();
    this.ItemGroup();
    this.GetData();
  }
  DisplayCol() {	
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['Product_id','Short_nm','Action'];
	}
  ItemGroup(){
    this.repmodel.CG = 'A';
    this.repmodel.Status = 'P';
    this._cservice.PrdGroupList()
      .subscribe(data => {
        this.PrdGroupList = data;        
        this.loading = false;
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }
  disableField(checked) {
      if (!checked) {
        this.DataForm.value.Prodgrp_id;
      } else {
        this.DataForm.value.Prodgrp_id;
      }
  }

  StockYN(Product_id: number, YesNo: boolean){
    let str1: string;
    if(YesNo === true){
      str1 = "Y";
    }else{
      str1 = "N";
    }
    this.loading = true;
        const dialogRef = this.ManufacturingService.Ignore_Items(Product_id,str1)
        .subscribe(
          data => {
            this.notificationService.success(" Data saved Successfully... ");
          },
          err => {
            console.log(err);
            this.notificationService.warn("Error in deleting data ...");
          }
        )
  }

  GetData() {
    this.loading = true;
		this.datalist = [];    
      let prdgrp_id = this.DataForm.get("Prodgrp_id").value;
      this.StoreprodService.getDatas(prdgrp_id)
        .subscribe(
          (data1: any) => {
            data1.map(m => {
              if (m.En === 'Y'){
                m.Check= true;
            }
          });
            this.datalist = data1;
            console.log(this.datalist);
            this.dataSource = new MatTableDataSource(<any>data1);
            this.dataSource.paginator = this.paginator;
            this.DisplayCol();
            this.loading = false;
          },
            err => {
              let errstr = this.hardfunc.getError(err);
              this.notificationService.warn(errstr);
            }
          )
  }
  UpdDivision(Product_id: number, Division: string){    
    this.ManufacturingService.UpdDivision(Product_id, Division)
      .subscribe((data: any) => {        
        this.notificationService.success("Data Saved Sucessfully... ");
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        this.notificationService.warn(errstr);
      });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  openDialog(Pk_id: number): void {
		const dialogRef = this.dialog.open(StoreProdComponent, {
			width: '800px',
      height: '300px',
			data: { id: Pk_id }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  createFormGroup() {
    return this.fb.group({
      Opstock: new FormControl(''),
      Prod_code: new FormControl(),      
      Prodgrp_id: new FormControl("*All*")
    });
  }
}
