import { Component, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { FdrService } from './fdr.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FdrListComponent } from './fdr-list/fdr-list.component';
import * as moment from 'moment';
import { CommonService } from 'src/app/Services/Common/common.service';


@Component({
  selector: 'app-fdr',
  templateUrl: './fdr.component.html',
  styleUrls: ['./fdr.component.css']
})
export class FDRComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  repmodel: any = {};
  DataForm: FormGroup;
  title: string;
  initialValues: any[];
  newDate = new Date();
  BankList: any = [];
  Days: number = 0;

  constructor(private hardfunc: HardFuncService,
    private _http: HttpClient,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private fdrSerivce: FdrService,
    private notificationService: NotificationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    @Optional() public dialogRef: MatDialogRef<FdrListComponent>,
    private commonservice: CommonService,
    ) { }

  ngOnInit() {
    this.GetData();
    this.DataForm = this.createFormGroup();
    this.initialValues = this.DataForm.value;
    this.loading = true;
    this.getfdrList();
  }
  ngOnDestroy(): void {
    console.clear();
  }
  GetData() {
  if (this.ReceivedData) {

    if (this.ReceivedData.id == -1) {
      this.title = "ADDITION";
    }
    else {
      this.fdrSerivce.GetDatabyId(this.ReceivedData.id)
        .subscribe(
          (data: any) => {
            data.Dstatus = data.Dstatus.trim();
            this.DataForm.patchValue(data);
            this.title = "EDITING";
            return false;
          },
          err => {
            let errstr = this.hardfunc.getError(err);
            console.log(err);
            this.notificationService.warn(errstr);
          }
        )
    }
  }
  else {
    this.ReceivedData = {};
    this.ReceivedData.id = -1;
    this.title = "ADDITION";
  }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }


  DoCalc() {
    // Maturity Date 
    // let Period = this.DataForm.controls.Period.value;
    // matDate = moment(matDate).add(Period,peri);
    // this.DataForm.controls.Matdt.patchValue(new Date(matDate));
    
    // // Maturity Value
    // let Pamt = this.DataForm.controls.Amt.value;
    // let Roi = this.DataForm.controls.Roi.value;
    //  let Days:number = moment(this.DataForm.controls.Matdt.value).diff(this.DataForm.controls.Fdrdt.value,'days');
    //  this.DataForm.controls.Period_d.patchValue(Days);
    // let Mamt =  Math.round(Pamt+ ((Pamt*Roi*Days)/36500));
    // this.DataForm.controls.Matval.patchValue(Mamt);
   // const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const oneDay = 24 * 60 * 60 * 1000;
    let Fdrdt = this.DataForm.controls.Fdrdt.value;
    let matdt = this.DataForm.controls.Matdt.value;
    const diffDays = Math.round(Math.abs((Fdrdt - matdt) / oneDay));
    this.DataForm.controls.Period_d.patchValue(diffDays);
  }

  SaveFDR() {
    let Fdrdt = this.hardfunc.hsConvDt(this.DataForm.controls.Fdrdt.value);
    let matdt = this.hardfunc.hsConvDt(this.DataForm.controls.Matdt.value);
    this.DataForm.controls.Fdrdt.patchValue(Fdrdt);
    this.DataForm.controls.Matdt.patchValue(matdt);   
    this.fdrSerivce.SaveData(this.DataForm.value)
      .subscribe(
        data => {
          this.loading = false;
          this.ResetData();
          this.GetData();
          this.notificationService.success(' Data Saved Successfully... ');
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  ResetData() {
    if (this.ReceivedData.id > 0) {
      this.dialogRef.close();
    }
    else {
      this.DataForm.reset();
      this.DataForm.patchValue(this.initialValues);
      Object.keys(this.DataForm.controls).forEach(key => {
        this.DataForm.get(key).setErrors(null);
      });
    }
  }
  getfdrList() {
    this.loading = true;
    this.commonservice.CodeList('FDR_BANK')
      .subscribe(
        (data: any) => {
          this.BankList = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
        }
      );
  }
  onClear(){
    
  }
  createFormGroup() {
    return this.fb.group({
      Fd_id: new FormControl(-1),
      Firm: new FormControl('NFF', Validators.required),
      Bank_id: new FormControl('',Validators.required),
      Fdrno: new FormControl('',Validators.required),
      Amt: new FormControl(0,[Validators.min(1),Validators.required ]),
      Fdrdt: new FormControl(new Date()),
      Peri: new FormControl('Y'),
      Period: new FormControl(0),
      Period_y: new FormControl(0),
      Period_m: new FormControl(0),
      Period_d: new FormControl(0),
      Roi: new FormControl(0,[Validators.min(0.01),Validators.required ] ),
      Matval: new FormControl(0,[Validators.min(1),Validators.required ]),
      Matdt: new FormControl(new Date()),
      Remarks: new FormControl(''),
      Liendept: new FormControl(''),
      Dstatus: new FormControl('')
    });
  }



    
    
}
