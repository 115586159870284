import { Injectable } from '@angular/core';
//import * as domHelper from '../../helpers/dom.helper';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as moment from 'moment'
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HardFuncService {
  CompName : string ;
  CompAdd : string ;
  BaseAPIUrl : string ;
  UserName : string ;
  Login_id: string;
  EmailSign: string;
  UserToken : string ;
  SessionStartTime : Date;
  UserImage:string;
  Ip_Addr:string;
  Firmx : string ;
  Yrx : string ;
  Dir :string = "N";
  Mkt : string = "N";
  Fdate : Date = new Date();
  Tdate : Date;
  public PermittedModules: any = [];
  LastRefresh:string;
  
  constructor(private http: HttpClient) {
    //this.BaseAPIUrl = "http://localhost:11677/";
    //this.BaseAPIUrl = "http://192.168.0.70/";
    //this.BaseAPIUrl = "https://api.newage-india.in/";
    this.BaseAPIUrl = environment.BaseAPIUrl;

    let myItem = localStorage.getItem("UserName");
    if (myItem != null) {
      this.UserName = myItem;
    }
    let myItem2 = localStorage.getItem("UserToken");
    if (myItem2 != null) {
      this.UserToken = myItem2;
    }
    let myItem3 = localStorage.getItem("Login_id");
    if (myItem3 != null) {
       this.Login_id = myItem3;
     }
    
    let myItem4 = localStorage.getItem("SessionStartTime");
    if (myItem4 != null) {
      this.SessionStartTime = moment(myItem4).toDate();
    }

  //   this.UserName = (localStorage.getItem("UserName") ?? null);
  //   this.UserToken = localStorage.getItem("UserToken") ?? null;
  //   this.Login_id = localStorage.getItem("Login_id") ?? null;
  //   const sessionStartTime = localStorage.getItem("SessionStartTime");
  //   this.SessionStartTime = sessionStartTime ? moment(sessionStartTime).toDate() : null;
 }

  ChangeServer(val:string){
    // switch (val){
    //    case '1' :
    //       this.BaseAPIUrl = "http://localhost:11677/";
    //       break;
    //     case '2' :
    //         this.BaseAPIUrl = "http://ecampus.cusmc.org/";
    //         break;
    //     case '3' :
    //       //this.BaseAPIUrl = "http://localhost:11677/";  
    //      // this.BaseAPIUrl = "https://117.220.241.98:90/";
    //       this.BaseAPIUrl = "https://api.newage-india.in/";
    //         break;
    //     case '4' :
    //       //this.BaseAPIUrl = "http://localhost:4200/sales/enqreports/";  
    //       this.BaseAPIUrl = "api/EnqAPI/AllEnqs";
    //         break;
    //     default:
    //       this.BaseAPIUrl = "http://117.220.241.98:90/";
    //       //this.BaseAPIUrl = "https://api.newage-india.in/";
    //       break;    
    // }
    //localStorage.setItem("Server",val);
  }

  ChangeFirm(val:string){
    localStorage.setItem("Firmx",val);
    this.Firmx = val;
  }
  
  SetPermittedModules(data:any){
    this.PermittedModules = [];

    data.map(m=> {
      this.PermittedModules.push({Wmodule_id:m.wmodule_id, Permission:m.Permission});
    });
    // console.log(this.PermittedModules);
  }

  GetLocalPermissionbyModule_id(id: any) {
    var data = this.PermittedModules.filter(w => w.Wmodule_id === id )[0];
    if (!data)
    {
      return 'NNNNNNNNNN';
    }
    else
    {
      return data.Permission;
    }
  }

  ChangeYear(val:string){      
    localStorage.setItem("Yrx",val);
    this.Yrx = val;    
    this.Fdate = moment("20"+val.substring(0,2)+"-04-01").toDate();
    this.Tdate = moment("20"+val.substring(2,4)+"-03-31").toDate();     
  }

  PostHeaders(){
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Accept': 'application/json',      
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    return headers;
  }

  GetHeaders(){
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    return headers;
  }

  DTOC(sdt: Date) {

    let day = (sdt.getDate()).toString().padStart(2,'0');
    let month = (sdt.getMonth() + 1).toString().padStart(2,'0');
    let year = sdt.getFullYear().toString();
    let dts = day + '/' + month + '/' + year;
    return dts;
  }

  getError(err : any)
  {
      let retstr = "";
      switch (err.status) {
        case 400: // Bad Request
          retstr = "Bad Request " + err.error.Message;;
          break;
        case 401: // Unauthorized
          retstr = "Unauthorized user   ";
          break;
        case 403: // Forbidden
          retstr = "Forbidden ";
          break;
        case 404: // Not Found
          retstr = "Not Found "
          break;
        case 405: // Method not allowed
          retstr = "Method not allowed ";
          break;    
        case 500: // Internal server Error
          retstr = "Internal server Error ";
          break; 
        case 0: // Internal server Error
          retstr = "Server connectivity might be loss, please try again !!! ";
          break;     
        default:
          retstr = "Unknown Error ";
          break;
      }
      return retstr;
  }

  getFileType(ext : string)
  {
      let retstr = "";
      switch (ext) {
        case '.pdf': 
          retstr = "application/pdf";
          break;
          case '.gif': 
          retstr = "image/gif";
          break;
          case '.jpg': 
          retstr = "image/jpeg";
          break;
          case '.mp3': 
          retstr = "audio/mpeg";
          break;
          case '.png': 
          retstr = "image/png";
          break;
          case '.xls': 
          retstr = "application/vnd.ms-excel";
          break;
          case '.xlsx': 
          retstr = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          break;
          case '.doc': 
          retstr = "application/msword";
          break;
          case '.docx': 
          retstr = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          break;
        default:
          retstr = "";
          break;
      }
      return retstr;
  }

  hsConvDt(dt) {
    dt = new Date(dt);
    let hoursDiff = dt.getHours() - dt.getTimezoneOffset() / 60;
    let minutesDiff = (dt.getHours() - dt.getTimezoneOffset()) % 60;
    dt.setHours(hoursDiff);
    dt.setMinutes(minutesDiff);
    return dt
}



 //base64 encoded ascii to ucs-2 string
 
}


