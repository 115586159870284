import { Component, OnInit } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';

@Component({
  selector: 'app-firm-year',
  templateUrl: './firm-year.component.html',
  styleUrls: ['./firm-year.component.css']
})
export class FirmYearComponent implements OnInit {
  
  firmx: string;
  yrx: string;
  constructor(private hardfunc: HardFuncService) { }

  ngOnInit() {
    let myItem1 = localStorage.getItem("Firmx");
    if (myItem1 != null && myItem1.length > 0) {
      this.firmx = myItem1;
    } else{
      this.firmx =  "NFF"; 
      
    }
    this.hardfunc.ChangeFirm(this.firmx);
    

    let myItem2 = localStorage.getItem("Yrx");
    if (myItem2 != null && myItem2.length > 0) {
      this.yrx = myItem2;
    }
    else{
      this.yrx = "2021";
    }
  }

  FirmSelection(event: any) {
    localStorage.setItem("Firmx",this.firmx );
    this.hardfunc.ChangeFirm(this.firmx);
  }

  YearSelection(event: any) {
    localStorage.setItem("Yrx",this.yrx );
    this.hardfunc.ChangeYear(this.yrx);
  }

}
