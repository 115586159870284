import { Component, OnInit } from '@angular/core';
import { SalesService } from '../../sales.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { PendordDialogComponent } from '../../pendord/pendord-dialog/pendord-dialog.component';
import { T } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-rej-list',
  templateUrl: './rej-list.component.html',
  styleUrls: ['./rej-list.component.css']
})
export class RejListComponent implements OnInit {

  loading: boolean = false;
  rejlist: any = [];
  repmodel: any = {};
  Username: string;
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  DeleteRightsYN: any = [];
  DelRights: string;

  constructor(private SalesService: SalesService,
              private hardfunc: HardFuncService,
              private NotificationSerivce: NotificationService,
              private dialog: MatDialog,
            ) { }

  ngOnInit() {
    // this.repmodel.Firm = "NFF";
    // this.repmodel.Yrx = "2425";
    this.Username = this.hardfunc.UserName;
    this.DeleteRightsYN = ['Alpesh','hms','hardik','hiren'];
    this.DeleteRightsYN.forEach(element => {
        if(this.Username === element){
          this.DelRights = element;
        }
    });
    this.GetData(); 
    this.loading = true;   
  }

  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['Firm', 'PartyName', 'InvDetail','TransDet','Remarks','Action'];
  }
  GetData(){
    this.loading = false;
    this.SalesService.RejList()
      .subscribe((data: any) => {
        
        this.datalist = data;        
        this.datalist.map(r => {
          if(r.Notes){
            let remark = r.Notes.replace("#$#","\n");
            remark = remark.replace("#$#","\n");            
            r.Notes = remark;            
          }                    
        });
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DisplayCol();
        this.loading = true;
      },
      err => {
        this.loading = true;
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.NotificationSerivce.warn(errstr);
      });
  }
  RejDelete(data: any){
    this.SalesService.RejDelete(data)
      .subscribe((data: any) => {        
        this.NotificationSerivce.warn("Data Deleted Successfully...");
        this.GetData();
      },
      err => {
        this.loading = true;
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.NotificationSerivce.warn(errstr);
      });
  }
  ChatDialog(Rejection_id: number, Vtype: string){    
    let width = '1000px';
    let height = '500px';    
    const dialogRef = this.dialog.open(PendordDialogComponent, {
      width: width,
      height: height,
      data: { Ordno: Rejection_id, Oloc: 1, Notes_type: Vtype }      
    });
    dialogRef.afterClosed().subscribe(result => {      
      console.log('The dialog was closed');
      this.GetData();
    });
  }
}
