import { Component, OnInit, ViewChild } from '@angular/core';
import { ManufacturingService } from '../manufacturing.services';
import { Sort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { MatPaginator } from '@angular/material';

@Component({
  selector: 'app-grn1-list',
  templateUrl: './grn1-list.component.html',
  styleUrls: ['./grn1-list.component.css']
})
export class Grn1ListComponent implements OnInit {
  
  loading: boolean = false;
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private Manufacturing: ManufacturingService,
              private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getData();
  }
  DisplayCol() {	
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['Pmst_cod','Pono','Qnty','Pqnty','Bqnty','Dqnty','Dunit','Rate'];
	}
  getData() {
    this.loading = true;
    this.datalist = [];
    var data: any = {};
    this.Manufacturing.getDatasGrn1(data)
      .subscribe(
        data => {
      this.datalist = data;
      this.loading = false;
      this.dataSource = new MatTableDataSource(<any>this.datalist);
      this.DisplayCol();
      this.dataSource.paginator = this.paginator;
    },
    err => {
      if (err.error.error_description) {
        this.openSnackBar(err.error.error_description,"dismiss");
      }
      else {
        this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
      }
    }
    )
    }	  
    openSnackBar(message: string, action: string) {
      this._snackBar.open(message, action, {
      duration: 2000,
      });
    }

}
