import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TempmastComponent } from '../../shared/tempmast/tempmast.component';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-quote-crud',
  templateUrl: './quote-crud.component.html',
  styleUrls: ['./quote-crud.component.css']
})
export class QuoteCrudComponent implements OnInit {
  
  Enqno: number;
  constructor(private dialog: MatDialog,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.Enqno = Number(params.get('Enqno'));
    });
  }
  QuotConf(Vtype: string, Fldname: string){
    const dialogRef = this.dialog.open(TempmastComponent, {
      width: '1400px',
      height: '800px',
      data: { Vtype: Vtype, Fldname: Fldname }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed ');
    });
  }
}
