import { Component, Inject, OnInit, Optional } from '@angular/core';
import { SalesService } from '../sales.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-quote-history',
  templateUrl: './quote-history.component.html',
  styleUrls: ['./quote-history.component.css']
})
export class QuoteHistoryComponent implements OnInit {

  loading: boolean = false;
  Quotehistory: any = [];

  constructor(private SalesService: SalesService,
              private hardfunc: HardFuncService,
              private NotificationService: NotificationService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any) { }

  ngOnInit() {
    this.loading = true;
    console.log(this.ReceivedData.data);
    this.GetDatas();
  }
  GetDatas() {
    this.loading = true;
    let data: any = {};
    data.Qot_no = this.ReceivedData.Qot_no;
    data.Status = "N";
    this.SalesService.QuoteList(data)
      .subscribe((data: any) => {
        console.log(data);
        this.Quotehistory = data;
        this.loading = false;
      },
      err => {
        this.loading = false;
        let errstr = this.hardfunc.getError(err);
        this.NotificationService.warn(errstr);
      });
  }
}
