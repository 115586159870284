import { Component, OnInit, Optional, Inject, Input } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators, FormControlName } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { PartyHelpComponent } from '../../shared/party-help/party-help.component';
import { MatDialog, MatTableDataSource, MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@angular/material';
import { ManufacturingService } from '../../manufacturing/manufacturing.services';
import { HttpClient } from '@angular/common/http';
import { SalesService } from '../sales.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TempmastComponent } from '../../shared/tempmast/tempmast.component';
import { TproductComponent } from '../../shared/tproduct/tproduct.component';
import { CommonService } from 'src/app/Services/Common/common.service';
import { QuoteListComponent } from '../quote-crud/quote-list/quote-list.component';
import { EnqHelpComponent } from '../enq-help/enq-help.component';
import { AdminService } from '../../admin/admin.service';
import { DmsService } from 'src/app/Services/Common/dms.service';
import * as moment from 'moment'

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css']
})
export class QuoteComponent implements OnInit {

  loading: boolean = false;
  DataForm: FormGroup;
  filteredOptions: any = [];
  datalist: any = [];
  repmodel: any = {};
  StateList: any = [];
  DirectorList: any = [];
  MarketingList: any = [];
  FieldMKtList: any = [];
  TotalAmt: number;
  TaxTotal: number;
  TotalPOvalue: number;
  initialValues: any[];
  public editor = ClassicEditor;
  title: string;
  Totalbal: number;
  PrdGroupList: any = [];
  TgroupList: any = [];
  TgroupId: any = [];
  //Hsncode: string[] = [];
  docu: string;
  idx: any = [1, 2, 3, 4];
  docuAtt: any = [];
  datasheet: string;
  drawing: string;
  @Input('Enqno') Enqno:  number;
  EMailAtt: any = [];
  SubCodedata: any = [];
  SubCodelist: any = [];
  SubCode: any = [];
  WithoutTax: number;
  WithTaxTot: number;
  msgFromChild1 = [];
  //inputvar ="Hi i am parent";
  
  constructor(private fb: FormBuilder,
              private hardfunc: HardFuncService,
              private dialog: MatDialog,
              private MnfSerivce: ManufacturingService,
              private _http: HttpClient,
              private SalesService: SalesService,
              private NotificationService: NotificationService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              @Optional() public dialogRef: MatDialogRef<QuoteListComponent>,
              private _cservice: CommonService,
              private _snackBar: MatSnackBar,
              private adminservice: AdminService,
              private dmsservice: DmsService) { }

  ngOnInit() {
    this.DataForm = this.createFormGroup();
    this.initialValues = this.DataForm.value;
    this.loading = true;
    //this.GetPrdDetails();
    this.getState();
    this.getHandles();
    this.getMarketing();
    this.GetFieldMkt();
    this.GetDatas();
    this.GetTgroup();
    this.EnqMailAtt(); 
    this.GetSubCode();
    if(this.Enqno){
      this.DataForm.get("Qot_no").patchValue(this.Enqno);
      this.EnqSrno();
    }
  }
  HelpDialog() {
    let data = {
      Firmx: this.hardfunc.Firmx,
      Cond: " a.Grp in  (''B'',''H'' ) "
    }
    const dialogRef = this.dialog.open(PartyHelpComponent, {
      width: '970px',
      height: '848px',
      data
    });
    dialogRef.afterClosed().subscribe(result => {
      this.DataForm.get('Pmst_cod').patchValue(result.id);
      this.DataForm.get('Party_name').patchValue(result.id +'-'+ result.nm.trim());
      this.DataForm.get('Party_add').patchValue(result.Party_adr1.trim() + '-' + result.Party_adr2.trim() + '-' + result.Party_adr3.trim()
        + '-' + result.Party_city.trim() + '-' + result.Party_Stat.trim());
      this.DataForm.get('Party_city').patchValue(result.Party_city.trim());
      this.DataForm.get('Party_state').patchValue(result.Party_Stat.trim());
    });
  }
  
  EnqMailAtt(){
    this.SalesService.EnqMailAtt(this.Enqno)
      .subscribe((data: any) => {
        this.EMailAtt = data;
        this.LoadImages()
      });
  }
  
  LoadImages() {
    this.EMailAtt.map(m=> 
      {  
        m.FileType = "OTH";       
      }  
    )
  }
  LoadImagebyId(objFile: any){
    this.loading = true;
    this.dmsservice.LoadImagebyId(objFile.Docu_id)
    .subscribe(
      (data1: any) => {
        objFile.ImageStr = 'data:image/jpeg;base64,'+data1;
        this.loading = false;
      },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn("Error in image loading "+errstr);
        }
      )
  }
    
  DoTotal() {        
    let aFormArray: FormArray = this.DataForm.controls['SubDatas'] as FormArray;
    this.WithoutTax = 0;
    this.WithTaxTot = 0;
    this.TotalAmt = 0;
    this.TaxTotal = 0;
        
    for (let c of aFormArray.controls) {      
      let d: FormGroup = c as FormGroup;
            
      let QntyVal = Number(d.controls["Qnt"].value) as number;
      let UnitPriceVal = Number(d.controls["Basic"].value) as number;
      let IgstVal = Number(d.controls["Gst_per"].value) as number;      

      // this.WithoutTax = QntyVal * UnitPriceVal || 0;
      // this.WithTaxTot = QntyVal * UnitPriceVal * IgstVal / 100 || 0;    
      // d.controls['WithoutTax'].patchValue(this.WithoutTax);
      // d.controls['WithTaxTot'].patchValue(this.WithTaxTot);        

      this.TotalAmt += QntyVal * UnitPriceVal || 0;
      this.TaxTotal += QntyVal * UnitPriceVal * IgstVal / 100 || 0;    
      d.controls['Netrate'].patchValue(this.TotalAmt + this.TaxTotal);           
    } 
    // this.TotalPOvalue = this.TotalAmt + this.TaxTotal;         
  }
  // 9. api/Common/StateMastsAPI/GetDatas API For --Get State Master
  getState() {
    let url = this.hardfunc.BaseAPIUrl + "api/Common/StateMastsAPI/GetDatas";
    this._http.post(url, this.repmodel)
      .subscribe((data1: any) => {
        this.StateList = data1;
      }, err => {
        console.log(err);
      });
  }
  AddContDetail() {
    (this.DataForm.controls['QuoteConts'] as FormArray).push(this.ContactDetailsForm());
  }
  addItems() {
    (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubdata());
  }
  DeleteRow(i: number) {
    if(this.ReceivedData.id > 1)
    {
      //(this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);
      let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
      let formgroup = (formarray.at(i) as FormGroup);
      formgroup.get('Noted').patchValue('D');
      this.DoTotal();      
    }
    else{
      (this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);
      this.DoTotal();
    }
  }
  ContDetremove(i: number){
    (this.DataForm.controls['QuoteConts'] as FormArray).removeAt(i);
  }
  GetDatas() {
    this.loading = true;
    if (this.ReceivedData) {
      if (this.ReceivedData.id == -1) {
        this.title = "Quotation Entry";
      }
      else {
        this.loading = true;
        this.SalesService.GetQuotebyId(this.ReceivedData.id)
          .subscribe((data: any) => {
            console.log(data);
            this.title = "Quotation Editing";
            if(this.ReceivedData.Status === 'R'){
              data.EditRevise = "R"; 
            }else{
              data.EditRevise = 'E';
            }
            data.SelectPDF = "S";
            this.DataForm.patchValue(data);   
            const formarr = new FormArray([]);
              data.SubDatas.map(m => {
                formarr.push(
                  this.fb.group({
                    Item: m.Item,
                    Qnt: m.Qnt,
                    Basic: m.Basic,
                    Gst_per: m.Gst_per,
                    Netrate: m.Netrate,
                    Quote1_id: m.Quote1_id,
                    Quote_id: m.Quote_id,
                    Product_id: m.Product_id,
                    Prodgrp_id: m.Prodgrp_id,
                    Tproduct_id: m.Tproduct_id,
                    DocuAtt: m.DocuAtt,
                    Hsncode: "",
                    DocuName: "",
                    Noted: "",                    
                  })
                );
              });
              this.DataForm.setControl("SubDatas", formarr);
              
              const formarr1 = new FormArray([]);
              data.QuoteConts.map(m => {
                formarr1.push(
                  this.fb.group({
                    Pk_id: m.Pk_id,
                    Quote_id: m.Quote_id,                    
                    Cname: m.Cname,
                    Email: m.Email,
                    Phone: m.Phone,
                    Desgauthor: m.Desgauthor
                  })
                );
              });              
              this.DataForm.setControl("QuoteConts", formarr1);
              this.title = "Quotation Editing";
              this.loading = false;
              return false;
              
          },
            err => {
              this.loading = false;
              let errstr = this.hardfunc.getError(err);
              this.NotificationService.warn(errstr);
            }
          )
      }
    }
    else {
      this.ReceivedData = {};
      this.ReceivedData.id = -1;
      this.title = "Quotation Entry";
    }
  }

  msgToParent() {
    console.log(this.DataForm.value);
    this.msgFromChild1.push(this.DataForm.value);
    console.log(this.msgFromChild1);
    // this.msgFromChild1.push(Product_nm);
    // this.msgFromChild1.push(this.ReceivedData)
  }
  EnqSrno(){
    let Srno: number;
    Srno = this.DataForm.get("Qot_no").value;    
    this.SalesService.GetEnqDatabySrno(Srno)
      .subscribe((data: any) => {        
        this.DataForm.get('Pmst_cod').patchValue(data.Pmst_cod);
        this.DataForm.get('Party_name').patchValue(data.Party_name.trim());
        this.DataForm.get('Party_add').patchValue(data.Addr1);
        this.DataForm.get('Party_city').patchValue(data.City.trim());
        this.DataForm.get('Party_state').patchValue(data.State.trim());

        //Contact Detail 
        const formarr2 = new FormArray([]);
                formarr2.push(
                  this.fb.group({
                    Cname: data.Poc,
                    Email: data.Email_id,
                    Phone: data.Cell,
                    Desgauthor: data.Desgauthor
                  })
                );
        this.DataForm.setControl("QuoteConts", formarr2);
        
        //Item
        const formarr3 = new FormArray([]);
                  data.SubDatas.map(m => {
                    formarr3.push(
                      this.fb.group({
                        Item: m.Prodname,
                        Qnt: m.Qnt,  
                        Prodgrp_id: m.Prodgrp_id,    
                        Basic: 0,
                        Gst_per: 0,
                        Hsncode: "",
                        DocuName: "",
                        Netrate: 0,
                        DocuAtt: ""
                      })
                    );
                  });              
        this.DataForm.setControl("SubDatas", formarr3);
      });
  }
  SaveData() {
    this.loading = true;       
    var QuoteNo = this.DataForm.get("Qot_no").value;
    var PartyName = this.DataForm.get("Party_name").value;
    console.log(this.DataForm.value);
    this.SalesService.SaveQuote(this.DataForm.value)
      .subscribe((data: any) => {        
        let headers = this.hardfunc.PostHeaders();        
        
        //Create PDF
        let url = this.hardfunc.BaseAPIUrl + "api/Sales/quotesAPI/CreateQuotePDF1";
        this._http.post(url, data, {headers: headers, responseType: 'blob' as 'json'})
          .subscribe((data1: any) => {              
              const data = window.URL.createObjectURL(data1);

              var link = document.createElement('a');
              link.href = data;
              link.target = "_blank";
              var CurrDate = new Date();
              
              link.download = QuoteNo +" "+ PartyName + " " + moment(CurrDate).format("DD/MM/yyyy") +" "+ ".pdf";
              link.click();
              setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
              }, 100);               
          });
          this.loading = false;
        this.NotificationService.success("Data Saved Sucessfully...");
        this.ResetData();        
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        console.log(err);
        this.NotificationService.warn(errstr);
        this.loading = false;
      }
    );
  }
  
  
  ItemGroup(){
    this.repmodel.CG = 'A';
    this.repmodel.Status = 'P';
    this._cservice.PrdGroupListAll()
      .subscribe(data => {
        this.PrdGroupList = data;
        this.loading = false;
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }
  GetSubCode(){
    this.SalesService.GetSubCode()
      .subscribe((data: any) => {
        this.SubCodedata = data;
        let Codenm = "#";
        this.SubCode = [];
        data.map(m => {
          if(Codenm !== m.Cd)
          {
            this.SubCode.push({Cd: m.Cd});
          }
          Codenm = m.Cd;
        });
      });
  }
  SubList(Cd: string){
    this.SubCodelist = [];
    this.SubCodedata.map(m => {
      if(Cd === m.Cd){
        this.SubCodelist.push({Vals: m.Vals});
      }
    });
  }
  
  EnqHelp(){
    const dialogRef = this.dialog.open(EnqHelpComponent, {
      width: '900px',
      //data: {T_price: T_price}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
        this.DataForm.get('Qot_no').patchValue(result.Srno);
        this.DataForm.get('Pmst_cod').patchValue(result.Pmst_cod);
        this.DataForm.get('Party_name').patchValue(result.Party_name.trim());
        this.DataForm.get('Party_add').patchValue(result.Addr1);
        this.DataForm.get('Party_city').patchValue(result.City.trim());
        this.DataForm.get('Party_state').patchValue(result.State.trim());

        //Contact Detail 
        const formarr2 = new FormArray([]);
                formarr2.push(
                  this.fb.group({
                    Cname: result.Poc,
                    Email: result.Email_id,
                    Phone: result.Cell,
                    Desgauthor: result.Desgauthor
                  })
                );
        this.DataForm.setControl("QuoteConts", formarr2);
        
        //Item
        const formarr3 = new FormArray([]);
                  result.Subdatas.map(m => {
                    formarr3.push(
                      this.fb.group({
                        Prodgrp_id: 0,
                        Item: m.Prodname,
                        Qnt: m.Qnt,   
                        Basic: 0,
                        Hsncode: "",
                        DocuName: "",
                        Gst_per: 0,
                        DocuAtt: "",
                        Netrate: 0
                      })
                    );
                  });              
        this.DataForm.setControl("SubDatas", formarr3);
        
      });
  }
  GetTgroup(){
    this.loading = true;
    this.SalesService.GetTgroup()
      .subscribe(data => {
        this.TgroupList = data;
        console.log(this.TgroupList);
        // let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
        // let formgroup = (formarray.at(index) as FormGroup);
        // formgroup.get('Product_id').patchValue(result.Tproduct_id);
        // console.log('The dialog was closed');
        this.loading = false;
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }
  
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  
  // 8. api/HandlesAPI/GetData -- API For -- Get Directore Type=D
  getHandles() {
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=D";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.DirectorList = data1;
      }, err => {
        console.log(err);
      });
  }

  // 8. api/HandlesAPI/GetData -- API For -- Get Marketing Type=M
  getMarketing() {
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=M";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.MarketingList = data1;
      }, err => {
        console.log(err);
      });
  }
  GetFieldMkt() {
    let url = this.hardfunc.BaseAPIUrl + "api/UsersAPI/getAllUsersSelectedMkt?Mkt=Y";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.FieldMKtList = data1;
      }, err => {
        console.log(err);
      });
  }
  TempmastDialog(Vtype: string,Fldname: string){
    
    let width = '1000px';
    let height = '500px';
    if(Vtype === 'ET') {
      width = '1000px';
      height = '300px';
    }      
    const dialogRef = this.dialog.open(TempmastComponent, {
      width: width,
      height: height,      
      data: {Vtype: Vtype, Fldname: Fldname}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.Fldname.trim() === 'T_price'){
        this.DataForm.get('T_price').patchValue(result.Tempstr);
      }
      if(result.Fldname.trim() === 'T_payment'){
        this.DataForm.get('T_paym').patchValue(result.Tempstr);
      }
      if(result.Fldname.trim() === 'T_warranty'){
        this.DataForm.get('T_guarantee').patchValue(result.Tempstr);
      }
      if(result.Fldname.trim() === 'T_validity'){
        this.DataForm.get('T_validity').patchValue(result.Tempstr);
      }      
    });
  }
  GetTgroupbyId(Tgroup_id: number,i: number){
    this.loading = true;
    
    this.SalesService.GetTgroupbyId(Tgroup_id)
      .subscribe((data: any) => {
        let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
        let formgroup = (formarray.at(i) as FormGroup);
        formgroup.get('Gst_per').patchValue(data.Gst_per);

        formgroup.get('Hsncode').patchValue(data.Hsn_code);

        this.loading = false;
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }
  TproductDialog(grp_id: number,grp_nm: string, i: number){
    const dialogRef = this.dialog.open(TproductComponent, {
      width: '1200px',
      height: '700px',
      data: {Grp_id: grp_id,Grp_nm: grp_nm}
    });
    dialogRef.afterClosed().subscribe(result => {      
      console.log(result);
      let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
      let formgroup = (formarray.at(i) as FormGroup);
      formgroup.get('Item').patchValue(result.Product_nm);
      formgroup.get('Tproduct_id').patchValue(result.Tproduct_id);      
      formgroup.get('Prodgrp_id').patchValue(result.Prodgrp_id);
      
      this.docu = "";
      this.datasheet = "";
      this.drawing = "";
      result.Doculist.map(e => {       
          if(e.Checked === true){
            this.docu += e.Docu_id + ",";
            if(e.Docu_type === 142){
              this.datasheet = "Data Sheet";
            }
            if(e.Docu_type === 141){
              this.drawing = "UL/FM Certificate";
            }
          }
          
      });
      console.log(this.datasheet + this.drawing);

      formgroup.get('DocuAtt').patchValue(this.docu);
      formgroup.get('DocuName').patchValue(this.datasheet + "," + this.drawing);
      
      //formgroup.get('Document').patchValue(this.datasheet + " , " + this.drawing);

      console.log('The dialog was closed');
    });
  }
  createFormGroup() {
    return this.fb.group({
      Firm: new FormControl('NFF'),
      Quote_id: new FormControl(-1),
      Enq_no: new FormControl(0, Validators.required),
      Ref_no: new FormControl(''),
      Qot_no: new FormControl('',Validators.required),
      Qot_ver: new FormControl(0),
      Desi_auth: new FormControl(''),      
      Party_ref: new FormControl(''),
      Proj_nm: new FormControl(''),
      Cons_nm: new FormControl(''),
      Quote_dt: new FormControl(new Date()),
      Pmst_cod: new FormControl(''),
      Party_name: new FormControl('',[Validators.required,Validators.maxLength(100)]),
      Party_add: new FormControl('',[Validators.required,Validators.maxLength(500)]),
      Party_city: new FormControl('',[Validators.required,Validators.maxLength(100)]),
      Party_state: new FormControl('',[Validators.required,Validators.maxLength(50)]),
      QuotAtt: new FormControl(''),
      pdc_code: new FormControl(''),
      pdc_vals: new FormControl(''),       
      T_price: new FormControl('Ex-works basis'),
      T_gst: new FormControl('GST shall be charged extra as mentioned in the Quote or as applicable at the time of supply.'),
      Transport: new FormControl('Freight charges shall be charged / payable extra at actual by the buyer.'),      
      T_paym: new FormControl('Advance along with the Purchase Order'),      
      T_deli: new FormControl(''), //If input any value disp. on paragragh
      T_guarantee: new FormControl('12 months'),   
      T_validity: new FormControl('15 days'),                    
      // Notes: new FormControl(''),
      Status: new FormControl('D'),
      Pdf_tit: new FormControl(''),
      Intrem: new FormControl(''),
      EditRevise: new FormControl(''), // Only For Revise
      SelectPDF: new FormControl('S'),
      SubDatas: this.fb.array([this.CreateSubdata()]),
      QuoteConts: this.fb.array([this.ContactDetailsForm()]),
    })
  }
  get SubDatas(): FormArray {
    return this.DataForm.get("SubDatas") as FormArray
  }
  CreateSubdata(): FormGroup {
    return this.fb.group({
      Quote1_id: new FormControl(-1),
      Quote_id: new FormControl(-1),
      Prodgrp_id: new FormControl(-1),
      Qot_no: new FormControl(),
      Qot_ver: new FormControl(),
      Product_id: [''],
      Tproduct_id: new FormControl(-1),
      Item: [''],
      Qnt: new FormControl('',Validators.required),
      Basic: new FormControl('',Validators.required),
      Gst_per: new FormControl('',Validators.required),
      Netrate: new FormControl(0),
      DocuAtt: new FormControl(''),
      Hsncode: new FormControl(''),  // Only display HsnCode
      DocuName: new FormControl(''), // Only display Document Name
      Noted:  new FormControl(''),
      WithoutTax: new FormControl(0),
      WithTaxTot: new FormControl(0)
    });
  }
  ContactDetailsForm(): FormGroup {
    return this.fb.group({
      Pk_id: new FormControl(-1),
      Quote_id: new FormControl(-1),
      Cname: new FormControl(''),
      Email: new FormControl('',[Validators.pattern(
        /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})(; ?\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3}))*$/)]),
      Phone: new FormControl(),
      Desgauthor: new FormControl('')
    });
  }
  
  ResetData() {
    if (this.ReceivedData.id > 0) {
      this.dialogRef.close();
    }
    else {
    this.DataForm.reset();
    this.DataForm.patchValue(this.initialValues);
    Object.keys(this.DataForm.controls).forEach(key => {
      this.DataForm.get(key).setErrors(null);
    });
    }
  }
  private _filter(value: string) {
    if (!value) {
      this.filteredOptions = [];
      return;
    }
    if (value.length < 3) {
      this.filteredOptions = [];
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredOptions = this.datalist.filter(option => option.nm.toLowerCase().includes(filterValue));
  }

}
