import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';
import { MatDialog, MatDialogRef, MatOptionSelectionChange, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AdminService } from '../../admin.service';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';

@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.css']
})
export class ModuleListComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];

  constructor(
    public dialog: MatDialog,
    private _http:HttpClient, 
    private service: CommonService,
    private notificationService : NotificationService,
    private adminservice: AdminService,
    private _snackBar: MatSnackBar,
    private hardfunc: HardFuncService) { }

  ngOnInit() {
  }

  DisplayCol() {
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['Wmodule_id' , 'Wmodule_nm' , 'Url','Parent_id', 'Action'];
	  }
  getData() {
    this.loading = true;
    this.datalist = [];

    this.adminservice.getAllModules()
    .subscribe(
      (data:any) => {
        this.datalist = data;
        this.loading = false;
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DisplayCol();
      },
      err => {
        this.loading = false;
        let errstr =  this.hardfunc.getError(err);
        console.log(err);
        this.notificationService.warn(errstr);
        
      }
    )
  }
  openDialog(Wmodule_id: number){

  }
  openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
		  duration: 2000,
		});
	  }
}
